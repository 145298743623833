<template>
    <Modal width="600" borderLeft="4px solid #2ecc71" @close="$emit('close')">
        <div slot="header">
            <h1
                class="text-blue-base uppercase mb-1 py-1 border-b-2 border-blue-base"
            >
                Novo Anexo
            </h1>
        </div>
        <div slot="body">
            <form v-on:submit.prevent="onFormSubmit()">
                <div class="w-full mb-5 mt-5">
                    <label class="form-label text-sm" for="attachment">
                        Anexo
                    </label>
                    <label
                        :class="[
                            errors && errors.attachment
                                ? 'bg-red-100 border-red-400'
                                : '',
                            'form-input-file group'
                        ]"
                    >
                        <span class="py-3">{{
                            attachment
                                ? attachment.name
                                : "Nenhum arquivo selecionado"
                        }}</span>
                        <span
                            class="bg-gray-300 group-hover:bg-gray-400 rounded-r py-3 px-3"
                        >
                            Escolher arquivo
                        </span>
                        <input
                            @change="attach"
                            type="file"
                            class="hidden"
                            id="attachment"
                            aria-describedby="attach-help"
                            :required="requireAttachment"
                        />
                    </label>
                    <div v-if="errors && errors.attachment">
                        <p
                            v-for="(error, index) in errors.attachment"
                            v-bind:key="index"
                            class="italic text-red-base"
                        >
                            {{ error }}
                        </p>
                    </div>
                    <p
                        v-else-if="requireAttachment"
                        class="text-red-base italic"
                    >
                        O campo anexo é obrigatório.
                    </p>
                    <p id="attach-help" class="text-gray-600 text-xs italic">
                        Formatos suportados: pdf, png, jpg.
                    </p>
                </div>

                <div class="w-full mt-5 mb-5">
                    <label class="form-label text-sm" for="description">
                        Descrição do anexo
                    </label>
                    <input
                        :class="[
                            errors && errors.description
                                ? 'bg-red-100 border-red-400'
                                : '',
                            'form-input'
                        ]"
                        v-model="description"
                        id="description"
                        type="text"
                    />
                    <div v-if="errors && errors.description">
                        <p
                            v-for="(error, index) in errors.description"
                            v-bind:key="index"
                            class="italic text-red-base"
                        >
                            {{ error }}
                        </p>
                    </div>
                </div>
            </form>
        </div>
        <div slot="footer">
            <div class="d-flex items-center justify-content-center">
                <button
                    class="btn btn-outline-secondary close-modal mr-2"
                    @click="$emit('close')"
                >
                    Cancelar
                </button>

                <button
                    v-bind:disabled="
                        attachment == null && description.length < 3
                    "
                    v-on:click="onFormSubmit"
                    class="btn btn-primary disable-onload"
                    type="submit"
                >
                    Enviar
                </button>
            </div>
        </div>
    </Modal>
</template>
<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/core/components/Modal";

@Component({
    components: {
        Modal
    }
})
export default class NewAttachment extends Vue {
    @Prop()
    ticket;

    constructor() {
        super();
        this.errors = null;
        this.attachment = null;
        this.description = "";
    }

    attach(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.attachment = files[0];
    }

    onFormSubmit() {
        this.$Progress.start();
        let formData = new FormData();

        formData.append("attachment", this.attachment);
        formData.append("description", this.description);
        formData.append("ticket_id", this.ticket.id + "");
        //formData.append("link", this.$store.state.muse.link.id + "");

        this.$http.post("/api/support/attachment", formData).then(
            response => {
                this.onSuccess(response);
            },
            response => {
                this.errors = response.data;
                this.onError(response);
            }
        );
    }

    onSuccess(response) {
        this.$Progress.finish();
        this.$toast.success({
            title: "OK",
            message: response.data.success
        });
        this.$emit("close");
    }

    onError(response) {
        this.$Progress.finish();
        if (response.response.data.errors) {
            this.$toast.error({
                title: "",
                message: response.response.data.errors.attachment[0],
                icon: "fa fa-exclamation"
            });
        }
    }
}
</script>
<style lang="scss" scoped>
small,
#description {
    margin-bottom: 15px;
}
</style>
