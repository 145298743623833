var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-between w-full my-8 border-b-2 border-blue-base bg-white shadow-lg"},[_c('span',{staticClass:"mt-2 text-base uppercase text-blue-base"},[_vm._v(" Informações trabalhistas ")]),_vm._t("default")],2),_c('div',{staticClass:"grid gap-5 grid-cols-1 md:grid-cols-12 px-3"},[_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" CPF/CNPJ * ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.document.length == 11 ? _vm.mask(_vm.beneficiarioTrabalhador.document, "###.###.###-##") : _vm.mask(_vm.beneficiarioTrabalhador.document, "##.###.###/####-##"))+" ")]:[_c('span',[_c('TheMask',{staticClass:"h-auto py-2 rounded form-input",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioTrabalhadorForm.hasErrors &&
                                !_vm.beneficiarioTrabalhadorForm.document
                        },attrs:{"mask":['##.###.###/####-##', '###.###.###-##'],"required":""},on:{"input":_vm.checkCPFValid},model:{value:(_vm.beneficiarioTrabalhadorForm.document),callback:function ($$v) {_vm.$set(_vm.beneficiarioTrabalhadorForm, "document", $$v)},expression:"beneficiarioTrabalhadorForm.document"}}),(_vm.beneficiarioTrabalhadorForm.hasErrors &&
                        !_vm.beneficiarioTrabalhadorForm.document
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2),_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Nome da Instituição ")]),_c('span',[(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.nminstituicao)+" ")]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioTrabalhadorForm.nminstituicao),expression:"beneficiarioTrabalhadorForm.nminstituicao"}],staticClass:"h-auto py-2 rounded form-input",class:{
                        'bg-red-100 border-red-400':
                            _vm.beneficiarioTrabalhadorForm.hasErrors &&
                            !_vm.beneficiarioTrabalhadorForm.nminstituicao
                    },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioTrabalhadorForm.nminstituicao)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioTrabalhadorForm, "nminstituicao", $event.target.value)}}}),(_vm.beneficiarioTrabalhadorForm.hasErrors &&
                        !_vm.beneficiarioTrabalhadorForm.nminstituicao
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()]],2)]),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Data de admissão ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.dtadmissao)+" ")]:[_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioTrabalhadorForm.dtadmissao),expression:"beneficiarioTrabalhadorForm.dtadmissao"}],staticClass:"h-auto py-2 rounded form-input",class:{
                                    'bg-red-100 border-red-400': _vm.hasErrors && (!_vm.beneficiarioTrabalhadorForm.dtadmissao || !_vm.isValidAdmissionDate),
                                },attrs:{"type":"text","required":""},domProps:{"value":(_vm.beneficiarioTrabalhadorForm.dtadmissao)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioTrabalhadorForm, "dtadmissao", $event.target.value)},$event => _vm.validateAdmissionDateOnInput($event.target.value)]}}),(!_vm.beneficiarioTrabalhadorForm.isValidAdmissionDate && _vm.beneficiarioTrabalhadorForm.dtadmissao && _vm.beneficiarioTrabalhadorForm.dtadmissao.length === 10)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Data inválida ")]):_vm._e(),(!_vm.beneficiarioTrabalhadorForm.isValidAdmissionDate && _vm.beneficiarioTrabalhadorForm.dtadmissao.length === 8)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Data inválida ")]):_vm._e(),(_vm.beneficiarioTrabalhadorForm.hasErrors &&
                        !_vm.beneficiarioTrabalhadorForm.dtadmissao
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Profissão ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.nmprofissao)+" ")]:[_c('span',[_c('span',[_c('SearchSelect',{staticClass:"h-auto rounded form-select",class:{
                                'bg-red-100 border-red-400':
                                    _vm.beneficiarioTrabalhadorForm.hasErrors &&
                                    !_vm.beneficiarioTrabalhadorForm.profissao
                            },attrs:{"selected":_vm.beneficiarioTrabalhador.profissao,"search-keys":['profissao'],"search-remote":true,"lazy-load":true,"option-value":$event => $event.nmprofissao,"option-key":"idprofissao","name":"idprofissao","no-query-param":"","options-url":`/api/spl-navegantes/profissoes/`,"field-class":"py-2"},on:{"update:selected":function($event){return _vm.$set(_vm.beneficiarioTrabalhador, "profissao", $event)},"changed":_vm.profissaoSelected}}),(_vm.beneficiarioTrabalhadorForm.hasErrors &&
                            !_vm.beneficiarioTrabalhadorForm.profissao
                        )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)])]],2),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Salário ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.vlsalario)+" ")]:[_c('span',[_c('money',_vm._b({staticClass:"h-auto py-2 rounded form-input",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioTrabalhadorForm.hasErrors &&
                                !_vm.beneficiarioTrabalhadorForm.vlsalario
                        },attrs:{"required":""},model:{value:(_vm.beneficiarioTrabalhadorForm.vlsalario),callback:function ($$v) {_vm.$set(_vm.beneficiarioTrabalhadorForm, "vlsalario", $$v)},expression:"beneficiarioTrabalhadorForm.vlsalario"}},'money',_vm.money,false)),(_vm.beneficiarioTrabalhadorForm.hasErrors &&
                        !_vm.beneficiarioTrabalhadorForm.vlsalario
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2)]),_c('div',{staticClass:"grid gap-5 grid-cols-1 md:grid-cols-12 px-3"},[_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" CEP ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.cep ? _vm.mask(_vm.beneficiarioTrabalhador.cep, "##.###-##") : '')+" ")]:[_c('div',[_c('TheMask',{staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioTrabalhadorForm.hasErrors &&
                                !_vm.beneficiarioTrabalhadorForm.cep
                        },attrs:{"mask":['##.###-###'],"required":""},on:{"input":_vm.onCepChangeInstituicao},model:{value:(_vm.beneficiarioTrabalhadorForm.cep),callback:function ($$v) {_vm.$set(_vm.beneficiarioTrabalhadorForm, "cep", $$v)},expression:"beneficiarioTrabalhadorForm.cep"}}),(_vm.beneficiarioTrabalhadorForm.hasErrors &&
                        !_vm.beneficiarioTrabalhadorForm.cep)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2),_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Endereço ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.street)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioTrabalhadorForm.street),expression:"beneficiarioTrabalhadorForm.street"}],staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioTrabalhadorForm.hasErrors &&
                                !_vm.beneficiarioTrabalhadorForm.street
                        },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioTrabalhadorForm.street)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioTrabalhadorForm, "street", $event.target.value)}}}),(
                        _vm.beneficiarioTrabalhadorForm.hasErrors &&
                        !_vm.beneficiarioTrabalhadorForm.street
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2),_c('div',{staticClass:"col-span-1"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Número ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.number)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioTrabalhadorForm.number),expression:"beneficiarioTrabalhadorForm.number"}],staticClass:"h-auto py-2 rounded form-input mb-0",attrs:{"type":"number"},domProps:{"value":(_vm.beneficiarioTrabalhadorForm.number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioTrabalhadorForm, "number", $event.target.value)}}})])]],2),_c('div',{staticClass:"col-span-2"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Bairro ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.district)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioTrabalhadorForm.district),expression:"beneficiarioTrabalhadorForm.district"}],staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioTrabalhadorForm.hasErrors &&
                                !_vm.beneficiarioTrabalhadorForm.district
                        },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioTrabalhadorForm.district)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioTrabalhadorForm, "district", $event.target.value)}}}),(
                        _vm.beneficiarioTrabalhadorForm.hasErrors &&
                        !_vm.beneficiarioTrabalhadorForm.district
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Complemento ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.complement)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioTrabalhadorForm.complement),expression:"beneficiarioTrabalhadorForm.complement"}],staticClass:"h-auto py-2 rounded form-input mb-0",attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioTrabalhadorForm.complement)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioTrabalhadorForm, "complement", $event.target.value)}}})])]],2),_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Cidade/estado ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioTrabalhador.municipio ? _vm.beneficiarioTrabalhador.municipio.name + " / " + _vm.beneficiarioTrabalhador.municipio.state .name : "")+" ")]:[_c('div',[_c('search-select',{attrs:{"selected":_vm.beneficiarioTrabalhador.municipio,"search-keys":['name'],"search-remote":true,"lazy-load":true,"options-url":"/api/registry/location?take=50&paginate=false","option-key":"id","option-value":city =>
                            city.name +
                            ' / ' +
                            city.state.initials,"field-class":"py-2","name":"city","placeholder":"Selecione uma cidade..."},on:{"update:selected":function($event){return _vm.$set(_vm.beneficiarioTrabalhador, "municipio", $event)},"changed":_vm.cityInstituicaoSelected}}),(_vm.beneficiarioTrabalhadorForm.hasErrors && !this.beneficiarioTrabalhadorForm.municipio)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }