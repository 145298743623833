import Index from "./views/Index";
import DocumentPasses from './views/DocumentPasses';
import ShowNavegantes from "./views/ShowNavegantes";
import EditNavegantes from "./views/NavegantesEdit";
import Step01 from "./views/Step01";
import Step02 from "./views/Step02";
import Step03 from "./views/Step03";

export default [
    {
        path: "/spl-navegantes",
        name: "spl-navegantes.index",
        component: Index,
        meta: {
            forAuth: true,
            module: "spl-navegantes"
        }
    },
    {
        path: "/spl-navegantes/cadastro",
        name: "spl-navegantes.show",
        component: ShowNavegantes,
        meta: {
            forAuth: true,
            module: "spl-navegantes"
        }
    },
    {
        path: "/spl-navegantes/:id/editar",
        name: "spl-navegantes.edit",
        component: EditNavegantes,
        meta: {
            forAuth: true,
            module: "spl-navegantes"
        }
    },
    {
        path: "/spl-navegantes/:id/passes",
        name: "spl-navegantes.documents",
        component: DocumentPasses,
        meta: {
            forAuth: true,
            module: "spl-navegantes"
        }
    },
    {
        path: "/spl-navegantes/cadastro/passo01",
        name: "spl-navegantes.step01",
        component: Step01,
        meta: {
            forAuth: true,
            module: "spl-navegantes"
        }
    },

    {
        path: "/spl-navegantes/cadastro/passo02",
        name: "spl-navegantes.step02",
        component: Step02,
        meta: {
            forAuth: true,
            module: "spl-navegantes"
        }
    },

    {
        path: "/spl-navegantes/cadastro/passo03",
        name: "spl-navegantes.step03",
        component: Step03,
        meta: {
            forAuth: true,
            module: "spl-navegantes"
        }
    }
];
