var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.getters.isAuthenticated && _vm.$store.getters.isUserLoaded)?_c('div',{class:[
        _vm.fewModules ? 'md:pt-16' : 'md:pt-4',
        'bg-white md:bg-gray-lighter md:px-20'
    ]},[_c('div',{staticClass:"px-6 md:bg-white md:shadow"},[_c('div',{class:[
                _vm.fewModules
                    ? 'md:flex-col md:max-w-2xl'
                    : 'md:justify-start md:max-w-6xl',
                'mx-auto flex items-center justify-center py-4 md:px-6 border-b-2 border-blue-base'
            ]},[_c('div',{class:_vm.fewModules ? 'md:mt-8' : ''},[_c('Avatar',{attrs:{"name":_vm.$store.state.auth.user.name}})],1),_c('div',{class:_vm.fewModules ? 'md:mt-2' : ''},[(_vm.$store.state.auth.user)?_c('h1',{staticClass:"flex items-center justify-center text-lg leading-tight text-blue-base"},[_vm._v(" Olá, "+_vm._s(_vm.$store.state.auth.user.business_name || _vm.$store.state.auth.user.name)+" "),(!_vm.$store.state.auth.user.links.length)?_c('Icon',{staticClass:"h-5 ml-1",attrs:{"icon":"attention"}}):_vm._e()],1):_vm._e(),_c('h2',{class:[
                        _vm.fewModules ? 'md:text-center' : '',
                        'mt-1 text-gray-darker leading-tight text-sm'
                    ]},[(_vm.$store.getters.isOrganization)?_c('div',{staticClass:"font-extrabold"},[_vm._v(" Você está logado na conta de CNPJ a qual é usada somente para vincular/desvincular gestores, para fazer qualquer outra operação entre na sua conta de CPF. "),_c('router-link',{staticClass:"ml-1 underline cursor-pointer text-blue-base hover:text-blue-light",attrs:{"to":{ name: 'organization.managers' }}},[_vm._v(" Clique aqui para vincular gestores. ")])],1):(!_vm.$store.state.auth.user.links.length)?_c('div',{staticClass:"text-red-base md:flex"},[_c('div',[_vm._v(" Você ainda não possui nenhum vínculo. ")]),_c('router-link',{staticClass:"underline cursor-pointer md:ml-1 hover:text-red-800",attrs:{"to":{ name: 'link.create' }}},[_vm._v(" Clique aqui para fazer a solicitação. ")])],1):(!_vm.$store.state.auth.link)?_c('div',[_vm._v(" Selecione uma instituição. ")]):_c('div',[_vm._v("Selecione um módulo.")])])])]),_c('div',{class:[
                _vm.fewModules ? 'max-w-2xl' : 'md:justify-start max-w-6xl',
                _vm.modules.length === 2 ? 'md:justify-start' : '',
                'flex flex-wrap mx-2 md:mx-auto mt-4 pb-8 justify-between align-middle items-start'
            ]},[(
                    !_vm.$store.state.auth.link &&
                        _vm.$store.state.auth.user.links.length > 0
                )?_vm._l((_vm.$store.state.auth.user.links),function(link){return _c('div',{key:link.id,staticClass:"card-company",on:{"click":function($event){return _vm.selectLink(link)}}},[_c('div',{staticClass:"w-12 h-12 bg-center bg-contain border-2 rounded-full border-blue-base img-icon-company"}),_c('div',{staticClass:"name-company"},[_vm._v(" "+_vm._s(link.organization.business_name || link.organization.name)+" ")]),_c('div',{staticClass:"document-company"},[_vm._v(" "+_vm._s(link.organization.document_formatted)+" ")])])}):(_vm.modules.length > 0)?_vm._l((_vm.modules),function(module,index){return _c('ModuleButton',{key:index,class:[
                        _vm.fewModules ? '' : 'md:mr-6 md:ml-8',
                        _vm.modules.length === 2 ? 'md:mr-6' : '',
                        'mx-4 my-4'
                    ],attrs:{"name":module.name,"icon":module.icon,"route":module.route}})}):_vm._e()],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }