import Service from "./Service";

export default class RegistryService extends Service {
    constructor() {
        super();
        this.routerUser = "/api/user";
        this.routerCities = "/api/registry/location/cities";
        this.routerOrganization = "/api/registry/organization";
        this.routerDeterOrganizations = "/api/registry/organizations";
        this.routerRegistryUser = "/api/registry/User";
        this.routerResetPassoword = "/api/registry/User/reset/password";
        this.routerConfirmationPassoword =
            "/api/registry/User/resend/confirmation";
        this.result = {};
        this.code = "";
    }

    async getUserInfo(params) {
        this.result = await this.request(`${this.routerUser}/${params}`, "get");
        if (this.result["status"] === "success") {
            return this.result["response"]["data"];
        } else {
            let response = this.result.response;
            if (response.status !== 500) {
                if (response.response.data.error) {
                    this.$toast.error({
                        title: "",
                        message: response.response.data.error,
                        icon: "fa fa-exclamation"
                    });
                    return response.response.data;
                }
            }
        }
    }

    async getCities() {
        let info = await this.request(this.routerCities, "get");
        let response = info["response"];
        let status = info["status"];
        if (status === "success") {
            this.result["cities"] = response.data;
            this.result["errors"] = null;
        } else {
            this.result["cities"] = [];
            this.result["errors"] = response.data.error;
        }

        return this.result;
    }

    async getInfoOrganization(id) {
        let info = await this.request(
            `${this.routerOrganization}/${id}`,
            "get"
        );
        let response = info["response"];

        return response.data;
    }

    async getDeterInfoOrganization(id) {
        let info = await this.request(
            `${this.routerDeterOrganizations}/deter/${id}`,
            "get"
        );
        let response = info["response"];

        return response.data;
    }

    async postRecover(data) {
        let info = await this.request(this.routerResetPassoword, "post", data);
        this.$Progress.finish();
        let body = info.response.data;

        if (info.status === "success") {
            this.$toast.success({
                title: "OK",
                message: body.success
            });
            return body.success;
        } else {
            let status = info.response.status;

            if (status === 422) return status;
            this.$toast.error({
                title: "",
                message: body.error ? body.error : body.document,
                icon: "fa fa-exclamation"
            });
            return false;
        }
    }

    async postConfirmation(data) {
        let info = await this.request(
            this.routerConfirmationPassoword,
            "post",
            data
        );
        this.$Progress.finish();
        let body = info.response.data;

        if (info.status === "success") {
            this.$toast.success({
                title: "OK",
                message: body.success
            });

            return false;
        } else {
            this.$toast.error({
                title: "",
                message: body.error ? body.error : body.document,
                icon: "fa fa-exclamation"
            });

            return true;
        }
    }

    async postRegister(data) {
        let info = await this.request(this.routerRegistryUser, "post", data);
        this.$Progress.finish();
        let body = info.response.body;

        if (info.status === "success") {
            this.$toast.success({
                title: "OK",
                message: body.success
            });
            this.$router.push({ name: "login", params: {} });

            return false;
        } else {
            this.$toast.error({
                title: "",
                message: body.error ? body.error : body.document,
                icon: "fa fa-exclamation"
            });

            return body.error;
        }
    }

    async putRegistryUser(user) {
        this.$Progress.start();
        let route = "/api/registry/User/" + user.id;

        let info = [];
        info = await this.request(route, "put", user, "", true);
        this.$Progress.finish();

        let response = info.response;

        if (info.status === "success") {
            this.result.success = true;

            if (this.$store.state.auth.user.document === user.document) {
                this.$store.commit("setUser", user);
            }
        } else {
            if (response.status === 422) {
                this.result.errors = response.body;
            }
        }

        return this.result;
    }

    async putRegistryLegalInformation(userId, data) {
        this.$Progress.start();
        let route = "/api/registry/organization/" + userId;

        let info = await this.request(route, "put", data, "", true);
        this.$Progress.finish();
        let response = info.response;

        if (info.status === "success") {
            this.result.success = true;
        } else {
            if (response.status === 422) {
                this.result.errors = response.body;
            }
        }

        return this.result;
    }

    async reRegistrationData(data, checkData = true) {
        this.$Progress.start();
        let route = "/api/registry/re-registration-data";
        let typeRequest = "post";

        if (checkData) {
            route =
                "/api/registry/re-registration-data/" + data.organization_id;
            typeRequest = "get";
        }

        let info = await this.request(route, typeRequest, data, "", true);
        this.$Progress.finish();
        let response = info.response;
        if (info.status === "success") {
            this.result.success = true;
        } else {
            if (response.status === 422) {
                this.result.errors = response.body;
            }
        }
        return this.result;
    }

    async putRegistrationData(data) {
        this.$Progress.start();
        let route =
            "/api/registry/re-registration-data/" + data.organization_id;
        let typeRequest = "put";

        let info = await this.request(route, typeRequest, data, "", true);
        this.$Progress.finish();
        let response = info.response;
        if (info.status === "success") {
            this.result.success = true;
        } else {
            if (response.status === 422) {
                this.result.errors = response.body;
            }
        }
        return this.result;
    }

    async putRegistryUserContact(userId, data, bySupport = false) {
        this.$Progress.start();
        let route = "/api/registry/user/" + userId + "/contact";

        if (bySupport) {
            route = "/api/support/data-user/" + userId + "/contact";
        }

        let info = await this.request(route, "put", data, "", true);
        this.$Progress.finish();
        let response = info.response.response;

        if (info.status === "success") {
            this.result.success = true;
        } else {
            if (response.status === 422) {
                this.result.errors = response.data.errors;
            }
        }

        return this.result;
    }
}
