import { Vue, Component } from "vue-property-decorator";
import Table, { Th } from "@/core/components/Table";
import Breadcrumb from "@/core/components/Breadcrumbs";
import ManagerConfirmation from "./ManagerConfirmation";

@Component
export default class Managers extends Vue {
    constructor() {
        super();
        this.title = "Gerenciar Gestores";
        this.data = [];
        this.orderBy = {
            name: "ASC"
        };
        this.onError = {
            call: this.onErrorCallback
        };
        this.refresh = {
            call: null
        };
        this.searchText = "";
        this.requestParameter = {
            search: null
        };
    }

    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
        this.$Progress.finish();
    }

    crumbs() {
        return [
            { name: "Início", path: "index" },
            { name: "Minha Instituição", path: "organization.index" },
            { name: "Gerenciar Gestores", path: "" }
        ];
    }

    onLoad(response) {
        this.data = response.data;
    }

    searchData(e) {
        e.preventDefault();
        this.requestParameter.search = this.searchText;
        this.refresh.call();
    }

    conditionToBadge(condition) {
        if (condition === "Vínculo Ativo") {
            return "badge badge-success";
        }
        if (condition === "Vínculo Rejeitado") {
            return "badge badge-danger";
        }
        if (condition === "Vínculo Inativo") {
            return "badge badge-danger";
        }
        if (condition === "Vínculo Solicitado") {
            return "badge badge-warning";
        }
        return "badge badge-default";
    }

    render() {
        return (
            <div class="w-full p-5">
                <Breadcrumb crumbs={this.crumbs()} />
                <div class="container-fluid">
                    <Table
                        url={"/api/registry/organization/managers"}
                        onLoad={this.onLoad}
                        errorHandler={this.onError}
                        autoLoad={true}
                        orderBy={this.orderBy}
                        requestParameter={this.requestParameter}
                        refresh={this.refresh}
                    >
                        <div
                            slot={"search"}
                            class="flex flex-column items-center"
                        >
                            <div class="col-sm mb-2 text-left">
                                <h2 class="title-list">
                                    Procurar por pessoa física para vincular e
                                    conceder perfil de gestor.
                                </h2>
                            </div>
                            <div class="col-sm mb-2 flex">
                                <form onSubmit={this.searchData}>
                                    <div class="input-group flex justify-content-center items-center">
                                        <input
                                            v-model={this.searchText}
                                            placeholder={
                                                "Procurar por nome ou CPF "
                                            }
                                            class="form-control"
                                            style="margin-left: 0px; border-radius: 0.25rem 0 0 0.25rem;"
                                            type="search"
                                        />
                                        <span class="input-group-btn">
                                            <button
                                                type={"submit"}
                                                class="btn btn-primary p-2"
                                                style={
                                                    "border-radius: 0 0.25rem 0.25rem 0;"
                                                }
                                            >
                                                Pesquisar
                                            </button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <thead slot={"thead"} class="thead-light">
                            <tr>
                                <Th column={"name"} name={"Nome"} />
                                <Th column={"document"} name={"CPF"} />
                                <th>Perfis</th>
                                <th>Situação</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody slot={"tbody"} class="align-middle">
                            {this.data.map(line => {
                                return (
                                    <tr>
                                        <td>{line.name}</td>
                                        <td>{line.document_formatted}</td>
                                        <td>
                                            {line.links.length > 0 ? (
                                                line.links[0].profiles.length >
                                                0 ? (
                                                    line.links[0].profiles.map(
                                                        profile => {
                                                            return (
                                                                <span class="mr-1 badge badge-primary">
                                                                    {
                                                                        profile.name
                                                                    }
                                                                </span>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <span class="badge badge-default">
                                                        Nenhum
                                                    </span>
                                                )
                                            ) : (
                                                <span class="badge badge-default">
                                                    Nenhum
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {line.links.length > 0 ? (
                                                <span
                                                    class={this.conditionToBadge(
                                                        line.links[0].condition
                                                            .name
                                                    )}
                                                >
                                                    {
                                                        line.links[0].condition
                                                            .name
                                                    }
                                                </span>
                                            ) : (
                                                <span class="badge badge-default">
                                                    Não vinculado
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {line.links.length === 0 ? (
                                                <ManagerConfirmation
                                                    onConfirm={() => {
                                                        this.refresh.call();
                                                    }}
                                                    modal={true}
                                                    action="link"
                                                    manager={line}
                                                />
                                            ) : line.links[0].condition.name ===
                                              "Vínculo Ativo" ? (
                                                <ManagerConfirmation
                                                    onConfirm={() => {
                                                        this.refresh.call();
                                                    }}
                                                    modal={true}
                                                    action="disable"
                                                    manager={line}
                                                />
                                            ) : line.links[0].condition.name ===
                                                  "Vínculo Inativo" ||
                                              line.links[0].condition.name ===
                                                  "Vínculo Rejeitado" ? (
                                                <ManagerConfirmation
                                                    onConfirm={() => {
                                                        this.refresh.call();
                                                    }}
                                                    modal={true}
                                                    action="enable"
                                                    manager={line}
                                                />
                                            ) : line.links[0].condition.name ===
                                              "Vínculo Solicitado" ? (
                                                <div>
                                                    <ManagerConfirmation
                                                        onConfirm={() => {
                                                            this.refresh.call();
                                                        }}
                                                        modal={true}
                                                        action="accept"
                                                        manager={line}
                                                    />
                                                    <ManagerConfirmation
                                                        onConfirm={() => {
                                                            this.refresh.call();
                                                        }}
                                                        modal={true}
                                                        action="deny"
                                                        manager={line}
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                            {this.data.length === 0 ? (
                                <tr>
                                    <td colSpan={5}>
                                        Nenhuma pessoa física encontrada!
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}
