import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
class Modal extends Vue {
    @Prop({ default: 300 })
    width;
    @Prop()
    borderLeft;
    @Prop({ default: "" })
    containerClass;
    @Prop({ default: false })
    noPadding;
    @Prop({ default: false })
    noOverflowY;
    @Prop({ default: false })
    noMargin;
    @Emit()
    Close() {}

    render() {
        const containerStyle = {
            width: this.width + "px"
        };
        if (this.borderLeft) {
            containerStyle["border-left"] = this.borderLeft;
        }
        if (this.noPadding) {
            containerStyle["padding"] = 0;
        }
        return (
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div
                            style={containerStyle}
                            class={"modal-container " + this.containerClass}
                        >
                            {this.$slots["header"]}

                            <div
                                class={
                                    "modal-body " +
                                    (!this.noOverflowY
                                        ? " overflow-y-auto"
                                        : "") +
                                    (!this.noMargin ? " my-2" : " m-0")
                                }
                                id="modal-body"
                                style={this.noPaddingStyle}
                            >
                                {this.$slots["body"]}
                            </div>

                            {this.$slots.hasOwnProperty("footer") ? (
                                this.$slots["footer"]
                            ) : (
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-outline-secondary"
                                        onClick={this.Close}
                                    >
                                        Fechar
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </transition>
        );
    }
}

export default Modal;

// VUE JSX HOT LOADER //
if (module.hot) require("/home/gitlab-runner/builds/glrt-b-z/0/scmobi/scmobi-frontend-v4/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-4b43d607/Modal.jsx" });