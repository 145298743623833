<script>
import Modules from "@/domains/home/partials/Modules";

export default {
    components: {
        Modules
    }
};
</script>

<template>
    <div class="bg-white md:bg-gray-lighter">
        <Modules />
    </div>
</template>
