<template>
    <div class="w-full bg-gray-light">
        <div class="grid gap-3 px-2 my-4 lg:max-w-5xl sm:px-6">
            <Breadcrumbs :crumbs="crumbs" />
            <SystemInfo
                :helpLink="'https://ajuda.sie.sc.gov.br/categorias-artigos/linhas/'"
                :alerts="alerts"
            />
            <UpdateInfo :updates="updates" />

            <!--Exemplo de Graficos-->
            <!--            <div class="flex flex-wrap mt-2">-->
            <!--                <div class="sm:mr-2">-->
            <!--                    <div-->
            <!--                        class="bg-white shadow py-4 pl-8 pr-5 text-blue-base border-b-2 flex justify-between items-center "-->
            <!--                    >-->
            <!--                        <div class="font-medium uppercase">Frota</div>-->
            <!--                    </div>-->
            <!--                    <div-->
            <!--                        class="bg-white shadow border-t border-gray-light flex flex-col pb-2 mb-2 "-->
            <!--                    >-->
            <!--                        <div-->
            <!--                            class="title-reg px-8 py-2 flex-1 text-sm bg-gray-dark text-white"-->
            <!--                        >-->
            <!--                            Relatório de Idade da Frota-->
            <!--                        </div>-->
            <!--                        <div class=" flex">-->
            <!--                            <div class="p-4">-->
            <!--                                <div>-->
            <!--                                    <Portlet />-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                    <div-->
            <!--                        class="bg-white shadow py-4 pl-8 pr-5 text-blue-base border-b-2 flex justify-between items-center "-->
            <!--                    >-->
            <!--                        <div class="font-medium uppercase">-->
            <!--                            Índice financeiro-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div-->
            <!--                        class="bg-white shadow border-t border-gray-light flex flex-col pb-2 mb-2"-->
            <!--                    >-->
            <!--                        <div-->
            <!--                            class="title-reg px-8 py-2 flex-1 text-sm bg-gray-dark text-white"-->
            <!--                        >-->
            <!--                            Relatório de Idade da Frota-->
            <!--                        </div>-->
            <!--                        <div class="flex">-->
            <!--                            <div class="p-4">-->
            <!--                                <PortletBar />-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="mr-2">-->
            <!--                    <div-->
            <!--                        class="bg-white shadow py-4 pl-8 pr-5 text-blue-base border-b-2 flex justify-between items-center "-->
            <!--                    >-->
            <!--                        <div class="font-medium uppercase">-->
            <!--                            Índice financeiro-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div-->
            <!--                        class="bg-white shadow border-t border-gray-light flex flex-col pb-2 mb-2 justify-between"-->
            <!--                    >-->
            <!--                        <div-->
            <!--                            class="title-reg px-8 py-2 flex-1 text-sm bg-gray-dark text-white"-->
            <!--                        >-->
            <!--                            Relatório de Idade da Frota-->
            <!--                        </div>-->
            <!--                        <div class="flex">-->
            <!--                            <div class="p-4 align-middle">-->
            <!--                                <PortletMixedChart />-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="">-->
            <!--                    <div-->
            <!--                        class="bg-white shadow py-4 pl-8 pr-5 text-blue-base border-b-2 flex justify-between items-center "-->
            <!--                    >-->
            <!--                        <div class="font-medium uppercase">-->
            <!--                            Lorem Ipsum-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div-->
            <!--                        class="bg-white shadow border-t border-gray-light flex flex-col pb-2 mb-2 justify-between"-->
            <!--                    >-->
            <!--                        <div-->
            <!--                            class="title-reg px-8 py-2 flex-1 text-sm bg-gray-dark text-white"-->
            <!--                        >-->
            <!--                            Teste Teste-->
            <!--                        </div>-->
            <!--                        <div class="flex">-->
            <!--                            <div class="p-4 align-middle">-->
            <!--                                <PortletPolarChart />-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import SystemInfo from "@/core/components/portlets/SystemInfo";
import UpdateInfo from "@/core/components/portlets/UpdateInfo";
// import Portlet from "@/domains/user/portlets/Portlet";
// import PortletBar from "@/domains/user/portlets/PortletBar";
// import PortletMixedChart from "@/domains/user/portlets/PortletMixedChart";
// import PortletPolarChart from "@/domains/user/portlets/PortletPolarChart";

export default {
    components: {
        Breadcrumbs,
        SystemInfo,
        UpdateInfo
    },
    data() {
        return {
            title: "Usuário SCmobi",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Linhas", path: "user.index" }
            ],
            alerts: [
                "Mantenha seus dados de linhas atualizados.",
                "Neste componente, você podera registrar suas rotas e pontos de sessões referentes às linhas"
            ],
            updates: [
                "Gere o Certificado de Linha escolhendo a linha na lista e clicando no botão Certificado."
            ]
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>
