import Index from "./views/Index";
import Managers from "./views/Managers";
import EditInfo from "./views/EditInfo";
import ChangePassword from "./views/ChangePassword";
import Subordinates from "./views/Subordinates";

export default [
    {
        path: "/instituicao",
        name: "organization.index",
        component: Index,
        meta: {
            forAuth: true,
            module: "organization"
        }
    },
    {
        path: "/instituicao/gestores",
        name: "organization.managers",
        component: Managers,
        meta: {
            forAuth: true,
            module: "organization"
        }
    },
    {
        path: "/instituicao/editar-dados",
        name: "organization.edit",
        component: EditInfo,
        meta: {
            forAuth: true,
            module: "organization"
        }
    },
    {
        path: "/instituicao/alterar-senha",
        name: "organization.password",
        component: ChangePassword,
        meta: {
            forAuth: true,
            module: "organization"
        }
    },
    {
        path: "/instituicao/gerir-subordinados",
        name: "organization.subordinates",
        component: Subordinates,
        meta: {
            forAuth: true,
            module: "organization"
        }
    }
];
