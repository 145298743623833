<template>
    <div class="w-full bg-gray-light">
        <div class="m-4">
            <breadcrumbs class="mb-8" :crumbs="crumbs" />
            <basic-informations
                v-if="user"
                :user="user"
                :editable="this.$store.state.auth.user.document.length === 11"
                :disabled="disableds"
            />

            <contacts
                v-if="user"
                :user="user"
                :editable="this.$store.state.auth.user.document.length === 11"
            />

            <legal-information
                v-if="user"
                :user="user"
                :editable="this.$store.state.auth.user.document.length === 11"
            />
        </div>
    </div>
</template>

<script>
import { Component } from "vue-property-decorator";
import Icon from "@/core/components/Icon";
import Breadcrumbs from "@/core/components/Breadcrumbs";
import IconBadge from "@/core/components/badges/IconBadge";
import BasicInformations from "@/domains/user/components/BasicInformations";
import Contacts from "@/domains/user/components/Contacts";
import LegalInformation from "@/domains/user/components/LegalInformation";
import RegistryService from "@/core/services/RegistryService";

@Component({
    components: {
        breadcrumbs: Breadcrumbs,
        "basic-informations": BasicInformations,
        contacts: Contacts,
        "legal-information": LegalInformation,
        icon: Icon,
        IconBadge
    }
})
export default class EditInfo extends RegistryService {
    constructor() {
        super();
        this.user = null;
        this.disableds = [];
        this.title = "Editar Dados da Instituição";
        this.crumbs = [
            { name: "Início", path: "index" },
            {
                name: "Minha Instituição",
                path: "organization.index"
            },
            {
                name: "Editar Dados da Instituição",
                path: "organization.edit"
            }
        ];
    }

    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
        this.disableds = ["name"];
        this.getUserData();
    }

    async getUserData() {
        let info = await this.getUserInfo(
            this.$store.getters.isOrganization
                ? this.$store.state.auth.user.document
                : this.$store.state.auth.link.organization.document
        );
        this.user = info;
        this.$Progress.finish();
    }
}
</script>
