import axios from "axios";

export default {
    setLogout(state) {
        delete axios.defaults.headers.common["Authorization"];
        delete axios.defaults.headers.common["X-Link"];
        state.token = null;
        state.expiration = null;
        state.type = null;
        state.refresh_token = null;
        state.user = null;
        state.link = null;
        state.links = null;
        state.permissions = null;
    },
    setToken(state, payload) {
        state.token = payload.access_token;
        state.expiration = payload.expires_in + Date.now();
        state.type = payload.token_type;
        state.refresh_token = payload.refresh_token;
        axios.defaults.headers.common["Authorization"] =
            state.type + " " + state.token;
        // Vue.http.headers.common['Authorization'] = state.type + ' ' + state.token;
        // Vue.http.options.root = process.env.DOMAIN;
    },
    setUser(state, payload) {
        state.user = payload;
        // if (state.user.links.length === 1) {
        //     state.link = state.user.links[0];
        // }
    },
    setPhones(state, payload) {
        state.user.phones = payload;
    },
    setEmails(state, payload) {
        state.user.emails = payload;
    },
    setLink(state, payload) {
        state.link = payload;
        axios.defaults.headers.common["X-Link"] =
            state.link.organization_id + "";
    },
    setPermissions(state, payload) {
        state.permissions = payload;
    },
    setAgreement(state, payload) {
        state.re_registration_agreement = payload;
    },
    clearLink(state) {
        state.link = null;
        axios.defaults.headers.common["X-Link"] = null;
    }
};
