var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ResponsiveTable',{attrs:{"hasPagination":false,"headers":_vm.headers,"data":_vm.dataMap(_vm.my_tickets),"pagination":_vm.pagination,"disabled":_vm.loading,"status":_vm.status},on:{"changePage":page => {
            _vm.changePage(page);
        }},scopedSlots:_vm._u([{key:"row",fn:function(props){return [_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.matricula))]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.nome))]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.cpf))]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.beneficiario))]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.expiracao))]),_c('td',{staticClass:"flex items-center h-full gap-2",class:props.tdClass,style:(`color: ${_vm.getColorBySituation(props.row.situacao)}`)},[_c('div',{staticClass:"h-max"},[(props.row.situacao === 'ANALISADO' ||
                        props.row.situacao === 'ATIVO' ||
                        props.row.situacao === 'ENTREGUE'
                        )?_c('Check'):_vm._e(),(props.row.situacao === 'PENDENTE')?_c('Pending'):_vm._e(),(props.row.situacao === 'CANCELADO')?_c('Cancel'):_vm._e(),(props.row.situacao === 'VENCIDO')?_c('Defeated'):_vm._e()],1),_vm._v(" "+_vm._s(props.row.situacao)+" ")]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.atualizacao))]),_c('td',{staticClass:"flex items-center",class:props.tdClass},[_c('router-link',{staticClass:"text-blue-base",attrs:{"to":{
                    name: 'spl-navegantes.edit',
                    params: { id: props.row.idbeneficiario }
                },"title":"Ver Opçõess"}},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.faEye}})],1)],1)]}},{key:"responsive",fn:function(props){return [_c('tr',[_c('th',{class:props.thClass},[_vm._v("Matricula")]),_c('td',{class:props.tdClass + ' relative'},[_c('ActionsDropdown',{staticClass:"absolute right-0 top-0"},[_c('template',{slot:"actions"},[_c('router-link',{staticClass:"block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left",attrs:{"to":{
                                name: 'spl-navegantes.show',
                                params: { id: props.row.matricula }
                            }}},[_vm._v(" Ver Opçõess ")])],1)],2),_vm._v(" "+_vm._s(props.row.matricula)+" ")],1)]),_c('tr',[_c('th',{class:props.thClass},[_vm._v("Nome")]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.nome))])]),_c('tr',[_c('th',{class:props.thClass},[_vm._v("Opções")]),_c('td',{class:props.tdClass},[_c('IconBadge',{attrs:{"text":props.row.Opções}})],1)]),_c('tr',[_c('th',{class:props.thClass},[_vm._v("Data de Expiraçõa")]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.expiracao))])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }