<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import LinksList from "@/domains/user/components/LinksList";
import Icon from "@/core/components/Icon";

export default {
    components: {
        Breadcrumbs,
        LinksList,
        Icon
    },
    data: () => {
        return {
            title: "Meus Vínculos",
            crumbs: [
                { name: "Início", path: "index" },
                {
                    name: "Usuário SCmobi",
                    path: "user.index"
                },
                { name: "Meus Vínculos", path: "user.links" }
            ],
            search: ""
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },
    methods: {
        async clearFilters() {
            await this.getResult();
            this.$refs.table.getLinks();
        },
        async getResult() {
            return new Promise(resolve => {
                this.search = "";
                resolve(this.search);
            });
        }
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-6" :crumbs="crumbs" />
            <div class="flex flex-wrap items-center justify-between">
                <div class="w-full lg:w-auto lg:mb-0">
                    <router-link
                        tag="button"
                        class="px-2 py-2 mb-2 form-new-blue sm:py-1"
                        :to="{ name: 'link.create' }"
                    >
                        <div class="flex items-center justify-center">
                            <Icon icon="plus" class="h-4 mr-1 fill-current" />
                            Novo Vínculo
                        </div>
                    </router-link>
                </div>
                <form
                    @submit.prevent="$refs.table.getLinks"
                    class="flex w-full mb-2 mr-1 md:w-auto"
                >
                    <div v-on:click="clearFilters" class="btn-clean-filter">
                        Limpar Filtros
                    </div>
                    <div class="relative inline-block w-full">
                        <button
                            type="submit"
                            class="absolute right-0 flex items-center hidden px-2 rounded-r-sm cursor-pointer md:block bg-blue-background active:bg-image-none active:bg-blue-darkest"
                        >
                            <Icon
                                icon="search"
                                class="h-4 my-2 text-white fill-current"
                            />
                        </button>
                        <input
                            v-model="search"
                            class="pl-4 filter-search"
                            type="text"
                        />
                    </div>
                    <button
                        class="flex w-32 ml-1 md:hidden form-submit "
                        type="submit"
                    >
                        Pesquisar
                    </button>
                </form>
            </div>
            <LinksList ref="table" :search="search" />
        </div>
    </div>
</template>
