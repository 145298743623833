<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import { TheMask } from "vue-the-mask";
import SearchLinks from "@/domains/user/components/SearchLinks";

export default {
    components: {
        Breadcrumbs,
        TheMask,
        SearchLinks
    },
    data() {
        return {
            title: "Solicitar Vínculo",
            crumbs: [
                { name: "Início", path: "index" },
                {
                    name: "Usuário SCmobi",
                    path: "user.index"
                },
                { name: "Meus Vínculos", path: "user.links" },
                { name: "Solicitar Vínculo", path: "link.create" }
            ],
            search: null,
            document: null
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },
    methods: {
        async clearFilters() {
            await this.getResult();
            this.$refs.table.getLinks();
        },
        async getResult() {
            return new Promise(resolve => {
                this.search = "";
                this.document = "";
                resolve(this.search);
            });
        }
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="my-4 px-2 sm:px-6">
            <portal-target name="modals" />
            <Breadcrumbs class="mb-8" :crumbs="crumbs" />
            <h1 class="text-blue-base uppercase mb">
                Solicitar vínculo
            </h1>
            <form
                @submit.prevent="$refs.table.getLinks"
                class="text-xs border-t-2 border-blue-base pt-4"
            >
                <div
                    class="w-full lg:w-1/2 xl:w-2/4 xl:max-w-screen-md bg-yellow-base border border-gray-dark p-5 mb-6"
                >
                    <h2 class="font-medium text-gray-darker mb-3 font-medium">
                        Para ter acesso às informações, é necessário estar
                        vinculado à alguma instituição. Digite a razão social ou
                        o CNPJ para prosseguir.
                    </h2>
                </div>

                <div class="flex items-center justify-content-center">
                    <div
                        class="flex items-center md:w-1/2 mb-4 mt-4 justify-center sm:justify-start"
                    >
                        <div class="mr-2">
                            <label class="form-label">Razão Social</label>
                            <input
                                v-model="search"
                                class="form-input"
                                placeholder="Razão Social"
                                type="text"
                            />
                        </div>
                        <div class="mr-2 text-gray-darker">
                            ou
                        </div>
                        <div class="mr-2">
                            <label class="form-label">CNPJ</label>
                            <TheMask
                                mask="##.###.###/####-##"
                                v-model="document"
                                placeholder="00.000.000/0000-00"
                                class="form-input"
                            />
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div
                            v-on:click="clearFilters"
                            class="btn-clean-filter flex justify-content-center"
                        >
                            <span v-if="search || document">
                                Limpar Filtros
                            </span>
                        </div>
                        <button
                            class="form-submit w-full sm:w-auto py-2 sm:py-1"
                            type="submit"
                        >
                            Pesquisar
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="px-6 mb-6">
            <SearchLinks ref="table" :search="search" :document="document" />
        </div>
    </div>
</template>
