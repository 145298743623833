<template>
    <div ref="editor"></div>
</template>

<script>
import Quill from "quill";

export default {
    props: {
        value: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: "Descreva o seu problema em detalhes..."
        }
    },
    data() {
        return {
            editor: null
        };
    },
    mounted() {
        this.editor = this.createEditor();
        this.handleEditorValue();
    },
    methods: {
        /**
         * Create an instance of the editor.
         */
        createEditor() {
            return new Quill(this.$refs.editor, {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }, "link"]
                    ]
                },
                theme: "snow",
                scrollingContainer: "html, body",
                placeholder: this.placeholder
            });
        },
        /**
         * Handle the editor value.
         */
        handleEditorValue() {
            this.editor.root.innerHTML = this.value;
            this.editor.on("text-change", () => {
                this.$emit(
                    "input",
                    this.editor.getText() ? this.editor.root.innerHTML : ""
                );
            });
        }
    }
};
</script>
