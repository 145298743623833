var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"relative h-full -mt-1"},[_c('button',{staticClass:"h-full lg:text-right lg:flex lg:items-center text-blue-lightest hover:text-white focus:text-white focus:outline-none",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('Icon',{staticClass:"h-8 fill-current",attrs:{"icon":"modules"}})],1),(_vm.isOpen)?_c('div',{staticClass:"absolute z-50 right-0 w-64 bg-gray-light rounded-sm shadow-md border-white border-4"},[_c('div',{staticClass:"text-xs flex flex-col bg-white p-1"},[(_vm.modules.length)?_c('div',{staticClass:"justify-between items-center"},_vm._l((_vm.modules),function(m){return _c('router-link',{key:m.route,attrs:{"to":{ name: m.route },"title":m.name,"exact":""},scopedSlots:_vm._u([{key:"default",fn:function({
                        href,
                        route,
                        navigate,
                        isActive
                    }){return [_c('button',{class:[
                            isActive
                                ? 'bg-gray-dark text-white'
                                : 'text-gray-darker hover:bg-gray-base active:bg-gray-dark active:text-white',
                            'w-20 rounded-sm my-1 p-2 align-middle'
                        ],attrs:{"active":isActive,"href":href},on:{"click":e => {
                                _vm.close();
                                navigate(e);
                            }}},[_c('div',{staticClass:"ml-2 icon h-12 w-12 mb-1 text-blue-base"},[_c('Icon',{staticClass:"h-6 fill-current",attrs:{"icon":m.icon}})],1),_c('p',{staticClass:"truncate tracking-wide font-medium text-xs justify-center"},[_vm._v(" "+_vm._s(m.name)+" ")])])]}}],null,true)})}),1):_c('div',{staticClass:"text-right text-gray-dark w-full p-2"},[_vm._v(" Nenhum módulo disponível ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }