export default [
    {
        path: "/gerenciamento-transportadoras",
        name: "transport-manager.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-manager" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport-manager"
        }
    },
    {
        path: "/gerenciamento-transportadoras/frota/:id",
        name: "transport-manager.fleet",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-manager" */ "./views/ShowFleet.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport-manager"
        }
    },
    {
        path: "/gerenciamento-transportadoras/veiculo",
        name: "transport-manager.vehicle",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-manager" */ "./views/Vehicle.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport-manager"
        }
    },
    {
        path: "/gerenciamento-transportadoras/veiculo/:plate",
        name: "transport-manager.vehicle.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-manager" */ "./views/ShowVehicle.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport-manager"
        }
    },
    {
        path: "/gerenciamento-transportadoras/pedido-registro",
        name: "transport-manager.registrations",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-manager" */ "./views/Registrations.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport-manager"
        }
    },
    {
        path: "/gerenciamento-transportadoras/renovacoes",
        name: "transport-manager.renewals",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-manager" */ "./views/Renewals.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport-manager"
        }
    },
    {
        path: "/gerenciamento-transportadoras/incluir-veiculo",
        name: "transport-manager.vehicle-additions",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-manager" */ "./views/VehicleAdditions.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport-manager"
        }
    },
    {
        path: "/gerenciamento-transportadoras/excluir-veiculo",
        name: "transport-manager.vehicle-removals",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-manager" */ "./views/VehicleRemovals.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport-manager"
        }
    }
];
