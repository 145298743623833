<script>
import { requestRenewal } from "@/domains/transport/services/carrier";

export default {
    props: {
        carrier: Object
    },
    data() {
        return {
            quantity: null,
            status: "pending",
            errors: null
        };
    },
    methods: {
        send() {
            this.$Progress.start();
            this.errors = null;
            this.status = "pending";
            requestRenewal(this.carrier.organization_id, this.quantity)
                .then(response => {
                    this.$emit("success");
                    this.status = "finished";
                    this.$Progress.finish();
                    this.$toast.success({
                        title: "OK",
                        message: response.message
                    });
                })
                .catch(e => {
                    if (e.status !== 422) {
                        this.$toast.error({
                            message: e.error
                        });
                    } else {
                        this.errors = e.error;
                    }
                    this.status = "rejected";
                    this.$Progress.finish();
                });
        }
    }
};
</script>

<template>
    <form @submit.prevent="send">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
                <div
                    :class="[
                        status === 'finished' ? 'bg-green-100' : 'bg-blue-100',
                        'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10'
                    ]"
                >
                    <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        :class="[
                            'h-6 w-6',
                            status === 'finished'
                                ? 'text-green-600'
                                : 'text-blue-600'
                        ]"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                    </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                        class="text-lg leading-normal font-medium text-gray-900"
                    >
                        <span v-if="status === 'finished'">
                            Renovação solicitada
                        </span>
                        <span v-else>Solicitar Renovação de Registro</span>
                    </h3>
                    <div
                        v-if="carrier.field_activity.name === 'Privado'"
                        class="mt-2"
                    >
                        <p
                            v-if="status === 'finished'"
                            class="text-left text-sm leading-tight text-gray-dark"
                        >
                            Agora imprima e pague as guias de Renovação e
                            Vistoria no módulo
                            <router-link
                                class="text-blue-base underline"
                                :to="{ name: 'financial.bill' }"
                            >
                                Financeiro</router-link
                            >.
                        </p>
                        <p
                            v-else
                            class="text-left text-sm leading-tight text-gray-500"
                        >
                            Esta ação irá iniciar o procedimento de
                            <span class="font-medium">Renovação</span> do seu
                            Registro. Informe abaixo a quantidade de veículos
                            que serão renovados.
                        </p>
                    </div>
                    <div v-else class="mt-2">
                        <p
                            v-if="status === 'finished'"
                            class="text-left text-sm leading-tight text-gray-dark"
                        >
                            A renovação foi solicitada com sucesso.
                        </p>
                        <p
                            v-else
                            class="text-left text-sm leading-tight text-gray-500"
                        >
                            Esta ação irá iniciar o procedimento de Renovação do
                            seu Registro.
                        </p>
                    </div>
                    <div
                        v-if="
                            carrier.field_activity.name === 'Privado' &&
                                status !== 'finished'
                        "
                        class="mt-3 sm:mt-2"
                    >
                        <label
                            class="uppercase tracking-wide font-medium text-gray-500 text-xs"
                        >
                            Quantidade de veículos
                        </label>
                        <input
                            class="form-input w-full"
                            v-model="quantity"
                            v-focus
                            required
                        />
                        <div v-if="errors && errors.quantity">
                            <p
                                v-for="(error, index) in errors.quantity"
                                v-bind:key="index"
                                class="italic text-red-base text-xs"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span
                v-if="status !== 'finished'"
                class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
            >
                <button
                    type="submit"
                    class="uppercase inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-xs leading-normal font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:leading-tight"
                >
                    Confirmar
                </button>
            </span>
            <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
                <button
                    @click="$emit('close')"
                    type="button"
                    class="uppercase inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-xs leading-normal font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:leading-tight"
                >
                    <span v-if="status === 'finished'">Fechar</span>
                    <span v-else>Cancelar</span>
                </button>
            </span>
        </div>
    </form>
</template>
