var render = function render(){var _vm=this,_c=_vm._self._c;return (this.module)?_c('nav',{key:this.$route.name,staticClass:"fixed top-0 left-0 z-30 flex flex-col flex-grow w-full h-screen bg-white shadow group sidebar-mobile lg:sidebar lg:z-0 lg:sticky lg:w-16 lg:max-w-xs lg:h-auto lg:max-h-screen lg:block text-blue-light",class:[
        _vm.mobileVisible ? 'sidebar-mobile-visible' : '',
        _vm.keepOpen ? 'keep-open' : ''
    ]},[_c('div',{staticClass:"relative z-0 hidden float-right my-2 -mr-4 transition duration-500 transform cursor-pointer lg:block group-hover:rotate-180",class:[_vm.keepOpen ? 'rotate-180' : ''],on:{"click":_vm.toggleKeepOpen}},[_c('Icon',{staticClass:"w-10 h-10 fill-current text-blue-base",attrs:{"icon":"sidebarArrow"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full py-1 pr-5 border-b border-white lg:hidden bg-blue-background"},[_c('img',{staticClass:"h-12",attrs:{"src":require("@/core/assets/images/logo-scmobi-white.svg"),"alt":"SCmobi"}}),_c('div',{staticClass:"cursor-pointer lg:hidden",on:{"click":function($event){$event.preventDefault();return _vm.toggleOnMobile.apply(null, arguments)}}},[_c('Icon',{staticClass:"h-6 text-white fill-current",attrs:{"icon":"close"}})],1)]),_c('div',{staticClass:"flex flex-col justify-between flex-grow lg:block"},[_c('ul',_vm._l((_vm.items),function(item){return _c('router-link',{key:item.name,staticClass:"cursor-pointer clear",attrs:{"tag":"li","id":item.id || item.route,"to":{ name: item.route },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{class:[
                        isExactActive
                            ? 'bg-blue-base text-white'
                            : 'text-gray-darker hover:bg-gray-base',
                        'w-full flex items-center text-center py-2 pl-3'
                    ],attrs:{"active":isActive,"href":href},on:{"click":e => {
                            _vm.mobileVisible = false;
                            navigate(e);
                        }}},[_c('Icon',{staticClass:"flex-none fill-current",class:[
                            item.param.home ? 'w-10 h-10' : 'h-8',
                            isExactActive ? 'text-white' : 'text-blue-base'
                        ],attrs:{"icon":item.icon}}),(item.param.home)?_c('div',{staticClass:"flex-col ml-2 text-left truncate lg:hidden group-hover:block",class:[_vm.keepOpen ? 'block-item' : '']},[_c('div',{staticClass:"text-sm font-medium"},[_vm._v("Home")]),_c('div',{staticClass:"text-xs font-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])]):_c('div',{staticClass:"flex-col ml-4 text-left truncate lg:hidden group-hover:block",class:[_vm.keepOpen ? 'block-item' : '']},[_c('div',{staticClass:"text-sm font-medium lg:text-xs"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)]}}],null,true)})}),1),_c('ul',{staticClass:"block lg:hidden"},[_c('router-link',{staticClass:"cursor-pointer clear",attrs:{"tag":"li","to":{ name: 'index' },"exact":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('div',{staticClass:"flex items-center w-full py-3 pl-4 text-center text-white border-t bg-blue-base active:bg-gray-dark border-blue-darker",attrs:{"active":isActive,"href":href},on:{"click":e => {
                            _vm.mobileVisible = false;
                            navigate(e);
                        }}},[_c('Icon',{staticClass:"flex-none h-10 text-white fill-current",attrs:{"icon":"modules"}}),_c('div',{staticClass:"flex-col ml-4 text-left truncate lg:hidden group-hover:block",class:[_vm.keepOpen ? 'block-item' : '']},[_c('div',{staticClass:"text-sm font-medium lg:text-xs"},[_vm._v(" Módulos ")])])],1)]}}],null,false,4089092541)})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }