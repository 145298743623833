<template>
    <nav
        v-if="this.module"
        :class="[
            mobileVisible ? 'sidebar-mobile-visible' : '',
            keepOpen ? 'keep-open' : ''
        ]"
        class="fixed top-0 left-0 z-30 flex flex-col flex-grow w-full h-screen bg-white shadow group sidebar-mobile lg:sidebar lg:z-0 lg:sticky lg:w-16 lg:max-w-xs lg:h-auto lg:max-h-screen lg:block text-blue-light"
        :key="this.$route.name"
    >
        <div
            @click="toggleKeepOpen"
            class="relative z-0 hidden float-right my-2 -mr-4 transition duration-500 transform cursor-pointer lg:block group-hover:rotate-180"
            :class="[keepOpen ? 'rotate-180' : '']"
        >
            <Icon
                icon="sidebarArrow"
                class="w-10 h-10 fill-current text-blue-base"
            />
        </div>
        <div
            class="flex items-center justify-between w-full py-1 pr-5 border-b border-white lg:hidden bg-blue-background"
        >
            <img
                class="h-12"
                src="@/core/assets/images/logo-scmobi-white.svg"
                alt="SCmobi"
            />
            <div
                class="cursor-pointer lg:hidden"
                @click.prevent="toggleOnMobile"
            >
                <Icon icon="close" class="h-6 text-white fill-current" />
            </div>
        </div>
        <div class="flex flex-col justify-between flex-grow lg:block">
            <ul>
                <router-link
                    tag="li"
                    v-for="item in items"
                    :id="item.id || item.route"
                    v-bind:key="item.name"
                    :to="{ name: item.route }"
                    class="cursor-pointer clear"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    exact
                >
                    <div
                        :class="[
                            isExactActive
                                ? 'bg-blue-base text-white'
                                : 'text-gray-darker hover:bg-gray-base',
                            'w-full flex items-center text-center py-2 pl-3'
                        ]"
                        :active="isActive"
                        :href="href"
                        @click="
                            e => {
                                mobileVisible = false;
                                navigate(e);
                            }
                        "
                    >
                        <Icon
                            :icon="item.icon"
                            class="flex-none fill-current"
                            :class="[
                                item.param.home ? 'w-10 h-10' : 'h-8',
                                isExactActive ? 'text-white' : 'text-blue-base'
                            ]"
                        />
                        <div
                            v-if="item.param.home"
                            class="flex-col ml-2 text-left truncate lg:hidden group-hover:block"
                            :class="[keepOpen ? 'block-item' : '']"
                        >
                            <div class="text-sm font-medium">Home</div>
                            <div class="text-xs font-medium">
                                {{ item.name }}
                            </div>
                        </div>
                        <div
                            v-else
                            class="flex-col ml-4 text-left truncate lg:hidden group-hover:block"
                            :class="[keepOpen ? 'block-item' : '']"
                        >
                            <div class="text-sm font-medium lg:text-xs">
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                </router-link>
            </ul>
            <ul class="block lg:hidden">
                <router-link
                    tag="li"
                    :to="{ name: 'index' }"
                    class="cursor-pointer clear"
                    v-slot="{ href, navigate, isActive }"
                    exact
                >
                    <div
                        class="flex items-center w-full py-3 pl-4 text-center text-white border-t bg-blue-base active:bg-gray-dark border-blue-darker"
                        :active="isActive"
                        :href="href"
                        @click="
                            e => {
                                mobileVisible = false;
                                navigate(e);
                            }
                        "
                    >
                        <Icon
                            icon="modules"
                            class="flex-none h-10 text-white fill-current"
                        />
                        <div
                            class="flex-col ml-4 text-left truncate lg:hidden group-hover:block"
                            :class="[keepOpen ? 'block-item' : '']"
                        >
                            <div class="text-sm font-medium lg:text-xs">
                                Módulos
                            </div>
                        </div>
                    </div>
                </router-link>
            </ul>
        </div>
    </nav>
</template>

<script>
import Icon from "@/core/components/Icon";

export default {
    components: {
        Icon
    },
    data() {
        return {
            module: null,
            items: [],
            loading: false,
            mobileVisible: false,
            keepOpen: false
        };
    },
    beforeMount() {
        this.initiateSidebar();
    },
    updated() {
        if (this.$route.meta.module !== this.module) {
            this.module = this.$route.meta.module;
            this.getModuleItems();
        }
    },
    methods: {
        initiateSidebar() {
            if (this.$route.meta.module) {
                this.module = this.$route.meta.module;
                if (this.module == "spl-navegantes") {
                    this.items = this.metodoFixo();
                } else {
                    this.getModuleItems();
                }
            }
        },
        toggleKeepOpen() {
            this.keepOpen = !this.keepOpen;
        },
        getModuleItems() {
            this.loading = true;
            this.$axios
                .post("/api/security/submenu/" + this.module)
                .then(response => {
                    this.loading = false;
                    this.items = response.data;
                    if (this.items.length === 0) {
                        // this.$router.replace({ name: "index" });
                    }
                })
                .catch(() => {
                    this.loading = false;
                    // this.$router.replace({ name: "index" });
                });
        },
        toggleOnMobile() {
            this.mobileVisible = !this.mobileVisible;
        },
        metodoFixo() {
            return [
                {
                    name: "Home",
                    icon: "homeUser",
                    route: "spl-navegantes.index",
                    param: { home: true },
                },
                {
                    name: "Beneficiários",
                    icon: "document",
                    route: "spl-navegantes.show",
                    param: [],
                },
            ];

        }
    }
};
</script>
