<script>
import { TheMask } from "vue-the-mask";
import { mask } from "@/core/services/helpers";
export default {
    components: { TheMask },
    props: {
        emails: {
            type: Array,
            default: () => [
                {
                    contact: ""
                }
            ]
        },
        phones: {
            type: Array,
            default: () => [
                {
                    contact: ""
                }
            ]
        },
        emailsForm: {
            type: Array,
            default: () => [
                {
                    contact: ""
                }
            ]
        },
        phonesForm: {
            type: Array,
            default: () => [
                {
                    contact: ""
                }
            ]
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        mask,
        getTpCurso(tpcurso) {
            if (tpcurso) {
                return this.tipoCursos.find(
                    item => Number(item.value) === Number(tpcurso)
                ).name;
            }
        }
    }
};
</script>
<template>
    <div class="grid gap-5 grid-cols-1 md:grid-cols-12 mb-2 px-3">
        <div class="col-span-6">
            <template v-if="!readonly">
                <span class="text-sm form-label text-gray-dark">Email</span>
                <span v-for="(email, index) in emailsForm" :key="index" class="flex items-center gap-5 mb-3">
                    <input v-model="emailsForm[index].contact" type="text" class="h-auto py-2 mb-0 rounded form-input" />

                    <div>
                        <Icon v-if="emails.length > 1" icon="x" class="h-6 cursor-pointer fill-current" />
                    </div>
                </span>
            </template>
            <template v-else>
                <div v-for="(email, index) in emails" :key="index" class="pb-2">
                    <span class="text-sm form-label text-gray-dark">
                        EMAIL
                    </span>
                    <div>
                        {{ email.contact }}
                    </div>
                </div>
            </template>
        </div>
        <div class="col-span-6">
            <template v-if="!readonly">
                <span class="text-sm form-label text-gray-dark">Telefone</span>
                <span v-for="(phone, index) in phonesForm" :key="index" class="flex items-center gap-5 mb-3">
                    <TheMask v-model="phonesForm[index].contact" :mask="['(##) #####.####']"
                        class="h-auto py-2 mb-0 rounded form-input" required style="flex: 1" />

                    <div>
                        <Icon v-if="phones.length > 1" icon="x" class="h-6 cursor-pointer fill-current" />
                    </div>
                </span>
            </template>
            <template v-else>
                <div v-for="(phone, index) in phones" :key="index" class="pb-2">
                    <span class="text-sm form-label text-gray-dark">
                        TELEFONE
                    </span>
                    <div>
                        {{
                            phone.contact
                            ? mask(phone.contact, "(##) #####-####")
                            : ""
                        }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
