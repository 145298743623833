<script>
import Editor from "@/core/components/Editor";
import Select from "@/core/components/Select";

export default {
    props: {
        shouldRedirect: {
            type: Boolean,
            default: true
        },
        requireAttachment: {
            type: Boolean,
            default: false
        },
        subjectPlaceholder: {
            type: String,
            default: null
        }
    },
    components: {
        Editor,
        Select
    },
    data() {
        return {
            subject: "",
            content: "",
            attachment: null,
            description: "",
            category_id: null,
            priority_id: null,
            agent_id: null,
            categories: [],
            priorities: [],
            agents: [],
            errors: null,
            classSelectAgent: "form-select"
        };
    },
    mounted() {
        if (this.subjectPlaceholder) {
            this.subject = this.subjectPlaceholder;
        }
        if (this.isAgent) {
            this.getCategories();
            this.getPriorities();
            this.getAgents();
        }
    },
    computed: {
        isAgent() {
            return (
                this.$store.state.auth.permissions &&
                this.$store.state.auth.permissions.includes("close-ticket")
            );
        },
        authorizedAgents() {
            if (this.agents.length) {
                let agent = this.agents.filter(agent => {
                    return agent.categories.find(
                        category => category.id === this.category_id
                    );
                });
                if (agent.length > 0) this.setClassSelect("form-select");
                return agent;
            }
            this.setClassSelect("form-select bg-gray-light");
            return [];
        }
    },
    methods: {
        setClassSelect(cl) {
            this.classSelectAgent = cl;
        },
        getCategories() {
            this.$axios
                .post("/api/support/category/search", {
                    take: false,
                    paginate: false,
                    order: { name: "ASC" }
                })
                .then(response => {
                    this.categories = response.data;
                })
                .catch(() => {});
        },
        getPriorities() {
            this.$axios
                .post("/api/support/priority/search", {
                    take: false,
                    paginate: false,
                    order: { name: "ASC" }
                })
                .then(response => {
                    this.priorities = response.data;
                })
                .catch(() => {});
        },
        getAgents() {
            this.$axios
                .post("/api/support/agent/search", {
                    take: false,
                    paginate: false
                })
                .then(response => {
                    this.agents = response.data;
                })
                .catch(() => {});
        },
        attach(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.attachment = files[0];
        },
        submit() {
            this.$Progress.start();
            let data = new FormData();
            data.append("subject", this.subject);
            data.append("content", this.content);
            if (this.$store.state.auth.link) {
                data.append(
                    "organization_id",
                    this.$store.state.auth.link.organization_id
                );
            }
            if (this.attachment !== null) {
                data.append("attachment", this.attachment);
                data.append("description", this.description);
            }
            if (this.category_id) {
                data.append("category_id", this.category_id);
            }
            if (this.priority_id) {
                data.append("priority_id", this.priority_id);
            }
            if (this.agent_id) {
                data.append("agent_id", this.agent_id);
            }
            this.$axios
                .post("/api/support/ticket", data)
                .then(response => {
                    let ticket_id = response.data.ticket;
                    this.$Progress.finish();
                    this.$toast.success({
                        title: "OK",
                        message: response.data.success
                    });
                    this.$emit("ticketCreated");
                    if (this.shouldRedirect) {
                        this.$router.push({
                            name: "ticket.show",
                            params: { id: ticket_id }
                        });
                    }
                })
                .catch(e => {
                    this.$Progress.finish();
                    if (e.response.status === 422) {
                        this.errors = e.response.data.errors;
                    } else {
                        this.$toast.error({
                            title: "",
                            message:
                                e.response.data.message || e.response.data.error
                        });
                    }
                });
        }
    }
};
</script>

<template>
    <form @submit.prevent="submit" class="flex flex-col w-full text-xs">
        <div class="flex flex-col mb-6">
            <label class="form-label" for="subject">
                Assunto
            </label>
            <input
                :class="[
                    errors && errors.subject ? 'bg-red-100 border-red-400' : '',
                    'form-input'
                ]"
                v-model="subject"
                id="subject"
                type="text"
                placeholder="Descreva brevemente o seu problema"
            />
            <div v-if="errors && errors.subject">
                <p
                    v-for="(error, index) in errors.subject"
                    v-bind:key="index"
                    class="italic text-red-base"
                >
                    {{ error }}
                </p>
            </div>
        </div>
        <div class="flex flex-col mb-6">
            <label class="form-label" for="content">
                Descrição
            </label>
            <span class="mb-3">
                <Editor
                    id="content"
                    class="w-full bg-white"
                    v-model="content"
                />
            </span>
            <div v-if="errors && errors.content">
                <p
                    v-for="(error, index) in errors.content"
                    v-bind:key="index"
                    class="italic text-red-base"
                >
                    {{ error }}
                </p>
            </div>
        </div>
        <div class="flex flex-wrap mb-6">
            <div class="w-full mb-6 md:w-1/2 md:pr-3 md:mb-0">
                <label class="form-label" for="attachment">
                    Anexo
                </label>
                <label
                    :class="[
                        errors && errors.attachment
                            ? 'bg-red-100 border-red-400'
                            : '',
                        'form-input-file group'
                    ]"
                >
                    <span class="py-3">{{
                        attachment
                            ? attachment.name
                            : "Nenhum arquivo selecionado"
                    }}</span>
                    <span
                        class="px-3 py-3 bg-gray-300 rounded-r group-hover:bg-gray-400"
                    >
                        Escolher arquivo
                    </span>
                    <input
                        @change="attach"
                        type="file"
                        class="hidden"
                        id="attachment"
                        aria-describedby="attach-help"
                        :required="requireAttachment"
                    />
                </label>
                <div v-if="errors && errors.attachment">
                    <p
                        v-for="(error, index) in errors.attachment"
                        v-bind:key="index"
                        class="italic text-red-base"
                    >
                        {{ error }}
                    </p>
                </div>
                <p v-else-if="requireAttachment" class="italic text-red-base">
                    O campo anexo é obrigatório.
                </p>
                <p id="attach-help" class="italic text-gray-600">
                    Formatos suportados: pdf, png, jpg.
                </p>
            </div>
            <div class="w-full md:w-1/2 md:pl-3">
                <label class="form-label" for="description">
                    Descrição do anexo
                </label>
                <input
                    :class="[
                        errors && errors.description
                            ? 'bg-red-100 border-red-400'
                            : '',
                        'form-input'
                    ]"
                    v-model="description"
                    id="description"
                    type="text"
                />
                <div v-if="errors && errors.description">
                    <p
                        v-for="(error, index) in errors.description"
                        v-bind:key="index"
                        class="italic text-red-base"
                    >
                        {{ error }}
                    </p>
                </div>
            </div>
        </div>
        <template v-if="isAgent">
            <div class="mb-3">
                <label class="form-label">
                    Campos opcionais
                </label>
            </div>
            <div class="flex flex-wrap mb-6">
                <div class="w-full mb-6 md:w-1/3 md:pr-3 md:mb-0">
                    <label class="form-label" for="category">
                        Categoria
                    </label>
                    <Select
                        id="category"
                        class="w-full mb-3"
                        :options="categories"
                        :selected.sync="category_id"
                    />
                    <div v-if="errors && errors.category_id">
                        <p
                            v-for="(error, index) in errors.category_id"
                            v-bind:key="index"
                            class="italic text-red-base"
                        >
                            {{ error }}
                        </p>
                    </div>
                </div>
                <div class="w-full mb-6 md:w-1/3 md:px-3 md:mb-0">
                    <label class="form-label" for="priority">
                        Prioridade
                    </label>
                    <Select
                        id="priority"
                        class="w-full mb-3"
                        :options="priorities"
                        :selected.sync="priority_id"
                    />
                    <div v-if="errors && errors.priority_id">
                        <p
                            v-for="(error, index) in errors.priority_id"
                            v-bind:key="index"
                            class="italic text-red-base"
                        >
                            {{ error }}
                        </p>
                    </div>
                </div>
                <div class="w-full md:w-1/3 md:pl-3">
                    <label class="form-label" for="agent">
                        Agente
                    </label>
                    <Select
                        id="agent"
                        class="w-full mb-3"
                        :select-class="classSelectAgent"
                        :options="authorizedAgents"
                        :selected.sync="agent_id"
                    />
                    <div v-if="errors && errors.agent_id">
                        <p
                            v-for="(error, index) in errors.agent_id"
                            v-bind:key="index"
                            class="italic text-red-base"
                        >
                            {{ error }}
                        </p>
                    </div>
                </div>
            </div>
        </template>
        <div>
            <button type="submit" class="form-submit md:w-auto">
                Enviar
            </button>
        </div>
    </form>
</template>
