let AutoFocus = {};

AutoFocus.install = function(Vue) {
    Vue.directive("focus", {
        // When the bound element is inserted into the DOM...
        inserted: function(el) {
            // Focus the element
            el.focus();
        }
    });
};

export default AutoFocus;
