var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ResponsiveTable',{attrs:{"headers":_vm.headers,"data":_vm.dataMap(_vm.links),"pagination":_vm.pagination,"disabled":_vm.loading,"status":_vm.status},on:{"changePage":page => {
            _vm.changePage(page);
        }},scopedSlots:_vm._u([{key:"row",fn:function(props){return [_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.name))]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.document))]),_c('td',{class:props.tdClass},[(props.row.profiles.length)?_c('div',_vm._l((props.row.profiles),function(profile){return _c('span',{key:profile.id,staticClass:"bg-green-100 border border-teal-700 text-teal-700 font-medium p-1 rounded text-xs mr-1 my-2"},[_vm._v(" "+_vm._s(profile.name)+" ")])}),0):_c('span',{staticClass:"bg-red-100 border border-red-base text-red-base font-medium p-1 rounded text-xs mr-1 my-2"},[_vm._v(" Nenhum perfil ")])]),_c('td',{class:props.tdClass},[(props.row.status)?_c('div',[_c('IconBadge',{attrs:{"text":props.row.status.name}})],1):_c('div',[_vm._v("Não vinculado")])]),_c('td',{class:props.tdClass},[(!props.row.countLinks)?_c('div',[_c('ProfileSelection',{attrs:{"modal":true,"action":"link","subordinate":props.row.line},on:{"save":_vm.refresh}})],1):_c('div',[(props.row.status.name === 'Vínculo Ativo')?_c('div',[_c('ProfileSelection',{attrs:{"modal":true,"action":"edit","subordinate":props.row.line},on:{"save":_vm.refresh}}),(
                            _vm.$store.state.auth.user.id !== props.row.line.id
                        )?_c('div',[_c('SubordinateConfirmation',{attrs:{"modal":true,"action":"disable","subordinate":props.row.line},on:{"confirm":_vm.refresh}})],1):_vm._e()],1):_vm._e(),(props.row.status.name === 'Vínculo Solicitado')?_c('div',[_c('ProfileSelection',{attrs:{"modal":true,"action":"accept","subordinate":props.row.line},on:{"save":_vm.refresh}}),_c('SubordinateConfirmation',{attrs:{"modal":true,"action":"deny","subordinate":props.row.line},on:{"confirm":_vm.refresh}})],1):_vm._e(),(
                        props.row.status.name === 'Vínculo Inativo' ||
                            props.row.status.name === 'Vínculo Rejeitado'
                    )?_c('div',[_c('ProfileSelection',{attrs:{"modal":true,"action":"enable","subordinate":props.row.line},on:{"save":_vm.refresh}})],1):_vm._e()])])]}},{key:"responsive",fn:function(props){return [_c('tr',{staticClass:"p-2"},[_c('th',{class:props.thClass},[_vm._v("CPF")]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.document))])]),_c('tr',{staticClass:"p-2"},[_c('th',{class:props.thClass},[_vm._v("Perfis")]),(props.row.profiles.length)?_c('div',{staticClass:"pt-3 px-2"},[(props.row.profiles.length)?_c('div',_vm._l((props.row.profiles),function(profile){return _c('span',{key:profile.id,staticClass:"bg-green-100 border border-teal-700 text-teal-700 font-medium p-1 rounded text-xs mr-1"},[_vm._v(" "+_vm._s(profile.name)+" ")])}),0):_vm._e()]):_c('span',{staticClass:"bg-red-100 border border-red-base text-red-base font-medium p-1 rounded text-xs mt-3 mx-2"},[_vm._v(" Nenhum perfil ")])]),_c('tr',{staticClass:"p-2"},[_c('th',{class:props.thClass},[_vm._v("Situação")]),(props.row.status)?_c('div',{staticClass:"pt-3 px-2"},[_c('IconBadge',{attrs:{"text":props.row.status.name}})],1):_c('div',{staticClass:"pt-3 px-2"},[_vm._v("Não vinculado")])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }