const ValidCityCodes = {
    NAVEGANTES: 4583,
    ITAJAI: 4540
};

export async function getAddressByCep(cep) {
    const data = await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: "GET"
    });
    return await data.json();
}

export async function checkIfCepIsValidForBeneficiario(cep) {
    const { localidade } = await getAddressByCep(cep);

    return (
        localidade.toLocaleLowerCase().includes("navegantes") ||
        localidade.toLocaleLowerCase().includes("itajaí")
    );
}
export function getCityCode(cityName/*, axios*/) {

    // const { data } = await axios.get(
    //     `/api/spl-navegantes/municipios/`
    // );

    return cityName.toLocaleLowerCase().includes("navegantes")
        ? ValidCityCodes.NAVEGANTES
        : ValidCityCodes.ITAJAI;
}
