var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"width":"600","borderLeft":"4px solid #2ecc71"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h1',{staticClass:"text-blue-base uppercase mb-1 py-1 border-b-2 border-blue-base"},[_vm._v(" Novo Anexo ")])]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onFormSubmit()}}},[_c('div',{staticClass:"w-full mb-5 mt-5"},[_c('label',{staticClass:"form-label text-sm",attrs:{"for":"attachment"}},[_vm._v(" Anexo ")]),_c('label',{class:[
                        _vm.errors && _vm.errors.attachment
                            ? 'bg-red-100 border-red-400'
                            : '',
                        'form-input-file group'
                    ]},[_c('span',{staticClass:"py-3"},[_vm._v(_vm._s(_vm.attachment ? _vm.attachment.name : "Nenhum arquivo selecionado"))]),_c('span',{staticClass:"bg-gray-300 group-hover:bg-gray-400 rounded-r py-3 px-3"},[_vm._v(" Escolher arquivo ")]),_c('input',{staticClass:"hidden",attrs:{"type":"file","id":"attachment","aria-describedby":"attach-help","required":_vm.requireAttachment},on:{"change":_vm.attach}})]),(_vm.errors && _vm.errors.attachment)?_c('div',_vm._l((_vm.errors.attachment),function(error,index){return _c('p',{key:index,staticClass:"italic text-red-base"},[_vm._v(" "+_vm._s(error)+" ")])}),0):(_vm.requireAttachment)?_c('p',{staticClass:"text-red-base italic"},[_vm._v(" O campo anexo é obrigatório. ")]):_vm._e(),_c('p',{staticClass:"text-gray-600 text-xs italic",attrs:{"id":"attach-help"}},[_vm._v(" Formatos suportados: pdf, png, jpg. ")])]),_c('div',{staticClass:"w-full mt-5 mb-5"},[_c('label',{staticClass:"form-label text-sm",attrs:{"for":"description"}},[_vm._v(" Descrição do anexo ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],class:[
                        _vm.errors && _vm.errors.description
                            ? 'bg-red-100 border-red-400'
                            : '',
                        'form-input'
                    ],attrs:{"id":"description","type":"text"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing)return;_vm.description=$event.target.value}}}),(_vm.errors && _vm.errors.description)?_c('div',_vm._l((_vm.errors.description),function(error,index){return _c('p',{key:index,staticClass:"italic text-red-base"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()])])]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"d-flex items-center justify-content-center"},[_c('button',{staticClass:"btn btn-outline-secondary close-modal mr-2",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"btn btn-primary disable-onload",attrs:{"disabled":_vm.attachment == null && _vm.description.length < 3,"type":"submit"},on:{"click":_vm.onFormSubmit}},[_vm._v(" Enviar ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }