import Service from "@/core/services/Service";

export default class TicketService extends Service {
    constructor() {
        super();
        this.routerTicket = "/api/support/ticket/";
        this.result = {};
    }

    async getCount() {
        try {
            let response = await this.request(
                this.routerTicket + "count",
                "get"
            );
            response["status"] === "success"
                ? (this.result = response["response"]["body"])
                : (this.result = {
                      active: 0,
                      closed: 0
                  });
            return this.result;
        } catch (e) {
            this.result = { active: 0, closed: 0 };
            return this.result;
        }
    }

    async deleteSupportTicket(idTicket) {
        this.$Progress.start();

        let route = "/api/support/ticket/" + idTicket;

        let info = await this.request(route, "delete", false, "", true);

        this.$Progress.finish();

        if (info.success) this.$router.push({ name: "support.ticket" });
    }

    async getConfig() {
        try {
            const data = { "": "" };
            let response = await this.request(
                this.routerTicket + "config",
                "post",
                data
            );
            response["status"] === "success"
                ? (this.result = true)
                : (this.result = false);
            return this.result;
        } catch (e) {
            return this.result;
        }
    }

    async getTicket(ticketRoute) {
        try {
            let response = await this.request(
                this.routerTicket + ticketRoute,
                "get"
            );
            response["status"] === "success"
                ? (this.result = response)
                : (this.result = false);

            if (!this.result && response["response"]["status"] !== 500) {
                this.$toast.error({
                    title: "",
                    message: response["response"]["body"]["error"],
                    icon: "fa fa-exclamation"
                });
            }
            return this.result;
        } catch (e) {
            return this.result;
        }
    }
}
