export default [
    {
        /**
         * Open url used on the site/blog.
         */
        path: "/transportadoras",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-correto" */ "./views/CarriersPublicPage.vue"
            ),
        name: "carriers.list",
        meta: {
            hideSidebar: true
        }
    },
    {
        /**
         * Transporte Correto
         */
        path: "/transportecorreto",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transp-correto" */ "./views/Audit.vue"
            ),
        name: "transport.audit",
        meta: {
            hideSidebar: true
        }
    },
    {
        path: "/transporte",
        name: "transport.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transport" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport"
        }
    },
    {
        path: "/transporte/frota",
        name: "transport.fleet",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transport" */ "./views/vehicles/Index.js"
            ),
        meta: {
            forAuth: true,
            module: "transport"
        }
    },
    {
        path: "/transporte/solicitar-inclusao",
        name: "transport.request-addition",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transport" */ "./views/vehicles/RequestVehicleAddition.js"
            ),
        meta: {
            forAuth: true,
            module: "transport"
        }
    },
    {
        path: "/transporte/solicitar-exclusao",
        name: "transport.request-removal",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transport" */ "./views/RequestVehicleRemoval.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport"
        }
    },
    {
        path: "/transporte/modalidade-temporaria",
        name: "transport.temporary-modality",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transport" */ "./views/temporaryModality/Index.js"
            ),
        meta: {
            forAuth: true,
            module: "transport"
        }
    },
    {
        path: "/transporte/adicionar-modalidade-temporaria",
        name: "transport.add-temporary-modality",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transport" */ "./views/temporaryModality/AddTemporaryModality.vue"
            ),
        meta: {
            forAuth: true,
            module: "transport"
        }
    },
    {
        path: "/transporte/certificado",
        name: "transport.certificate",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transport" */ "./views/ListVehiclesCertificate.js"
            ),
        meta: {
            forAuth: true,
            module: "transport"
        }
    },
    {
        path: "/transporte/agendar-modalidade",
        name: "transport.modality",
        component: () =>
            import(
                /* webpackChunkName: "chunk-transport" */ "./views/modality/Index.js"
            ),
        meta: {
            forAuth: true,
            module: "transport"
        }
    }
];
