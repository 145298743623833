import { Component, Prop, Emit } from "vue-property-decorator";
import Modal from "@/core/components/Modal";
import Table from "@/core/components/Table";
import SecurityService from "../../../core/services/SecurityService";

@Component
class ProfileSelection extends SecurityService {
    @Prop({ default: null })
    action;

    @Prop({ default: null })
    subordinate;

    @Prop({ default: false })
    modal;

    @Emit()
    Save() {}

    constructor() {
        super();
        this.visible = false;
        this.isLoading = false;
        this.data = [];
        this.selectedProfiles = [];
        this.onError = {
            call: this.onErrorCallback
        };
        this.refresh = {
            call: null
        };
        this.requestParameter = {
            search: null
        };
    }

    mounted() {
        if (this.subordinate.links.length >= 1) {
            this.selectedProfiles = this.subordinate.links[0].profiles.map(
                profile => profile.id.toString()
            );
        }
    }

    onLoad(response) {
        this.data = response.data;
    }

    confirm() {
        this.isLoading = true;
        this.$Progress.start();
        const data = {
            action: this.action,
            user_id: this.subordinate.id,
            profiles: this.selectedProfiles
        };

        let info = this.postConfirmSubordinate(data);
        this.visible = false;
        this.isLoading = false;

        if (info) this.Save();
    }

    open() {
        this.visible = true;
    }

    close() {
        this.visible = false;
    }

    changeProfiles(item) {
        let { target } = item;
        if (target.checked) {
            this.selectedProfiles.push(target.value);
        } else {
            this.selectedProfiles = this.selectedProfiles.filter(
                p => p !== target.value
            );
        }
    }

    conditionToBadge(condition) {
        if (condition === "Vínculo Ativo") {
            return "badge badge-success";
        }
        if (condition === "Vínculo Rejeitado") {
            return "badge badge-danger";
        }
        if (condition === "Vínculo Inativo") {
            return "badge badge-danger";
        }
        if (condition === "Vínculo Solicitado") {
            return "badge badge-warning";
        }
        return "badge badge-default";
    }
    renderButton() {
        switch (this.action) {
            case "link":
                return (
                    <div
                        disabled={this.isLoading}
                        class="block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left text-xs cursor-pointer"
                        onClick={this.open}
                        title="Vincular esta pessoa"
                    >
                        Vincular
                    </div>
                );
            case "accept":
                return (
                    <div
                        disabled={this.isLoading}
                        class="block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left text-xs cursor-pointer"
                        onClick={this.open}
                        title="Aceitar pedido de vínculo"
                    >
                        Aceitar
                    </div>
                );
            case "edit":
                return (
                    <div
                        disabled={this.isLoading}
                        class="block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left text-xs cursor-pointer"
                        onClick={this.open}
                        title="Editar perfis do subordinado"
                    >
                        Editar Perfis
                    </div>
                );
            case "enable":
                return (
                    <div
                        disabled={this.isLoading}
                        class="block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left text-xs cursor-pointer"
                        onClick={this.open}
                        title="Ativar vínculo"
                    >
                        Ativar
                    </div>
                );
            default:
                return "";
        }
    }

    isSelected(line) {
        return (
            this.selectedProfiles.find(p => p === line.id.toString()) ===
            line.id.toString()
        );
    }

    getContent() {
        return (
            <div class="container">
                <div class="row justify-content-center">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{this.subordinate.name}</h5>
                            <div class="text-muted">
                                {this.subordinate.document_formatted}
                            </div>
                            <div class="text-muted">
                                {this.subordinate.links.length > 0 ? (
                                    <span
                                        class={this.conditionToBadge(
                                            this.subordinate.links[0].condition
                                                .name
                                        )}
                                    >
                                        {
                                            this.subordinate.links[0].condition
                                                .name
                                        }
                                    </span>
                                ) : (
                                    <span class="badge badge-default">
                                        Não vinculado
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <Table
                        class="w-100 mx-5"
                        url={"/api/security/available-profiles"}
                        pagination={false}
                        onLoad={this.onLoad}
                        errorHandler={this.onError}
                        autoLoad={true}
                        requestParameter={this.requestParameter}
                        refresh={this.refresh}
                    >
                        <div slot={"search"} />
                        <thead slot={"thead"} class="thead-light">
                            <tr>
                                <th />
                                <th>Perfil</th>
                                <th>Descrição</th>
                            </tr>
                        </thead>
                        <tbody slot={"tbody"} class="align-middle">
                            {this.data.map(line => {
                                return (
                                    <tr>
                                        <td>
                                            <input
                                                value={line.id.toString()}
                                                checked={this.isSelected(line)}
                                                onChange={this.changeProfiles}
                                                type="checkbox"
                                            />
                                        </td>
                                        <td>{line.name}</td>
                                        <td>{line.description}</td>
                                    </tr>
                                );
                            })}
                            {this.data.length === 0 ? (
                                <tr>
                                    <td
                                        colSpan={3}
                                        class="justify-content-center"
                                    >
                                        Nenhum perfil disponível!
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )}
                        </tbody>
                    </Table>
                </div>
                <div class="row justify-content-center">
                    <button class="form-new-blue" onClick={this.close}>
                        Cancelar
                    </button>
                    <button class="form-new-blue" onClick={this.confirm}>
                        Confirmar
                    </button>
                </div>
            </div>
        );
    }

    getContentModal() {
        return (
            <portal to="modals">
                <Modal width="700" noPadding={true} onClose={this.close}>
                    <div slot="header" style="text-align: right;">
                        <button
                            class="btn btn-outline-secondary mt-2 mr-2"
                            onClick={this.close}
                        >
                            Fechar
                        </button>
                    </div>
                    <div slot="body">{this.getContent()}</div>
                    <div slot="footer" />
                </Modal>
            </portal>
        );
    }

    render() {
        return (
            <div class="d-inline">
                {this.renderButton()}
                {this.visible
                    ? this.modal
                        ? this.getContentModal()
                        : this.getContent()
                    : ""}
            </div>
        );
    }
}

export default ProfileSelection;
