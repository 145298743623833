<template>
    <div class="w-full bg-gray-light">
        <div class="modal-edit md:p-5 p-0">
            <div class="md:px-2">
                <div
                    class="flex flex-wrap justify-between align-baseline mb-1 border-blue-base border-b-2 py-2"
                >
                    <h1 class="text-blue-base uppercase">
                        Representante da Empresa
                    </h1>
                </div>
            </div>
            <div
                class="flex flex-col mx-2 border-t-2 text-xs bg-white mb-2"
                v-for="(rep, index) in contacts"
                :key="index"
            >
                <div>
                    <div
                        class="flex md:flex-row flex-col justify-between w-full px-2 py-3 border items-center cursor-pointer"
                        @click="setTab(index)"
                        v-bind:class="{ 'bg-gray-400': index === tabSelected }"
                    >
                        <div
                            class="uppercase font-16"
                            v-bind:class="{
                                'text-blue-600 font-bold': index !== tabSelected
                            }"
                        >
                            Contato
                        </div>
                        <div
                            class="w-full sm:w-auto px-5 py-2 cursor-pointer"
                            v-bind:class="{
                                'btn-white ': index !== tabSelected,
                                'form-submit ': index === tabSelected
                            }"
                        >
                            Cadastrar
                            <FontAwesomeIcon
                                v-if="index === tabSelected"
                                :icon="faChevronUp"
                            ></FontAwesomeIcon>
                            <FontAwesomeIcon
                                v-else
                                :icon="faChevronDown"
                            ></FontAwesomeIcon>
                        </div>
                    </div>

                    <form
                        class="md:px-4 px-2 pt-8 text-xs"
                        v-if="index === tabSelected"
                    >
                        <div class="flex flex-col">
                            <div
                                class="flex justify-center md:justify-start flex-wrap"
                            >
                                <div class="flex flex-col md:w-3/4 w-full ">
                                    <span
                                        class="form-label text-gray-dark flex justify-between"
                                    >
                                        Nome Completo

                                        <div
                                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                            v-if="errors[index]['name']"
                                        >
                                            <span>
                                                {{ errors[index]["name"] }}
                                            </span>
                                        </div>
                                    </span>
                                    <input
                                        class="form-input rounded py-4 h-auto mr-1"
                                        v-model="rep.name"
                                        type="text"
                                    />
                                </div>
                                <div class="flex flex-col md:w-1/4 w-full">
                                    <span
                                        class="form-label text-gray-dark flex justify-between"
                                    >
                                        CPF
                                        <div
                                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                            v-if="errors[index]['cpf']"
                                        >
                                            <span>
                                                {{ errors[index]["cpf"] }}
                                            </span>
                                        </div>
                                    </span>
                                    <span v-if="isEdit">
                                        <the-mask
                                            v-if="isEdit"
                                            v-model="rep.cpf"
                                            class="form-input rounded py-4 h-auto md:ml-1"
                                            :mask="['###.###.###-##']"
                                        />
                                    </span>
                                </div>
                            </div>

                            <div
                                class="flex flex-wrap mt-2 justify-center md:justify-start"
                            >
                                <div class="w-full flex flex-wrap">
                                    <div class="w-full md:w-1/2 md:mr-3 mb-4">
                                        <span
                                            class="form-label text-gray-dark flex justify-between"
                                        >
                                            Cargo

                                            <div
                                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                                v-if="
                                                    errors[index][
                                                        'relationship_type'
                                                    ]
                                                "
                                            >
                                                <span>
                                                    {{
                                                        errors[index][
                                                            "relationship_type"
                                                        ]
                                                    }}
                                                </span>
                                            </div>
                                        </span>
                                        <select
                                            class="form-select py-4"
                                            placeholder="selecione"
                                            v-model="rep.relationship_type"
                                        >
                                            <option value="1"
                                                >Proprietário
                                            </option>
                                            <option value="2"
                                                >Procurador
                                            </option>
                                            <option value="3">Contador</option>
                                        </select>
                                    </div>

                                    <div class="mb-4 w-full md:w-1/3 ">
                                        <span
                                            class="form-label text-gray-dark flex justify-between"
                                        >
                                            E-mail de contato

                                            <div
                                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                                v-if="errors[index]['email']"
                                            >
                                                <span>
                                                    {{ errors[index]["email"] }}
                                                </span>
                                            </div>
                                        </span>
                                        <input
                                            class="form-input rounded py-4 h-auto"
                                            v-model="rep.email"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div class="w-full flex flex-wrap">
                                    <div class="w-full md:w-1/2 md:mr-3 mb-4">
                                        <span
                                            class="form-label text-gray-dark flex justify-between"
                                        >
                                            Telefone Comercial

                                            <div
                                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                                v-if="errors[index]['telC']"
                                            >
                                                <span>
                                                    {{ errors[index]["telC"] }}
                                                </span>
                                            </div>
                                        </span>

                                        <the-mask
                                            v-model="rep.telC"
                                            class="form-input rounded py-4 h-auto md:ml-1"
                                            :mask="['(##)######-#####']"
                                        />
                                    </div>

                                    <div class="mb-4 w-full md:w-1/3 ">
                                        <span
                                            class="form-label text-gray-dark flex justify-between"
                                        >
                                            Celular Comercial

                                            <div
                                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                                v-if="
                                                    errors[index]['cell_phone']
                                                "
                                            >
                                                <span>
                                                    {{
                                                        errors[index][
                                                            "cell_phone"
                                                        ]
                                                    }}
                                                </span>
                                            </div>
                                        </span>
                                        <the-mask
                                            v-model="rep.cell_phone"
                                            class="form-input rounded py-4 h-auto md:ml-1"
                                            :mask="['(##)######-#####']"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="flex justify-center sm:justify-start mt-8 w-full mb-6">
                <div
                    v-on:click="backStep()"
                    class="form-submit w-full sm:w-auto px-10 py-2 mr-5 cursor-pointer"
                    style="background: #828282"
                >
                    voltar
                </div>
                <button
                    v-on:click="onFormSubmit()"
                    class="form-submit w-full sm:w-auto px-10 py-2"
                >
                    Avançar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import RegistryService from "@/core/services/RegistryService";
import SearchSelect from "@/core/components/SearchSelect";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

@Component({
    components: {
        "search-select": SearchSelect,
        "the-mask": TheMask,
        FontAwesomeIcon,
        faChevronDown,
        faChevronUp
    }
})
export default class StepTwo extends RegistryService {
    @Prop()
    organization;

    contacts = [];
    tabSelected = 0;

    constructor() {
        super();
        this.showError = false;
        this.errors = [];
        this.isEdit = true;
        this.faChevronDown = faChevronDown;
        this.faChevronUp = faChevronUp;
    }

    beforeMount() {
        this.contacts = this.organization.contacts;
        this.setErrorsArray();
    }

    setErrorsArray() {
        this.errors = [];
        for (let r of this.contacts) {
            if (r) {
                let ar = {
                    cell_phone: "",
                    cpf: "",
                    email: "",
                    relationship_type: "",
                    telC: ""
                };
                this.errors.push(ar);
            }
        }
    }

    setTab(tab) {
        if (tab === this.tabSelected) {
            this.tabSelected = -1;
        } else {
            this.tabSelected = tab;
        }
    }

    valideStep() {
        this.showError = false;

        if (!this.contacts[0].cpf) {
            this.errors[0]["cpf"] = "* Preencha o campo";
            this.showError = true;
        }

        if (!this.validaCpf(this.contacts[0].cpf)) {
            this.errors.cpfResponsible = ["* CPF inválido"];
            this.showError = true;
        }

        if (!this.contacts[0].cell_phone) {
            this.errors[0]["cell_phone"] = "* Preencha o campo";
            this.showError = true;
        }
        if (!this.contacts[0].email) {
            this.errors[0]["email"] = "* Preencha o campo";
            this.showError = true;
        }
        if (!this.contacts[0].relationship_type) {
            this.errors[0]["relationship_type"] = "* Preencha o campo";
            this.showError = true;
        }
        if (!this.contacts[0].name) {
            this.errors[0]["name"] = "* Preencha o campo";
            this.showError = true;
        }

        for (let i = 0; i < this.contacts.length; i++) {
            if (this.contacts[i].name) {
                if (!this.contacts[i].cpf) {
                    this.errors[i]["cpf"] = "* Preencha o campo";
                    this.showError = true;
                }

                if (!this.validaCpf(this.contacts[i].cpf)) {
                    this.errors[i]["cpf"] = "* CPF inválido.";
                    this.showError = true;
                }

                if (!this.contacts[i].cell_phone) {
                    this.errors[i]["cell_phone"] = "* Preencha o campo";
                    this.showError = true;
                }
                if (!this.contacts[i].email) {
                    this.errors[i]["email"] = "* Preencha o campo";
                    this.showError = true;
                }
                if (!this.contacts[i].relationship_type) {
                    this.errors[i]["relationship_type"] = "* Preencha o campo";
                    this.showError = true;
                }
            }
        }

        if (!this.showError) {
            this.$emit("update:organization", this.organization);
            this.$emit("progressStep");
        }
    }

    backStep() {
        this.$emit("returnStep");
    }

    validaCpf(cpf) {
        cpf = cpf.replace(/[^\d]+/g, "");
        if (cpf === "") {
            return false;
        }

        if (
            cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999"
        ) {
            return false;
        }

        let add = 0;
        for (let i = 0; i < 9; i++) {
            add += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11) {
            rev = 0;
        }
        if (rev !== parseInt(cpf.charAt(9))) {
            return false;
        }
        add = 0;
        for (let i = 0; i < 10; i++) {
            add += parseInt(cpf.charAt(i)) * (11 - i);
        }
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11) {
            rev = 0;
        }
        if (rev !== parseInt(cpf.charAt(10))) {
            return false;
        }
        return true;
    }

    citySelected(city) {
        this.user.city = city;
        this.user.city_id = city.id;
    }

    async onFormSubmit() {
        this.setErrorsArray();
        this.valideStep();
    }
}
</script>
