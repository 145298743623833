<script>
export default {
    methods: {
        close() {
            this.$store.commit("clearLink");
            this.$router.push({ name: "index" });
            this.$emit("close");
        }
    }
};
</script>

<template>
    <div>
        <div
            class="flex flex-col px-4 pt-5 pb-4 space-y-4 bg-white sm:p-12 sm:pb-4"
        >
            <img
                class="w-48"
                src="@/core/assets/images/logo-scmobi.png"
                alt="SCmobi"
            />
            <p class="text-sm font-medium">
                A complementação dos dados de cadastro desta empresa não foi
                realizada. Para ter acesso a este formulário, se autentique no
                SCmobi utilizando o CNPJ de sua operadora.
            </p>
            <p class="text-sm font-medium">
                O procedimento, simples e rápido, é objeto da Instrução
                Normativa SIE – nº 002/2021, publicada no Diário Oficial nº
                21.487 de 26/03/2021, disponível em
                <a
                    href="https://www.sie.sc.gov.br/"
                    class="text-blue-darker hover:text-blue-base"
                    target="_blank"
                >
                    www.sie.sc.gov.br</a
                >.
            </p>
        </div>
        <div
            class="px-4 py-3 bg-gray-100 sm:px-6 sm:flex sm:flex-col sm:place-items-center"
        >
            <span
                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
                <button
                    @click="close"
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 text-xs font-medium leading-normal uppercase transition duration-150 ease-in-out rounded-md shadow-sm btn-blue focus:outline-none sm:leading-tight"
                >
                    Fechar
                </button>
            </span>
        </div>
    </div>
</template>
