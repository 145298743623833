import { Component, Prop, Emit } from "vue-property-decorator";
import Modal from "@/core/components/Modal";
import SecurityService from "../../../core/services/SecurityService";

@Component
class SubordinateConfirmation extends SecurityService {
    @Prop({ default: null })
    action;

    @Prop({ default: null })
    subordinate;

    @Prop({ default: false })
    modal;

    @Emit()
    Confirm() {}

    constructor() {
        super();
        this.visible = false;
        this.isLoading = false;
    }

    confirm() {
        this.isLoading = true;
        this.$Progress.start();
        const data = {
            action: this.action,
            user_id: this.subordinate.id
        };

        let info = this.postConfirmSubordinate(data);
        this.visible = false;
        this.isLoading = false;

        if (info) this.Confirm();
    }

    open() {
        this.visible = true;
    }

    close() {
        this.visible = false;
    }

    renderButton() {
        switch (this.action) {
            case "deny":
                return (
                    <div
                        disabled={this.isLoading}
                        class="block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left text-xs cursor-pointer"
                        onClick={this.open}
                        title="Rejeitar pedido de vínculo"
                    >
                        Rejeitar
                    </div>
                );
            case "disable":
                return (
                    <div
                        disabled={this.isLoading}
                        class="block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left text-xs cursor-pointer"
                        onClick={this.open}
                        title="Desativar vínculo"
                    >
                        Desativar
                    </div>
                );
            default:
                return "";
        }
    }

    renderMessage() {
        switch (this.action) {
            case "deny":
                return (
                    <h5>
                        Deseja rejeitar o pedido de vínculo de{" "}
                        <strong>{this.subordinate.name}</strong>?
                    </h5>
                );
            case "disable":
                return (
                    <h5>
                        Deseja desativar o vínculo de{" "}
                        <strong>{this.subordinate.name}</strong>?
                    </h5>
                );
            default:
                return "";
        }
    }

    getContent() {
        return (
            <div class="container p-3 mt-5">
                <div class="mb-5 row justify-content-center">
                    {this.renderMessage()}
                </div>
                <div class="row justify-content-center">
                    <button class="form-new-blue" onClick={this.close}>
                        Cancelar
                    </button>
                    <button class="form-new-blue" onClick={this.confirm}>
                        Confirmar
                    </button>
                </div>
            </div>
        );
    }

    getContentModal() {
        return (
            <portal to="modals">
                <Modal width="800" noPadding={true} onClose={this.close}>
                    <div slot="header" style="text-align: right;">
                        <button
                            class="btn btn-outline-secondary mt-2 mr-2"
                            onClick={this.close}
                        >
                            Fechar
                        </button>
                    </div>
                    <div slot="body">{this.getContent()}</div>
                    <div slot="footer" />
                </Modal>
            </portal>
        );
    }

    render() {
        return (
            <div class="d-inline">
                {this.renderButton()}
                {this.visible
                    ? this.modal
                        ? this.getContentModal()
                        : this.getContent()
                    : ""}
            </div>
        );
    }
}

export default SubordinateConfirmation;
