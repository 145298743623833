import axios from "axios";

async function getModules() {
    try {
        const response = axios.post("/api/security/menu");
        return response;
    } catch (e) {
        throw {
            status: e.response.status,
            error:
                e.response.data.errors ||
                e.response.data.error ||
                e.response.data.message ||
                "Erro interno, tente novamente ou mais tarde."
        };
    }
}

export { getModules };
