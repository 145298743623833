import axios from "axios";

export default {
    async getToken({ commit, dispatch }, user) {
        user.client_id = 2;
        user.client_secret = process.env.VUE_APP_KEY;
        user.grant_type = "password";
        try {
            const resp = await axios({
                url: "/oauth/token",
                data: user,
                method: "POST"
            });
            commit("setToken", resp.data);
            await dispatch("getUser");
            return true;
        } catch (err) {
            commit("setLogout");
            throw err;
        }
    },
    async getUser({ commit }) {
        try {
            const resp = await axios({ url: "/api/me" });
            commit("setUser", resp.data);
            return resp;
        } catch (err) {
            commit("setLogout");
            throw err;
        }
    },
    async getPermissions({ commit }) {
        try {
            const resp = await axios({ url: "/api/me/permissions" });
            commit("setPermissions", resp.data);
            return resp;
        } catch (err) {
            commit("setPermissions", null);
            throw err;
        }
    },
    async getLogout({ commit }) {
        try {
            const resp = await axios({ url: "/api/logout", method: "DELETE" });
            commit("setLogout");
            return resp;
        } catch (err) {
            commit("setLogout");
            throw err;
        }
    }
};
