export default [
    {
        path: "/administrador",
        name: "admin.index",
        component: () =>
            import(/* webpackChunkName: "chunk-admin" */ "./views/Index.vue"),
        meta: {
            forAuth: true,
            module: "admin"
        }
    },
    {
        path: "/administrador/perfis",
        name: "admin.profiles",
        component: () =>
            import(
                /* webpackChunkName: "chunk-admin" */ "./views/profiles/Index"
            ),
        meta: {
            forAuth: true,
            module: "admin"
        }
    },
    {
        path: "/administrador/perfis-instituicoes",
        name: "admin.organization-profiles",
        component: () =>
            import(
                /* webpackChunkName: "chunk-admin" */ "./views/organizations/Index"
            ),
        meta: {
            forAuth: true,
            module: "admin"
        }
    },
    {
        path: "/administrador/auditoria",
        name: "admin.audit",
        component: () =>
            import(/* webpackChunkName: "chunk-admin" */ "./views/audit/Index"),
        meta: {
            forAuth: true,
            module: "admin"
        }
    }
];
