<template>
    <div class="h-full">
        <div
            v-if="this.$store.getters.isLinkSelected"
            class="relative h-full"
            v-click-outside="close"
        >
            <button
                @click="isOpen = !isOpen"
                class="h-full lg:text-right lg:flex lg:items-center text-blue-lightest hover:text-white focus:text-white focus:outline-none"
            >
                <div class="text-right">
                    <div class="flex justify-end items-center">
                        <p class="text-sm uppercase">
                            {{
                                this.$store.state.auth.link.organization
                                    .business_name ||
                                    this.$store.state.auth.link.organization
                                        .name
                            }}
                        </p>
                    </div>
                    <p class="text-xs">
                        {{
                            this.$store.state.auth.link.organization
                                .document_formatted
                        }}
                    </p>
                </div>
                <Icon icon="arrowDown" class="ml-2 h-2 fill-current" />
            </button>
            <div v-if="isOpen" class="absolute w-64 h-64 z-50 right-0">
                <div
                    class="max-h-full overflow-y-auto bg-white rounded-sm shadow-md border-white border-4"
                >
                    <button
                        @click="reloadLinks"
                        class="w-full flex items-center justify-end border-b-2 border-gray-light bg-white text-gray-dark hover:bg-green-base hover:text-white active:bg-green-base active:text-white px-3 py-2"
                    >
                        <Icon icon="refresh" class="mr-2 h-3 fill-current" />
                        <span class="text-xs tracking-wider">
                            Atualizar vínculos
                        </span>
                    </button>
                    <button
                        v-for="link in otherLinks(
                            this.$store.state.auth.user.links
                        )"
                        v-bind:key="link.id"
                        @click="selectLink(link)"
                        class="w-full flex flex-col justify-center items-end px-3 py-2 text-right text-xs text-gray-800 border-white border-t-4 bg-gray-lighter hover:bg-gray-base hover:text-gray-darker active:text-white active:bg-gray-dark focus:outline-none"
                    >
                        <span>{{
                            link.organization.business_name ||
                                link.organization.name
                        }}</span>
                        <span>{{ link.organization.document_formatted }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div
            v-else-if="this.$store.state.auth.user.links.length"
            class="relative h-full"
            v-click-outside="close"
        >
            <button
                @click="isOpen = !isOpen"
                class="h-full lg:text-right lg:flex lg:items-center text-blue-lightest hover:text-white focus:text-white focus:outline-none"
            >
                <div class="flex items-center">
                    <Icon
                        icon="attention"
                        class="w-4 h-4 mr-2"
                        viewBox="0 0 25 25"
                    />
                    <p class="text-sm">
                        Selecione um vínculo
                    </p>
                </div>
                <Icon icon="arrowDown" class="ml-1 h-2 fill-current" />
            </button>
            <div v-if="isOpen" class="absolute w-64 h-64 z-50 right-0">
                <div
                    class="max-h-full overflow-y-auto bg-white rounded-sm shadow-md border-white border-4"
                >
                    <button
                        @click="reloadLinks"
                        class="w-full flex items-center justify-end border-b-2 border-gray-light bg-white text-gray-dark hover:bg-green-base hover:text-white active:bg-green-base active:text-white px-3 py-2"
                    >
                        <Icon icon="refresh" class="mr-2 h-3 fill-current" />
                        <span class="text-xs tracking-wider">
                            Atualizar vínculos
                        </span>
                    </button>
                    <button
                        @click="selectLink(link)"
                        v-for="link in this.$store.state.auth.user.links"
                        v-bind:key="link.id"
                        class="w-full flex flex-col justify-center items-end px-3 py-2 text-right text-xs text-gray-800 border-white border-t-4 bg-gray-lighter hover:bg-gray-base hover:text-gray-darker active:text-white active:bg-gray-dark focus:outline-none"
                    >
                        <span>{{
                            link.organization.business_name ||
                                link.organization.name
                        }}</span>
                        <span>{{ link.organization.document_formatted }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="relative h-full" v-click-outside="close">
            <button
                @click="isOpen = !isOpen"
                class="h-full lg:text-right lg:flex lg:items-center text-blue-lightest hover:text-white focus:text-white focus:outline-none"
            >
                <div class="flex items-center">
                    <Icon
                        icon="attention"
                        class="w-4 h-4 mr-2"
                        viewBox="0 0 25 25"
                    />
                    <p class="text-sm">
                        Você ainda não possui nenhum vínculo
                    </p>
                </div>
                <Icon icon="arrowDown" class="ml-1 h-2 fill-current" />
            </button>
            <div
                v-if="isOpen"
                class="absolute w-64 z-50 right-0 bg-white rounded-sm shadow-md border-white border-4"
            >
                <button
                    @click="reloadLinks"
                    class="w-full flex items-center justify-end border-b-2 border-gray-light bg-white text-gray-dark hover:bg-green-base hover:text-white active:bg-green-base active:text-white px-3 py-2"
                >
                    <Icon icon="refresh" class="mr-2 h-3 fill-current" />
                    <span class="text-xs tracking-wider">
                        Atualizar vínculos
                    </span>
                </button>
                <router-link
                    :to="{ name: 'link.create' }"
                    class="w-full flex flex-col justify-center items-end px-5 py-2 text-xs text-white border-white border-t-4 bg-red-base hover:bg-red-800 active:bg-red-800 focus:outline-none"
                >
                    <span>
                        Para ter acesso às informações, é necessário estar
                        vinculado à alguma instituição. Não sabe como vincular
                        uma instituição? Clique aqui.
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/core/components/Icon";

export default {
    components: {
        Icon
    },
    data() {
        return {
            isOpen: false
        };
    },
    created() {
        const handleEscape = e => {
            if (e.key === "Esc" || e.key === "Escape") {
                this.close();
            }
        };
        document.addEventListener("keydown", handleEscape);
        this.$once("hook:beforeDestroy", () => {
            document.removeEventListener("keydown", handleEscape);
        });
    },
    methods: {
        otherLinks(links) {
            return links.filter(link => {
                return link.id !== this.$store.state.auth.link.id;
            });
        },
        reloadLinks() {
            this.$Progress.start();
            this.$store
                .dispatch("getUser")
                .then(() => {
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$Progress.finish();
                });
        },
        selectLink(link) {
            this.$store.commit("setLink", link);
            this.$store.dispatch("getPermissions");
            if (this.$route.name !== "index") {
                this.$router.replace({ name: "index" });
            }
        },
        close() {
            this.isOpen = false;
        }
    }
};
</script>
