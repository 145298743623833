<script>
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";
import { mask } from "@/core/services/helpers";
import { getAddressByCep } from "../../getAddressByCep";
import SearchSelect from "@/core/components/SearchSelect";
import { parse } from "date-fns";
export default {
    components: { TheMask, Money, SearchSelect },
    props: {
        beneficiarioTrabalhador: {
            nminstituicao: '',
            tpcurso: null,
            nuseriecurso: null,
            idcurso: null,
            document: "",
            vlsalario: 0,
            nmprofissao: "",
            cep: "",
            street: "",
            number: "",
            district: "",
            complement: "",
            numunicipio: {
                name: "",
                state: {
                    name: ""
                }
            }
        },
        beneficiarioTrabalhadorForm: {
            nminstituicao: '',
            tpcurso: null,
            nuseriecurso: null,
            idcurso: null,
            document: "",
            vlsalario: 0,
            nmprofissao: "",
            cep: "",
            street: "",
            number: "",
            district: "",
            complement: "",
            numunicipio: {
                name: "",
                state: {
                    name: ""
                }
            },
            isValidAdmissionDate: {
                type: Boolean,
                default: false
            },
            hasErrors: false
        },
        hasErrors: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },
    data: () => {
        return {
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                precision: 2
            }
        };
    },
    methods: {
        mask,
        getTpCurso(tpcurso) {
            if (tpcurso) {
                return this.tipoCursos.find(
                    item => Number(item.value) === Number(tpcurso)
                ).name;
            }
        },
        profissaoSelected(profissao) {
            this.beneficiarioTrabalhadorForm.profissao = profissao;
        },
        validateAdmissionDateOnInput(value) {
            let originalValue = value.slice(0, -1);
            let valid = true;

            // previne que a data seja maior que 10 caracteres
            if (value.length > 10) {
                valid = false;
            }

            // valida se foi digitado alfanumérico
            if (isNaN(value.charAt(value.length - 1)) && value.charAt(value.length - 1) !== '/') {
                valid = false;
            }

            // valida primeiro digito
            if (value.length == 1) {
                valid = new RegExp(/^[0-3]$/).test(value.charAt(0));
            }

            // valida segundo digito
            if (value.length == 2) {
                if (value.charAt(0) == '3') {
                    valid = new RegExp(/^[0-1]$/).test(value.charAt(1));
                } else if (value.charAt(0) == '0') {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(1));
                } else {
                    valid = new RegExp(/^[0-9]$/).test(value.charAt(1));
                }
                value += '/';
            }

            // valida terceiro digito
            if (value.length == 4) {
                valid = new RegExp(/^[0-1]$/).test(value.charAt(3));
            }

            // valida quarto digito
            if (value.length == 5) {
                if (value.charAt(3) == '1') {
                    valid = new RegExp(/^[0-2]$/).test(value.charAt(4));
                } else {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(4));
                }
                value += '/';
            }

            if (valid) {
                this.beneficiarioTrabalhadorForm.dtadmissao = value;
                if (value.length === 10) {
                    const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
                    if (parsedDate > new Date()) {
                        this.$toast.error({
                            title: "Erro",
                            message: "Data de admissão não pode ser maior que a data atual!"
                        });
                        this.beneficiarioTrabalhadorForm.isValidAdmissionDate = false;
                    } else {
                        this.beneficiarioTrabalhadorForm.isValidAdmissionDate = true;
                    }
                }
                if (value.length === 8) {
                    this.beneficiarioTrabalhadorForm.isValidAdmissionDate = false;
                }
            } else {
                this.$nextTick(() => {
                    this.beneficiarioTrabalhadorForm.dtadmissao = originalValue;
                });
            }
        },
        async checkCPFValid(document) {
            if (document.length == 11 || document.length == 14) {
                try {
                    const response = await this.$axios.get(`/api/spl-navegantes/beneficiarios/documento/${document}`);
                    if (response.data) {
                        this.beneficiarioTrabalhadorForm.nminstituicao = response.data.name;
                        this.beneficiarioTrabalhador.nminstituicao = response.data.name;
                        this.beneficiarioTrabalhadorForm.district = response.data.district;
                        this.beneficiarioTrabalhadorForm.cep = response.data.cep;
                        this.beneficiarioTrabalhador.cep = response.data.cep;
                        this.beneficiarioTrabalhadorForm.street = response.data.street;
                        this.beneficiarioTrabalhadorForm.number = response.data.number;
                        this.beneficiarioTrabalhadorForm.complement = response.data.complement;
                        this.beneficiarioTrabalhadorForm.municipio = response.data.city;
                    } else {
                        this.$toast.error({
                            message: "Documento não encontrado."
                        });
                    }

                } catch (error) {
                    //console.log(error)
                }
            }
        },
        async onCepChangeInstituicao(cep) {
            if (cep.length === 8) {
                this.$Progress.start();
                const {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    ibge
                } = await getAddressByCep(cep);
                if (logradouro) {
                    this.beneficiarioTrabalhadorForm.street = logradouro;
                    this.beneficiarioTrabalhadorForm.district = bairro;
                    this.beneficiarioTrabalhadorForm.municipio = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    }
                    this.beneficiarioTrabalhador.municipio = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    }
                } else {
                    this.$toast.error({
                        message: "CEP Inválido."
                    });
                }
                this.$Progress.finish();
            }
        },
        cityInstituicaoSelected(city) {
            this.beneficiarioTrabalhadorForm.municipio = city;
            this.beneficiarioTrabalhador.municipio = city;
        }
    }
};
</script>
<template>
    <div>
        <div class="flex justify-between w-full my-8 border-b-2 border-blue-base bg-white shadow-lg">
            <span class="mt-2 text-base uppercase text-blue-base">
                Informações trabalhistas
            </span>
            <slot></slot>
        </div>
        <div class="grid gap-5 grid-cols-1 md:grid-cols-12 px-3">
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    CPF/CNPJ *
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.document.length == 11 ?
                        mask(beneficiarioTrabalhador.document, "###.###.###-##") :
                        mask(beneficiarioTrabalhador.document, "##.###.###/####-##")
                    }}
                </template>
                <template v-else>
                    <span>
                        <TheMask v-model="beneficiarioTrabalhadorForm.document"
                            :mask="['##.###.###/####-##', '###.###.###-##']" class="h-auto py-2 rounded form-input"
                            :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioTrabalhadorForm.hasErrors &&
                                    !beneficiarioTrabalhadorForm.document
                            }" required @input="checkCPFValid" />
                        <div v-if="beneficiarioTrabalhadorForm.hasErrors &&
                            !beneficiarioTrabalhadorForm.document
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </span>
                </template>
            </div>
            <div class="col-span-6">
                <span class="text-sm form-label text-gray-dark">
                    Nome da Instituição
                </span>
                <span>
                    <template v-if="readonly">
                        {{ beneficiarioTrabalhador.nminstituicao }}
                    </template>
                    <template v-else>
                        <input v-model="beneficiarioTrabalhadorForm.nminstituicao" :class="{
                            'bg-red-100 border-red-400':
                                beneficiarioTrabalhadorForm.hasErrors &&
                                !beneficiarioTrabalhadorForm.nminstituicao
                        }" type="text" class="h-auto py-2 rounded form-input" />
                        <div v-if="beneficiarioTrabalhadorForm.hasErrors &&
                            !beneficiarioTrabalhadorForm.nminstituicao
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </template>
                </span>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Data de admissão
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.dtadmissao }}
                </template>
                <template v-else>
                    <span>
                            <input v-model="beneficiarioTrabalhadorForm.dtadmissao" type="text"
                                    class="h-auto py-2 rounded form-input" :class="{
                                        'bg-red-100 border-red-400': hasErrors && (!beneficiarioTrabalhadorForm.dtadmissao || !isValidAdmissionDate),
                                    }" required @input="$event => validateAdmissionDateOnInput($event.target.value)" />
                        <div v-if="!beneficiarioTrabalhadorForm.isValidAdmissionDate && beneficiarioTrabalhadorForm.dtadmissao && beneficiarioTrabalhadorForm.dtadmissao.length === 10"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Data inválida
                        </div>
                        <div v-if="!beneficiarioTrabalhadorForm.isValidAdmissionDate && beneficiarioTrabalhadorForm.dtadmissao.length === 8"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Data inválida
                        </div>
                        <div v-if="beneficiarioTrabalhadorForm.hasErrors &&
                            !beneficiarioTrabalhadorForm.dtadmissao
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </span>
                </template>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Profissão
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.nmprofissao }}
                </template>
                <template v-else>
                    <span>
                        <span>
                            <SearchSelect :selected.sync="beneficiarioTrabalhador.profissao"
                                :search-keys="['profissao']" :search-remote="true" :lazy-load="true"
                                :option-value="$event => $event.nmprofissao" option-key="idprofissao" name="idprofissao"
                                no-query-param :options-url="`/api/spl-navegantes/profissoes/`
                                    " field-class="py-2" @changed="profissaoSelected"
                                class="h-auto rounded form-select" :class="{
                                    'bg-red-100 border-red-400':
                                        beneficiarioTrabalhadorForm.hasErrors &&
                                        !beneficiarioTrabalhadorForm.profissao
                                }"></SearchSelect>
                            <div v-if="beneficiarioTrabalhadorForm.hasErrors &&
                                !beneficiarioTrabalhadorForm.profissao
                            " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </span>
                </template>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Salário
                </span>

                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.vlsalario }}
                </template>
                <template v-else>
                    <span>
                        <money class="h-auto py-2 rounded form-input" v-model="beneficiarioTrabalhadorForm.vlsalario"
                            :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioTrabalhadorForm.hasErrors &&
                                    !beneficiarioTrabalhadorForm.vlsalario
                            }" v-bind="money" required />
                        <div v-if="beneficiarioTrabalhadorForm.hasErrors &&
                            !beneficiarioTrabalhadorForm.vlsalario
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </span>
                </template>
            </div>
        </div>
        <div class="grid gap-5 grid-cols-1 md:grid-cols-12 px-3">
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    CEP
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.cep ? mask(beneficiarioTrabalhador.cep, "##.###-##") : '' }}
                </template>
                <template v-else>
                    <div>
                        <TheMask v-model="beneficiarioTrabalhadorForm.cep" :mask="['##.###-###']"
                            class="h-auto py-2 rounded form-input mb-0" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioTrabalhadorForm.hasErrors &&
                                    !beneficiarioTrabalhadorForm.cep
                            }" @input="onCepChangeInstituicao" required />
                        <div v-if="beneficiarioTrabalhadorForm.hasErrors &&
                            !beneficiarioTrabalhadorForm.cep"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-6">
                <span class="text-sm form-label text-gray-dark">
                    Endereço
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.street }}
                </template>
                <template v-else>
                    <div>
                        <input v-model="beneficiarioTrabalhadorForm.street" type="text"
                            class="h-auto py-2 rounded form-input mb-0" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioTrabalhadorForm.hasErrors &&
                                    !beneficiarioTrabalhadorForm.street
                            }" />
                        <div v-if="
                            beneficiarioTrabalhadorForm.hasErrors &&
                            !beneficiarioTrabalhadorForm.street
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-1">
                <span class="text-sm form-label text-gray-dark">
                    Número
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.number }}
                </template>
                <template v-else>
                    <div>
                        <input v-model="beneficiarioTrabalhadorForm.number" type="number"
                            class="h-auto py-2 rounded form-input mb-0" />
                        <!-- <div
                            v-if="
                                hasErrors &&
                                    !enderecoInstituicaoForm.number
                            "
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                        >
                            Campo obrigatório
                        </div> -->
                    </div>
                </template>
            </div>
            <div class="col-span-2">
                <span class="text-sm form-label text-gray-dark">
                    Bairro
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.district }}
                </template>
                <template v-else>
                    <div>
                        <input v-model="beneficiarioTrabalhadorForm.district" type="text"
                            class="h-auto py-2 rounded form-input mb-0" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioTrabalhadorForm.hasErrors &&
                                    !beneficiarioTrabalhadorForm.district
                            }" />
                        <div v-if="
                            beneficiarioTrabalhadorForm.hasErrors &&
                            !beneficiarioTrabalhadorForm.district
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Complemento
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.complement }}
                </template>
                <template v-else>
                    <div>
                        <input v-model="beneficiarioTrabalhadorForm.complement" type="text"
                            class="h-auto py-2 rounded form-input mb-0" />
                    </div>
                </template>
            </div>
            <div class="col-span-6 ">
                <span class="text-sm form-label text-gray-dark">
                    Cidade/estado
                </span>
                <template v-if="readonly">
                    {{ beneficiarioTrabalhador.municipio ? beneficiarioTrabalhador.municipio.name +
                        " / " +
                        beneficiarioTrabalhador.municipio.state
                            .name : "" }}
                </template>
                <template v-else>
                    <div>
                        <search-select :selected.sync="beneficiarioTrabalhador.municipio" :search-keys="['name']"
                            :search-remote="true" :lazy-load="true"
                            options-url="/api/registry/location?take=50&paginate=false" option-key="id" :option-value="city =>
                                city.name +
                                ' / ' +
                                city.state.initials
                                " field-class="py-2" name="city" placeholder="Selecione uma cidade..."
                            @changed="cityInstituicaoSelected" />
                        <div v-if="beneficiarioTrabalhadorForm.hasErrors && !this.beneficiarioTrabalhadorForm.municipio"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
