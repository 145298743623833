<template>
    <header
        class="sticky top-0 z-30 flex items-center justify-between w-full py-1 pr-5 bg-blue-background"
    >
        <div class="hidden lg:flex">
            <router-link :to="{ name: 'index' }">
                <img
                    class="h-12"
                    src="@/core/assets/images/logo-scmobi-white.svg"
                    alt="SCmobi"
                />
            </router-link>
        </div>
        <div
            v-if="!this.$route.meta.hideSidebar"
            class="cursor-pointer lg:hidden"
            @click.prevent="toggleSidebar"
        >
            <Icon icon="menu" class="h-6 mx-4 text-white fill-current" />
        </div>
        <div class="lg:hidden">
            <router-link :to="{ name: 'index' }">
                <img
                    class="h-12"
                    src="@/core/assets/images/logo-scmobi-white.svg"
                    alt="SCmobi"
                />
            </router-link>
        </div>
        <div class="items-center justify-end hidden lg:flex">
            <div v-if="!this.$store.getters.isOrganization" class="lg:mr-6">
                <Links />
            </div>
            <a
                href="https://ajuda.sie.sc.gov.br"
                target="_blank"
                class="hidden lg:block lg:text-right lg:flex lg:items-center lg:mr-6 text-blue-lightest hover:text-white focus:text-white"
            >
                <Icon icon="help" class="h-6 mr-1 fill-current" />
                <p class="text-sm">Ajuda</p>
            </a>
            <Notifications class="lg:mr-6" />
            <div v-if="showModules" class="lg:mr-6">
                <Modules />
            </div>
            <div>
                <Account />
            </div>
        </div>
    </header>
</template>

<script>
import Account from "@/core/components/header/Account";
import Links from "@/core/components/header/Links";
import Notifications from "@/core/components/header/Notifications";
import Icon from "@/core/components/Icon";
import Modules from "@/core/components/header/Modules";

export default {
    components: {
        Account,
        Links,
        Notifications,
        Icon,
        Modules
    },
    computed: {
        showModules: function() {
            if (this.$route.name == "index") {
                return false;
            }
            return true;
        }
    },
    methods: {
        toggleSidebar() {
            this.$emit("toggleSidebar");
        }
    }
};
</script>
