import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "@/core/components/Icon";

@Component
export default class ModuleButton extends Vue {
    @Prop({ default: "" })
    name;
    @Prop({ default: "user" })
    icon;
    @Prop({ default: "index" })
    route;

    render() {
        return (
            <router-link to={{ name: this.route }} class="w-24">
                <div class="h-24 mb-1 rounded-full items-center flex justify-center bg-gray-light text-blue-base">
                    <Icon class="h-10 fill-current" icon={this.icon} />
                </div>
                <p class="text-gray-darker font-bold text-xs text-center">
                    {this.name}
                </p>
            </router-link>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/home/gitlab-runner/builds/glrt-b-z/0/scmobi/scmobi-frontend-v4/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-61f44cb4/ModuleButton.jsx" });