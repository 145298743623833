<script>
import { TheMask } from "vue-the-mask";
import { getAddressByCep } from "../../getAddressByCep";
import { mask } from "@/core/services/helpers";
import SearchSelect from "@/core/components/SearchSelect";
import { parse } from "date-fns";
export default {
    components: { TheMask, SearchSelect },
    props: {
        beneficiarioEstagiario: {
            type: Object,
            default: () => {
                return {
                    nminstituicao: "",
                    document: "",
                    dtadmissao: "",
                    dtencerramento: "",
                    cep: "",
                    street: "",
                    number: "",
                    district: "",
                    complement: "",
                    municipio: {
                        name: "",
                        state: {
                            name: ""
                        }
                    }
                };
            }
        },
        beneficiarioEstagiarioForm: {
            type: Object,
            default: () => {
                return {
                    nminstituicao: "",
                    document: "",
                    dtadmissao: "",
                    dtencerramento: "",
                    cep: "",
                    street: "",
                    number: "",
                    district: "",
                    complement: "",
                    municipio: {
                        name: "",
                        state: {
                            name: ""
                        }
                    },
                    hasErrors: {
                        type: Boolean,
                        default: false
                    },
                    isValidClosureDate: {
                        type: Boolean,
                        default: true
                    },
                    isValidAdmissionDate: {
                        type: Boolean,
                        default: true
                    }
                };
            }
        },
        hasErrors: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        mask,
        async checkCPFValid(document) {
            if (document.length == 14) {
                const { data } = await this.$axios.get(
                    `/api/spl-navegantes/beneficiarios/documento/${document}`
                );
                if (data) {
                    this.beneficiarioEstagiarioForm.nminstituicao = data.name;
                    this.nminstituicao = data.name;
                    this.beneficiarioEstagiarioForm.district = data.district;
                    this.beneficiarioEstagiarioForm.street = data.street;
                    this.beneficiarioEstagiarioForm.cep = data.cep;
                    this.beneficiarioEstagiario.cep = data.cep;
                    this.beneficiarioEstagiarioForm.number = data.number;
                    this.beneficiarioEstagiarioForm.complement = data.complement;
                    this.beneficiarioEstagiarioForm.municipio = data.city;
                    this.beneficiarioEstagiario.municipio = data.city;
                }
            }
        },
        compareAdmissionDateAndClosureDate(dtadmissao, dtencerramento) {
            if (dtadmissao.length === 10 && dtencerramento.length === 10) {
                const parsedAdmissionDate = parse(dtadmissao, 'dd/MM/yyyy', new Date());
                const parsedClosureDate = parse(dtencerramento, 'dd/MM/yyyy', new Date());
                if (parsedAdmissionDate > parsedClosureDate) {
                    this.$toast.error({
                        title: "Erro",
                        message: "Data de encerramento não pode ser menor que a data de admissão!"
                    });
                    this.beneficiarioEstagiarioForm.isValidClosureDate = false;
                    return false;
                } else {
                    this.beneficiarioEstagiarioForm.isValidClosureDate = true;
                    return true;
                }
            }
            return true;
        },
        validateClosureDateOnInput(value) {
            let originalValue = value.slice(0, -1);
            let valid = true;

            // previne que a data seja maior que 10 caracteres
            if (value.length > 10) {
                valid = false;
            }

            // valida se foi digitado alfanumérico
            if (isNaN(value.charAt(value.length - 1)) && value.charAt(value.length - 1) !== '/') {
                valid = false;
            }

            // valida primeiro digito
            if (value.length == 1) {
                valid = new RegExp(/^[0-3]$/).test(value.charAt(0));
            }

            // valida segundo digito
            if (value.length == 2) {
                if (value.charAt(0) == '3') {
                    valid = new RegExp(/^[0-1]$/).test(value.charAt(1));
                } else if (value.charAt(0) == '0') {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(1));
                } else {
                    valid = new RegExp(/^[0-9]$/).test(value.charAt(1));
                }
                value += '/';
            }

            // valida terceiro digito
            if (value.length == 4) {
                valid = new RegExp(/^[0-1]$/).test(value.charAt(3));
            }

            // valida quarto digito
            if (value.length == 5) {
                if (value.charAt(3) == '1') {
                    valid = new RegExp(/^[0-2]$/).test(value.charAt(4));
                } else {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(4));
                }
                value += '/';
            }

            if (valid) {
                this.beneficiarioEstagiarioForm.dtencerramento = value;
                let dtadmissao = this.beneficiarioEstagiarioForm.dtadmissao;
                if (value.length === 10) {
                    if (dtadmissao && this.compareAdmissionDateAndClosureDate(dtadmissao, value)) {
                        const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
                        if (parsedDate < new Date()) {
                            this.$toast.error({
                                title: "Erro",
                                message: "Data de encerramento não pode ser menor que a data atual!"
                            });
                            this.isValidClosureDate = false;
                        } else {
                            this.isValidClosureDate = true;
                        }
                    }
                }
                if (value.length === 8) {
                    this.isValidClosureDate = false;
                }
            } else {
                this.$nextTick(() => {
                    this.beneficiarioEstagiarioForm.dtencerramento = originalValue;
                });
            }
        },
        validateAdmissionDateOnInput(value) {
            let originalValue = value.slice(0, -1);
            let valid = true;

            // previne que a data seja maior que 10 caracteres
            if (value.length > 10) {
                valid = false;
            }

            // valida se foi digitado alfanumérico
            if (isNaN(value.charAt(value.length - 1)) && value.charAt(value.length - 1) !== '/') {
                valid = false;
            }

            // valida primeiro digito
            if (value.length == 1) {
                valid = new RegExp(/^[0-3]$/).test(value.charAt(0));
            }

            // valida segundo digito
            if (value.length == 2) {
                if (value.charAt(0) == '3') {
                    valid = new RegExp(/^[0-1]$/).test(value.charAt(1));
                } else if (value.charAt(0) == '0') {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(1));
                } else {
                    valid = new RegExp(/^[0-9]$/).test(value.charAt(1));
                }
                value += '/';
            }

            // valida terceiro digito
            if (value.length == 4) {
                valid = new RegExp(/^[0-1]$/).test(value.charAt(3));
            }

            // valida quarto digito
            if (value.length == 5) {
                if (value.charAt(3) == '1') {
                    valid = new RegExp(/^[0-2]$/).test(value.charAt(4));
                } else {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(4));
                }
                value += '/';
            }

            if (valid) {
                this.beneficiarioEstagiarioForm.dtadmissao = value;
                if (value.length === 10) {
                    const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
                    if (parsedDate > new Date()) {
                        this.$toast.error({
                            title: "Erro",
                            message: "Data de admissão não pode ser maior que a data atual!"
                        });
                        this.beneficiarioEstagiarioForm.isValidAdmissionDate = false;
                    } else {
                        this.beneficiarioEstagiarioForm.isValidAdmissionDate = true;
                    }
                }
                if (value.length === 8) {
                    this.beneficiarioEstagiarioForm.isValidAdmissionDate = false;
                }
            } else {
                this.$nextTick(() => {
                    this.beneficiarioEstagiarioForm.dtadmissao = originalValue;
                });
            }
        },
        async onCepChangeInstituicao(cep) {
            if (cep.length === 8) {
                this.$Progress.start();
                const {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    ibge
                } = await getAddressByCep(cep);
                if (logradouro) {
                    this.beneficiarioEstagiarioForm.street = logradouro;
                    this.beneficiarioEstagiarioForm.district = bairro;
                    this.beneficiarioEstagiarioForm.municipio = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    }
                } else {
                    this.$toast.error({
                        message: "CEP Inválido."
                    });
                }
                this.$Progress.finish();
            }
        },
        cityInstituicaoSelected(city) {
            this.beneficiarioEstagiarioForm.municipio = city;
        }
    },
    data: () => {
        return {
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                precision: 2
            },
        };
    }
};
</script>
<template>
    <div>
        <div class="flex justify-between w-full my-8 border-b-2 border-blue-base bg-white shadow-lg">
            <span class="mt-2 text-base uppercase text-blue-base">
                Informações do estágio
            </span>
            <slot></slot>
        </div>
        <div class="grid gap-5 grid-cols-1 md:grid-cols-12 px-3">
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    CNPJ
                </span>
                <template v-if="readonly">
                    {{ mask(beneficiarioEstagiario.document, "##.###.###/####-##") }}
                </template>
                <template v-else>
                    <span>
                        <TheMask v-model="beneficiarioEstagiarioForm.document" :mask="['##.###.###/####-##']"
                            class="h-auto py-2 rounded form-input" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstagiarioForm.hasErrors &&
                                    !beneficiarioEstagiarioForm.document
                            }" required @input="checkCPFValid" />
                        <div v-if="beneficiarioEstagiarioForm.hasErrors &&
                            !beneficiarioEstagiarioForm.document
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </span>
                </template>
            </div>
            <div class="col-span-7">
                <span class="text-sm form-label text-gray-dark">
                    Nome da Instituição
                </span>
                <span>
                    <template v-if="readonly">
                        {{ beneficiarioEstagiario.nminstituicao }}
                    </template>
                    <template v-else>
                        <input v-model="beneficiarioEstagiarioForm.nminstituicao" :class="{
                            'bg-red-100 border-red-400':
                                beneficiarioEstagiarioForm.hasErrors &&
                                !beneficiarioEstagiarioForm.nminstituicao
                        }" type="text" class="h-auto py-2 rounded form-input" />
                        <div v-if="beneficiarioEstagiarioForm.hasErrors &&
                            !beneficiarioEstagiarioForm.nminstituicao
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </template>
                </span>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Data de admissão
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstagiario.dtadmissao }}
                </template>
                <template v-else>
                    <span>
                        <input v-model="beneficiarioEstagiarioForm.dtadmissao" type="text"
                            class="h-auto py-2 rounded form-input" :class="{
                                'bg-red-100 border-red-400': hasErrors && (!beneficiarioEstagiarioForm.dtadmissao || !validBirthDate),
                            }" required @input="$event => validateAdmissionDateOnInput($event.target.value)" />
                        <div v-if="!beneficiarioEstagiarioForm.isValidAdmissionDate && beneficiarioEstagiarioForm.dtadmissao && beneficiarioEstagiarioForm.dtadmissao.length === 10"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Data inválida
                        </div>
                        <div v-if="!beneficiarioEstagiarioForm.isValidAdmissionDate && beneficiarioEstagiarioForm.dtadmissao.length === 8"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Data inválida
                        </div>
                        <div v-if="beneficiarioEstagiarioForm.hasErrors &&
                            !beneficiarioEstagiarioForm.dtadmissao
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </span>
                </template>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Data de termino
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstagiario.dtencerramento }}
                </template>
                <template v-else>
                    <span>
                        <input v-model="beneficiarioEstagiarioForm.dtencerramento" type="text"
                            class="h-auto py-2 rounded form-input" :class="{
                                'bg-red-100 border-red-400': hasErrors && (!beneficiarioEstagiarioForm.dtencerramento || !isValidClosureDate),
                            }" required @input="$event => validateClosureDateOnInput($event.target.value)" />
                        <div v-if="!beneficiarioEstagiarioForm.isValidClosureDate && beneficiarioEstagiarioForm.dtencerramento.length === 10"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Data inválida
                        </div>
                        <div v-if="!beneficiarioEstagiarioForm.isValidClosureDate && beneficiarioEstagiarioForm.dtencerramento.length === 8"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Data inválida
                        </div>
                        <div v-if="beneficiarioEstagiarioForm.hasErrors &&
                            !beneficiarioEstagiarioForm.dtencerramento
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </span>
                </template>
            </div>
        </div>
        <div class="grid gap-5 grid-cols-1 md:grid-cols-12 px-3">
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    CEP
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstagiario.cep ? mask(beneficiarioEstagiario.cep, "##.###-##") : '' }}
                </template>
                <template v-else>
                    <div>
                        <TheMask v-model="beneficiarioEstagiarioForm.cep" :mask="['##.###-###']"
                            class="h-auto py-2 rounded form-input mb-0" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstagiarioForm.hasErrors &&
                                    !beneficiarioEstagiarioForm.cep
                            }" @input="onCepChangeInstituicao" required />
                        <div v-if="
                            beneficiarioEstagiarioForm.hasErrors &&
                            !beneficiarioEstagiarioForm.cep
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-6">
                <span class="text-sm form-label text-gray-dark">
                    Endereço
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstagiario.street }}
                </template>
                <template v-else>
                    <div>
                        <input v-model="beneficiarioEstagiarioForm.street" type="text"
                            class="h-auto py-2 rounded form-input mb-0" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstagiarioForm.hasErrors &&
                                    !beneficiarioEstagiarioForm.street
                            }" />
                        <div v-if="
                            beneficiarioEstagiarioForm.hasErrors &&
                            !beneficiarioEstagiarioForm.street
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-1">
                <span class="text-sm form-label text-gray-dark">
                    Número
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstagiario.number }}
                </template>
                <template v-else>
                    <div>
                        <input v-model="beneficiarioEstagiarioForm.number" type="number"
                            class="h-auto py-2 rounded form-input mb-0" />
                        <!-- <div
                            v-if="
                                hasErrors &&
                                    !enderecoInstituicaoForm.number
                            "
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                        >
                            Campo obrigatório
                        </div> -->
                    </div>
                </template>
            </div>
            <div class="col-span-2">
                <span class="text-sm form-label text-gray-dark">
                    Bairro
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstagiario.district }}
                </template>
                <template v-else>
                    <div>
                        <input v-model="beneficiarioEstagiarioForm.district" type="text"
                            class="h-auto py-2 rounded form-input mb-0" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstagiarioForm.hasErrors &&
                                    !beneficiarioEstagiarioForm.district
                            }" />
                        <div v-if="
                            beneficiarioEstagiarioForm.hasErrors &&
                            !beneficiarioEstagiarioForm.district
                        " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Complemento
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstagiario.complement }}
                </template>
                <template v-else>
                    <div>
                        <input v-model="beneficiarioEstagiarioForm.complement" type="text"
                            class="h-auto py-2 rounded form-input mb-0" />
                    </div>
                </template>
            </div>
            <div class="col-span-6 ">
                <span class="text-sm form-label text-gray-dark">
                    Cidade/estado
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstagiario.municipio ? beneficiarioEstagiario.municipio.name +
                        " / " +
                        beneficiarioEstagiario.municipio.state
                            .name : '' }}
                </template>
                <template v-else>
                    <div>
                        <search-select :selected.sync="beneficiarioEstagiarioForm.municipio" :search-keys="['name']"
                            :search-remote="true" :lazy-load="true"
                            options-url="/api/registry/location?take=50&paginate=false" option-key="id" :option-value="city =>
                                city.name +
                                ' / ' +
                                city.state.initials
                                " field-class="py-2" name="city" placeholder="Selecione uma cidade..."
                            @changed="cityInstituicaoSelected" />
                        <div v-if="beneficiarioEstagiarioForm.hasErrors &&
                            !beneficiarioEstagiarioForm.municipio"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
