<script>
import ResponsiveTable from "@/core/components/table/ResponsiveTable";
import IconBadge from "@/core/components/badges/IconBadge";
import ProfileSelection from "@/domains/organization/components/ProfileSelection";
import SubordinateConfirmation from "@/domains/organization/components/SubordinateConfirmation";

export default {
    components: {
        ResponsiveTable,
        ProfileSelection,
        SubordinateConfirmation,
        IconBadge
    },
    props: {
        search: {
            closed: {
                type: Boolean,
                default: false
            },
            type: String,
            default: ""
        }
    },
    data() {
        return {
            status: "pending",
            loading: false,
            headers: ["Nome", "CPF", "Perfis", "Situação", ""],
            page: 1,
            pagination: {},
            links: []
        };
    },
    mounted() {
        this.getLinks();
    },
    methods: {
        getLinks() {
            this.$Progress.start();
            this.loading = true;
            this.$axios
                .post(
                    "/api/security/manager/my-subordinates" +
                        "?page=" +
                        this.page,
                    {
                        search: this.search,
                        orderBy: {
                            id: "DESC"
                        }
                    }
                )
                .then(resp => {
                    this.links = resp.data.data;
                    this.pagination = resp.data;
                    delete this.pagination.data;
                    this.loading = false;
                    this.status = "finished";
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.links = [];
                    this.loading = false;
                    this.status = "rejected";
                    this.$Progress.finish();
                });
        },
        refresh() {
            this.page = 1;
            this.search = "";
            this.getLinks();
        },
        changePage(page) {
            if (page !== this.page) {
                this.page = page;
                this.getLinks();
            }
        },
        dataMap(data) {
            if (data.length > 0) {
                return data.map(item => {
                    return {
                        name: item.name,
                        document: item.document_formatted,
                        countLinks: item.links.length,
                        line: item,
                        profiles: item.links.length
                            ? item.links[0].profiles
                            : [],
                        status: item.links.length
                            ? item.links[0].condition
                            : null
                    };
                });
            }
            return [];
        }
    }
};
</script>

<template>
    <ResponsiveTable
        :headers="headers"
        :data="dataMap(links)"
        :pagination="pagination"
        :disabled="loading"
        :status="status"
        @changePage="
            page => {
                changePage(page);
            }
        "
    >
        <template #row="props">
            <td :class="props.tdClass">{{ props.row.name }}</td>
            <td :class="props.tdClass">{{ props.row.document }}</td>
            <td :class="props.tdClass">
                <div v-if="props.row.profiles.length">
                    <span
                        v-for="profile in props.row.profiles"
                        v-bind:key="profile.id"
                        class="bg-green-100 border border-teal-700 text-teal-700 font-medium p-1 rounded text-xs mr-1 my-2"
                    >
                        {{ profile.name }}
                    </span>
                </div>
                <span
                    v-else
                    class="bg-red-100 border border-red-base text-red-base font-medium p-1 rounded text-xs mr-1 my-2"
                >
                    Nenhum perfil
                </span>
            </td>
            <td :class="props.tdClass">
                <div v-if="props.row.status">
                    <IconBadge :text="props.row.status.name" />
                </div>
                <div v-else>Não vinculado</div>
            </td>

            <td :class="props.tdClass">
                <div v-if="!props.row.countLinks">
                    <ProfileSelection
                        :modal="true"
                        action="link"
                        :subordinate="props.row.line"
                        @save="refresh"
                    />
                </div>

                <div v-else>
                    <div v-if="props.row.status.name === 'Vínculo Ativo'">
                        <ProfileSelection
                            :modal="true"
                            action="edit"
                            :subordinate="props.row.line"
                            @save="refresh"
                        />
                        <div
                            v-if="
                                $store.state.auth.user.id !== props.row.line.id
                            "
                        >
                            <SubordinateConfirmation
                                :modal="true"
                                action="disable"
                                :subordinate="props.row.line"
                                @confirm="refresh"
                            />
                        </div>
                    </div>

                    <div v-if="props.row.status.name === 'Vínculo Solicitado'">
                        <ProfileSelection
                            :modal="true"
                            action="accept"
                            :subordinate="props.row.line"
                            @save="refresh"
                        />
                        <SubordinateConfirmation
                            :modal="true"
                            action="deny"
                            :subordinate="props.row.line"
                            @confirm="refresh"
                        />
                    </div>

                    <div
                        v-if="
                            props.row.status.name === 'Vínculo Inativo' ||
                                props.row.status.name === 'Vínculo Rejeitado'
                        "
                    >
                        <ProfileSelection
                            :modal="true"
                            action="enable"
                            :subordinate="props.row.line"
                            @save="refresh"
                        />
                    </div>
                </div>
            </td>
        </template>
        <template #responsive="props">
            <tr class="p-2">
                <th :class="props.thClass">CPF</th>
                <td :class="props.tdClass">{{ props.row.document }}</td>
            </tr>
            <tr class="p-2">
                <th :class="props.thClass">Perfis</th>
                <div v-if="props.row.profiles.length" class="pt-3 px-2">
                    <div v-if="props.row.profiles.length">
                        <span
                            v-for="profile in props.row.profiles"
                            v-bind:key="profile.id"
                            class="bg-green-100 border border-teal-700 text-teal-700 font-medium p-1 rounded text-xs mr-1"
                        >
                            {{ profile.name }}
                        </span>
                    </div>
                </div>
                <span
                    v-else
                    class="bg-red-100 border border-red-base text-red-base font-medium p-1 rounded text-xs mt-3 mx-2"
                >
                    Nenhum perfil
                </span>
            </tr>
            <tr class="p-2">
                <th :class="props.thClass">Situação</th>
                <div v-if="props.row.status" class="pt-3 px-2">
                    <IconBadge :text="props.row.status.name" />
                </div>
                <div v-else class="pt-3 px-2">Não vinculado</div>
            </tr>
        </template>
    </ResponsiveTable>
</template>
