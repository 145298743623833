import axios from "axios";

async function getCarrierStatus() {
    try {
        const response = await axios.get("/api/registry/status");
        let carrier = response.data.carrier;
        let irregular = response.data.irregular;
        let expired = response.data.expired;
        return {
            carrier: carrier,
            irregular: irregular,
            expired: expired
        };
    } catch (e) {
        throw {
            status: e.response.status,
            error:
                e.response.data.errors ||
                e.response.data.error ||
                e.response.data.message ||
                "Erro interno, tente novamente ou mais tarde."
        };
    }
}

async function requestRenewal(carrierId, vehiclesQuantity) {
    try {
        let data = {
            billable_id: carrierId,
            quantity: vehiclesQuantity
        };
        const response = await axios.post(
            "/api/registry/renewal/request",
            data
        );
        let renewal_request = response.data.renewal_request;
        let message = response.data.success;
        return {
            message: message,
            renewal_request: renewal_request
        };
    } catch (e) {
        throw {
            status: e.response.status,
            error:
                e.response.data.errors ||
                e.response.data.error ||
                e.response.data.message ||
                "Erro interno, tente novamente ou mais tarde."
        };
    }
}

export { getCarrierStatus, requestRenewal };
