<script>
import ActionsDropdown from "@/core/components/ActionsDropdown";
import ResponsiveTable from "@/core/components/table/ResponsiveTable";
import IconBadge from "@/core/components/badges/IconBadge";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// IMPORT ICONS
import Check from "./icons/Check.vue";
import Pending from "./icons/Pending.vue";
import Cancel from "./icons/Cancel.vue";
import Defeated from "./icons/Defeated.vue";

export default {
    components: {
        ActionsDropdown,
        ResponsiveTable,
        IconBadge,
        FontAwesomeIcon,
        Check,
        Pending,
        Cancel,
        Defeated
    },
    props: {
        pagination: {
            default: {
                current_page: 1,
                first_page_url:
                    "http://dev.scmobi.sie.sc.gov.br/api/registry/person/organizations?page=1",
                from: 1,
                last_page: 1,
                last_page_url:
                    "http://dev.scmobi.sie.sc.gov.br/api/registry/person/organizations?page=1",
                next_page_url: null,
                path:
                    "http://dev.scmobi.sie.sc.gov.br/api/registry/person/organizations",
                // per_page: 15,
                per_page: 6,
                prev_page_url: null,
                to: 2,
                total: 1
            }
        },
        closed: {
            default: 0
        },
        search: {
            type: String,
            default: ""
        },
        filter: {
            type: String,
            default: null
        },
        idbeneficiario: {
            type: String
        },
        my_document: {
            type: String
        },
        my_tickets: {
            type: Array
        },
        allTickets: {
            type: Array
        }
    },
    data() {
        return {
            faEye,
            status: "pending",
            loading: false,
            headers: [
                "Matrícula",
                "Nome",
                "CPF",
                "Tipo de Benificiário",
                "Data de Expiração",
                "Situação do Cadastro",
                "última atualização",
                "Opções"
            ],
            page: 1,

            tickets: []
        };
    },
    watch: {
        closed: function () {
            this.page = 1;
            this.getList();
        },
        filteredTickets: function (tickets) {
            this.pagination.total = tickets.length;
        }
    },
    computed: {
        filteredTickets() {
            if (this.filter) {
                const filttered = this.tickets.filter(
                    item => item.situacao === this.filter
                );
                // this.pagination.total = filttered.length;
                return filttered;
            }
            return this.tickets;
        }
    },
    mounted() {
        // this.getTickets();
        this.getList();
    },
    methods: {
        changePage(page) {
            if (page !== this.page) {
                this.$emit("refresh", this.pageList(this.allTickets, page, 6));
                this.page = page;
                // this.getList();
            }
        },
        pageList(array, pageNumber, pageSize) {
            return array.slice(
                (pageNumber - 1) * pageSize,
                pageNumber * pageSize
            );
        },
        dataMap(data) {
            if (data.length > 0) {
                return data.map(item => {
                    return {
                        idbeneficiario: item.idbeneficiario,
                        matricula: item.matricula,
                        nome: item.nome,
                        cpf: item.cpf,
                        beneficiario: item.beneficiario,
                        expiracao: item.expiracao,
                        situacao: item.situacao,
                        atualizacao: item.atualizacao,
                        Opções: item.Opções
                    };
                });
            }
            return [];
        },
        getColorBySituation(situation) {
            const situations = [
                { label: "ANALISADO", color: "#028C4A" },
                { label: "PENDENTE", color: "#DC904A" },
                { label: "ATIVO", color: "#028C4A" },
                { label: "ENTREGUE", color: "#028C4A" },
                { label: "VENCIDO", color: "#DA3E3E" },
                { label: "CANCELADO", color: "#DA3E3E" },
                { label: "A VENCER", color: "#DA3E3E" }
            ];
            const item = situations.find(item => item.label === situation);

            if (item) {
                return item.color;
            }
            return "black";
        },
        getTipoBeneficiario(status) {
            if (status === "0") {
                return "Não Informado";
            }else if (status === "1") {
                return "Estudante";
            }
            else if (status === "2") {
                return "Trabalhador";
            } else if (status === "3") {
                return "Estagiario";
            }
            // else if (status === "2") {
            //     return "Estagiario";
            // } else if (status === "3") {
            //     return "Trabalhador";
            // }
            return "";
        },
        getSituationRegister(situation) {
            situation = String(situation);
            if (situation === "1") {
                return "ANALISADO";
            } else if (situation === "2") {
                return "ANALISADO";
            } else if (situation === "3") {
                return "ATIVO";
            } else if (situation === "4") {
                return "VENCIDO";
            } else if (situation === "5") {
                return "PENDENTE";
            } else if (situation === "6") {
                return "CANCELADO";
            } else if (situation === "7") {
                return "A VENCER";
            }

            return "";
        },
        getFormattedDate(data) {
            if (data) {
                let dateObject = new Date(data);
                let day = String(dateObject.getDate()).padStart(2, "0");
                let month = String(dateObject.getMonth() + 1).padStart(2, "0");
                let year = dateObject.getFullYear();
                let formattedDate = day + "/" + month + "/" + year;
                return formattedDate;
            }
            // return "00/00/0000";
            return "";
        },
        getRoundTime(data) {
            // const formatRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6}$/;

            // if (!formatRegex.test(data)) {
            //     return "";
            // }

            const currentDate = new Date();
            const paramDate = new Date(data);
            const difference = currentDate - paramDate;

            if (difference >= 1000 * 60 * 60 * 24 * 365) {
                const years = Math.round(
                    difference / (1000 * 60 * 60 * 24 * 365)
                );

                return `há ${years === 1 ? "1 ano" : `${years} anos`}`;
            } else if (difference >= 1000 * 60 * 60 * 24) {
                const days = Math.round(difference / (1000 * 60 * 60 * 24));
                return `há ${days === 1 ? "1 dia" : `${days} dias`}`;
            } else if (difference >= 1000 * 60 * 60) {
                const hours = Math.round(difference / (1000 * 60 * 60));
                return `há ${hours === 1 ? "1 hora" : `${hours} horas`}`;
            } else if (difference >= 1000 * 60) {
                const minutes = Math.round(difference / (1000 * 60));
                return `há ${minutes === 1 ? "1 minuto" : `${minutes} minutos`
                    }`;
            } else {
                const seconds = Math.round(difference / 1000);
                return `há ${seconds === 1 ? "1 segundo" : `${seconds} segundos`
                    }`;
            }
        },
        async getList() {
            this.$Progress.start();
            try {
                const res = await this.$axios.post(
                    "/api/spl-navegantes/beneficiarios",
                    {
                        take: "5000"
                    }
                );

                if (res.status === 200 && res.data?.sucess) {
                    // this.list = res.data.sucess;
                    const ordered_list = Object.values(res.data.sucess);

                    ordered_list.sort((a1, a2) => {

                        const data1 = new Date(a1.dtalteracao);
                        const data2 = new Date(a2.dtalteracao);
                        return data2 - data1;

                    });
                    const my_list = await Promise.all(
                        ordered_list.map(async item => {
                            // const item = my_item[1];
                            const tipo_beneficiario = this.getTipoBeneficiario(
                                item.tpbeneficiario
                            );
                            const situacao_cadastro = this.getSituationRegister(
                                item.controle_registro?.idsituacao
                            );
                            const formattedDate = this.getFormattedDate(
                                item.controle_registro?.dtexpiracao
                            );
                            // const roundTime = this.getRoundTime(
                            //     item?.doacao?.dtalteracao
                            // );
                            const roundTime = this.getRoundTime(
                                item?.dtalteracao
                            );

                            return {
                                idbeneficiario: item.idbeneficiario,
                                matricula: item.idbeneficiario ?? "",
                                nome: item.nmnome ?? "",
                                cpf: item.usuario?.document_formatted ?? "",
                                beneficiario: tipo_beneficiario,
                                expiracao: formattedDate,
                                situacao: situacao_cadastro,
                                atualizacao: roundTime,
                                Opções: true
                            };
                        })
                    );

                    this.tickets = my_list;
                    this.$emit("setAllTickets", my_list);
                    this.$emit("refresh", this.pageList(my_list, 1, 10));
                    // this.pagination = {
                    //     current_page: 1,
                    //     first_page_url:
                    //         "http://dev.scmobi.sie.sc.gov.br/api/registry/person/organizations?page=1",
                    //     from: 1,
                    //     last_page: 1,
                    //     last_page_url:
                    //         "http://dev.scmobi.sie.sc.gov.br/api/registry/person/organizations?page=1",
                    //     next_page_url: null,
                    //     path:
                    //         "http://dev.scmobi.sie.sc.gov.br/api/registry/person/organizations",
                    //     per_page: 15,
                    //     prev_page_url: null,
                    //     to: 2,
                    //     total: my_list.length
                    // };
                }
            } catch (e) {
                this.$toast.error({
                    message:
                        e.response?.data?.message || e.response?.data?.error
                });
            } finally {
                this.loading = false;
                this.status = "finished";
                this.$Progress.finish();
            }
        }
    }
};
</script>

<template>
    <div>
        <ResponsiveTable :hasPagination="false" :headers="headers" :data="dataMap(my_tickets)" :pagination="pagination" :disabled="loading"
            :status="status" @changePage="page => {
                changePage(page);
            }
                ">
            <template #row="props">
                <td :class="props.tdClass">{{ props.row.matricula }}</td>
                <td :class="props.tdClass">{{ props.row.nome }}</td>
                <td :class="props.tdClass">{{ props.row.cpf }}</td>
                <td :class="props.tdClass">{{ props.row.beneficiario }}</td>
                <td :class="props.tdClass">{{ props.row.expiracao }}</td>
                <td :class="props.tdClass" :style="`color: ${getColorBySituation(props.row.situacao)}`"
                    class="flex items-center h-full gap-2">
                    <div class="h-max">
                        <Check v-if="props.row.situacao === 'ANALISADO' ||
                            props.row.situacao === 'ATIVO' ||
                            props.row.situacao === 'ENTREGUE'
                            " />
                        <Pending v-if="props.row.situacao === 'PENDENTE'" />
                        <Cancel v-if="props.row.situacao === 'CANCELADO'" />
                        <Defeated v-if="props.row.situacao === 'VENCIDO'" />
                    </div>
                    {{ props.row.situacao }}
                </td>
                <td :class="props.tdClass">{{ props.row.atualizacao }}</td>
                <td :class="props.tdClass" class="flex items-center">
                    <router-link :to="{
                        name: 'spl-navegantes.edit',
                        params: { id: props.row.idbeneficiario }
                    }" class="text-blue-base" title="Ver Opçõess">
                        <FontAwesomeIcon :icon="faEye"></FontAwesomeIcon>
                    </router-link>

                </td>
            </template>
            <template #responsive="props">
                <tr>
                    <th :class="props.thClass">Matricula</th>
                    <td :class="props.tdClass + ' relative'">
                        <ActionsDropdown class="absolute right-0 top-0">
                            <template slot="actions">
                                <router-link :to="{
                                    name: 'spl-navegantes.show',
                                    params: { id: props.row.matricula }
                                }"
                                    class="block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left">
                                    Ver Opçõess
                                </router-link>
                            </template>
                        </ActionsDropdown>
                        {{ props.row.matricula }}
                    </td>
                </tr>
                <tr>
                    <th :class="props.thClass">Nome</th>
                    <td :class="props.tdClass">{{ props.row.nome }}</td>
                </tr>
                <tr>
                    <th :class="props.thClass">Opções</th>
                    <td :class="props.tdClass">
                        <IconBadge :text="props.row.Opções" />
                    </td>
                </tr>
                <tr>
                    <th :class="props.thClass">Data de Expiraçõa</th>
                    <td :class="props.tdClass">{{ props.row.expiracao }}</td>
                </tr>
            </template>
        </ResponsiveTable>
    </div>
</template>

<style scoped>
::v-deep(th) {
    background: #c7c7c7 !important;
    color: #333333;
}

::v-deep(tbody tr:nth-child(even)) {
    background-color: #efefef;
}

::v-deep(tbody tr:nth-child(odd)) {
    background-color: white;
}
</style>
