<template>
    <div class="w-full bg-gray-light">
        <div class="m-4 ">
            <breadcrumbs class="mb-8" :crumbs="crumbs" />
            <basic-informations
                v-if="user"
                :user="user"
                :editable="this.$store.state.auth.user.document.length === 11"
                :disabled="disableds"
            ></basic-informations>

            <contacts
                v-if="user"
                :user="user"
                :editable="this.$store.state.auth.user.document.length === 11"
            ></contacts>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import Icon from "@/core/components/Icon";
import Breadcrumbs from "@/core/components/Breadcrumbs";
import IconBadge from "@/core/components/badges/IconBadge";
import BasicInformations from "@/domains/user/components/BasicInformations";
import Contacts from "@/domains/user/components/Contacts";

@Component({
    components: {
        breadcrumbs: Breadcrumbs,
        "basic-informations": BasicInformations,
        contacts: Contacts,
        icon: Icon,
        IconBadge
    }
})
export default class MyInfo extends Vue {
    constructor() {
        super();
        this.user = this.$store.state.auth.user;
        this.disableds = [];
        this.title = "Meu Cadastro";
        this.crumbs = [
            { name: "Início", path: "index" },
            { name: "Usuário Scmobi", path: "user.index" },
            { name: "Meu Cadastro", path: "user.my-info" }
        ];
    }

    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
}
</script>
