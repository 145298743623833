import mutations from "./mutations";

const state = {
    props: null
};

export default {
    state,
    mutations
};
