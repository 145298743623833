<script>
export default {
    props: {
        selectClass: {
            type: String,
            default: "form-select"
        },
        divClass: {
            type: String,
            default: ""
        },
        /** Use "selected.sync" when passing this prop */
        selected: {
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        optionKey: {
            default: () => {
                return item => {
                    return parseInt(item.id);
                };
            }
        },
        optionValue: {
            default: () => {
                return item => {
                    return item.name;
                };
            }
        },
        customCaret: {
            type: Boolean,
            default: false
        },
        optionalPlaceholderIcon: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            current: null,
            auxId: Math.random().toString(36).substring(7)
        };
    },
    mounted() {
        this.current = this.selected;
    },
    updated() {
        this.current = this.selected;
    },
    methods: {
        select() {
            /* Return selected option */
            this.$emit("update:selected", this.current);
            this.$emit("changed", this.current);

            if (this.customCaret) {
                this.$nextTick(() => {
                    this.removeRotate180();
                });
            }
        },
        addRotate180() {
            let caretElement = document.getElementById(this.auxId);
            caretElement.classList.add("rotate-180");
            caretElement.classList.remove("rotate");
        },
        removeRotate180() {
            this.$nextTick(() => {
                let caretElement = document.getElementById(this.auxId);
                caretElement.classList.remove("rotate-180");
                caretElement.classList.add("rotate");
            });
        },
    }
};
</script>

<style>
.custom-caret-div {
    position: relative;
    display: inline-block;
    border: 1px solid #c7c7c7;
    border-radius: 0.25rem;
}

.custom-caret {
    background-image: none !important;
    background-size: 0.75rem !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: 0;
    z-index: 10;
}

.rotate {
    transition: transform 0.2s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    transform: rotate(0deg);
}

.rotate-180 {
    transition: transform 0.2s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    transform: rotate(180deg);
}
</style>

<template>
    <div :class="[divClass, 'relative inline-block border-1px bg-white', { 'custom-caret-div': customCaret }]">
        <template v-if="optionalPlaceholderIcon">
            <div class="flex items-center absolute z-0 top-2 left-3">
                <img class="h-3 mr-2" :src="require(`@/core/assets/images/${optionalPlaceholderIcon}`)">
                <span class="text-xs ">{{ selected.name }}</span>
            </div>
        </template>
        <template v-if="customCaret">
            <img :id="auxId" class="caret h-3 absolute top-3 left-11/12 rotate"
                :src="require(`@/core/assets/images/icon_caret.svg`)">
        </template>
        <select v-model="current" @change="select" :class="[selectClass, 'pr-6', { 'custom-caret': customCaret }]"
            @focus="$event => customCaret ? addRotate180() : $event"
            @focusout="$event => customCaret ? removeRotate180($event.target) : $event">
            <option v-for="option in options" v-bind:key="option.id" :value="optionKey(option)">
                {{ optionValue(option) }}
            </option>
        </select>
    </div>
</template>
