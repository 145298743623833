<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import SystemInfo from "@/core/components/portlets/SystemInfo";

export default {
    components: {
        Breadcrumbs,
        SystemInfo
    },
    data: () => {
        return {
            title: "SPL Navegantes",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "SPL Navegantes", path: "spl-navegantes.index" }
            ],
            alerts: [
                "Consulte a equipe técnica em caso de dúvida ou correção.",
            ]
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="lg:max-w-5xl my-4 px-2 sm:px-6">
            <Breadcrumbs class="mb-8" :crumbs="crumbs" />
            <SystemInfo
                :helpLink="'https://ajuda.sie.sc.gov.br/bc/suporte/'"
                :alerts="alerts"
            />
        </div>
    </div>
</template>
