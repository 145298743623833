var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ResponsiveTable',{attrs:{"headers":_vm.headers,"data":_vm.dataMap(_vm.tickets),"pagination":_vm.pagination,"disabled":_vm.loading,"status":_vm.status},on:{"changePage":page => {
            _vm.changePage(page);
        }},scopedSlots:_vm._u([{key:"row",fn:function(props){return [_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.id))]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.subject))]),_c('td',{class:props.tdClass},[_c('IconBadge',{attrs:{"text":props.row.status.name}})],1),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.last_update))]),_c('td',{class:props.tdClass},[_c('router-link',{staticClass:"text-blue-base",attrs:{"to":{
                    name: 'ticket.show',
                    params: { id: props.row.id }
                },"title":"Ver detalhes"}},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.faEye}})],1)],1)]}},{key:"responsive",fn:function(props){return [_c('tr',[_c('th',{class:props.thClass},[_vm._v("Número")]),_c('td',{class:props.tdClass + ' relative'},[_c('ActionsDropdown',{staticClass:"absolute right-0 top-0"},[_c('template',{slot:"actions"},[_c('router-link',{staticClass:"block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left",attrs:{"to":{
                                name: 'ticket.show',
                                params: { id: props.row.id }
                            }}},[_vm._v(" Ver detalhes ")])],1)],2),_vm._v(" "+_vm._s(props.row.id)+" ")],1)]),_c('tr',[_c('th',{class:props.thClass},[_vm._v("Assunto")]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.subject))])]),_c('tr',[_c('th',{class:props.thClass},[_vm._v("Situação")]),_c('td',{class:props.tdClass},[_c('IconBadge',{attrs:{"text":props.row.status.name}})],1)]),_c('tr',[_c('th',{class:props.thClass},[_vm._v("Última atualização")]),_c('td',{class:props.tdClass},[_vm._v(_vm._s(props.row.last_update))])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }