import actions from "./actions";
import mutations from "./mutations";

const state = {
    token: null,
    type: null,
    expiration: null,
    refresh_token: null,
    user: null,
    link: null,
    permissions: null,
    re_registration_agreement: null
};

const getters = {
    isAuthenticated: state => !!state.token,
    isUserLoaded: state => !!state.user,
    isLinkSelected: state => !!state.link,
    isOrganization: state => !!(state.user.document.length === 14)
};

export default {
    state,
    getters,
    actions,
    mutations
};
