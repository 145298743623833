<script>
import { TheMask } from "vue-the-mask";
import Select from "@/core/components/Select";
import SearchSelect from "@/core/components/SearchSelect";
import Breadcrumbs from "@/core/components/Breadcrumbs";
import Icon from "@/core/components/Icon";
import { isValid, parse } from "date-fns";
import { validateCPF } from "@/core/services/helpers";
import {
    checkIfCepIsValidForBeneficiario,
    getAddressByCep
} from "../getAddressByCep";
export const TipoBeneficiario = {
    ESTUDANTE: 1,
    TRABALHADOR: 2,
    ESTAGIARIO: 3
};

export default {
    components: {
        Breadcrumbs,
        TheMask,
        Select,
        Icon,
        "search-select": SearchSelect
    },

    data: () => {
        return {
            TipoBeneficiario,
            title: "SPL Navegantes",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "SPL Navegantes", path: "spl-navegantes.index" },
                { name: "Cadastro", path: "spl-navegantes.show" }
            ],
            beneficiario: {
                nmnome: "",
                dtnascimento: "",
                nmfiliacao: "",
                tpparentesco: null,
                document: "",
                cep: "",
                street: "",
                number: "",
                district: "",
                complement: "",
                municipio: "",
                filiacao: [
                    {
                        document: "",
                        nmnome: "",
                        tpparentesco: null,
                        cep: "",
                        street: "",
                        number: "",
                        district: "",
                        complement: "",
                        municipio: ""
                    }
                ],
                contatos: {
                    email: [""],
                    telefone: [""]
                },
                contato_filiacao: {
                    email: [""],
                    telefone: [""]
                },

                numunicipio_beneficiario: {},
                tpbeneficiario: TipoBeneficiario.ESTUDANTE
            },

            endereco: {
                cep: "",
                endereço: "",
                numero: "",
                complemnto: "",
                bairro: "",
                cidade: "",
                estado: ""
            },

            filiacaoOptions: [
                { id: 1, name: "MÃE" },
                { id: 2, name: "PAI" },
                { id: 3, name: "TIO" },
                { id: 4, name: "RESPONSÁVEL" }
            ],
            hasErrors: false,
            hasFiliacaoErrors: false,
            valid: false,
            validBirthDate: false,
            isFiliacaoInfoRequired: false,
            isFiliacaoDocumentRequired: [],
            isFiliacaoCepRequired: [],
        };
    },
    mounted() {
        const beneficiario = JSON.parse(
            sessionStorage.getItem("beneficiarioStore")
        );
        if (beneficiario) {
            this.beneficiario = beneficiario;
        }
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },

    methods: {
        validateBeneficiarioCEP() {
            if (this.beneficiario.municipio.name != 'Itajaí' && this.beneficiario.municipio.name != 'Navegantes') {
                this.$toast.error({
                    title: "Erro",
                    message:
                        "CEP do beneficário inválido! Município deve ser Itajaí ou Navegantes"
                });
                return false;
            }
            return true;
        },
        async validateFiliacaoCEP() {
            let isValid = true;
            this.$Progress.start();

            for (const filiacao of this.beneficiario.filiacao) {
                const isCepValid = await checkIfCepIsValidForBeneficiario(
                    filiacao.cep
                );
                if (!isCepValid) {
                    this.$toast.error({
                        title: "Erro",
                        message:
                            "CEP da filiação inválido! Município deve ser Itajaí ou Navegantes"
                    });
                    isValid = false;
                }
            }
            this.$Progress.finish();
            return isValid;
        },
        validateBirthDateOnInput(value) {
            let originalValue = value.slice(0, -1);
            let valid = true;

            // previne que a data seja maior que 10 caracteres
            if (value.length > 10) {
                valid = false;
            }

            // valida se foi digitado alfanumérico
            if (isNaN(value.charAt(value.length - 1)) && value.charAt(value.length - 1) !== '/') {
                valid = false;
            }

            // valida primeiro digito
            if (value.length == 1) {
                valid = new RegExp(/^[0-3]$/).test(value.charAt(0));
            }

            // valida segundo digito
            if (value.length == 2) {
                if (value.charAt(0) == '3') {
                    valid = new RegExp(/^[0-1]$/).test(value.charAt(1));
                } else if(value.charAt(0) == '0') {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(1));
                } else {
                    valid = new RegExp(/^[0-9]$/).test(value.charAt(1));
                }
                value += '/';
            }

            // valida terceiro digito
            if (value.length == 4) {
                valid = new RegExp(/^[0-1]$/).test(value.charAt(3));
            }

            // valida quarto digito
            if (value.length == 5) {
                if (value.charAt(3) == '1') {
                    valid = new RegExp(/^[0-2]$/).test(value.charAt(4));
                } else {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(4));
                }
                value += '/';
            }

            if (valid) {
                this.beneficiario.dtnascimento = value;
                if (this.beneficiario.dtnascimento && this.beneficiario.dtnascimento.length === 10) {
                    const parsedDate = parse(this.beneficiario.dtnascimento, 'dd/MM/yyyy', new Date());
                    this.validBirthDate = isValid(parsedDate) && this.checkAtLeast5YearOld(parsedDate);
                    if (this.validBirthDate) {
                        // this.isFiliacaoDocumentRequired = this.checkMajority(parsedDate) < 18 && this.checkForFiliacaoDocument() ? true : false;
                        // this.isFiliacaoCepRequired = this.checkMajority(parsedDate) < 18 && this.checkForFiliacaoCep() ? true : false;
                        this.isFiliacaoInfoRequired = this.checkMajority(parsedDate) < 18 && this.checkForFiliacaoInfoRequired() ? true : false;
                    }
                }
                if (this.beneficiario.dtnascimento && this.beneficiario.dtnascimento.length === 8) {
                    this.validBirthDate = false;
                }
            } else {
                this.$nextTick(() => {
                    this.beneficiario.dtnascimento = originalValue;
                });
            }
        },
        checkAtLeast5YearOld(parsedDate) {
            var today = new Date();
            var age = today.getFullYear() - parsedDate.getFullYear();
            var m = today.getMonth() - parsedDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < parsedDate.getDate())) {
                age--;
            }
            if(age >= 5){
                return true;
            } else {
                this.$toast.error({
                    title: "Erro",
                    message: "Beneficiário deve ter no mínimo 5 anos de idade."
                });
                return false;
            }
        },

        checkMajority(parsedDate) {
            var today = new Date();
            var age = today.getFullYear() - parsedDate.getFullYear();
            var m = today.getMonth() - parsedDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < parsedDate.getDate())) {
                age--;
            }
            return age;
        },

        checkForFiliacaoInfoRequired() {
            let isRequired = false;
            this.beneficiario.filiacao.forEach((filiacao, index) => {
                if (!filiacao.document && filiacao.cep) {
                    this.setFiliacaoDocumentRequired(index);
                }
                if (!filiacao.cep && filiacao.document) {
                    this.setFiliacaoCepRequired(index);
                }
            });

            if (this.beneficiario.dtnascimento && this.beneficiario.dtnascimento.length === 10) {
                const parsedDate = parse(this.beneficiario.dtnascimento, 'dd/MM/yyyy', new Date());
                if (this.checkMajority(parsedDate) < 18) {
                    isRequired = true;
                    this.beneficiario.filiacao.forEach((filiacao) => {
                        if (filiacao.document.length === 11 && this.checkCPFValid(filiacao.document)
                            && filiacao.cep && filiacao.cep.length === 8) {
                            isRequired = false;
                        }
                    });
                }
            }
            this.isFiliacaoInfoRequired = isRequired;
            return isRequired;
        },

        setFiliacaoDocumentRequired(index) {
            this.isFiliacaoDocumentRequired[index] = true;
        },

        setFiliacaoCepRequired(index) {
            this.isFiliacaoCepRequired[index] = true;
        },

        async onNextStepClick() {
            this.isFormValid();
            this.validateDocuments();

            if (this.hasErrors) {
                this.$toast.error({
                    title: "Erro",
                    message: "Verifique os campos obrigatórios!"
                });
            } else {
                const isCepsValid = this.validateBeneficiarioCEP();
                // const isFiliacaoCepsValid = await this.validateFiliacaoCEP();
                if (isCepsValid) {
                    sessionStorage.setItem(
                        "beneficiarioStore",
                        JSON.stringify(this.beneficiario)
                    );
                    this.$router.push({ name: "spl-navegantes.step02" });
                }
            }
        },
        async checkCPFValid(document) {
            if (document.length == 11) {
                const { data } = await this.$axios.get(
                    `/api/spl-navegantes/beneficiarios/documento/usuario/${document}`
                );
                if (data) {
                    if (data.error == 'true') {
                        this.beneficiario.document = '';
                        this.$toast.error({ message: "CPF já cadastrado na base de dados." });
                    }
                    if (data.name) {
                        this.beneficiario.nmnome = data.name;
                        this.beneficiario.cep = data.cep;
                        this.beneficiario.street = data.street;
                        this.beneficiario.number = data.number;
                        this.beneficiario.district = data.district;
                        this.beneficiario.complement = data.complement;
                        this.beneficiario.municipio = data.city;
                    }
                }
            }
        },
        async getFiliacaoByDocument(document, index) {
            if (document.length == 11) {
                const { data } = await this.$axios.get(
                    `/api/spl-navegantes/beneficiarios/documento/${document}`
                );
                if (data) {
                    this.beneficiario.filiacao[index].nmnome = data.name;
                    this.beneficiario.filiacao[index].cep = data.cep;
                    this.beneficiario.filiacao[index].street = data.street;
                    this.beneficiario.filiacao[index].number = data.number;
                    this.beneficiario.filiacao[index].district = data.district;
                    this.beneficiario.filiacao[index].complement = data.complement;
                    this.beneficiario.filiacao[index].numunicipio = data.city_id;
                }
            }
        },
        citySelected(city) {
            this.beneficiario.municipio = city;
            this.valid = !!city;
        },
        cityFiliacaoSelected(city, index) {
            this.beneficiario.filiacao[index].municipio = city;
        },
        validateDocuments() {
            if (this.beneficiario.document) {
                if (!validateCPF(this.beneficiario.document)) {
                    this.$toast.error({
                        title: "Erro",
                        message: "CPF do beneficiário inválido!"
                    });
                    this.hasErrors = true;
                }
            }
            this.beneficiario.filiacao.forEach(filiacao => {
                if (filiacao.document) {
                    if (!validateCPF(filiacao.document)) {
                        this.$toast.error({
                            title: "Erro",
                            message: "CPF da filiação inválido!"
                        });
                        this.hasErrors = true;
                    }
                }
            });
        },
        isFormValid() {
            const { document, nmnome, dtnascimento, cep, street, district, municipio, filiacao } = this.beneficiario;

            let additionalConditions = false;
            let additionalConditionsSatisfied = true;

            if (this.beneficiario.dtnascimento && this.beneficiario.dtnascimento.length === 8) {
                this.validBirthDate = false;
            }
            if (this.beneficiario.dtnascimento && this.beneficiario.dtnascimento.length === 10) {
                this.validBirthDate = true;
                const parsedDate = parse(this.beneficiario.dtnascimento, 'dd/MM/yyyy', new Date());
                if (this.checkAtLeast5YearOld(parsedDate)) {
                    // se menor de 18 anos, então precisa de informações adicionais
                    additionalConditions = this.checkMajority(parsedDate) < 18;
                    additionalConditionsSatisfied = false;
                } else {
                    this.validBirthDate = false;
                }
            }

            filiacao.forEach(item => {
                const { document, nmnome, cep, tpparentesco } = item;

                // essa validação serve para permitir que apenas uma filiação possua as duas informações preenchidas
                if (additionalConditions && !additionalConditionsSatisfied) {
                    additionalConditionsSatisfied = document && cep ? true : false;
                }

                // validações para filiação
                if (
                    // CPF vazio e CEP preenchido ou
                    ((!this.isNullOrEmpty(document) && this.isNullOrEmpty(cep)) ||
                        // CPF preenchido e CEP vazio ou
                        (this.isNullOrEmpty(document) && !this.isNullOrEmpty(cep))) ||
                    // se nome vazio ou
                    this.isNullOrEmpty(nmnome) ||
                    // se tpParentesco vazio ou
                    this.isNullOrEmpty(tpparentesco) ||
                    // se possui condições adicionais e não foram satisfeitas
                    (additionalConditions && !additionalConditionsSatisfied)
                ) {
                    this.hasFiliacaoErrors = true;
                } else {
                    this.hasFiliacaoErrors = false;
                }
            });
            if (
                this.hasFiliacaoErrors ||
                (this.isNullOrEmpty(document) || this.isNullOrEmpty(nmnome)) ||
                // this.isNullOrEmpty(document) ||
                // this.isNullOrEmpty(nmnome) ||
                this.isNullOrEmpty(cep) ||
                this.isNullOrEmpty(street) ||
                // this.isNullOrEmpty(number) ||
                this.isNullOrEmpty(district) ||
                municipio === "" ||
                this.isNullOrEmpty(dtnascimento) || !this.validBirthDate
            ) {
                this.hasErrors = true;
            } else {
                this.hasErrors = false;
            }
        },
        addFiliacao() {
            this.beneficiario.filiacao.push({
                document: "",
                nmnome: "",
                tpparentesco: null,
                cep: "",
                street: "",
                number: "",
                district: "",
                complement: "",
                numunicipio: ""
            });
        },
        async onCepChangeBeneficiario(cep) {
            if (cep.length === 8) {
                this.$Progress.start();
                const {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    ibge
                } = await getAddressByCep(cep);
                if (logradouro) {
                    this.beneficiario.street = logradouro;
                    this.beneficiario.district = bairro;
                    //this.beneficiario.municipio = `${localidade} / ${uf};
                    this.beneficiario.municipio = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    };
                } else {
                    this.$toast.error({
                        message: "CEP Inválido."
                    });
                }
                this.$Progress.finish();
            }
        },
        async onCepChangeFiliacao(cep, index) {
            if (cep.length === 8) {
                this.$Progress.start();
                const {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    ibge
                } = await getAddressByCep(cep);
                if (logradouro) {
                    this.beneficiario.filiacao[index].street = logradouro;
                    this.beneficiario.filiacao[index].district = bairro;
                    this.beneficiario.filiacao[index].municipio = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    }
                    //this.beneficiario.filiacao[index].municipio = `${localidade} / ${uf}`;
                } else {
                    this.$toast.error({
                        message: "CEP Inválido."
                    });
                }
                this.$Progress.finish();
            }
        },
        removeFiliacao(filiacao) {
            const index = this.beneficiario.filiacao.indexOf(filiacao);
            this.beneficiario.filiacao.splice(index, 1);
        },
        isNullOrEmpty(value) {
            return value === null || value === "";
        },
        onCancelClick() {
            sessionStorage.clear();
            this.$router.push({ name: "spl-navegantes.show" });
        },
        addEmail() {
            this.beneficiario.contatos.email.push("");
        },
        addPhone() {
            this.beneficiario.contatos.telefone.push("");
        },
        addFiliacaoEmail() {
            this.beneficiario.contato_filiacao.email.push("");
        },
        addFiliacaoPhone() {
            this.beneficiario.contato_filiacao.telefone.push("");
        },
        removeEmail(email) {
            const index = this.beneficiario.contatos.email.indexOf(email);
            this.beneficiario.contatos.email.splice(index, 1);
        },
        removePhone(telefone) {
            const index = this.beneficiario.contatos.telefone.indexOf(telefone);
            this.beneficiario.contatos.telefone.splice(index, 1);
        },
        removeFiliacaoEmail(email) {
            const index = this.beneficiario.contato_filiacao.email.indexOf(
                email
            );
            this.beneficiario.contato_filiacao.email.splice(index, 1);
        },
        removeFiliacaoPhone(telefone) {
            const index = this.beneficiario.contato_filiacao.telefone.indexOf(
                telefone
            );
            this.beneficiario.contato_filiacao.telefone.splice(index, 1);
        }
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-6" :crumbs="crumbs" />
            <div class="bg-white shadow-lg p-4  lg:max-w-full my-4 px-2 sm:px-6 ">
                <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Informações Pessoais
                    </span>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            CPF*
                        </span>
                        <span>
                            <TheMask v-model="beneficiario.document" :mask="['###.###.###-##']"
                                class="h-auto py-2 rounded form-input " :class="{
                                    'bg-red-100 border-red-400':
                                        hasErrors && !beneficiario.document
                                }" required @input="checkCPFValid" />
                            <div v-if="hasErrors && !beneficiario.document"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                    <div class="w-6/12">
                        <span class="text-sm form-label text-gray-dark">
                            Nome*
                        </span>
                        <span>
                            <input v-model="beneficiario.nmnome" type="text" class="h-auto py-2 rounded form-input"
                                :class="{
                                    'bg-red-100 border-red-400':
                                        hasErrors && !beneficiario.nmnome
                                }" />
                            <div v-if="hasErrors && !beneficiario.nmnome"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            Data de Nascimento*
                        </span>
                        <span>
                            <input v-model="beneficiario.dtnascimento" type="text"
                                class="h-auto py-2 rounded form-input" :class="{
                                    'bg-red-100 border-red-400': hasErrors && (!beneficiario.dtnascimento || !validBirthDate),
                                }" required @input="$event => validateBirthDateOnInput($event.target.value)" />
                            <div v-if="!validBirthDate && beneficiario.dtnascimento && beneficiario.dtnascimento.length === 10"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Data inválida
                            </div>
                            <div v-if="!validBirthDate && beneficiario.dtnascimento.length === 8"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Data inválida
                            </div>
                            <div v-if="hasErrors && !beneficiario.dtnascimento"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                </div>
                <div class="flex space-x-4 my-2">
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            Tipo de beneficiário*
                        </span>
                    </div>
                    <div class="flex space-x-4 w-3/12">
                        <span>
                            <input v-model="beneficiario.tpbeneficiario" :value="TipoBeneficiario.ESTUDANTE"
                                :id="TipoBeneficiario.ESTUDANTE" type="radio" />
                        </span>
                        <label :for="TipoBeneficiario.ESTUDANTE" class="text-sm form-label text-gray-dark">
                            Estudante
                        </label>
                    </div>
                    <div class="flex space-x-4 w-3/12">
                        <span>
                            <input v-model="beneficiario.tpbeneficiario" :value="TipoBeneficiario.ESTAGIARIO"
                                :id="TipoBeneficiario.ESTAGIARIO" type="radio" />
                        </span>
                        <label :for="TipoBeneficiario.ESTAGIARIO" class="text-sm form-label text-gray-dark">
                            Estagiário
                        </label>
                    </div>
                    <div class="flex space-x-4 w-3/12">
                        <span>
                            <input v-model="beneficiario.tpbeneficiario" :value="TipoBeneficiario.TRABALHADOR"
                                :id="TipoBeneficiario.TRABALHADOR" type="radio" />
                        </span>
                        <label :for="TipoBeneficiario.TRABALHADOR" class="text-sm form-label text-gray-dark">
                            Trabalhador
                        </label>
                    </div>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            CEP*
                        </span>
                        <span>
                            <TheMask v-model="beneficiario.cep" :mask="['##.###-###']"
                                class="h-auto py-2 rounded form-input" required @input="onCepChangeBeneficiario" />
                        </span>
                        <div v-if="hasErrors && !beneficiario.cep"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </div>
                    <div class="w-6/12">
                        <span class="text-sm form-label text-gray-dark">
                            Endereço*
                        </span>
                        <span>
                            <input v-model="beneficiario.street" type="text" class="h-auto py-2 rounded form-input"
                                :class="{
                                    'bg-red-100 border-red-400':
                                        hasErrors && !beneficiario.street
                                }" />
                            <div v-if="hasErrors && !beneficiario.street"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            Número
                        </span>
                        <span>
                            <input v-model="beneficiario.number" type="number" class="h-auto py-2 rounded form-input" />
                        </span>
                    </div>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            Complemento
                        </span>
                        <span>
                            <input v-model="beneficiario.complement" type="text"
                                class="h-auto py-2 rounded form-input" />
                        </span>
                    </div>
                    <div class="w-4/12">
                        <span class="text-sm form-label text-gray-dark">
                            Bairro*
                        </span>
                        <span>
                            <input v-model="beneficiario.district" type="text" :class="{
                                'bg-red-100 border-red-400':
                                    hasErrors && !beneficiario.district
                            }" class="h-auto py-2 rounded form-input" />
                            <div v-if="hasErrors && !beneficiario.district"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                    <div class="w-5/12">
                        <span class="text-sm form-label text-gray-dark">
                            Cidade/Estado*
                        </span>
                        <span>
                            <search-select :selected.sync="beneficiario.municipio" :search-keys="['name']"
                                :search-remote="true" :lazy-load="true"
                                options-url="/api/registry/location?take=50&paginate=false" option-key="id"
                                :option-value="city => city.name + ' / ' + city.state.initials" field-class="py-2"
                                name="city" placeholder="Selecione uma cidade..." @changed="citySelected" />
                            <div v-if="hasErrors && !beneficiario.municipio"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                </div>

                <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Contato do Beneficiário
                    </span>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-6/12">
                        <span class="text-sm form-label text-gray-dark">Email*</span>
                        <span v-for="(email, index) in beneficiario.contatos
                            .email" :key="index">
                            <span class="flex items-center gap-5 mb-3">
                                <input v-model="beneficiario.contatos.email[index]" type="text" :class="{
                                    'bg-red-100 border-red-400':
                                        hasErrors &&
                                        !beneficiario.contatos.email[index]
                                }" class="h-auto py-2 mb-0 rounded form-input" required />

                                <div @click="removeEmail(email)">
                                    <Icon v-if="
                                        beneficiario.contatos.email.length >
                                        1
                                    " icon="x" class="h-6 cursor-pointer fill-current" />
                                </div>
                            </span>
                            <div v-if="
                                hasErrors &&
                                !beneficiario.contatos.email[index]
                            " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                    <div class="w-6/12">
                        <span class="text-sm form-label text-gray-dark">
                            Telefone*
                        </span>
                        <span v-for="(telefone, index) in beneficiario.contatos
                            .telefone" :key="index">
                            <span class="flex items-center gap-5 mb-3">
                                <TheMask v-model="beneficiario.contatos.telefone[index]
                                    " :mask="['(##) #####.####']" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiario.contatos.telefone[
                                            index
                                            ]
                                    }" class="h-auto py-2 mb-0 rounded form-input" required style="flex: 1" />
                                <div @click="removePhone(telefone)">
                                    <Icon v-if="
                                        beneficiario.contatos.telefone
                                            .length > 1
                                    " icon="x" class="h-6 cursor-pointer fill-current" />
                                </div>
                            </span>
                            <div v-if="
                                hasErrors &&
                                !beneficiario.contatos.telefone[index]
                            " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-6/12">
                        <button @click="addEmail" class="px-2 py-2 mb-2 form-new-green sm:py-1">
                            <div class="flex items-center justify-center">
                                Novo Email
                            </div>
                        </button>
                    </div>
                    <div class="w-6/12">
                        <button @click="addPhone" class="px-2 py-2 mb-2 form-new-green sm:py-1">
                            <div class="flex items-center justify-center">
                                Novo Telefone
                            </div>
                        </button>
                    </div>
                </div>
                <div :key="index" v-for="(filiacao, index) in beneficiario.filiacao">
                    <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                        <span class="mt-2 text-base uppercase text-blue-base">
                            FILIAÇÃO
                        </span>
                        <button v-if="beneficiario.filiacao.length > 1" @click="removeFiliacao(filiacao)"
                            class="px-2 py-2 mb-2 form-new-green sm:py-1 border-red-400">
                            <div class="flex text-red-400 items-center justify-center">
                                Remover
                            </div>
                        </button>
                    </div>

                    <div>
                        <div class="flex space-x-4 mt-2">
                            <div class="w-3/12">
                                <span class="text-sm form-label text-gray-dark">
                                    CPF{{ isFiliacaoInfoRequired || isFiliacaoDocumentRequired[index] ? '*' : '' }}
                                </span>
                                <span>
                                    <TheMask v-model="filiacao.document" :mask="['###.###.###-##']" :class="{
                                        'bg-red-100 border-red-400': hasErrors &&
                                            // mensagem de erro se CPF vazio e CEP preenchido ou vice-versa ou
                                            (!filiacao.document && filiacao.cep) ||
                                            // mensagem de erro se nome vazio e obrigatório
                                            (!filiacao.document && (isFiliacaoInfoRequired || isFiliacaoDocumentRequired[index]))
                                    }" class="h-auto py-2 rounded form-input" @input="$event => {
                                        getFiliacaoByDocument($event, index);
                                        checkForFiliacaoInfoRequired();
                                    }" />
                                    <div v-if="hasErrors && !filiacao.document && (isFiliacaoInfoRequired || isFiliacaoDocumentRequired[index])"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Informe o CPF
                                    </div>
                                </span>
                            </div>
                            <div class="w-6/12">
                                <span class="text-sm form-label text-gray-dark">
                                    Nome*
                                </span>
                                <span>
                                    <input v-model="filiacao.nmnome"
                                        :class="{ 'bg-red-100 border-red-400': hasErrors && !filiacao.nmnome }"
                                        type="text" class="h-auto py-2 rounded form-input" />
                                    <div v-if="hasErrors && !filiacao.nmnome"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Informe o Nome
                                    </div>
                                </span>
                            </div>
                            <div class="w-3/12">
                                <span class="text-sm form-label text-gray-dark">
                                    Parentesco*
                                </span>
                                <span>
                                    <Select :key="filiacao.tpparentesco" id="status" class="w-full" :selectClass="hasErrors && !filiacao.tpparentesco
                                        ? 'bg-red-100 border-red-400 form-select filter-select'
                                        : 'form-select filter-select'
                                        " :options="filiacaoOptions" :selected.sync="filiacao.tpparentesco" />
                                    <div v-if="
                                        hasErrors && !filiacao.tpparentesco
                                    " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="flex space-x-4 mt-2">
                            <div class="w-3/12">
                                <span class="text-sm form-label text-gray-dark">
                                    CEP{{ isFiliacaoInfoRequired || isFiliacaoCepRequired[index] ? '*' : '' }}
                                </span>
                                <span>
                                    <TheMask v-model="filiacao.cep" :mask="['##.###-###']" :class="{
                                        'bg-red-100 border-red-400': hasErrors &&
                                            // mensagem de erro se CPF vazio e CEP preenchido ou vice-versa ou
                                            (filiacao.document && !filiacao.cep) ||
                                            // mensagem de erro se nome vazio e obrigatório
                                            (!filiacao.cep && (isFiliacaoInfoRequired || isFiliacaoCepRequired[index]))
                                    }" class="h-auto py-2 rounded form-input" required @input="$event => {
                                        onCepChangeFiliacao($event, index);
                                        checkForFiliacaoInfoRequired();
                                    }" />
                                </span>
                                <div v-if="hasErrors && !filiacao.cep && (isFiliacaoInfoRequired || isFiliacaoCepRequired[index])"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Informe o CEP
                                </div>
                            </div>
                            <div class="w-6/12">
                                <span class="text-sm form-label text-gray-dark">
                                    Endereço
                                </span>
                                <span>
                                    <input v-model="filiacao.street" type="text"
                                        class="h-auto py-2 rounded form-input" />
                                </span>
                            </div>
                            <div class="w-3/12">
                                <span class="text-sm form-label text-gray-dark">
                                    Número
                                </span>
                                <span>
                                    <input v-model="filiacao.number" type="number"
                                        class="h-auto py-2 rounded form-input" />
                                </span>
                            </div>
                        </div>

                        <div class="flex space-x-4 mt-2">
                            <div class="w-3/12">
                                <span class="text-sm form-label text-gray-dark">
                                    Complemento
                                </span>
                                <span>
                                    <input v-model="filiacao.complement" type="text"
                                        class="h-auto py-2 rounded form-input" />
                                </span>
                            </div>
                            <div class="w-4/12">
                                <span class="text-sm form-label text-gray-dark">
                                    Bairro
                                </span>
                                <span>
                                    <input v-model="filiacao.district" type="text"
                                        class="h-auto py-2 rounded form-input" />
                                </span>
                            </div>
                            <div class="w-5/12">
                                <span class="text-sm form-label text-gray-dark">
                                    Cidade/Estado
                                </span>
                                <span>
                                    <search-select :selected.sync="filiacao.municipio" :search-keys="['name']"
                                        :search-remote="true" :lazy-load="true"
                                        options-url="/api/registry/location?take=50&paginate=false" option-key="id"
                                        :option-value="city => city.name + ' / ' + city.state.initials"
                                        field-class="py-2" name="city" placeholder="Selecione uma cidade..."
                                        @changed="cityFiliacaoSelected" />
                                    <div v-if="hasErrors && !filiacao.municipio && !isNullOrEmpty(filiacao.document)"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-6/12">
                        <button @click="addFiliacao" class="px-2 py-2 mb-2 form-new-green sm:py-1">
                            <div class="flex items-center justify-center">
                                Adicionar Filiação
                            </div>
                        </button>
                    </div>
                </div>

                <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Contato da filiação
                    </span>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-6/12">
                        <span class="text-sm form-label text-gray-dark">Email</span>
                        <span v-for="(email, index) in beneficiario
                            .contato_filiacao.email" :key="index" class="flex items-center gap-5 mb-3">
                            <input v-model="beneficiario.contato_filiacao.email[index]
                                " type="text" class="h-auto py-2 mb-0 rounded form-input" />

                            <div @click="removeFiliacaoEmail(email)">
                                <Icon v-if="
                                    beneficiario.contato_filiacao.email
                                        .length > 1
                                " icon="x" class="h-6 cursor-pointer fill-current" />
                            </div>
                        </span>
                    </div>
                    <div class="w-6/12">
                        <span class="text-sm form-label text-gray-dark">
                            Telefone
                        </span>
                        <span v-for="(telefone, index) in beneficiario
                            .contato_filiacao.telefone" :key="index" class="flex items-center gap-5 mb-3">
                            <TheMask v-model="beneficiario.contato_filiacao.telefone[
                                index
                            ]
                                " :mask="['(##) #####.####']" class="h-auto py-2 mb-0 rounded form-input" required
                                style="flex: 1" />
                            <div @click="removeFiliacaoPhone(telefone)">
                                <Icon v-if="
                                    beneficiario.contato_filiacao.telefone
                                        .length > 1
                                " icon="x" class="h-6 cursor-pointer fill-current" />
                            </div>
                        </span>
                    </div>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-6/12">
                        <button @click="addFiliacaoEmail" class="px-2 py-2 mb-2 form-new-green sm:py-1">
                            <div class="flex items-center justify-center">
                                Novo Email
                            </div>
                        </button>
                    </div>
                    <div class="w-6/12">
                        <button @click="addFiliacaoPhone" class="px-2 py-2 mb-2 form-new-green sm:py-1">
                            <div class="flex items-center justify-center">
                                Novo Telefone
                            </div>
                        </button>
                    </div>
                </div>

                <div
                    class="mt-10 flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4 justify-between">
                    <div class="flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4">
                        <button @click="onCancelClick" type="button"
                            class="flex items-center justify-center py-2 form-submit md:w-auto"
                            style="background: #828282">
                            Cancelar
                        </button>
                        <button @click="onNextStepClick" type="button"
                            class="flex items-center justify-center py-2 form-submit md:w-auto">
                            Avançar
                        </button>
                    </div>
                    <div style="color: #1767b2">Passo 1 de 3</div>
                </div>
            </div>
        </div>
    </div>
</template>
