<script>
import Avatar from "@/core/components/Avatar";
import Icon from "@/core/components/Icon";
import ModuleButton from "@/domains/home/components/ModuleButton";
import { getModules } from "@/domains/home/services";

export default {
    components: {
        Avatar,
        Icon,
        ModuleButton
    },
    data() {
        return {
            modules: [
                {
                    name: "Suporte",
                    icon: "support",
                    route: "support.index"
                }
            ]
        };
    },
    computed: {
        fewModules() {
            if (this.modules.length > 5) {
                return false;
            }
            return true;
        }
    },
    created() {
        this.$Progress.start();
        getModules()
            .then(resp => {
                this.modules = Object.values(resp.data);
                this.$Progress.finish();
            })
            .catch(() => {
                this.$Progress.finish();
            });
    },
    mounted() {
        document.title = process.env.VUE_APP_TITLE;
    },
    methods: {
        selectLink(link) {
            this.$store.commit("setLink", link);
            this.$store.dispatch("getPermissions");
            if (this.$route.name !== "index") {
                this.$router.replace({ name: "index" });
            }
        }
    }
};
</script>

<style scoped>
.card-company {
    padding: 20px;
    border: 1px solid #f3f3f3;
    display: flex;
    margin: 5px;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    border-radius: 5px;
    color: #9a9a9a;
    height: 170px;
    width: 170px;
    justify-content: space-around;
    cursor: pointer;
    transition: all 0.5s;
}

.card-company:hover {
    box-shadow: 0px 10px 9px -7px rgba(0, 0, 0, 0.75);
    margin-top: -5px;
}

.img-icon-company {
    background-image: url("~@/core/assets/images/company_back.png");
}

.name-company {
    border-bottom: 1px solid;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #4c87be;
}
</style>

<template>
    <div
        v-if="$store.getters.isAuthenticated && $store.getters.isUserLoaded"
        :class="[
            fewModules ? 'md:pt-16' : 'md:pt-4',
            'bg-white md:bg-gray-lighter md:px-20'
        ]"
    >
        <div class="px-6 md:bg-white md:shadow">
            <div
                :class="[
                    fewModules
                        ? 'md:flex-col md:max-w-2xl'
                        : 'md:justify-start md:max-w-6xl',
                    'mx-auto flex items-center justify-center py-4 md:px-6 border-b-2 border-blue-base'
                ]"
            >
                <div :class="fewModules ? 'md:mt-8' : ''">
                    <Avatar :name="$store.state.auth.user.name" />
                </div>
                <div :class="fewModules ? 'md:mt-2' : ''">
                    <h1
                        v-if="$store.state.auth.user"
                        class="flex items-center justify-center text-lg leading-tight text-blue-base"
                    >
                        Olá,
                        {{
                            $store.state.auth.user.business_name ||
                                $store.state.auth.user.name
                        }}
                        <Icon
                            v-if="!$store.state.auth.user.links.length"
                            icon="attention"
                            class="h-5 ml-1"
                        />
                    </h1>
                    <h2
                        :class="[
                            fewModules ? 'md:text-center' : '',
                            'mt-1 text-gray-darker leading-tight text-sm'
                        ]"
                    >
                        <div
                            v-if="$store.getters.isOrganization"
                            class="font-extrabold"
                        >
                            Você está logado na conta de CNPJ a qual é usada
                            somente para vincular/desvincular gestores, para
                            fazer qualquer outra operação entre na sua conta de
                            CPF.
                            <router-link
                                class="ml-1 underline cursor-pointer text-blue-base hover:text-blue-light"
                                :to="{ name: 'organization.managers' }"
                            >
                                Clique aqui para vincular gestores.
                            </router-link>
                        </div>
                        <div
                            v-else-if="!$store.state.auth.user.links.length"
                            class="text-red-base md:flex"
                        >
                            <div>
                                Você ainda não possui nenhum vínculo.
                            </div>
                            <router-link
                                class="underline cursor-pointer md:ml-1 hover:text-red-800"
                                :to="{ name: 'link.create' }"
                            >
                                Clique aqui para fazer a solicitação.
                            </router-link>
                        </div>
                        <div v-else-if="!$store.state.auth.link">
                            Selecione uma instituição.
                        </div>
                        <div v-else>Selecione um módulo.</div>
                    </h2>
                </div>
            </div>
            <div
                :class="[
                    fewModules ? 'max-w-2xl' : 'md:justify-start max-w-6xl',
                    modules.length === 2 ? 'md:justify-start' : '',
                    'flex flex-wrap mx-2 md:mx-auto mt-4 pb-8 justify-between align-middle items-start'
                ]"
            >
                <template
                    v-if="
                        !$store.state.auth.link &&
                            $store.state.auth.user.links.length > 0
                    "
                >
                    <div
                        v-for="link in $store.state.auth.user.links"
                        :key="link.id"
                        @click="selectLink(link)"
                        class="card-company"
                    >
                        <div
                            class="w-12 h-12 bg-center bg-contain border-2 rounded-full border-blue-base img-icon-company"
                        >
                            <!-- <img src="@/core/assets/images/company_back.png" /> -->
                        </div>
                        <div class="name-company">
                            {{
                                link.organization.business_name ||
                                    link.organization.name
                            }}
                        </div>
                        <div class="document-company">
                            {{ link.organization.document_formatted }}
                        </div>
                    </div>
                </template>
                <template v-else-if="modules.length > 0">
                    <ModuleButton
                        v-for="(module, index) in modules"
                        :key="index"
                        :class="[
                            fewModules ? '' : 'md:mr-6 md:ml-8',
                            modules.length === 2 ? 'md:mr-6' : '',
                            'mx-4 my-4'
                        ]"
                        :name="module.name"
                        :icon="module.icon"
                        :route="module.route"
                    />
                </template>
            </div>
        </div>
    </div>
</template>
