<template>
    <Modal
        width="500"
        borderLeft="4px solid #ff4d4d"
        @close="$emit('close')"
        class="text-center"
    >
        <div slot="header">
            <h2 class="text-muted">Atenção!</h2>
        </div>
        <div slot="body">
            <h5>{{ message }}</h5>
        </div>
        <span slot="footer">
            <div class="d-flex justify-content-around">
                <button class="btn btn-danger" v-on:click="$emit('ok')">
                    Confirmar
                </button>
                <button
                    class="btn btn-outline-secondary"
                    v-on:click="$emit('close')"
                >
                    Cancelar
                </button>
            </div>
        </span>
    </Modal>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import Modal from "@/core/components/Modal";

@Component({
    props: ["message"],
    components: {
        Modal
    }
})
export default class Delete extends Vue {}
</script>
