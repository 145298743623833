<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import Icon from "@/core/components/Icon";
import NavegantesList from "@/domains/spl-navegantes/partials/NavegantesList";

export default {
    components: {
        Breadcrumbs,
        Icon,
        NavegantesList
    },
    data: () => {
        return {
            title: "SPL Navegantes",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "SPL Navegantes", path: "spl-navegantes.index" },
                { name: "Cadastro", path: "spl-navegantes.show" }
            ],
            search: "",
            closed: 0,
            filter: null,
            idbeneficiario_search: "",
            document_search: "",
            status: "",
            my_tickets: [],
            allTickets: []
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },
    methods: {
        getTipoBeneficiario(status) {
            if (status === "1") {
                return "Estudante";
            } else if (status === "2") {
                return "Trabalhador";
            } else if (status === "3") {
                return "Estagiario";
            }
            return "";
        },
        getSituationRegister(situation) {
            situation = String(situation);
            if (situation === "1") {
                return "INICIADO";
            } else if (situation === "2") {
                return "ANALISADO";
            } else if (situation === "3") {
                return "ATIVO";
            } else if (situation === "4") {
                return "VENCIDO";
            } else if (situation === "5") {
                return "PENDENTE";
            } else if (situation === "6") {
                return "CANCELADO";
            }

            return "";
        },
        getFormattedDate(data) {
            if (data) {
                let dateObject = new Date(data);
                let day = String(dateObject.getDate()).padStart(2, "0");
                let month = String(dateObject.getMonth() + 1).padStart(2, "0");
                let year = dateObject.getFullYear();
                let formattedDate = day + "/" + month + "/" + year;
                return formattedDate;
            }
            // return "00/00/0000";
            return "";
        },
        getRoundTime(data) {
            const formatRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6}$/;

            if (!formatRegex.test(data)) {
                return "";
            }

            const currentDate = new Date();
            const paramDate = new Date(data);
            const difference = currentDate - paramDate;

            if (difference >= 1000 * 60 * 60 * 24 * 365) {
                const years = Math.round(
                    difference / (1000 * 60 * 60 * 24 * 365)
                );

                return `há ${years === 1 ? "1 ano" : `${years} anos`}`;
            } else if (difference >= 1000 * 60 * 60 * 24) {
                const days = Math.round(difference / (1000 * 60 * 60 * 24));
                return `há ${days === 1 ? "1 dia" : `${days} dias`}`;
            } else if (difference >= 1000 * 60 * 60) {
                const hours = Math.round(difference / (1000 * 60 * 60));
                return `há ${hours === 1 ? "1 hora" : `${hours} horas`}`;
            } else if (difference >= 1000 * 60) {
                const minutes = Math.round(difference / (1000 * 60));
                return `há ${minutes === 1 ? "1 minuto" : `${minutes} minutos`
                    }`;
            } else {
                const seconds = Math.round(difference / 1000);
                return `há ${seconds === 1 ? "1 segundo" : `${seconds} segundos`
                    }`;
            }
        },
        onStatusChange(status) {
            this.status = status;
            this.onSearch();
        },
        pageList(array, pageNumber, pageSize) {
            return array.slice(
                (pageNumber - 1) * pageSize,
                pageNumber * pageSize
            );
        },
        async resetSearch() {
            this.document_search = '';
            this.onSearch();
        },
        async onSearch() {
            this.$Progress.start();
            try {

                const regex = /^(?:\d{3}\.?\d{3}\.?\d{3}-?\d{2}|^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/;
                let document_search = this.document_search;

                if (regex.test(this.document_search)) {
                    document_search = this.document_search.replace(/[^0-9]/gm, '')
                }
                if(document_search == '' && this.idbeneficiario_search == ''){
                    this.$router.go(0);
                }else{
                    const res = await this.$axios.post(
                        "/api/spl-navegantes/beneficiarios",
                        {
                            take: "5000",
                            search: document_search,
                            idbeneficiario: this.idbeneficiario_search
                        }
                    );
                    if(res.data.sucess){
                        if(res.data.sucess.original && res.data.sucess.original.error){
                            this.$toast.error({
                                message: "Não há registros para essa pesquisa."
                            });
                        }else{
                            const my_list = await Promise.all(
                                Object.entries(res.data.sucess).map(async my_item => {
                                    const item = my_item[1];

                                    if (item == null)
                                        return {};

                                    const tipo_beneficiario = this.getTipoBeneficiario(
                                        item.tpbeneficiario
                                    );
                                    const situacao_cadastro = this.getSituationRegister(
                                        item.controle_registro?.idsituacao
                                    );
                                    const formattedDate = this.getFormattedDate(
                                        item.controle_registro?.dtexpiracao
                                    );
                                    const roundTime = this.getRoundTime(
                                        item?.doacao?.dtalteracao
                                    );

                                    return {
                                        idbeneficiario: item.idbeneficiario,
                                        matricula: item.idbeneficiario ?? "",
                                        nome: item.nmnome ?? "",
                                        cpf: item.usuario?.document_formatted ?? "",
                                        beneficiario: tipo_beneficiario,
                                        expiracao: formattedDate,
                                        situacao: situacao_cadastro,
                                        atualizacao: roundTime,
                                        Opções: true
                                    };
                                })
                            );

                            this.my_tickets = this.pageList(my_list, 1, 6);
                            this.allTickets = my_list;
                        }
                    }
                } 
            } catch (e) {

                this.$toast.error({
                    message:
                        e.response?.data?.message || e.response?.data?.error
                });
            } finally {
                this.loading = false;
                this.status = "finished";
                this.$Progress.finish();
            }
        }
    },
    computed: {
        pagination() {
            return {
                current_page: 1,
                first_page_url:
                    "https://scmobi.sie.sc.gov.br/api/registry/person/organizations?page=1",
                from: 1,
                last_page: 1,
                last_page_url:
                    "https://scmobi.sie.sc.gov.br/api/registry/person/organizations?page=1",
                next_page_url: null,
                path:
                    "https://scmobi.sie.sc.gov.br/api/registry/person/organizations",
                // per_page: 15,
                per_page: 10,
                prev_page_url: null,
                to: 2,
                total: 10
            };
        }
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-6" :crumbs="crumbs" />

            <div class="flex flex-wrap justify-between gap-4" style="margin-bottom: 0.5rem;">
                <div class="flex items-center w-full lg:w-auto lg:mb-0">
                    <router-link tag="button" class="form-new-blue sm:py-1" :to="{ name: 'spl-navegantes.step01' }">
                        <div class="flex items-center justify-center">
                            <Icon icon="plus" class="h-4 mr-1 fill-current" />
                            Novo cadastro
                        </div>
                    </router-link>
                </div>
                <div class="flex flex-grow justify-end gap-1 md:flex-row md:items-center">
                    <div class="wide-input">
                        <input v-model="idbeneficiario_search" @keyup.enter="onSearch" class="input-search text-xs" type="text"
                            placeholder="Pesquisar por Matrícula " />
                        <span class="input-icon">
                        </span>
                    </div>
                    <div class="wide-input">
                        <input v-model="document_search" @keyup.enter="onSearch" class="input-search-cpf text-xs" type="text"
                            placeholder="Pesquisar por Nome ou CPF" />

                    </div>
                    <button class="btn btn-primary my-btn" @click="onSearch" 
                        style="display: flex; align-items: center; justify-content: center;">
                        <Icon icon="search" class="h-4 input-icon fill-current" />
                        Pesquisar
                    </button>

                </div>
            </div>
            <div>
                <NavegantesList ref="table" :closed="closed" :search="search" :filter="filter"
                    :idbeneficiario="idbeneficiario_search" :my_document="document_search" :my_tickets="my_tickets"
                    :allTickets="allTickets" :pagination="pagination" @setAllTickets="allTickets = $event"
                    @refresh="my_tickets = $event" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.input-search {
    min-height: 36px;
}

.input-search-cpf {
    min-height: 36px;
    width: 200px;
}

button {
    min-height: 36px !important;
    margin: 0;
}

.wide-input {
    display: flex;
    align-items: center;
}

.wide-input input {
    flex-grow: 1;
    min-width: 100px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input-icon {
    margin-right: 0.3rem;
    cursor: pointer;
}
</style>
