<script>
import Header from "@/core/components/Header";
import Sidebar from "@/core/components/Sidebar";
import Footer from "@/core/components/Footer";
import Modal from "@/core/components/NewModal";
import RegistrationWarning from "@/domains/auth/components/RegistrationWarning";
import CircularWarning from "@/domains/auth/components/CircularWarning";
import {
    confirmReregistration,
    confirmCircularInfo
} from "@/domains/re-registration/services/reregistration";

export default {
    components: {
        CircularWarning,
        Header,
        Sidebar,
        Footer,
        Modal,
        RegistrationWarning
    },
    mounted() {
        if (this.$store.state.auth.link) {
            confirmReregistration(
                this.$store.state.auth.link.organization_id
            ).catch(() => {
                this.showRegistrationWarning();
            });
            confirmCircularInfo(
                this.$store.state.auth.link.organization_id
            ).then(data => {
                if (data.data === 2) {
                    this.showCircularWarning();
                }
            });
        }
    },
    methods: {
        toggleSidebar() {
            this.$refs.sidebar.toggleOnMobile();
        },
        showRegistrationWarning() {
            this.$refs.registrationWarning.show();
        },
        hideRegistrationWarning() {
            this.$refs.registrationWarning.hide();
        },
        showCircularWarning() {
            this.$refs.circularWarning.show();
        },
        hideCircularWarning() {
            this.$refs.circularWarning.hide();
        }
    }
};
</script>

<template>
    <div class="flex flex-col min-h-screen">
        <Header @toggleSidebar="toggleSidebar" />
        <div class="flex flex-grow w-full" :key="this.$route.meta.module">
            <portal-target name="main-modal" multiple />
            <Sidebar
                v-if="!this.$route.meta.hideSidebar"
                id="sidebar"
                ref="sidebar"
            />
            <Modal
                ref="registrationWarning"
                to="main-modal"
                contentClass="sm:max-w-4xl"
                :closeOnClickOutside="false"
            >
                <RegistrationWarning @close="hideRegistrationWarning" />
            </Modal>
            <Modal
                ref="circularWarning"
                to="main-modal"
                contentClass="sm:max-w-4xl"
                :closeOnClickOutside="false"
            >
                <CircularWarning @close="hideCircularWarning" />
            </Modal>
            <router-view class="flex-grow" />
        </div>
        <Footer />
    </div>
</template>
