<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import SystemInfo from "@/core/components/portlets/SystemInfo";

export default {
    components: {
        Breadcrumbs,
        SystemInfo
    },
    data: () => {
        return {
            title: "Recadastramento",
            crumbs: [
                { name: "Início", path: "index" },
                {
                    name: "Recadastramento",
                    path: "re-registration.index"
                }
            ],
            alerts: [
                "Mantenha os dados da sua instituição atualizados.",
                "A renovação de registro fica disponível somente um mês antes do vencimento.",
                "Não utilize uma guia de solicitação em outra solicitação. Uma guia de vistoria gerada em uma inclusão de veículo não pode ser usada na solicitação de renovação de registro.",
                "Esse Menu trata apenas de informações da sua conta de pessoa jurídica (CNPJ), para gerenciar informações da sua conta física (CPF) acesse o Menu Usuário SCmobi"
            ]
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="lg:max-w-5xl my-4 px-2 sm:px-6">
            <Breadcrumbs class="mb-8" :crumbs="crumbs" />
            <SystemInfo
                :helpLink="'https://ajuda.sie.sc.gov.br/bc/organizacao/'"
                :alerts="alerts"
            />
        </div>
    </div>
</template>
