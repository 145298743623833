<script>
import ArrowRight from "@/core/components/icons/arrow-right.vue";

export default {
    components: {
        ArrowRight
    },
    props: {
        services: {
            type: Array,
            required: true
        }
    },
    methods: {
        handleAction(service) {
            switch (service.form) {
                case "linesForm":
                    sessionStorage.setItem("serviceForm", JSON.stringify(service));
                    this.$router.push({ name: "lines.services.create", params: { service: service } });
                    break;
                default:
                    this.$toast.error({
                        message: "Esse serviço ainda não foi implementado no SCMobi"
                    });
                    break;
            }
        }
    }
};
</script>

<style scoped></style>

<template>
    <div>
        <table class="w-full">
            <thead>
                <tr>
                    <th class="w-3/12 text-gray-600 text-left pl-3 pb-2">Serviço</th>
                    <th class="w-8/12 text-gray-600 text-left pb-2">Descrição</th>
                    <th class="w-1/12"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(service, index) in services" :key="service.id" :class="{ 'bg-blue-100': index % 2 == 0 }">
                    <td class="p-3 font-bold text-sm text-gray-700">{{ service.name }}</td>
                    <td class="text-sm text-gray-600">{{ service.description }}</td>
                    <td>
                        <button class="text-blue-300 relative left-1/3" @click="handleAction(service)">
                            <ArrowRight class="h-4" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>