export default [
    {
        path: "/gerenciamento-processos",
        name: "protocol-manager.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-protocol-manager" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            module: "protocol-manager"
        }
    },
    {
        path: "/gerenciamento-processos/processo",
        name: "protocol-manager.process",
        component: () =>
            import(
                /* webpackChunkName: "chunk-protocol-manager" */ "./views/Process.vue"
            ),
        meta: {
            forAuth: true,
            module: "protocol-manager"
        }
    },
    {
        path: "/gerenciamento-processos/processo/:id",
        name: "protocol-manager.process.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-protocol-manager" */ "./views/ShowProcess.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "protocol-manager"
        }
    }
];
