export default [
    {
        path: "/gerenciamento-financeiro",
        name: "financial-manager.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-fin-manager" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            module: "financial-manager"
        }
    },
    {
        path: "/gerenciamento-financeiro/guia",
        name: "financial-manager.bill",
        component: () =>
            import(
                /* webpackChunkName: "chunk-fin-manager" */ "./views/Bills.vue"
            ),
        meta: {
            forAuth: true,
            module: "financial-manager"
        }
    },
    {
        path: "/gerenciamento-financeiro/guia/:id",
        name: "financial-manager.bill.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-fin-manager" */ "./views/ShowBill.vue"
            ),
        meta: {
            forAuth: true,
            module: "financial-manager"
        }
    }
];
