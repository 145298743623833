<template>
    <div class="flex items-center">
        <Icon
            :icon="this.icon || this.defaultIcon"
            :class="this.iconClass || this.defaultIconClass"
            class="mr-1 fill-current"
        />
        <div
            :class="this.textClass || this.defaultTextClass"
            class="text-xs font-medium tracking-wide uppercase"
        >
            {{ this.text }}
        </div>
    </div>
</template>

<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "@/core/components/Icon";

@Component({
    components: {
        Icon
    }
})
export default class IconBadge extends Vue {
    @Prop()
    icon;

    @Prop()
    iconClass;

    @Prop()
    textClass;

    @Prop()
    text;

    constructor() {
        super();
        this.success = [
            "autenticado",
            "finished",
            "executed",
            "finalizado",
            "executado",
            "pago",
            "ativo",
            "ativa",
            "vínculo ativo",
            "suspenso judicialmente",
            "remitido (covid-19)",
            "impresso",
            "impressa",
            "definitivo",
            "aprovado",
            "resolvido",
            "resolução enviada"
        ];
        this.error = [
            "failed",
            "issue",
            "falhou",
            "com problemas",
            "com pendências",
            "rejeitado",
            "negado",
            "em cancelamento",
            "cancelado",
            "cancelada",
            "inválido",
            "suspenso",
            "inativo",
            "vencido",
            "vencida",
            "vínculo inativo",
            "vínculo rejeitado",
            "faltando cnd"
        ];
        this.waiting = [
            "pending",
            "pendente",
            "solicitado",
            "vínculo solicitado",
            "provisório",
            "cadastro incompleto",
            "aguardando pagamento",
            "aguardando documentação",
            "aguardando validação",
            "aguardando validação manual",
            "sujeito a verificação",
            "aguardando envio ao sgpe",
            "aguardando ativação no sgpe",
            "aguardando aprovação"
        ];
        this.attention = ["aberto", "em aberto"];
        this.high = ["high", "alta", "alto"];
        this.medium = ["medium", "normal"];
        this.low = ["low", "baixa", "baixo"];
    }

    get defaultIcon() {
        if (this.icon) {
            return null;
        }
        if (this.error.includes(this.text.toLowerCase())) {
            return "close";
        }
        if (this.success.includes(this.text.toLowerCase())) {
            return "checkedCircle";
        }
        if (this.waiting.includes(this.text.toLowerCase())) {
            return "clock";
        }
        if (this.attention.includes(this.text.toLowerCase())) {
            return "exclamation";
        }
        if (this.high.includes(this.text.toLowerCase())) {
            return "arrowUp";
        }
        if (this.medium.includes(this.text.toLowerCase())) {
            return "arrowUp";
        }
        if (this.low.includes(this.text.toLowerCase())) {
            return "arrowDown";
        }
        return "config";
    }

    get defaultIconClass() {
        if (this.iconClass) {
            return null;
        }
        if (this.error.includes(this.text.toLowerCase())) {
            return "h-4 text-red-base";
        }
        if (this.success.includes(this.text.toLowerCase())) {
            return "h-4 text-teal-600";
        }
        if (this.waiting.includes(this.text.toLowerCase())) {
            return "h-4 text-orange-800";
        }
        if (this.attention.includes(this.text.toLowerCase())) {
            return "h-4 text-red-base";
        }
        if (this.high.includes(this.text.toLowerCase())) {
            return "h-2 text-red-base";
        }
        if (this.medium.includes(this.text.toLowerCase())) {
            return "h-2 text-gray-dark";
        }
        if (this.low.includes(this.text.toLowerCase())) {
            return "h-2 text-teal-600";
        }
        return "h-4 text-gray-darker";
    }

    get defaultTextClass() {
        if (this.textClass) {
            return null;
        }
        if (this.error.includes(this.text.toLowerCase())) {
            return "text-red-base";
        }
        if (this.success.includes(this.text.toLowerCase())) {
            return "text-teal-600";
        }
        if (this.waiting.includes(this.text.toLowerCase())) {
            return "text-orange-800";
        }
        if (this.attention.includes(this.text.toLowerCase())) {
            return "text-red-base";
        }
        if (this.high.includes(this.text.toLowerCase())) {
            return "text-red-base";
        }
        if (this.medium.includes(this.text.toLowerCase())) {
            return "text-gray-dark";
        }
        if (this.low.includes(this.text.toLowerCase())) {
            return "text-teal-600";
        }
        return "text-gray-darker";
    }
}
</script>
