<script>
export default {
    props: {
        crumbs: {
            type: Array,
            required: true
        }
    },
    computed: {
        secondToLast() {
            return this.crumbs
                .slice(-2)
                .reverse()
                .pop();
        }
    }
};
</script>

<template>
    <div>
        <nav class="hidden md:block" aria-label="breadcrumb">
            <ol>
                <li
                    v-for="(crumb, i) in crumbs"
                    v-bind:key="crumb.name"
                    class="inline-block text-xs"
                >
                    <span v-if="i < crumbs.length - 1">
                        <router-link
                            :class="[i === 0 ? 'mr-1' : 'mx-1']"
                            class="text-blue-base hover:text-blue-darkest"
                            :to="{ name: crumb.path }"
                        >
                            {{ crumb.name }}</router-link
                        >/
                    </span>
                    <span
                        v-else
                        aria-current="page"
                        class="mx-1 cursor-default text-gray-darker"
                    >
                        {{ crumb.name }}
                    </span>
                </li>
            </ol>
        </nav>
        <nav class="block md:hidden" aria-label="breadcrumb">
            <ol>
                <li class="inline-block text-xs">
                    <router-link
                        class="inline-flex items-center space-x-1 text-blue-base hover:text-blue-darkest"
                        :to="{ name: secondToLast.path }"
                    >
                        <svg
                            class="w-4 h-4"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path d="M15 19l-7-7 7-7"></path></svg
                        >Voltar para {{ secondToLast.name }}</router-link
                    >
                </li>
            </ol>
        </nav>
    </div>
</template>
