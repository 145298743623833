<script>
import Portlet from "@/core/components/Portlet";
import PortletGroup from "@/core/components/PortletGroup";
import Icon from "@/core/components/Icon";

export default {
    props: {
        helpLink: {
            type: String,
            required: false
        },
        alerts: {
            type: Array,
            required: false
        },
        text: {
            type: String,
            required: false
        }
    },
    components: {
        Portlet,
        PortletGroup,
        Icon
    }
};
</script>

<template>
    <div>
        <PortletGroup>
            <div slot="header">Informações do SCmobi</div>
            <Portlet
                v-if="!alerts"
                slot="first-portlet"
                class="rounded-none p-0 md:w-1/2 md:mr-2"
            >
                <div
                    slot="header"
                    class="bg-blue-background text-white text-sm font-medium px-8 py-2"
                >
                    Novidades da Versão 4.0 {{ help }}
                </div>
                <div slot="content">
                    <div class="text-gray-darker px-6 py-2">
                        <ul class="list-disc text-xs">
                            <li class="pb-2">
                                Novo layout do SCmobi.
                            </li>
                            <li>
                                Fretamentos e Viagens Especiais para
                                transportadoras com objetivo comercial.
                            </li>
                        </ul>
                    </div>
                </div>
            </Portlet>
            <Portlet
                v-if="!alerts"
                slot="second-portlet"
                class="rounded-none md:w-1/2 mt-1 md:mt-0"
            >
                <div
                    slot="header"
                    class="bg-orange-base uppercase text-white text-sm font-medium px-8 py-2"
                >
                    Importante!
                </div>
                <div slot="content">
                    <div class="text-xs text-gray-darker px-4 py-2">
                        <p class="text-justify">
                            Está em vigência a Lei 17.278 de outubro de 2017,
                            que altera as regras para o embarque e desembarque
                            de idosos, pessoas com deficiência e mulheres e
                            também estabelece a necessidade de afixar no
                            interior dos veículos o aviso desta nova regra, para
                            todos os veículos registrados para o transporte
                            rodoviário intermunicipal de passageiros.
                        </p>
                        <p class="text-justify mt-2">
                            Para mais informações, acesse a
                            <a
                                class="text-blue-base hover:text-blue-dark"
                                href="https://www2.deter.sc.gov.br/wp-content/uploads/2018/05/LEIN17278_5_OUTUBRO_2017.pdf"
                            >
                                Lei Nº17.278/17</a
                            >.
                        </p>
                    </div>
                </div>
            </Portlet>

            <Portlet
                v-if="alerts"
                slot="second-portlet"
                class="rounded-none md:w-full mt-1 md:mt-0"
            >
                <div
                    slot="header"
                    class="bg-orange-base uppercase text-white text-sm font-medium px-8 py-2"
                >
                    Importante!
                </div>
                <div slot="content">
                    <div class="text-xs text-gray-darker px-4 py-2">
                        <div class="mb-2">{{ text }}</div>
                        <ul class="list-disc ml-3">
                            <li
                                v-for="alert in alerts"
                                :key="alert"
                                class="mb-2"
                            >
                                {{ alert }}
                            </li>
                        </ul>
                    </div>
                </div>
            </Portlet>
        </PortletGroup>
        <div v-if="helpLink" class="flex justify-end">
            <a
                :href="helpLink"
                target="_blank"
                class="mt-2 hidden lg:block lg:text-right lg:flex lg:items-center lg:mr-6 text-blue-500 hover:text-blue-lightest focus:text-white"
            >
                <Icon icon="help" class="h-6 mr-1 fill-current" />
                <p class="text-sm">Ajuda</p>
            </a>
        </div>
    </div>
</template>
