<script>
import moment from "moment";
import Portlet from "@/core/components/Portlet";
import IconBadge from "@/core/components/badges/IconBadge";
import Loader from "@/core/components/loaders/BallClipRotate";
import Modal from "@/core/components/NewModal";
import { dateFormat } from "@/core/services/helpers";
import { getCarrierStatus } from "@/domains/transport/services/carrier";
import RequestRenewal from "@/domains/transport/components/RequestRenewal";

export default {
    components: {
        Portlet,
        IconBadge,
        Loader,
        Modal,
        RequestRenewal
    },
    data() {
        return {
            dateFormat: dateFormat,
            carrier: null,
            expired: null,
            status: "pending"
        };
    },
    mounted() {
        this.getCarrierStatus();
    },
    computed: {
        canRenew() {
            return moment(this.carrier.expire_at).diff(moment(), "days") <= 30;
        }
    },
    methods: {
        getCarrierStatus() {
            this.status = "pending";
            getCarrierStatus()
                .then(response => {
                    this.carrier = response.carrier;
                    this.expired = response.expired;
                    this.status = "finished";
                })
                .catch(e => {
                    this.status = "rejected";
                    this.$toast.error({
                        message: e.error
                    });
                });
        },
        renderStatus(status) {
            switch (status.toLowerCase()) {
                case "authenticated":
                    return "Autenticado";
                case "canceled":
                    return "Cancelado";
                case "invalid":
                    return "Inválido";
                case "cancelling":
                    return "Em cancelamento";
                case "changing":
                    return "Pendente";
                case "missing_cnd":
                    return "Faltando CND";
                default:
                    return "Inválido";
            }
        },
        showRequestRenewal() {
            this.$refs.renewal.show();
        },
        hideRequestRenewal() {
            this.$refs.renewal.hide();
        }
    }
};
</script>

<template>
    <Portlet v-if="$store.getters.isLinkSelected" class="relative">
        <div
            slot="header"
            class="flex items-center justify-between py-4 pl-8 pr-5 font-medium uppercase bg-white border-b-2 text-blue-base"
        >
            Operadora #{{ $store.state.auth.link.organization.id }}
        </div>
        <div slot="content">
            <div v-if="carrier && canRenew">
                <Modal
                    ref="renewal"
                    contentClass="sm:max-w-2xl"
                    :closeOnClickOutside="false"
                >
                    <RequestRenewal
                        :carrier="carrier"
                        @close="hideRequestRenewal"
                        @success="getCarrierStatus"
                    />
                </Modal>
            </div>
            <div v-if="carrier && status === 'finished'" class="text-xs">
                <dl>
                    <div class="flex px-5 mt-1 mb-2 text-gray-900">
                        <div class="flex flex-col w-1/2">
                            <dt
                                class="font-medium tracking-wide text-gray-500 uppercase"
                            >
                                Tipo
                            </dt>
                            <dd>{{ carrier.type.name }}</dd>
                        </div>
                        <div class="flex flex-col w-1/2">
                            <dt
                                class="font-medium tracking-wide text-gray-500 uppercase"
                            >
                                Campo de Atuação
                            </dt>
                            <dd>{{ carrier.field_activity.name }}</dd>
                        </div>
                    </div>
                    <div class="flex px-5 mb-2 text-gray-900">
                        <div
                            v-if="
                                carrier.status.name.toLowerCase() === 'canceled'
                            "
                            class="flex flex-col w-1/2"
                        >
                            <dt
                                class="font-medium tracking-wide text-gray-500 uppercase"
                            >
                                Desligado em
                            </dt>
                            <dd v-if="carrier.deleted_at">
                                {{ dateFormat(carrier.deleted_at) }}
                            </dd>
                            <dd v-else>Não informado</dd>
                        </div>
                        <div v-else class="flex flex-col w-1/2">
                            <dt
                                class="font-medium tracking-wide text-gray-500 uppercase"
                            >
                                Vencimento
                            </dt>
                            <dd
                                v-if="carrier.expire_at"
                                class="inline-flex items-baseline"
                            >
                                {{ dateFormat(carrier.expire_at) }}
                                <div
                                    v-if="expired"
                                    class="p-1 ml-2 text-xs font-medium text-red-700 bg-red-100 border border-red-700 rounded"
                                >
                                    Registro vencido
                                </div>
                            </dd>
                            <dd v-else>Indeterminado</dd>
                        </div>
                        <div class="flex flex-col w-1/2">
                            <dt
                                class="font-medium tracking-wide text-gray-500 uppercase"
                            >
                                Status
                            </dt>
                            <dd>
                                <IconBadge
                                    :text="renderStatus(carrier.status.name)"
                                />
                            </dd>
                        </div>
                    </div>
                </dl>
                <div v-if="canRenew" class="px-5 pt-2 mb-2 text-gray-900">
                    <div class="flex items-center">
                        <t-alert
                            v-if="carrier.active_renewal_request"
                            show
                            :dismissible="false"
                            variant="success"
                            class="w-full"
                        >
                            Renovação solicitada
                        </t-alert>
                        <button
                            v-else
                            @click.stop="showRequestRenewal"
                            class="px-2 py-1 form-green"
                        >
                            <svg
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                class="w-4 h-4 mr-1"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                            Solicitar Renovação
                        </button>
                    </div>
                </div>
            </div>
            <div v-else-if="status === 'rejected'" class="text-xs">
                <t-alert show :dismissible="false" variant="danger">
                    Não foi possível carregar as informações da transportadora.
                </t-alert>
            </div>
            <div v-else class="flex items-center justify-center h-32">
                <loader wrapperClass="text-blue-700" loaderClass="w-16 h-16" />
            </div>
        </div>
    </Portlet>
</template>
