import { Vue } from "vue-property-decorator";

export default class Service extends Vue {
    constructor() {
        super();
        this.result = {};
        this.service = this.$http;
    }

    async request(
        router,
        typeRequest = "get",
        data = false,
        options = "",
        toast = false
    ) {
        this.result = { response: "", status: "process" };

        switch (typeRequest) {
            case "get":
                this.service = this.$http.get(router);
                break;
            case "post":
                this.service = this.$http.post(router, data, options);
                break;
            case "put":
                this.service = this.$http.put(router, data, options);
                break;
            case "delete":
                this.service = this.$http.delete(router, data, options);
                break;
        }

        await this.service
            .then(response => {
                this.result = { response: response, status: "success" };
                if (toast) {
                    this.$toast.success({
                        title: "OK",
                        message: response.data.success
                    });
                }
                return this.result;
            })
            .catch(response => {
                this.result = { response: response, status: "rejected" };
                if (toast) {
                    if (
                        response.response.data.error &&
                        response.response.status !== 500 &&
                        response.response.status !== 422
                    ) {
                        this.$toast.error({
                            title: "",
                            message: response.response.data.error,
                            icon: "fa fa-exclamation"
                        });
                    }
                }
            });

        return this.result;
    }
}
