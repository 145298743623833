import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class SearchSelect extends Vue {
    /** This Field is Used to call the parent to filter the search Result */
    @Prop({
        default: () => {
            return search => {
                return search;
            };
        }
    })
    dataProvider;

    /** This Field is Used to call the parent to filter the search Result */
    @Prop({
        default: () => {
            return item => {
                return item.name;
            };
        }
    })
    renderProvider;

    /** This field store the placeholder */
    @Prop({ default: "Search" })
    placeholder;

    /** This field store the main div class's name(s) */
    @Prop({ default: "" })
    className;

    @Prop({ default: false })
    disabled;

    @Prop({
        default: () => {
            return {};
        }
    })
    configuration;

    @Prop({ default: null })
    selectedValue;

    @Prop({ default: null })
    loadedValue;

    @Emit()
    change(item) {
        this.selected = item;
        this.searchText = "";
        this.data = [];
    }

    constructor() {
        super();
        /** This field stores the data returned by the dataProvider */
        this.data = [];
        /** This field stores a boolean that indicates if the data is loading */
        this.searching = false;
        this.searchText = "";

        this.visible = false;

        this.config = {
            searchType: "onKeyUp" // onEnter or onKeyUp
        };

        this.selected = null;
    }

    mounted() {
        if (this.configuration) {
            this.config.searchType = this.configuration.hasOwnProperty(
                "searchType"
            )
                ? this.configuration.searchType
                : this.config.searchType;
        }

        if (this.selectedValue) {
            this.selected = this.selectedValue;
        }

        if (this.loadedValue) {
            this.data = this.loadedValue;
        }
    }

    search() {
        const promise = this.dataProvider(this.searchText);
        if (promise !== null && promise instanceof Promise) {
            promise.then(data => {
                this.data = data.data || data;
            });
        }
    }

    renderSearch() {
        if (this.data.length === 0) {
            if (this.searching) {
                return <div>Carregando...</div>;
            }
            return (
                <div class="p-2 bg-white border rounded">
                    {this.searchText.length === 0
                        ? "Digite para Pesquisar"
                        : "Sem Resultado!"}
                </div>
            );
        }
        return (
            <ul class="list-group border shadow">
                {this.data.map(line => (
                    <li
                        class="list-group-item hover-gray li-item"
                        style={"cursor: pointer "}
                        onClick={() => {
                            this.change(line);
                        }}
                    >
                        {this.renderProvider(line)}
                    </li>
                ))}
            </ul>
        );
    }

    render() {
        if (this.selectedValue) {
            this.selected = this.selectedValue;
        }
        if (this.loadedValue && this.searchText.length === 0) {
            this.data = this.loadedValue.data;
        }
        if (this.disabled) {
            const value =
                this.selected !== null
                    ? this.renderProvider(this.selected)
                    : "";
            return (
                <input
                    class="form-control rounded py-2 h-auto text-truncate"
                    type={"text"}
                    disabled={true}
                    value={value}
                />
            );
        }
        return (
            <div class={this.class} style={"width: 100%; position: relative;"}>
                <div
                    class="form-input rounded py-2 h-auto text-truncate"
                    onClick={() => {
                        if (!this.visible) {
                            this.visible = true;
                            setTimeout(() => {
                                this.$refs.search.focus();
                                this.$refs.search.onkeyup = ev => {
                                    if (this.config.searchType === "onEnter") {
                                        if (ev.keyCode === 13) {
                                            this.search();
                                        }
                                    } else {
                                        this.search();
                                    }
                                };
                            }, 100);
                        } else {
                            this.visible = false;
                        }
                    }}
                >
                    {this.selectedValue !== null ? (
                        this.renderProvider(this.selectedValue)
                    ) : (
                        <span style={"color: gray"}>{this.placeholder}</span>
                    )}
                </div>
                {this.visible ? (
                    <div
                        style={
                            this.visible
                                ? "width: 100%; position: absolute; z-index: 9999; background-color: #fff; padding: 0px;" +
                                  "margin-top: -50px; max-height: 250px; overflow: auto;"
                                : "display: none;"
                        }
                    >
                        <input
                            ref="search"
                            placeholder={this.placeholder}
                            class="form-control rounded py-2 h-auto"
                            type="text"
                            onblur={() => {
                                setTimeout(() => {
                                    this.visible = false;
                                }, 200);
                            }}
                            v-model={this.searchText}
                        />
                        {this.renderSearch()}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/home/gitlab-runner/builds/glrt-b-z/0/scmobi/scmobi-frontend-v4/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-8de16d0c/SearchSelect.jsx" });