<template>
    <div
        class="h-auto min-h-screen lg:flex lg:flex-col lg:justify-center bg-blue-background sm:bg-blue-background md:bg-image-none md:bg-gray-lighter"
    >
        <portal-target name="modals" multiple />
        <div
            class="max-w-md py-8 mx-auto my-4 bg-white shadow font-display xl:my-6 xl:py-4 sm:max-w-xl lg:max-w-4xl xl:max-w-6xl"
        >
            <div class="flex">
                <div
                    class="hidden lg:flex flex-col lg:border-r-2 lg:border-blue-dark lg:p-10 justify-between lg:mt-1 lg:px-16 lg:mr-8 lg:w-1/2"
                >
                    <img
                        class="lg:px-20 xl:px-28"
                        src="@/core/assets/images/logo_sc_branco.png"
                        alt="Governo de Santa Catarina"
                    />
                    <img
                        class="lg:mb-6 xl:px-16"
                        src="@/core/assets/images/logo-scmobi.png"
                        alt="SCmobi"
                    />
                    <div class="msg-login">
                        Ao utilizar o SCmobi, o usuário concorda que as
                        informações prestadas serão usadas de maneira sigilosa,
                        para fins estatísticos e de estudos, objetivando a
                        melhoria continua no sistema, conforme a Lei Geral de
                        Proteção de Dados Pessoais (LGPD),
                        <a
                            class="cursor-pointer font-bold text-blue-500"
                            target="_blank"
                            href="http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13709compilado.htm"
                        >
                            Lei nº 13.709, de 14 de agosto de 2018.</a
                        >
                        Para saber mais sobre a LGPD,
                        <a
                            class="cursor-pointer font-bold text-blue-500"
                            target="_blank"
                            href="https://www.sie.sc.gov.br/lgpd"
                        >
                            clique aqui.</a
                        >
                    </div>
                </div>
                <div class="w-full lg:w-1/2 lg:mr-8">
                    <div
                        class="flex justify-center mx-3 border-b-2 border-blue-dark lg:hidden"
                    >
                        <img
                            class="w-10/12 pb-4 mt-2"
                            src="@/core/assets/images/logo-scmobi.png"
                            alt="SCmobi"
                        />
                    </div>

                    <h1
                        class="mt-4 mb-3 text-xl font-medium leading-tight text-center uppercase lg:mt-8 lg:mb-8 text-blue-dark"
                    >
                        Sistema de Transporte<br />Intermunicipal de Passageiros
                    </h1>
                    <form @submit.prevent="login" class="p-4">
                        <div>
                            <label
                                class="mt-4 mb-2 text-sm font-medium leading-tight uppercase text-blue-dark"
                            >
                                CPF OU CNPJ
                            </label>
                            <TheMask
                                class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                                :mask="['###.###.###-##', '##.###.###/####-##']"
                                placeholder="000.000.000-00"
                                v-model="document"
                                v-focus
                                required
                            />
                        </div>
                        <div class="mt-4">
                            <label
                                class="mt-6 mb-2 text-sm font-medium leading-tight uppercase text-blue-dark"
                            >
                                SENHA
                            </label>
                            <input
                                class="block w-full px-4 py-2 leading-normal rounded-sm appearance-none placeholder-blue-lighter bg-gray-lighter focus:outline-none focus:shadow-outline"
                                type="password"
                                v-model="password"
                                placeholder="******"
                                required
                            />
                        </div>
                        <div class="justify-between lg:flex lg:items-center">
                            <Modal ref="confirmation">
                                <ResendConfirmation
                                    :document="document"
                                    @close="hideResendConfirmation"
                                />
                            </Modal>
                            <button
                                type="button"
                                @click.stop="showResendConfirmation"
                                class="flex items-center justify-center w-full py-3 my-5 mr-6 text-sm font-medium uppercase bg-white border rounded-sm xl:w-1/2 xl:h-12 border-gray-dark hover:bg-gray-dark focus:outline-none focus:shadow-outline active:bg-gray-base text-gray-dark hover:text-white lg:mt-6 lg:mb-2 lg:mr-4 lg:py-2"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="w-5 h-5 mr-2 fill-current"
                                    viewBox="0 0 20.86 17.53"
                                >
                                    <path
                                        d="M1.87 0H19a1.76 1.76 0 011.31.59 2 2 0 01.55 1.4 3.45 3.45 0 01-.61 1.88 5.6 5.6 0 01-1.42 1.53q-2.78 2.06-4.23 3.17l-1.18.89-.17.13-.32.25-.63.47-.6.41a3.18 3.18 0 01-.67.33 1.68 1.68 0 01-.59.12 1.63 1.63 0 01-.58-.12 3.18 3.18 0 01-.67-.33c-.24-.15-.44-.29-.6-.41l-.63-.47-.32-.25-.2-.13-1.19-.89-1.86-1.39L2.01 5.4A5.85 5.85 0 01.65 3.97a3 3 0 01-.64-1.7A2.64 2.64 0 01.49.65 1.62 1.62 0 011.87 0zM19.7 6.74q-2.39 1.71-4.05 3l5.21 5.57V5.65a6.71 6.71 0 01-1.16 1.09zM0 5.65a6.79 6.79 0 001.18 1.09q2.47 1.79 4 3L0 15.27zm20.25 11.39a1.75 1.75 0 01-1.22.49H1.87a1.73 1.73 0 01-1.22-.49l5.91-6.33.4.32 1.08.82a6.76 6.76 0 001.1.59 3.07 3.07 0 001.28.31 3 3 0 001.3-.29 6.36 6.36 0 001.1-.59c.28-.2.64-.47 1.08-.82l.41-.31z"
                                        fill-rule="evenodd"
                                    />
                                </svg>
                                <span>Reenviar Confirmação</span>
                            </button>
                            <Modal ref="password">
                                <ForgotPassword
                                    :document="document"
                                    @close="hideForgotPassword"
                                />
                            </Modal>
                            <button
                                type="button"
                                @click.stop="showForgotPassword"
                                class="mb-4 mr-4 text-base font-medium normal-case text-blue-dark lg:mb-0 lg:mt-4"
                            >
                                Esqueceu a senha?
                            </button>
                        </div>
                        <div class="xl:flex lg:mt-1">
                            <button
                                class="text-xs font-medium uppercase btn btn-bluegradient xl:w-1/2"
                                type="submit"
                            >
                                Entrar
                            </button>
                            <router-link
                                :to="{ name: 'register' }"
                                class="xl:w-1/2"
                            >
                                <button
                                    class="my-2 text-xs font-medium uppercase shadow btn btn:focus btn-gray btn-gray:hover btn-gray:focus btn-gray:active xl:ml-1"
                                    type="button"
                                >
                                    Cadastrar
                                </button>
                            </router-link>
                        </div>
                        <a
                            href="https://ajuda.sie.sc.gov.br"
                            target="_blank"
                            class="flex items-center justify-center mt-2 text-sm font-medium normal-case text-blue-dark md:mt-4 lg:mb-4"
                        >
                            <Icon
                                icon="help"
                                class="h-6 mx-2 fill-current text-blue-dark"
                            />Saiba mais sobre a conta SCmobi
                        </a>
                        <div class="msg-login lg:hidden block shadow">
                            Ao utilizar o SCmobi, o usuário concorda que as
                            informações prestadas serão usadas de maneira
                            sigilosa, para fins estatísticos e de estudos,
                            objetivando a melhoria continua no sistema, conforme
                            a Lei Geral de Proteção de Dados Pessoais (LGPD),
                            <a
                                class="cursor-pointer font-bold text-blue-500"
                                href="http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13709compilado.htm"
                            >
                                Lei nº 13.709, de 14 de agosto de 2018.</a
                            >
                            Para saber mais sobre a LGPD,
                            <a
                                class="cursor-pointer font-bold text-blue-500"
                                href="https://www.sie.sc.gov.br/lgpd"
                            >
                                clique aqui.</a
                            >
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import Icon from "@/core/components/Icon";
import Modal from "@/core/components/NewModal";
import ResendConfirmation from "@/domains/auth/components/ResendConfirmation";
import ForgotPassword from "@/domains/auth/components/ForgotPassword";

export default {
    components: {
        TheMask,
        Icon,
        Modal,
        ResendConfirmation,
        ForgotPassword
    },
    data() {
        return {
            title: "Entrar",
            document: "",
            password: ""
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },
    methods: {
        login() {
            this.$Progress.start();
            this.$store
                .dispatch("getToken", {
                    username: this.document,
                    password: this.password
                })
                .then(() => {
                    this.$toast.success({
                        position: "topCenter",
                        title: "OK",
                        message: "Autenticado com sucesso!"
                    });
                    this.$router.push("/");
                    this.$Progress.finish();
                })
                .catch(e => {
                    this.$Progress.finish();
                    if (e.response.status === 401) {
                        this.$toast.error({
                            message:
                                "As credenciais de usuário estão incorretas."
                        });
                    } else {
                        this.$toast.error({
                            message:
                                e.response.data.message || e.response.data.error
                        });
                    }
                });
        },
        showResendConfirmation() {
            this.$refs.confirmation.show();
        },
        hideResendConfirmation() {
            this.$refs.confirmation.hide();
        },
        showForgotPassword() {
            this.$refs.password.show();
        },
        hideForgotPassword() {
            this.$refs.password.hide();
        }
    }
};
</script>
