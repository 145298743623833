<script>
import { TheMask } from "vue-the-mask";
import { mask } from "@/core/services/helpers";
import { getAddressByCep } from "../../getAddressByCep";
import SearchSelect from "@/core/components/SearchSelect";
export default {
    components: { TheMask, SearchSelect },
    props: {
        beneficiarioEstudante: {
            type: Object,
            default: () => {
                return {
                    nminstituicao: "",
                    tpcurso: null,
                    nuseriecurso: null,
                    idcurso: null,
                    document: "",
                    nmcurso : "",
                    cep: "",
                    street: "",
                    number: "",
                    district: "",
                    complement: "",
                    municipio: {
                        name: "",
                        state: {
                            name: ""
                        }
                    }
                };
            }
        },
        beneficiarioEstudanteForm: {
            type: Object,
            default: () => {
                return {
                    nminstituicao: "",
                    tpcurso: null,
                    nuseriecurso: null,
                    idcurso: null,
                    document: "",
                    nmcurso : "",
                    cep: "",
                    street: "",
                    number: "",
                    district: "",
                    complement: "",
                    municipio: {
                        name: "",
                        state: {
                            name: ""
                        }
                    },
                    hasErrors: {
                        type: Boolean,
                        default: false
                    }
                };
            }
        },
        hasErrors: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },
    data: () => {
        return {
            tipoCursos: [
                { name: "Fundamental", value: 1 },
                { name: "Médio", value: 2 },
                { name: "Superior", value: 3 },
                { name: "Especialização", value: 4 },
                { name: "Técnico", value: 5 }
            ]
        };
    },
    methods: {
        mask,
        getTpCurso(tpcurso) {
            if (tpcurso) {
                return this.tipoCursos.find(
                    item => Number(item.value) === Number(tpcurso)
                ).name;
            }
        },
        cursoSelected(curso) {
            this.beneficiarioEstudanteForm.curso = curso;
        },
        async checkCPFValid(document){
            if(document.length == 14){
                const { data } = await this.$axios.get(
                    `/api/spl-navegantes/beneficiarios/documento/${document}`
                );
                if(data){
                    this.beneficiarioEstudanteForm.nminstituicao = data.name;
                    this.beneficiarioEstudante.nminstituicao = data.name;
                    this.beneficiarioEstudanteForm.district = data.district;
                    this.beneficiarioEstudanteForm.street = data.street;
                    this.beneficiarioEstudanteForm.cep = data.cep;
                    this.beneficiarioEstudante.cep = data.cep;
                    this.beneficiarioEstudanteForm.number = data.number;
                    this.beneficiarioEstudanteForm.complement = data.complement;
                    this.beneficiarioEstudanteForm.municipio = data.city;
                    this.beneficiarioEstudante.municipio = data.city;
                }
            }
        },
        async onCepChangeInstituicao(cep) {
            if (cep.length === 8) {
                this.$Progress.start();
                const {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    ibge
                } = await getAddressByCep(cep);
                if (logradouro) {
                    this.beneficiarioEstudanteForm.street = logradouro;
                    this.beneficiarioEstudanteForm.district = bairro;
                    this.beneficiarioEstudanteForm.municipio = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    }
                    this.beneficiarioEstudante.municipio = {
                        id: "",
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: "",
                            name: uf,
                            initials: uf
                        }
                    }
                }else{
                    this.$toast.error({
                        message: "CEP Inválido."
                    });
                }
                 this.$Progress.finish();
            }
        },
        cityInstituicaoSelected(city) {
            this.beneficiarioEstudanteForm.municipio = city;
        }
    }
};
</script>
<template>
    <div>
        <div class="flex justify-between w-full my-8 border-b-2 border-blue-base bg-white shadow-lg">
            <span class="mt-2 text-base uppercase text-blue-base">
                Informações estudante
            </span>
            <slot></slot>
        </div>
        <div class="grid gap-5 grid-cols-1 md:grid-cols-12 px-3">
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    CNPJ
                </span>
                <template v-if="readonly">
                    {{ mask(beneficiarioEstudante.document, "##.###.###/####-##") }}
                    
                </template>
                <template v-else>
                    <span>
                        <TheMask v-model="beneficiarioEstudanteForm.document" :mask="['##.###.###/####-##']"
                            class="h-auto py-2 rounded form-input" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.document
                            }" required
                            @input="checkCPFValid" />
                        <div v-if="beneficiarioEstudanteForm.hasErrors && !beneficiarioEstudanteForm.document
                            " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </span>
                </template>
            </div>
            <div class="col-span-6">
                <span class="text-sm form-label text-gray-dark">
                    Nome da Instituição
                </span>
                <span>
                    <template v-if="readonly">
                        {{ beneficiarioEstudante.nminstituicao }}
                    </template>
                    <template v-else>
                        <input v-model="beneficiarioEstudanteForm.nminstituicao" :class="{
                            'bg-red-100 border-red-400':
                            beneficiarioEstudanteForm.hasErrors &&
                                !beneficiarioEstudanteForm.nminstituicao
                        }" type="text" class="h-auto py-2 rounded form-input" />
                        <div v-if="beneficiarioEstudanteForm.hasErrors &&
                            !beneficiarioEstudanteForm.nminstituicao
                            " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </template>
                </span>
            </div>
            <div :class="{ 'col-span-12': !readonly, 'col-span-3': readonly }">
                <span class="text-sm form-label text-gray-dark">
                    Tipo de Curso
                </span>
                <template v-if="readonly">
                    {{ getTpCurso(beneficiarioEstudante.tpcurso) }}
                </template>
                <template v-else>
                    <div class="flex">
                        <div v-for="curso in tipoCursos" :key="curso.value" class="flex-1 flex align-center">
                            <span class="px-2">
                                <input @click="
                                    beneficiarioEstudanteForm.idcurso = null
                                    " v-model="beneficiarioEstudanteForm.tpcurso" :value="curso.value" :id="curso.name"
                                    type="radio" />
                            </span>
                            <label @click="
                                beneficiarioEstudanteForm.idcurso = null
                                " :for="curso.name" class="text-sm form-label text-gray-dark">
                                {{ curso.name }}
                            </label>
                        </div>
                    </div>
                    <div v-if="beneficiarioEstudanteForm.hasErrors && !beneficiarioEstudanteForm.tpcurso"
                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                        Campo obrigatório
                    </div>
                </template>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Ano de Inicío
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstudante.nuseriecurso }}
                </template>
                <template v-else>
                    <span>
                        <TheMask v-model="beneficiarioEstudanteForm.nuseriecurso" :mask="['####']"
                            class="h-auto py-2 rounded form-input" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.nuseriecurso
                            }" required />
                        <div v-if="beneficiarioEstudanteForm.hasErrors &&
                            !beneficiarioEstudanteForm.nuseriecurso
                            " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                            Campo obrigatório
                        </div>
                    </span>
                </template>
            </div>
            <div class="col-span-3"  v-if="beneficiarioEstudanteForm.tpcurso > 2">
                <span class="text-sm form-label text-gray-dark">
                    Curso
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstudante.nmcurso }}
                </template>
                <template v-else>
                    <SearchSelect :selected.sync="beneficiarioEstudanteForm.curso" :search-keys="['nome_curso']"
                        :search-remote="true" :lazy-load="true" :option-value="$event => $event.nmcurso"
                        option-key="idcurso" name="idcurso" no-query-param :options-url="`/api/spl-navegantes/cursos/${beneficiarioEstudanteForm.tpcurso}/`
                            " field-class="py-2" :disabled="(beneficiarioEstudanteForm.tpcurso == 1) || (beneficiarioEstudanteForm.tpcurso == 2)" 
        class="h-auto rounded form-select" :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.curso
                            }"
        @changed="cursoSelected" ></SearchSelect>
                </template>
                <div v-if="beneficiarioEstudanteForm.hasErrors && !beneficiarioEstudanteForm.curso"
                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                        Campo obrigatório
                </div>
            </div>
        </div>
        <div class="grid gap-5 grid-cols-1 md:grid-cols-12 px-3">
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    CEP
                </span>
                <template v-if="readonly">
                    {{   beneficiarioEstudante.cep ? mask(beneficiarioEstudante.cep, "##.###-##") : '' }}
                </template>
                <template v-else>
                    <div >
                        <TheMask
                            v-model="beneficiarioEstudanteForm.cep"
                            :mask="['##.###-###']"
                            class="h-auto py-2 rounded form-input mb-0"
                            :class="{
                                'bg-red-100 border-red-400':
                                beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.cep
                            }"
                            @input="onCepChangeInstituicao"
                            required
                        />
                        <div
                            v-if="
                                beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.cep
                            "
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                        >
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-6">
                <span class="text-sm form-label text-gray-dark">
                    Endereço
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstudante.street }}
                </template>
                <template v-else>
                    <div>
                        <input
                            v-model="beneficiarioEstudanteForm.street"
                            type="text"
                            class="h-auto py-2 rounded form-input mb-0"
                            :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.street
                            }"
                        />
                        <div
                            v-if="
                                beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.street
                            "
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                        >
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-1">
                <span class="text-sm form-label text-gray-dark">
                    Número
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstudante.number }}
                </template>
                <template v-else>
                    <div>
                        <input
                            v-model="beneficiarioEstudanteForm.number"
                            type="number"
                            class="h-auto py-2 rounded form-input mb-0"
                        />
                        <!-- <div
                            v-if="
                                hasErrors &&
                                    !enderecoInstituicaoForm.number
                            "
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                        >
                            Campo obrigatório
                        </div> -->
                    </div>
                </template>
            </div>
            <div class="col-span-2">
                <span class="text-sm form-label text-gray-dark">
                    Bairro
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstudante.district }}
                </template>
                <template v-else>
                    <div>
                        <input
                            v-model="beneficiarioEstudanteForm.district"
                            type="text"
                            class="h-auto py-2 rounded form-input mb-0"
                            :class="{
                                'bg-red-100 border-red-400':
                                    beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.district
                            }"
                        />
                        <div
                            v-if="
                                beneficiarioEstudanteForm.hasErrors &&
                                    !beneficiarioEstudanteForm.district
                            "
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                        >
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-span-3">
                <span class="text-sm form-label text-gray-dark">
                    Complemento
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstudante.complement }}
                </template>
                <template v-else>
                    <div >
                        <input
                            v-model="beneficiarioEstudanteForm.complement"
                            type="text"
                            class="h-auto py-2 rounded form-input mb-0"
                        />
                    </div>
                </template>
            </div>
            <div class="col-span-6 ">
                <span class="text-sm form-label text-gray-dark">
                    Cidade/estado
                </span>
                <template v-if="readonly">
                    {{ beneficiarioEstudante.municipio ? beneficiarioEstudante.municipio.name +
                            " / " +
                            beneficiarioEstudante.municipio.state
                                .name : ''}}
                </template>
                <template v-else>
                    <div>
                        <search-select
                            :selected.sync="beneficiarioEstudanteForm.municipio"
                            :search-keys="['name']"
                            :search-remote="true"
                            :lazy-load="true"
                            options-url="/api/registry/location?take=50&paginate=false"
                            option-key="id"
                            :option-value="
                                city =>
                                    city.name +
                                    ' / ' +
                                    city.state.initials
                            "
                            field-class="py-2"
                            name="city"
                            placeholder="Selecione uma cidade..."
                            @changed="cityInstituicaoSelected"
                        />
                        <div
                            v-if="beneficiarioEstudanteForm.hasErrors &&
                            !beneficiarioEstudanteForm.municipio"
                            class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                        >
                            Campo obrigatório
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
