<template>
    <Modal width="700" borderLeft="4px solid #2ecc71" @close="$emit('close')">
        <div slot="header" class="text-center">
            <h2 class="text-muted">Atenção!</h2>
        </div>
        <div slot="body">
            <div class="alert alert-warning" role="alert">
                Esta ação irá encerrar o chamado e colocá-lo como "Resolvido".
            </div>
            <form v-on:submit.prevent="onFormSubmit()">
                <div class="w-full mt-5 mb-5">
                    <label class="text-sm form-label" for="description">
                        Comentário:
                    </label>
                    <div class="w-3/4 py-3 mx-auto">
                        <Editor
                            id="content"
                            class="w-full bg-white"
                            v-model="content"
                            placeholder="Adicione um comentário"
                        />
                    </div>
                    <div v-if="errors && errors.content">
                        <p
                            v-for="error in errors.content"
                            v-bind:key="error"
                            class="text-xs italic text-red-base"
                        >
                            {{ error }}
                        </p>
                    </div>
                </div>
            </form>
        </div>
        <div slot="footer">
            <div class="flex justify-content-center">
                <button
                    v-on:click="onFormSubmit"
                    class="btn btn-primary disable-onload"
                    type="submit"
                >
                    Confirmar
                </button>
                <button
                    class="btn btn-outline-secondary close-modal"
                    @click="$emit('close')"
                >
                    Cancelar
                </button>
            </div>
        </div>
    </Modal>
</template>
<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/core/components/Modal";
import Editor from "@/core/components/Editor";

@Component({
    components: {
        Modal,
        Editor
    }
})
export default class Finish extends Vue {
    @Prop()
    ticket;

    constructor() {
        super();
        this.errors = null;
        this.content = "";
    }

    async onFormSubmit() {
        this.$Progress.start();
        const data = {
            content: this.content
        };
        try {
            const response = await this.$axios.patch(
                "/api/support/ticket/close/" + this.ticket.id,
                data
            );
            this.$Progress.finish();
            this.$toast.success({
                title: "OK",
                message: response.data.success
            });
            this.$emit("close");
        } catch (e) {
            this.$Progress.finish();
            if (e.response.status === 422) {
                this.errors = e.response.data.errors;
            } else {
                this.$toast.error({
                    title: "",
                    message: e.response.data.error,
                    icon: "fa fa-exclamation"
                });
            }
        }
    }
}
</script>
