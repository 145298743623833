<script>
import { Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import RegistryService from "@/core/services/RegistryService";
import SearchSelect from "@/core/components/SearchSelect";

@Component({
    components: {
        "search-select": SearchSelect,
        "the-mask": TheMask
    }
})
export default class BasicInformations extends RegistryService {
    @Prop()
    user;

    @Prop()
    editable;

    @Prop()
    create;

    @Prop({ default: () => [] })
    disabled;

    @Prop({ default: false })
    support;

    constructor() {
        super();
        this.errors = null;
        this.isEdit = false;
        this.userData = null;
    }

    mounted() {
        this.userData = Object.assign({}, this.user);
    }

    citySelected(city) {
        this.userData.city = city;
        this.userData.city_id = city.id;
    }

    async onFormSubmit() {
        let info = this.putRegistryUser(this.userData);

        if (info.errors) {
            this.errors = info.errors;
        } else {
            this.isEdit = false;
        }
    }
}
</script>

<template>
    <div class="w-full bg-gray-light">
        <div
            class="mx-2 my-4 modal-edit"
            v-bind:class="{ 'edit-shadow': isEdit }"
        >
            <div class="flex flex-wrap justify-between mb-1 align-baseline">
                <h1 class="uppercase text-blue-base">
                    Informações Gerais
                </h1>
                <div
                    v-if="!this.$store.getters.isOrganization"
                    class="form-new-blue"
                    v-bind:class="{ '': isEdit }"
                    v-on:click="isEdit = !isEdit"
                >
                    <div>
                        {{ isEdit ? "Fechar" : "Editar" }}
                    </div>
                </div>
            </div>
            <div class="flex px-4 pt-8 text-xs border-t-2 border-blue-base">
                <form v-on:submit.prevent="onFormSubmit()">
                    <div v-if="!editable && !support">
                        <div
                            class="w-full p-5 mb-4 border lg:w-1/2 xl:w-2/4 xl:max-w-screen-md bg-yellow-base border-gray-dark sm:mx-4"
                        >
                            Para editar essas informações, faça login com seu
                            CPF possuindo perfil de gestor.
                        </div>
                    </div>

                    <div class="flex justify-center md:justify-start">
                        <div class="flex flex-col w-2/4 mr-1 lg:w-64">
                            <span class="text-sm form-label text-gray-dark">
                                Nome / Razão Social
                            </span>
                            <span v-if="isEdit">
                                <input
                                    class="h-auto py-2 rounded form-input"
                                    :disabled="
                                        !editable ||
                                            !!disabled.find(
                                                a => a === 'name'
                                            ) === true
                                    "
                                    v-model="userData.name"
                                    type="text"
                                />
                            </span>
                            <span v-if="!isEdit">
                                {{ userData.name }}
                            </span>

                            <div v-if="errors && errors.name" class="col-lg-12">
                                <div class="mt-1 row">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.name"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col w-1/3 mr-1 lg:w-64">
                            <span class="text-sm form-label text-gray-dark">
                                CPF / CNPJ
                            </span>
                            <span v-if="isEdit">
                                <the-mask
                                    class="h-auto py-2 rounded form-input"
                                    :mask="[
                                        '###.###.###-##',
                                        '##.###.###/####-##'
                                    ]"
                                    v-model="userData.document"
                                    :disabled="!create"
                                />
                            </span>
                            <span v-if="!isEdit">
                                {{ userData.document }}
                            </span>
                            <div
                                v-if="errors && errors.document"
                                class="col-lg-12"
                            >
                                <div class="mt-1 row">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.document"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex flex-wrap justify-center mt-12 md:justify-start"
                    >
                        <div class="w-2/4 mb-4 mr-1 lg:w-64">
                            <span class="text-sm form-label text-gray-dark">
                                Endereço
                            </span>
                            <span v-if="isEdit">
                                <input
                                    class="h-auto py-2 rounded form-input"
                                    :disabled="!editable"
                                    v-model="userData.street"
                                    type="text"
                                />
                            </span>
                            <span v-if="!isEdit">
                                {{ userData.street }}
                            </span>
                        </div>

                        <div class="w-1/3 mb-4 mr-1 lg:w-64">
                            <span class="text-sm form-label text-gray-dark">
                                Número
                            </span>
                            <span v-if="isEdit">
                                <input
                                    class="h-auto py-2 rounded form-input"
                                    :disabled="!editable"
                                    v-model="userData.number"
                                    type="text"
                                />
                            </span>
                            <span v-if="!isEdit">
                                {{ userData.number }}
                            </span>
                            <div
                                v-if="errors && errors.number"
                                class="col-lg-12"
                            >
                                <div class="mt-1 row">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.number"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col w-2/4 mb-4 mr-1 lg:w-64">
                            <span class="text-sm form-label text-gray-dark">
                                Bairro
                            </span>
                            <span v-if="isEdit">
                                <input
                                    class="h-auto py-2 rounded form-input"
                                    :disabled="!editable"
                                    v-model="userData.district"
                                    type="text"
                                />
                            </span>
                            <span v-if="!isEdit">
                                {{ userData.district }}
                            </span>
                            <div
                                v-if="errors && errors.district"
                                class="col-lg-12"
                            >
                                <div class="mt-1 row">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.district"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col w-1/3 mb-4 mr-1 lg:w-64">
                            <span class="text-sm form-label text-gray-dark">
                                Complemento
                            </span>
                            <span v-if="isEdit">
                                <input
                                    class="h-auto py-2 rounded form-input"
                                    :disabled="!editable"
                                    v-model="userData.complement"
                                    type="text"
                                />
                            </span>
                            <span v-if="!isEdit">
                                {{ userData.complement }}
                            </span>
                            <div
                                v-if="errors && errors.complement"
                                class="col-lg-12"
                            >
                                <div class="mt-1 row">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.complement"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col w-2/4 mb-4 mr-1 lg:w-64">
                            <span class="text-sm form-label text-gray-dark">
                                CEP
                            </span>
                            <span v-if="isEdit">
                                <the-mask
                                    class="h-auto py-2 rounded form-input"
                                    :disabled="!editable"
                                    :mask="['#####-###']"
                                    v-model="userData.cep"
                                />
                            </span>
                            <span v-if="!isEdit">
                                {{ userData.cep }}
                            </span>
                            <div v-if="errors && errors.cep" class="col-lg-12">
                                <div class="mt-1 row">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.cep"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col w-1/3 mb-4 lg:w-64">
                            <span class="text-sm form-label text-gray-dark">
                                Cidade / Estado
                            </span>
                            <span v-if="isEdit">
                                <search-select
                                    v-if="editable"
                                    :selected.sync="userData.city"
                                    :search-keys="['name']"
                                    :search-remote="true"
                                    :lazy-load="true"
                                    options-url="/api/registry/location?take=50&paginate=false"
                                    option-key="id"
                                    :option-value="
                                        city =>
                                            city.name +
                                            ' / ' +
                                            city.state.initials
                                    "
                                    field-class="py-2"
                                    name="city"
                                    placeholder="Selecione uma cidade..."
                                    @changed="citySelected"
                                />
                                <input
                                    class="h-auto py-2 rounded form-input"
                                    type="text"
                                    disabled="disabled"
                                    v-else
                                    :value="
                                        userData.city
                                            ? userData.city.name
                                            : 'Não Informado'
                                    "
                                />
                            </span>
                            <div v-if="!isEdit">
                                <span v-if="userData.city"
                                    >{{ userData.city.name }} -
                                    {{ userData.city.state.initials }}</span
                                >
                            </div>

                            <div v-if="errors && errors.city" class="col-lg-12">
                                <div class="mt-1 row">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.city"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="isEdit"
                        class="flex justify-center w-full mt-8 mb-6 sm:justify-start"
                    >
                        <button
                            class="w-full form-submit sm:w-auto"
                            v-if="editable && !create"
                        >
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
