<script>
export default {
    methods: {
        close() {
            // this.$store.commit("clearLink");
            // this.$router.push({ name: "index" });
            this.$emit("close");
        }
    }
};
</script>

<template>
    <div>
        <div
            class="flex flex-col px-4 pt-5 pb-4 space-y-4 bg-white sm:p-12 sm:pb-4"
        >
            <img
                class="w-48"
                src="@/core/assets/images/logo-scmobi.png"
                alt="SCmobi"
            />
           <h1 class="font-medium">
            O Privativo das Transportadoras foi desativado.
            </h1>
            <p class="text-sm font-medium">
                Para realizar qualquer alteração na linha, você deverá gerar a guia referente ao serviço, através do <a href="/financeiro" class="underline text-blue-lighter">Financeiro do Scmobi</a> e enviar o <a href="http://ajuda.sie.sc.gov.br/wp-content/uploads/2024/08/REQUERIMENTO-DE-ALTERA%C3%87%C3%83O-DE-LINHA.doc" class="underline text-blue-lighter">requerimento</a> preenchido para o e-mail processoscmobi@sie.sc.gov.br.
            </p>
        </div>
        <div
            class="px-4 py-3 bg-gray-100 sm:px-6 sm:flex sm:flex-col sm:place-items-center"
        >
            <span
                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
                <button
                    @click="close"
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 text-xs font-medium leading-normal uppercase transition duration-150 ease-in-out rounded-md shadow-sm btn-blue focus:outline-none sm:leading-tight"
                >
                    Fechar
                </button>
            </span>
        </div>
    </div>
</template>
