<script>
import AuthenticatedDashboard from "@/domains/auth/components/AuthenticatedDashboard";

export default {
    components: {
        AuthenticatedDashboard
    },
    beforeCreate() {
        this.$axios.interceptors.response.use(
            response => {
                // Any status code that lie within the range of 2xx will cause this function to trigger
                return response;
            },
            error => {
                // Any status codes that falls outside the range of 2xx will cause this function to trigger
                const { response } = error;
                if (response.status === 401 && response.config) {
                    this.$store.commit("setLogout");
                    this.$router.push({ name: "login" });
                }
                if (response.status === 502 || response.status === 503) {
                    this.$toast.error({
                        message:
                            "O SCmobi está temporariamente indisponível. Tente novamente mais tarde."
                    });
                }
                return Promise.reject(error);
            }
        );
    }
};
</script>

<template>
    <div
        id="app"
        class="flex flex-col min-h-screen font-display"
        :key="this.$store.state.auth.link ? this.$store.state.auth.link.id : -1"
    >
        <vue-progress-bar />
        <AuthenticatedDashboard
            v-if="
                this.$store.getters.isAuthenticated &&
                    this.$store.getters.isUserLoaded
            "
        />
        <router-view v-else class="flex-grow" />
    </div>
</template>
