import Index from "./views/Index";

export default [
    {
        path: "/",
        name: "index",
        component: Index,
        meta: {
            forAuth: true,
            hideSidebar: true
        }
    }
];
