import Index from "./views/Index";
import SelectLink from "./views/SelectLink";
import MyInfo from "./views/MyInfo";
import ChangePassword from "./views/ChangePassword";
import UserLinks from "./views/UserLinks";
import CreateLink from "./views/CreateLink";

export default [
    {
        path: "/usuario",
        name: "user.index",
        component: Index,
        meta: {
            forAuth: true,
            module: "user"
        }
    },
    {
        path: "/escolher-vinculo",
        name: "link.select",
        component: SelectLink,
        meta: {
            forAuth: true,
            hideSidebar: true
        }
    },
    {
        path: "/usuario/meu-cadastro",
        name: "user.my-info",
        component: MyInfo,
        meta: {
            forAuth: true,
            module: "user"
        }
    },
    {
        path: "/usuario/alterar-senha",
        name: "user.password",
        component: ChangePassword,
        meta: {
            forAuth: true,
            module: "user"
        }
    },
    {
        path: "/usuario/meus-vinculos",
        name: "user.links",
        component: UserLinks,
        meta: {
            forAuth: true,
            module: "user"
        }
    },
    {
        path: "/usuario/meus-vinculos/novo",
        name: "link.create",
        component: CreateLink,
        meta: {
            forAuth: true,
            module: "user"
        }
    }
];
