<template>
    <div class="w-full bg-gray-light">
        <div class="m-4">
            <breadcrumbs class="mb-8" :crumbs="crumbs" />
            <div
                v-if="registered && !EditData"
                class="flex justify-center items-start flex-col"
            >
                <div
                    class="border p-5 rounded shadow flex justify-center flex-col items-center w-full"
                >
                    <h2 class="uppercase mb-5 font-bold text-blue-base ">
                        Recadastramento já efetuado
                    </h2>
                    <span class="mt-5"
                        >Recadastramento já foi efetuado em :
                        {{ timeRegistered }}
                    </span>

                    <div class="w-full mt-10">
                        <div>
                            <div
                                class="flex mb-1 py-2 justify-between items-center"
                            >
                                <div
                                    class="border-blue-500 border-b-2 md:w-full"
                                    style="height: 2px;"
                                ></div>
                                <div
                                    class="flex text-blue-base uppercase text-xs font-bold items-center justify-center"
                                    style=" width: 350px"
                                >
                                    Dados da empresa
                                </div>
                                <div
                                    class="border-blue-500 border-b-2 md:w-full"
                                    style="height: 2px;"
                                ></div>
                            </div>
                            <div
                                class="text-xs flex flex-col md:flex-row w-full mb-3"
                            >
                                <div
                                    class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Razão Social</label
                                    >
                                    <div>{{ organization.business_name }}</div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Nome Fantasia</label
                                    >
                                    <div>{{ organization.name }}</div>
                                </div>
                            </div>

                            <div
                                class="text-xs flex flex-col md:flex-row w-full mb-5"
                            >
                                <div
                                    class="md:mr-10 md:w-1/4 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Endereço</label
                                    >
                                    <div>{{ organization.street }}</div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/6 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark">Número</label>
                                    <div>{{ organization.number }}</div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/6 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark">Bairro</label>
                                    <div>{{ organization.district }}</div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/6 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Complemento</label
                                    >
                                    <div>{{ organization.complement }}</div>
                                </div>
                            </div>

                            <div
                                class="text-xs flex flex-col md:flex-row w-full mb-5"
                            >
                                <div
                                    class="md:mr-10 md:w-1/4 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Cidade/Estado</label
                                    >
                                    <div>
                                        {{ organization.city.name }} -
                                        {{ organization.city.state.initials }}
                                    </div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/4 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >E-mail Institucional</label
                                    >
                                    <div>{{ organization.email }}</div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/4 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Telefone Comercial</label
                                    >
                                    <div>
                                        {{ organization.commercial_phone }}
                                    </div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/4 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Celular Comercial</label
                                    >
                                    <div>{{ organization.cell_phone }}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                class="flex mb-1 py-2 justify-between items-center mt-10"
                            >
                                <div
                                    class="border-blue-500 border-b-2 md:w-full"
                                    style="height: 2px;"
                                ></div>
                                <div
                                    class="flex text-blue-base uppercase text-xs font-bold items-center justify-center"
                                    style=" width: 510px"
                                >
                                    Representantes da empresa
                                </div>
                                <div
                                    class="border-blue-500 border-b-2 md:w-full"
                                    style="height: 2px;"
                                ></div>
                            </div>

                            <div
                                class="border rounded shadow p-4"
                                v-for="(contact,
                                index) in organization.contacts"
                                :key="index"
                            >
                                <div v-if="contact['id']">
                                    <div
                                        class="w-full flex justify-center items-center uppercase text-xs text-gray-500
                mt-2
                font-bold underline mb-3"
                                    >
                                        Representante
                                        {{ index + 1 }}
                                    </div>
                                    <div
                                        class="text-xs flex flex-col md:flex-row w-full mb-3"
                                    >
                                        <div
                                            class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                        >
                                            <label class="text-gray-dark"
                                                >Nome Completo</label
                                            >
                                            <div>{{ contact.name }}</div>
                                        </div>
                                        <div
                                            class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                        >
                                            <label class="text-gray-dark"
                                                >CPF</label
                                            >
                                            <div>{{ contact.cpf }}</div>
                                        </div>
                                    </div>
                                    <div
                                        class="text-xs flex flex-col md:flex-row w-full mb-3"
                                    >
                                        <div
                                            class="md:mr-10 md:w-1/3 flex justify-center flex-col items-center"
                                        >
                                            <label class="text-gray-dark"
                                                >Cargo</label
                                            >
                                            <div>
                                                {{
                                                    typeRelation[
                                                        contact
                                                            .relationship_type
                                                    ]
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="md:mr-10 md:w-1/3 flex justify-center flex-col items-center"
                                        >
                                            <label class="text-gray-dark"
                                                >E-mail de contato</label
                                            >
                                            <div>{{ contact.email }}</div>
                                        </div>
                                        <div
                                            class="md:mr-10 md:w-1/3 flex justify-center flex-col items-center"
                                        >
                                            <label class="text-gray-dark"
                                                >Celular Comercial</label
                                            >
                                            <div>{{ contact.cell_phone }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                class="flex mb-1 py-2 justify-between items-center mt-10"
                            >
                                <div
                                    class="border-blue-500 border-b-2 md:w-full"
                                    style="height: 2px;"
                                ></div>
                                <div
                                    class="flex text-blue-base uppercase text-xs font-bold items-center justify-center"
                                    style=" width: 350px"
                                >
                                    Tecnologia
                                </div>
                                <div
                                    class="border-blue-500 border-b-2 md:w-full"
                                    style="height: 2px;"
                                ></div>
                            </div>
                            <div
                                class="text-xs flex flex-col md:flex-row w-full mb-3"
                            >
                                <div
                                    class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >A Empresa possui Sistema de Bilhetagem
                                        Eletrônica ?</label
                                    >
                                    <div>
                                        {{
                                            typeTicketing[
                                                organization.ticketing
                                            ]
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Empresa de Tecnologia</label
                                    >
                                    <div>
                                        {{ organization.ticketing_technology }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="text-xs flex flex-col md:flex-row w-full mb-3"
                            >
                                <div
                                    class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >A frota possui Sistema de Monitoramento
                                        de frota por Georreferenciamento
                                        ?</label
                                    >
                                    <div>
                                        {{
                                            typeTelemetry[
                                                organization.telemetry
                                            ]
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Empresa de Tecnologia</label
                                    >
                                    <div>
                                        {{ organization.telemetry_technology }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                class="flex mb-1 py-2 justify-between items-center mt-10"
                            >
                                <div
                                    class="border-blue-500 border-b-2 md:w-full"
                                    style="height: 2px;"
                                ></div>
                                <div
                                    class="flex text-blue-base uppercase text-xs font-bold items-center justify-center"
                                    style=" width: 670px"
                                >
                                    Responsável pelo Cadastramento
                                </div>
                                <div
                                    class="border-blue-500 border-b-2 md:w-full"
                                    style="height: 2px;"
                                ></div>
                            </div>
                            <div
                                class="text-xs flex flex-col md:flex-row w-full mb-3"
                            >
                                <div
                                    class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark"
                                        >Nome Completo</label
                                    >
                                    <div>
                                        {{ organization.confirmation.name }}
                                    </div>
                                </div>
                                <div
                                    class="md:mr-10 md:w-1/2 flex justify-center flex-col items-center"
                                >
                                    <label class="text-gray-dark">CPF</label>
                                    <div>
                                        {{ organization.confirmation.document }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex w-full justify-center items-center mt-10 mb-5">
                    <button
                        class="form-submit w-full sm:w-auto px-10 py-2"
                        @click="setEditData(true)"
                    >
                        Editar Dados Salvos
                    </button>
                </div>
            </div>

            <div v-if="!this.registered || this.EditData">
                <step-one
                    v-if="step === 1"
                    :organization.sync="organization"
                    @progressStep="nextStep()"
                />
                <step-two
                    v-if="step === 2"
                    :organization.sync="organization"
                    @progressStep="nextStep()"
                    @returnStep="returnStep()"
                />
                <step-three
                    v-if="step === 3"
                    :organization.sync="organization"
                    @progressStep="nextStep()"
                    @returnStep="returnStep()"
                />
                <step-four
                    v-if="step > 3"
                    :organization.sync="organization"
                    @progressStep="nextStep()"
                    @returnStep="returnStep()"
                />

                <Modal width="700" @close="$emit('close')" v-if="step === 5">
                    <div slot="body" class="p-10">
                        <div>
                            Declaro que as informações acima prestadas são
                            verdadeiras, e assumo a inteira responsabilidade
                            pelas mesmas.
                        </div>
                        <div class="flex items-center justify-center mt-10">
                            <button
                                class="form-submit w-full sm:w-auto px-10 py-2 mr-5 cursor-pointer shadow"
                                style="background: #828282"
                                @click="step = 4"
                            >
                                Cancelar
                            </button>
                            <button
                                class="form-submit w-full sm:w-auto px-10 py-2 shadow"
                                @click="onFormSubmit()"
                            >
                                Concluir
                            </button>
                        </div>
                    </div>
                    <div slot="footer"></div>
                </Modal>

                <div class="uppercase text-blue-700 flex justify-end p-5">
                    Passo {{ step }} de 4
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component } from "vue-property-decorator";
import Icon from "@/core/components/Icon";
import Breadcrumbs from "@/core/components/Breadcrumbs";
import IconBadge from "@/core/components/badges/IconBadge";
import RegistryService from "@/core/services/RegistryService";
import StepOne from "@/domains/re-registration/components/StepOne";
import StepFour from "@/domains/re-registration/components/StepFour";
import StepThree from "@/domains/re-registration/components/StepThree";
import StepTwo from "@/domains/re-registration/components/StepTwo";
import Modal from "@/core/components/Modal";
import { dateFormat } from "@/core/services/helpers";

@Component({
    components: {
        breadcrumbs: Breadcrumbs,
        "step-one": StepOne,
        "step-two": StepTwo,
        "step-three": StepThree,
        "step-four": StepFour,
        icon: Icon,
        IconBadge,
        Modal
    }
})
export default class EditInfo extends RegistryService {
    constructor() {
        super();
        this.registered = false;
        this.EditData = false;
        this.timeRegistered = "";
        this.user = null;
        this.step = 1;
        this.disableds = [];
        this.typeRelation = [
            "Não cadastrado",
            "Proprietário",
            "Procurador",
            "Contador"
        ];
        this.typeTelemetry = [
            "Não informado",
            "Em Toda a frota",
            "Em parte da frota",
            "Não possuo"
        ];
        this.typeTicketing = [
            "Não informado",
            "Em Toda a frota",
            "Em parte da frota",
            "Não possuo"
        ];
        this.title = "Editar Dados da Instituição";
        this.crumbs = [
            { name: "Início", path: "index" },
            {
                name: "Minha Instituição",
                path: "organization.index"
            },
            {
                name: "Editar Dados da Instituição",
                path: "organization.edit"
            }
        ];

        this.organization = {
            organization_id: 0,
            business_name: "",
            name: "",
            street: "",
            number: "",
            district: "",
            complement: "",
            cep: "",
            city: "",
            city_id: "",
            email: "",
            commercial_phone: "",
            cell_phone: "",
            contacts: [
                {
                    name: "",
                    cpf: "",
                    relationship_type: "",
                    email: "",
                    telC: "",
                    cellphone: ""
                },
                {
                    name: "",
                    cpf: "",
                    relationship_type: "",
                    email: "",
                    telC: "",
                    cellphone: ""
                },
                {
                    name: "",
                    cpf: "",
                    relationship_type: "",
                    email: "",
                    telC: "",
                    cellphone: ""
                },
                {
                    name: "",
                    cpf: "",
                    relationship_type: "",
                    email: "",
                    telC: "",
                    cellphone: ""
                },
                {
                    name: "",
                    cpf: "",
                    relationship_type: "",
                    email: "",
                    telC: "",
                    cellphone: ""
                }
            ],
            telemetry: 0,
            telemetry_technology: "",
            ticketing: 0,
            ticketing_technology: "",
            confirmation: {
                name: "",
                document: ""
            },
            nameResponsible: "",
            documentResponsible: ""
        };
    }

    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
        this.disableds = ["name"];
        this.getUserData();
    }

    async getUserData() {
        let info = await this.getUserInfo(
            this.$store.getters.isOrganization
                ? this.$store.state.auth.user.document
                : this.$store.state.auth.link.organization.document
        );
        this.user = info;

        let isOrg = this.$store.getters.isOrganization;
        if (!isOrg) {
            this.$router.push({ name: "index" });
        }

        this.organization.organization_id = info.id;

        this.getCheckRegistration();

        this.$Progress.finish();
    }

    setEditData(e) {
        this.EditData = e;
    }

    nextStep() {
        this.step++;
    }

    returnStep() {
        this.step--;
    }

    async onFormSubmit() {
        let info = {};

        if (this.EditData) {
            info = await this.putRegistrationData(this.organization);
        } else {
            info = await this.reRegistrationData(this.organization, false);
        }

        if (info.errors) {
            this.errors = info.errors;
        } else {
            this.step = 4;
            this.$router.push({ name: "re-registration.index" });
        }
        this.isEdit = false;
    }

    async getCheckRegistration() {
        let checkRegister = await this.reRegistrationData(
            this.organization,
            true
        );
        if (checkRegister.success) {
            this.timeRegistered = dateFormat(
                checkRegister.response.data.register.created_at,
                true
            );
            this.convertData();
            this.organization = checkRegister.response.data.register;
            this.countContacts();
            this.registered = true;
        }
    }

    countContacts() {
        const sizeContacts = this.organization.contacts.length;

        for (let cont = 0; cont < 5 - sizeContacts; cont++) {
            let c = {
                name: "",
                cpf: "",
                relationship_type: "",
                email: "",
                telC: "",
                cellphone: ""
            };

            this.organization.contacts.push(c);
        }
    }

    convertData() {}
}
</script>
