import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });
import auth from "@/domains/auth/store";
import transport from "@/domains/transport/store";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        transport
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
                // getItem: key => localStorage.getItem(key),
                // setItem: (key, value) => localStorage.setItem(key, value),
                // removeItem: key => localStorage.removeItem(key)
            }
        })
    ]
});
