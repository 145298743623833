<script>
import Portlet from "@/core/components/Portlet";
import PortletGroup from "@/core/components/PortletGroup";

export default {
    props: {
        updates: {
            type: Array,
            required: false
        },
    },
    components: {
        Portlet,
        PortletGroup,
    }
};
</script>

<template>
    <div>
        <PortletGroup>
            <div slot="header">Novas Funcionalidades</div>
            <Portlet slot="second-portlet" class="rounded-none md:w-full mt-1 md:mt-0">
                <div slot="header" class="bg-blue-background text-white text-sm font-medium px-8 py-2">
                    NOVIDADES DA VERSÃO!
                </div>
                <div slot="content">
                    <div class="text-xs text-gray-darker px-4 py-2">
                        <ul class="list-disc ml-3">
                            <li v-for="update in updates" :key="update" class="mb-2">
                                {{ update }}
                            </li>
                        </ul>
                    </div>
                </div>
            </Portlet>
        </PortletGroup>
    </div>
</template>
