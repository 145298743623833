<template>
    <footer class="sticky bottom-0 left-0 w-full lg:hidden">
        <transition name="slide" class="z-0">
            <div v-if="isOpen">
                <button
                    class="w-full h-20 border-t-2 border-blue-light text-gray-darker bg-gray-light hover:bg-red-base active:bg-red-base hover:text-white active:text-white"
                    @click="logout"
                >
                    <div class="flex items-center p-6">
                        <h1 class="mx-2 text-lg font-medium">Sair</h1>
                        <Icon icon="logout" class="h-6 fill-current"></Icon>
                    </div>
                </button>
            </div>
        </transition>
        <div class="bg-gradient-blue">
            <div
                class="bottom-0 left-0 flex items-center justify-between px-12 py-3"
            >
                <button
                    class="z-50 text-blue-lightest hover:text-white active:text-white focus:outline-none"
                    @click="toggle"
                >
                    <Icon icon="user" class="h-8 fill-current" />
                </button>
                <router-link
                    class="text-blue-lightest hover:text-white active:text-white"
                    :to="{ name: 'notifications.index' }"
                >
                    <Icon icon="notifications" class="h-8 fill-current" />
                </router-link>
                <router-link
                    class="text-blue-lightest hover:text-white active:text-white"
                    :to="{
                        name: this.$store.getters.isOrganization
                            ? 'organization.managers'
                            : 'link.select'
                    }"
                >
                    <Icon icon="myLinks" class="h-8 fill-current" />
                </router-link>
                <button
                    class="text-blue-lightest hover:text-white active:text-white"
                >
                    <a href="https://ajuda.sie.sc.gov.br" target="_blank">
                        <Icon icon="help" class="h-8 fill-current" />
                    </a>
                </button>
            </div>
        </div>
    </footer>
</template>

<script>
import Icon from "@/core/components/Icon";

export default {
    components: {
        Icon
    },
    data: function() {
        return {
            isOpen: false
        };
    },
    watch: {
        $route: function() {
            this.isOpen = false;
        }
    },
    methods: {
        logout() {
            this.$Progress.start();
            this.$store
                .dispatch("getLogout")
                .then(() => {
                    this.$router.push({ name: "login" });
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$Progress.finish();
                });
        },
        toggle() {
            this.isOpen = !this.isOpen;
        }
    }
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.8s, opacity 1s ease;
}
.slide-enter,
.slide-leave-to {
    transform: translateX(-50px);
    opacity: 0;
}
.slide-enter-to,
slideup-leave {
    transform: translateX(0px);
    opacity: 1;
}
</style>
