let HandleOutsideClick = {};

HandleOutsideClick.install = function(Vue) {
    let outsideClickEvent;
    Vue.directive("click-outside", {
        bind(el, binding, vnode) {
            outsideClickEvent = e => {
                const handler = binding.expression;
                if (!(el == e.target || el.contains(e.target))) {
                    vnode.context[handler]();
                }
            };
            document.addEventListener("click", outsideClickEvent);
            document.addEventListener("touchstart", outsideClickEvent);
        },
        update(el, binding, vnode) {
            outsideClickEvent = e => {
                const handler = binding.expression;
                if (!(el == e.target || el.contains(e.target))) {
                    vnode.context[handler]();
                }
            };
            document.addEventListener("click", outsideClickEvent);
            document.addEventListener("touchstart", outsideClickEvent);
        },
        unbind() {
            document.removeEventListener("click", outsideClickEvent);
            document.removeEventListener("touchstart", outsideClickEvent);
        }
    });
};

export default HandleOutsideClick;
