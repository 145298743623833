import Login from "./views/Login";
import Register from "./views/Register";

export default [
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            forVisitors: true
        }
    },
    {
        path: "/cadastro",
        name: "register",
        component: Register,
        meta: {
            forVisitors: true
        }
    },
    {
        path: "/cadastro/confirmar/:code",
        name: "confirm",
        component: () => import("./views/Confirm.vue"),
        props: true,
        meta: {
            forVisitors: true
        }
    }
];
