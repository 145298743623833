<script>
import { TheMask } from "vue-the-mask";
import { resetPassword } from "@/domains/auth/services";

export default {
    components: {
        TheMask
    },
    props: {
        document: { type: String, default: "" }
    },
    data() {
        return {
            input: "",
            status: "pending",
            message: null,
            errors: null
        };
    },
    mounted() {
        this.input = this.document;
    },
    methods: {
        send() {
            this.$Progress.start();
            this.errors = null;
            this.status = "pending";
            resetPassword(this.input)
                .then(response => {
                    this.$toast.success({
                        title: "OK",
                        message: response
                    });
                    this.message = response;
                    this.status = "finished";
                    this.$Progress.finish();
                })
                .catch(e => {
                    if (e.status !== 422) {
                        this.$toast.error({
                            message: e.error
                        });
                    } else {
                        this.errors = e.error;
                    }
                    if (e.status === 403) {
                        this.status = "no-email";
                    } else {
                        this.status = "rejected";
                    }
                    this.$Progress.finish();
                });
        }
    }
};
</script>

<template>
    <form @submit.prevent="send">
        <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
                <div
                    :class="[
                        status === 'finished' ? 'bg-green-100' : 'bg-red-100',
                        'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10'
                    ]"
                >
                    <svg
                        v-if="status === 'finished'"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-6 h-6 text-green-600"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                        />
                    </svg>
                    <svg
                        v-else
                        class="w-6 h-6 text-red-600"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                    </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                        class="text-lg font-medium leading-normal text-gray-900"
                    >
                        <span v-if="status === 'finished'">E-mail enviado</span>
                        <span v-else>Recuperar senha</span>
                    </h3>
                    <div class="mt-2">
                        <p
                            v-if="status === 'no-email' && input.length === 14"
                            class="text-sm leading-tight text-left text-red-base"
                        >
                            Não existe e-mail cadastrado para este CNPJ.
                            Solicite uma atualização de e-mail através de um
                            chamado.
                        </p>
                        <p
                            v-else-if="status === 'finished'"
                            class="text-sm leading-tight text-left text-gray-dark"
                        >
                            {{ message }}
                        </p>
                        <p
                            v-else
                            class="text-sm leading-tight text-left text-gray-500"
                        >
                            Digite o seu CPF ou CNPJ abaixo e clique em enviar.
                            Uma nova senha será enviada para o seu e-mail
                            cadastrado em nossa base.
                        </p>
                    </div>
                    <div v-if="status !== 'finished'" class="mt-3 sm:mt-2">
                        <TheMask
                            class="w-full form-input"
                            :mask="['###.###.###-##', '##.###.###/####-##']"
                            placeholder="000.000.000-00"
                            v-model="input"
                            v-focus
                            required
                        />
                        <div v-if="errors && errors.document">
                            <p
                                v-for="(error, index) in errors.document"
                                v-bind:key="index"
                                class="text-xs italic text-red-base"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-4 py-3 bg-gray-100 sm:px-6 sm:flex sm:flex-row-reverse">
            <span
                v-if="status !== 'finished'"
                class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
            >
                <button
                    type="submit"
                    class="inline-flex justify-center w-full px-4 py-2 text-xs font-medium leading-normal text-white uppercase transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green sm:leading-tight"
                >
                    Enviar
                </button>
            </span>
            <span
                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
                <button
                    @click="$emit('close')"
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 text-xs font-medium leading-normal text-gray-700 uppercase transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline sm:leading-tight"
                >
                    <span v-if="status === 'finished'">Fechar</span>
                    <span v-else>Cancelar</span>
                </button>
            </span>
            <a
                v-if="status !== 'finished'"
                target="_blank"
                title="Ajuda sobre Viagens"
                href="https://ajuda.sie.sc.gov.br/bc/meu-primeiro-acesso/"
                class="inline-flex items-center mr-auto space-x-2 text-sm underline text-cool-gray-500 hover:text-blue-light"
            >
                <svg
                    class="w-4 h-4 fill-current"
                    viewBox="0 0 384 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"
                    ></path>
                </svg>
                <div>
                    Ajuda
                </div>
            </a>
        </div>
    </form>
</template>
