import Index from "./views/Index";
import EditInfo from "./views/EditInfo";

export default [
    {
        path: "/cadastro",
        name: "re-registration.index",
        component: Index,
        meta: {
            forAuth: true,
            module: "re-registration"
        }
    },
    {
        path: "/cadastro/empresas",
        name: "re-registration.organization",
        component: EditInfo,
        meta: {
            forAuth: true,
            module: "re-registration"
        }
    }
];
