<script>
import ResponsiveTable from "@/core/components/table/ResponsiveTable";
import SolicitConfirmation from "@/domains/user/views/user-links/SolicitConfirmation";

export default {
    components: {
        ResponsiveTable,
        SolicitConfirmation
    },
    props: {
        search: {
            closed: {
                type: Boolean,
                default: false
            },
            type: String,
            default: ""
        },
        document: {
            type: String,
            default: "alfafa"
        }
    },
    data() {
        return {
            loading: false,
            headers: ["Nome", "CNPJ", ""],
            page: 1,
            pagination: {},
            links: []
        };
    },
    mounted() {
        this.getLinks();
    },
    methods: {
        getLinks() {
            let search = this.search ? this.search : this.document;
            this.$Progress.start();
            this.loading = true;
            this.$axios
                .post(
                    "/api/registry/person/organizations" + "?page=" + this.page,
                    {
                        // TODO: Incluir CNPJ na pesquisa; Solicitar button
                        search: search,
                        orderBy: {
                            id: "DESC"
                        }
                    }
                )
                .then(resp => {
                    this.links = resp.data.data;
                    this.pagination = resp.data;
                    delete this.pagination.data;
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.links = [];
                    this.loading = false;
                    this.$Progress.finish();
                });
        },
        changePage(page) {
            if (page !== this.page) {
                this.page = page;
                this.getLinks();
            }
        },
        dataMap(data) {
            if (data.length > 0) {
                return data.map(item => {
                    return {
                        name: item.name,
                        document: item.document_formatted,
                        linked: item.links.length > 0,
                        line: item
                    };
                });
            }
            return [];
        }
    }
};
</script>
<template>
    <ResponsiveTable
        :headers="headers"
        :data="dataMap(links)"
        :pagination="pagination"
        :disabled="loading"
        @changePage="
            page => {
                changePage(page);
            }
        "
    >
        <template #row="props">
            <td :class="props.tdClass">{{ props.row.name }}</td>
            <td :class="props.tdClass">{{ props.row.document }}</td>
            <td :class="props.tdClass + ' text-right'">
                <div v-if="!props.row.linked">
                    <SolicitConfirmation
                        action="link"
                        :organization="props.row.line"
                        :modal="true"
                    />
                </div>
            </td>
        </template>
        <template #responsive="props">
            <tr class="p-2">
                <th :class="props.thClass">CNPJ</th>
                <td :class="props.tdClass">{{ props.row.document }}</td>
            </tr>
        </template>
    </ResponsiveTable>
</template>
