<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import SubordinatesList from "@/domains/organization/views/SubordinatesList";
import Icon from "@/core/components/Icon";

export default {
    components: {
        Breadcrumbs,
        SubordinatesList,
        Icon
    },
    data: () => {
        return {
            title: "Gerir Subordinados",
            crumbs: [
                { name: "Início", path: "index" },
                {
                    name: "Minha Instituição",
                    path: "organization.index"
                },
                { name: "Gerir Subordinados", path: "" }
            ],
            search: ""
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },
    methods: {
        async clearFilters() {
            await this.getResult();
            this.$refs.table.getLinks();
        },
        async getResult() {
            return new Promise(resolve => {
                this.search = "";
                resolve(this.search);
            });
        }
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-6" :crumbs="crumbs" />
            <portal-target name="modals" />
            <div class="flex flex-wrap justify-between">
                <div class="px-2 mb-2 text-left col-sm md:mb-0">
                    <p class="text-sm text-muted text-gray-darker">
                        Procurar por pessoa física para vincular e conceder
                        perfis.
                    </p>
                </div>
                <form
                    @submit.prevent="$refs.table.getLinks"
                    class="flex w-full mb-2 mr-1 md:w-auto"
                >
                    <div v-on:click="clearFilters" class="btn-clean-filter">
                        Limpar Filtros
                    </div>
                    <div class="relative inline-block w-full">
                        <button
                            type="submit"
                            class="absolute right-0 flex items-center hidden px-2 rounded-r-sm cursor-pointer md:block bg-blue-background active:bg-image-none active:bg-blue-darkest"
                        >
                            <Icon
                                icon="search"
                                class="h-4 my-2 text-white fill-current"
                            />
                        </button>
                        <input
                            v-model="search"
                            class="pl-4 filter-search"
                            type="text"
                        />
                    </div>
                    <button
                        class="flex w-32 ml-1 md:hidden form-submit "
                        type="submit"
                    >
                        Pesquisar
                    </button>
                </form>
            </div>
            <SubordinatesList ref="table" :search="search" />
        </div>
    </div>
</template>
