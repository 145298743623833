<template>
    <div class="w-full pl-1">
        <div class="w-full text-xs flex flex-col bg-white overflow-scroll">
            <button
                @click="reloadLinks"
                class="w-full flex justify-end px-3 py-4 border-gray-light border-b-2 hover:bg-gray-dark focus:outline-none active:bg-green-base bg-white text-gray-dark hover:text-white text-base"
            >
                <Icon icon="refresh" class="mr-2 mt-1 h-4 fill-current" />
                <span class="">Atualizar vínculos</span>
            </button>
            <button
                v-for="link in otherLinks($store.state.auth.user.links)"
                v-bind:key="link.id"
                @click="selectLink(link)"
                class="w-full flex flex-col px-3 py-2 text-right text-xs text-gray-darker border-white border-t-4 bg-gray-light hover:bg-gray-base hover:text-gray-darker active:text-white active:bg-gray-dark focus:outline-none"
            >
                <span class="font-medium uppercase text-lg">{{
                    link.organization.business_name || link.organization.name
                }}</span>
                <span>{{ link.organization.document_formatted }}</span>
            </button>
        </div>
    </div>
</template>
<script>
import Icon from "@/core/components/Icon";

export default {
    components: {
        Icon
    },
    data: function() {
        return {
            isOpen: false,
            linksOpen: false
        };
    },
    created() {
        const handleEscape = e => {
            if (e.key === "Esc" || e.key === "Escape") {
                this.close();
            }
        };
        document.addEventListener("keydown", handleEscape);
        this.$once("hook:beforeDestroy", () => {
            document.removeEventListener("keydown", handleEscape);
        });
    },
    methods: {
        otherLinks(links) {
            return links.filter(link => {
                if (this.$store.state.auth.link) {
                    return link.id !== this.$store.state.auth.link.id;
                }
                return true;
            });
        },
        reloadLinks() {
            this.$Progress.start();
            this.$store
                .dispatch("getUser")
                .then(() => {
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$Progress.finish();
                });
        },
        selectLink(link) {
            this.$store.commit("setLink", link);
            if (this.$route.name !== "index") {
                this.$router.replace({ name: "index" });
            }
        },
        close() {
            this.isOpen = false;
        }
    }
};
</script>
