import Service from "./Service";

export default class ComponentService extends Service {
    constructor() {
        super();
        this.routerReassign = "/api/support";
        this.result = {};
        this.errors = null;
    }

    async postPage(url, data, page) {
        let info = await this.request(url + "?page=" + page, "post", data);
        if (info.status === "success") {
            let obj = {};
            Object.assign(obj, info.response.data);

            this.result.pages = obj.current_page + 5;

            if (this.result.pages > obj.last_page) {
                this.result.pages = obj.last_page;
            }

            this.result.data = obj;
        } else {
            this.result.pages = false;
            this.result.data = false;
        }

        return this.result;
    }
}
