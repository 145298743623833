<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import SystemInfo from "@/core/components/portlets/SystemInfo";

export default {
    components: {
        Breadcrumbs,
        SystemInfo
    },
    data: () => {
        return {
            title: "Suporte",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Suporte", path: "support.index" }
            ],
            alerts: [
                "Consulte a equipe técnica em caso de dúvida ou correção.",
                "Sempre que possível, anexe imagens do problema, isso facilita para nossa equipe identificar e solucionar o problema.",
                "Se você não recebeu o número do seu processo, favor verificar na caixa de lixo eletrônico. Se não o encontrar, favor enviar o questionamento para protocolo@sie.sc.gov.br.",
                "Acesse o SGPE para saber sobre a tramitação de sua solicitação."
            ]
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="lg:max-w-5xl my-4 px-2 sm:px-6">
            <Breadcrumbs class="mb-8" :crumbs="crumbs" />
            <SystemInfo
                :helpLink="'https://ajuda.sie.sc.gov.br/bc/suporte/'"
                :alerts="alerts"
            />
        </div>
    </div>
</template>
