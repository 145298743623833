var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full bg-gray-light"},[_c('div',{class:'mx-2 my-4 modal-edit' + (_vm.isEdit ? ' edit-shadow' : '')},[_c('div',{staticClass:"flex flex-wrap justify-between align-baseline mb-1"},[_c('h1',{staticClass:"text-blue-base uppercase"},[_vm._v("Contatos")]),(!this.$store.getters.isOrganization)?_c('div',{staticClass:"form-new-blue",on:{"click":() => (_vm.isEdit = !_vm.isEdit)}},[_c('div',[_vm._v(_vm._s(_vm.isEdit ? "Fechar" : "Editar"))])]):_vm._e()]),_c('div',{staticClass:"border-t-2 border-blue-base pt-8"},[_c('div',{staticClass:"flex"},[(_vm.isEdit)?_c('div',{staticClass:"w-full lg:w-1/2 xl:w-2/4 xl:max-w-screen-md bg-yellow-base border border-gray-dark p-5 mb-4 sm:mx-4"},[(_vm.editable)?_c('p',{staticClass:"text-xs mb-2"},[_vm._v(" Seu cadastro deve conter pelo menos um telefone e um e-mail "),_c('span',{staticClass:"font-bold"},[_vm._v(" válido ")]),_vm._v(" . Para editar o contato primário, acrescente um novo contato. ")]):_c('p',{staticClass:"text-xs mb-2"},[_vm._v(" Para editar essas informações, faça login com seu CPF possuindo perfil de gestor. ")])]):_vm._e()]),_c('div',{staticClass:"flex flex-wrap py-5 text-xs"},[_c('div',{staticClass:"w-full md:w-1/2 flex flex-column"},[_c('h3',{staticClass:"items-start mb-4 px-2 bg-gray-base uppercase"},[_vm._v(" Telefones ")]),_vm._l((_vm.phones),function(phone,index){return _c('div',{key:phone.id,staticClass:"w-full px-8 sm:px-10 md:px-0"},[_c('div',{staticClass:"w-full well"},[_c('div',{staticClass:"flex flex-col items-end"},[_c('div',{},[(
                                            _vm.editable &&
                                                _vm.phones.length > 1 &&
                                                _vm.isEdit
                                        )?_c('a',{attrs:{"title":"Remover telefone"},on:{"click":() => _vm.removePhone(index)}},[_c('Icon',{staticClass:"h-5 fill-current text-red-base focus:outline-none rounded-full bg-white shadow hover:bg-red-base hover:text-white active:bg-red-800",attrs:{"icon":"close"}})],1):_vm._e()])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2 lg:w-48"},[_c('div',{staticClass:"flex flex-col mr-1"},[_c('span',{staticClass:"form-label"},[_vm._v(" Telefone ")]),(_vm.isEdit)?_c('TheMask',{staticClass:"form-input rounded py-2 h-auto",attrs:{"disabled":!_vm.editable ||
                                                    (_vm.phones.length < 2 &&
                                                        phone.hasOwnProperty(
                                                            'id'
                                                        )),"mask":[
                                                '(##) ####-####',
                                                '(##) #####-####'
                                            ]},model:{value:(phone.contact),callback:function ($$v) {_vm.$set(phone, "contact", $$v)},expression:"phone.contact"}}):_c('span',[_vm._v(_vm._s(phone.contact))]),_vm._l((_vm.errors[
                                                `phones.${index}.contact`
                                            ]),function(error){return _c('span',{key:error,staticClass:"text-red-500 text-xs font-bold pb-1"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"form-label"},[_vm._v(" Observação ")]),(_vm.isEdit)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(phone.description),expression:"phone.description"}],staticClass:"form-input rounded py-2 h-auto",attrs:{"disabled":!_vm.editable},domProps:{"value":(phone.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(phone, "description", $event.target.value)}}}),_vm._l((_vm.errors[
                                                    `phones.${index}.description`
                                                ]),function(error){return _c('span',{key:error,staticClass:"text-red-500 text-xs font-bold pb-1"},[_vm._v(" "+_vm._s(error)+" ")])})],2):_c('span',[_vm._v(_vm._s(phone.description))])])])])])])}),(_vm.editable && _vm.isEdit)?_c('div',[_c('button',{staticClass:"form-green py-1 w-1/2 sm:w-40 mb-6",on:{"click":_vm.addPhone}},[_vm._v(" Novo telefone ")])]):_vm._e()],2),_c('div',{staticClass:"w-full md:w-1/2 lg:1/4 flex flex-column mt-8 md:mt-0"},[_c('h3',{staticClass:"items-start mb-4 bg-gray-base px-1 uppercase"},[_vm._v(" E-mails ")]),_vm._l((_vm.emails),function(email,index){return _c('div',{key:email.id,staticClass:"w-full px-8 sm:px-10 md:px-0"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-col items-end"},[_c('div',[(
                                            _vm.editable &&
                                                _vm.emails.length > 1 &&
                                                _vm.isEdit
                                        )?_c('a',{attrs:{"title":"Remover e-mail"},on:{"click":() => _vm.removeEmail(index)}},[_c('Icon',{staticClass:"h-5 fill-current text-red-base focus:outline-none rounded-full bg-white shadow hover:bg-red-base hover:text-white active:bg-red-800",attrs:{"icon":"close"}})],1):_vm._e()])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"flex flex-col mr-1"},[_c('span',{staticClass:"form-label"},[_vm._v(" E-mail ")]),(_vm.isEdit)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(email.contact),expression:"email.contact"}],staticClass:"form-input rounded py-2 h-auto",attrs:{"disabled":!_vm.editable ||
                                                    (_vm.emails.length < 2 &&
                                                        email.hasOwnProperty(
                                                            'id'
                                                        ))},domProps:{"value":(email.contact)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(email, "contact", $event.target.value)}}}):_c('span',[_vm._v(_vm._s(email.contact))]),_vm._l((_vm.errors[
                                                `emails.${index}.contact`
                                            ]),function(error){return _c('span',{key:error,staticClass:"text-red-500 text-xs font-bold pb-1"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]),_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"form-label"},[_vm._v(" Observação ")]),(_vm.isEdit)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(email.description),expression:"email.description"}],staticClass:"form-input rounded py-2 h-auto",attrs:{"disabled":!_vm.editable},domProps:{"value":(email.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(email, "description", $event.target.value)}}}):_c('span',[_vm._v(_vm._s(email.description))]),_vm._l((_vm.errors[
                                                `emails.${index}.description`
                                            ]),function(error){return _c('span',{key:error,staticClass:"text-red-500 text-xs font-bold pb-1"},[_vm._v(" "+_vm._s(error)+" ")])})],2)])])])])}),(_vm.editable && _vm.isEdit)?_c('div',[_c('button',{staticClass:"form-green py-1 focus:outline-none w-1/2 sm:w-40 mb-6",on:{"click":_vm.addEmail}},[_vm._v(" Novo e-mail ")])]):_vm._e()],2),(_vm.editable && _vm.isEdit)?_c('div',{staticClass:"flex justify-center sm:justify-start mt-8 px-2 w-full ml-2"},[_c('button',{staticClass:"form-submit w-full sm:w-auto",on:{"click":_vm.save}},[_vm._v(" Salvar ")])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }