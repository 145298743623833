import axios from "axios";

async function resetPassword(document) {
    try {
        const response = await axios.post("/api/registry/user/reset-password", {
            document: document
        });
        return response.data.success;
    } catch (e) {
        throw {
            status: e.response.status,
            error:
                e.response.data.errors ||
                e.response.data.error ||
                e.response.data.message
        };
    }
}

async function resendConfirmation(document) {
    try {
        const response = await axios.post(
            "/api/registry/user/resend-confirmation",
            {
                document: document
            }
        );
        return response.data.success;
    } catch (e) {
        throw {
            status: e.response.status,
            error:
                e.response.data.errors ||
                e.response.data.error ||
                e.response.data.message
        };
    }
}

export { resetPassword, resendConfirmation };
