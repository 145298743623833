<template>
    <div :class="['la-ball-clip-rotate', wrapperClass]">
        <div :class="['w-4 h-4', loaderClass]"></div>
    </div>
</template>

<script>
export default {
    props: {
        wrapperClass: { type: String, default: "" },
        loaderClass: { type: String, default: "" }
    }
};
</script>
