import Index from "./views/Index";

export default [
    {
        path: "/viagem",
        name: "travel.index",
        component: Index,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/soc",
        name: "travel.soc",
        component: () =>
            import(/* webpackChunkName: "chunk-soc" */ "./views/Soc.vue"),
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/soc/novo",
        redirect: { name: "travel.soc" }
    },
    {
        path: "/viagem/soc/novo/:type",
        name: "soc.create",
        component: () =>
            import(/* webpackChunkName: "chunk-soc" */ "./views/CreateSoc.vue"),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/soc/:id",
        name: "travel.soc.show",
        component: () =>
            import(/* webpackChunkName: "chunk-soc" */ "./views/ShowSoc.vue"),
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/soc/:id/passageiros",
        name: "travel.soc.passengers",
        component: () =>
            import(
                /* webpackChunkName: "chunk-soc" */ "./views/SocPassengers.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo",
        name: "travel.continuous-charter",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/ContinuousCharter.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/novo",
        name: "continuous-charter.create",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/CreateContinuousCharter.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id",
        name: "continuous-charter.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/ShowContinuousCharter.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id",
        name: "continuous_charter.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/ShowContinuousCharter.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id/editar",
        name: "continuous-charter.contract.edit",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/EditContinuousCharter.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id/editar-contratantes",
        name: "continuous-charter.contractors.edit",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/EditContinuousContractors.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id/adicionar-viagem",
        name: "continuous-charter.travel.create",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/CreateContinuousTravel.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id/viagem/:travelId",
        name: "continuous-charter.travel.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/ShowContinuousTravel.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id/viagem/:travelId/editar",
        name: "continuous-charter.travel.edit",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/EditContinuousTravel.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id/viagem/:travelId/veiculo",
        name: "continuous-charter.travel.vehicle",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/EditContinuousVehicle.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-continuo/:id/viagem/:travelId/passageiros",
        name: "continuous-charter.travel.passengers",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/ContinuousCharterPassengers.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento",
        name: "travel.freightage",
        component: () =>
            import(
                /* webpackChunkName: "chunk-freightage" */ "./views/Freightage.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento/:id",
        name: "freightage.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-freightage" */ "./views/ShowFreightage.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento/:id/editar",
        name: "freightage.contract.edit",
        component: () =>
            import(
                /* webpackChunkName: "chunk-freightage" */ "./views/EditFreightage.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento/:id/viagem/:travelId",
        name: "freightage.travel.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-freightage" */ "./views/ShowFreightageTravel.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento/:id/viagem/:travelId/passageiros",
        name: "freightage.travel.passengers",
        component: () =>
            import(
                /* webpackChunkName: "chunk-freightage" */ "./views/FreightagePassengers.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento/:id/viagem/:travelId/veiculo",
        name: "freightage.travel.vehicle",
        component: () =>
            import(
                /* webpackChunkName: "chunk-freightage" */ "./views/EditFreightageVehicle.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/viagem-especial",
        name: "travel.special-travel",
        component: () =>
            import(
                /* webpackChunkName: "chunk-special" */ "./views/SpecialTravel.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-eventual",
        name: "travel.occasional-travel",
        component: () =>
            import(
                /* webpackChunkName: "chunk-special" */ "./views/OccasionalTravel.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-eventual/novo",
        name: "travel.occasional-travel.create",
        component: () =>
            import(
                /* webpackChunkName: "chunk-special" */ "./views/CreateOccasionalTravel.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-eventual/:id",
        name: "travel.occasional-travel.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-special" */ "./views/ShowOccasional.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-eventual/:id/editar-contratantes",
        name: "travel.occasional-travel.contractors.edit",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/EditOccasionalContractors.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-eventual/:id/editar-viagem",
        name: "travel.occasional-travel.travel.update",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/EditOccasionalTravel.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/fretamento-eventual/:id/veiculo",
        name: "travel.occasional-travel.vehicle",
        component: () =>
            import(
                /* webpackChunkName: "chunk-charter" */ "./views/EditOccasionalVehicle.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/viagem-especial/:id",
        name: "travel.special-travel.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-special" */ "./views/ShowSpecial.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/viagem-especial/:id/adicionar-itinerarios",
        name: "special-travel.itineraries.create",
        component: () =>
            import(
                /* webpackChunkName: "chunk-special" */ "./views/CreateSpecialTravelItineraries.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    },
    {
        path: "/viagem/viagem-especial/:id/viagem/:travelId/passageiros",
        name: "travel.special-travel.passengers",
        component: () =>
            import(
                /* webpackChunkName: "chunk-special" */ "./views/SpecialPassengers.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel"
        }
    }
];
