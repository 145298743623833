<script>
import * as icons from "./icons";

export default {
    props: {
        icon: String
    },
    data() {
        return {
            icons
        };
    }
};
</script>

<template>
    <component v-bind:is="icons[icon]"></component>
</template>
