import Index from "./views/Index";
import Tickets from "./views/Tickets";
import CreateTicket from "./views/CreateTicket";
import ShowTicket from "./views/ShowTicket";

export default [
    {
        path: "/suporte",
        name: "support.index",
        component: Index,
        meta: {
            forAuth: true,
            module: "support"
        }
    },
    {
        path: "/suporte/chamado",
        name: "support.ticket",
        component: Tickets,
        meta: {
            forAuth: true,
            module: "support"
        }
    },
    {
        path: "/suporte/chamado/novo",
        name: "ticket.create",
        component: CreateTicket,
        meta: {
            forAuth: true,
            module: "support"
        }
    },
    {
        path: "/suporte/chamado/:id",
        name: "ticket.show",
        component: ShowTicket,
        props: true,
        meta: {
            forAuth: true,
            module: "support"
        }
    }
];
