import axios from "axios";
import toast from "izitoast";

async function confirmReregistration(organizationId) {
    try {
        const response = await axios.get(
            `/api/registry/re-registration/${organizationId}/confirmation`
        );
        return response;
    } catch (e) {
        toast.error({
            message:
                e.response.data.message ||
                e.response.data.error ||
                "Erro interno, tente novamente ou mais tarde."
        });
        throw e;
    }
}
async function confirmCircularInfo(organizationId) {
    try {
        const response = await axios.get(
            `/api/registry/re-registration/${organizationId}/circular-info`
        );
        return response;
    } catch (e) {
        toast.error({
            message:
                e.response.data.message ||
                e.response.data.error ||
                "Erro interno, tente novamente ou mais tarde."
        });
        throw e;
    }
}

export { confirmReregistration, confirmCircularInfo };
