<script>
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import ComponentService from "../services/ComponentService";

@Component
export default class Table extends ComponentService {
    @Prop({ default: true })
    hasSearch;
    @Prop({ default: "" })
    url;
    @Prop({ default: false })
    autoLoad;
    @Prop({
        default: () => {}
    })
    requestParameter;
    @Prop({
        default: () => {}
    })
    refresh;
    @Prop({
        default: () => {}
    })
    orderBy;
    @Prop({ default: true })
    pagination;
    @Prop({
        default: () => {}
    })
    errorHandler;
    @Prop({ default: true })
    hover;
    @Prop({ default: true })
    striped;

    constructor() {
        super();
        this.loading = false;
        this.search = {
            text: ""
        };
        this.data = {
            data: [],
            current_page: 0,
            last_page: 0
        };
        this.messageError = "";
        this.pages = 0; // Number of page(s) available
        this.page = 1; // Current Page
        this.callOnError = response => {
            if (response.body.error) {
                this.$toast.error({
                    title: "",
                    message: response.body.error,
                    icon: "fa fa-exclamation"
                });
            }
        };
    }

    mounted() {
        // Request data if the client requires
        if (this.autoLoad) {
            this.goToPage(1);
        }
        // Expose a function to allow external refresh of data
        if (this.refresh != null) {
            this.refresh.call = this.refreshData;
        }

        if (this.errorHandler != null) {
            this.callOnError = this.errorHandler.call;
        }
    }

    @Watch("orderBy")
    // onChildChanged(val, oldVal) {
    onChildChanged() {
        this.refreshData();
    }

    refreshData(page = null) {
        let nextPage = page || this.page;
        this.goToPage(nextPage);
    }

    onFormSubmit(e) {
        e.preventDefault();
        this.goToPage(1);
    }

    async requestPage(page = 1) {
        this.page = page;
        this.$Progress.start();
        this.loading = true;
        let data = Object.assign(
            Object.assign(this.search, this.requestParameter),
            { orderBy: this.orderBy }
        );
        let info = await this.postPage(this.url, data, page);

        this.$Progress.finish();
        this.loading = false;
        //
        if (info.status === "success") {
            this.data = info.response.data;
            this.pages = info.pages;
        } else {
            this.data = { data: [], current_page: 0, last_page: 0 };
            info = info.response.response;
            if (info.status === 404) {
                this.messageError = info.data.error;
            }
        }

        this.$emit("load", this.data);
    }

    goToPage(page = 1) {
        this.requestPage(page);
    }

    render() {
        const search = this.$slots.hasOwnProperty("search") ? (
            <div class="mt-4 mb-4">{this.$slots["search"]}</div>
        ) : (
            <form class="form-horizontal" onSubmit={this.onFormSubmit}>
                <input
                    v-model={this.search.text}
                    type="text"
                    class="form-control"
                    placeholder="Procurar CPF"
                />
            </form>
        );

        const form = this.hasSearch ? <div>{search}</div> : "";

        const thead = this.$slots.hasOwnProperty("thead") ? (
            this.$slots["thead"]
        ) : (
            <thead>
                <tr>
                    <th>HEADER</th>
                </tr>
            </thead>
        );

        const tbody = this.loading ? (
            <tr class="h-full">
                <td>Carregando!</td>
            </tr>
        ) : this.$slots.hasOwnProperty("tbody") ? (
            this.$slots["tbody"]
        ) : (
            <tbody>
                <tr>
                    <tr>BODY</tr>
                </tr>
            </tbody>
        );

        let pagination = [];

        // TODO v3.1 fix
        for (
            let i =
                this.data.current_page - 5 > 0 ? this.data.current_page - 5 : 1;
            i <= this.pages;
            i++
        ) {
            let classButton = "";
            i === this.data.current_page
                ? (classButton = "btn-pagination bg-blue-background text-white")
                : (classButton = "btn-pagination");
            pagination.push(
                <div class={classButton} onClick={() => this.goToPage(i)}>
                    {i}
                </div>
            );
        }

        const paginationBig =
            this.pages < this.data.last_page ? (
                <div class="btn-pagination">
                    <a class="page-link">...</a>
                </div>
            ) : (
                ""
            );

        const paginationFooter = this.pagination ? (
            <div class="flex items-center justify-end mt-2 mb-2 text-xs text-gray-800">
                <div class="mr-auto font-bold">Total: {this.data.total}</div>
                <div class="btn-pagination" onClick={() => this.goToPage(1)}>
                    Primeira
                </div>
                {paginationBig}
                {pagination}
                {paginationBig}
                <div
                    class="px-3 py-2 font-bold shadow cursor-pointer bg-gray-light hover:bg-gray-base"
                    onClick={() => this.goToPage(this.data.last_page)}
                >
                    Última
                </div>
            </div>
        ) : (
            ""
        );

        let tableClass = "table table-light";
        if (this.hover) {
            tableClass += " table-hover";
        }
        if (this.striped) {
            tableClass += " table-striped";
        }

        return (
            <div class="w-full">
                {form}
                <div class="border shadow table-responsive">
                    <table class={tableClass}>
                        {thead}
                        {tbody}
                    </table>
                </div>
                {paginationFooter}
            </div>
        );
    }
}

@Component
export class Th extends Vue {
    @Prop({ default: "" })
    column;
    @Prop({ default: "" })
    name;
    @Prop({
        default: () => {}
    })
    orderBy;

    order() {
        if (this.orderBy) {
            let orderBy = Object.assign({}, this.orderBy);

            if (!orderBy.hasOwnProperty(this.column)) {
                orderBy = {};
                orderBy[this.column] = "ASC";
            } else if (orderBy[this.column] === "ASC") {
                orderBy = {};
                orderBy[this.column] = "DESC";
            } else if (orderBy[this.column] === "DESC") {
                orderBy = {};
                orderBy[this.column] = "ASC";
            }

            this.$emit("update:orderBy", orderBy);
        }
    }

    render() {
        return (
            <th onClick={this.order} style={"cursor:pointer"}>
                {this.name}
            </th>
        );
    }
}
</script>

<style scoped src="../assets/css/components/table.css"></style>
