<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import ResponsiveTable from "@/core/components/table/ResponsiveTable";
import { mask } from "@/core/services/helpers";
import { format } from "date-fns";
import { TipoBeneficiario } from "./Step01.vue";
import { reactive } from "vue";
// import { getCityCode } from "../getAddressByCep";

export default {
    components: {
        Breadcrumbs,
        ResponsiveTable
    },
    
    data: () => {
        return {
            TipoBeneficiario,
            id: null,
            screenType: null,
            title: "SPL Navegantes",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "SPL Navegantes", path: "spl-navegantes.index" },
                { name: "Editar", path: "spl-navegantes.edit" },
                { name: "Solicitação de Passe", path: "spl-navegantes.documents" }
            ],
            beneficiario: {},
            instituicao: {},
            dataExpiracao: new Date(),
            tipoLocomocao: [
                { name: "Pedestre", value: 1 },
                { name: "Ciclista", value: 2 },
                { name: "Motociclista", value: 3 }
            ],
            tipoPasse: [
                { name: "Físico", value: 1 },
                { name: "Eletronico", value: 2 }
            ],
            locomocaoSelecionada: 1,
            tpPasseSelecionado: 1,
            ispendencia: false,
            observacao: "",
            getBeneficiarioLoading: false,
            obs: null,
            obs_pendencia: null,
            situations: [
            { id: 1, label: "INICIADO", color: "#DC904A" },
                { id: 2, label: "ANALISADO", color: "#028C4A" },
                { id: 3, label: "ATIVO", color: "#028C4A" },
                { id: 4, label: "VENCIDO", color: "#DA3E3E" },
                { id: 5, label: "PENDENTE", color: "#DC904A" },
                { id: 6, label: "CANCELADO", color: "#DA3E3E" },
                { id: 7, label: "A VENCER", color: "#DA3E3E" }
            ],
            passeForm: {
                quantidade: 0,
                jornada12x36: false
            },
            hasErrors: false,
            headers: [
                "Mês de Vigência",
                "Quantidade",
                "Tipo de Beneficiário",
                "Empresa",
                "Data da Entrega",
                "Observação"
            ],
            passes: reactive([]),
            passes_total: [],
            status: "pending",
            loading: false,
            page: 1,
        }
    },

    methods: {
        format,
        mask,
        getSituation(situationId) {
            if (situationId) {
                return this.situations.find(item => item.id === situationId);
            }
        },
        getTypeLabel(type) {
            switch (type) {
                case TipoBeneficiario.ESTUDANTE.toString():
                    return "Estudante";
                case TipoBeneficiario.TRABALHADOR.toString():
                    return "Trabalhador";
                case TipoBeneficiario.ESTAGIARIO.toString():
                    return "Estagiário";
            }
        },
        goBack() {
            this.$router.push({
                name: "spl-navegantes.edit",
                id: this.id
            });
        },
        dataMap(data) {
            if (data.length > 0) {
                return data.map(item => {
                    return {
                        mesvigencia: item.nmmesvigencia,
                        quantidade: item.nupasses,
                        tipo_beneficiario: item.tplocomocao,
                        dt_entrega: item.nupasses && item.dtalteracao ? format(new Date(item.dtalteracao), "dd/MM/yyyy") : '',
                        empresa: item.nminstituicao,
                        observacao: item.deobservacao
                    };
                });
            }
            return [];
        },
        isNullOrEmpty(value) {
            return value === null || value === "" || value === 0;
        },
        isFormValid() {
            if ((!this.locomocaoSelecionada) || 
                (this.isNullOrEmpty(this.passeForm.quantidade) && this.screenType == 'passes') ||
                this.ispendencia == true && (this.obs_pendencia === "" || this.obs_pendencia === undefined ))
                this.hasErrors = true
            else this.hasErrors = false

        },
        submit() {

            if(this.screenType == 'passes'){
                this.submitPasses();
            }else {
                this.submitDocumentos();
            }
        },
        submitDocumentos() {
            this.isFormValid();
            if (this.hasErrors) {
                this.$toast.error({
                    title: "Erro",
                    message: "Verifique os campos obrigatórios!"
                });
            } else {
                this.postDocuments();
                this.handlePasses();
            }
        },
        async postDocuments(){
            const response = await this.$axios.put(
                "/api/spl-navegantes/beneficiarios/editar/documentos/"+ this.id,
                {'ispendencia' : this.ispendencia,
                    'tplocomocao' : this.locomocaoSelecionada,
                    'deobservacao': this.obs_pendencia}
            ).then(function(response) {
                return response.data;
            })
            if(response && response.mensagem){
                this.$toast.error({
                    title: "Erro",
                    message: response.mensagem
                });
            }else{
                sessionStorage.clear();
                this.$toast.success({
                    title: "OK",
                    message: "Documentos entregues com sucesso"
                });
                this.$router.push({ name: "spl-navegantes.show" });
            }
        },
        submitPasses() {
            this.isFormValid();
            if (this.hasErrors) {
                this.$toast.error({
                    title: "Erro",
                    message: "Verifique os campos obrigatórios!"
                });
            } else {
                this.postPasses();
                this.handlePasses();
            }
        },
        async postPasses(){
            try {
                await this.$axios.put(
                    "/api/spl-navegantes/beneficiarios/editar/passes/"+ this.id,
                    {'ispendencia' : this.ispendencia,
                     'nupasses'    : this.passeForm.quantidade,
                     'tplocomocao' : this.locomocaoSelecionada,
                     'tppasse'     : this.tpPasseSelecionado,
                     'deobservacao': this.obs,
                     'fljornada'   : this.passeForm.jornada12x36}
                );
                sessionStorage.clear();
                this.$toast.success({
                    title: "OK",
                    message: "Passes entregues com sucesso"
                });

                this.$router.push({ name: "spl-navegantes.show" });
            } catch (error) {
                this.$toast.error({
                    message: "Error"
                });
            }
        },
        handlePasses() {
            this.$Progress.start();
            // const body = this.passeForm;
            this.$Progress.start();
            this.$router.push(
                {
                    name: "spl-navegantes.edit",
                    params: { id: this.id }
                }
            );
            this.$Progress.finish();
        },
        async getHistoricoPasses() {

            const url = '/api/spl-navegantes/beneficiarios/historico/' + this.id
            const { data } = await this.$axios.get(
                url,
                {
                    take: "20000"
                }
            )

            this.passes_total = data;
            this.passes = this.passes_total.slice(0,6);
            this.pagination.total = this.passes_total.length

        },
        changePage(page) {
            if (page !== this.page) {
                this.passes = this.passes_total.slice(
                    (page - 1) * 6,
                        page * 6
                    );
                this.page = page;
            }
        },
    },
    mounted() {
        try {
            this.beneficiario = JSON.parse(sessionStorage.getItem('beneficiarioEdit'))
            this.instituicao = JSON.parse(sessionStorage.getItem('beneficiarioInstituicao'))
            this.obs = sessionStorage.getItem('obs')
            this.id = this.$route.params.id;
            this.screenType = this.$route.params.screenType;
            this.tpPasseSelecionado = this.beneficiario.tppasse;
            this.passeForm.jornada12x36 = this.beneficiario.fljornada;
            
            document.title = this.title + " - " + process.env.VUE_APP_TITLE;
            
            this.getHistoricoPasses();
            if(this.beneficiario.fljornada){
                this.passeForm.quantidade = 30;
            }else{
                if(this.beneficiario.tpbeneficiario == '2'){
                    this.passeForm.quantidade = 50;
                }else{
                    this.passeForm.quantidade = 40;
                }
            }
            
            this.locomocaoSelecionada = this.beneficiario.tplocomocao;
        } catch (err) {
            //console.log(err);
        }
    },
    computed: {
        pagination() {
            return {
                current_page: 1,
                first_page_url:
                    "https://scmobi.sie.sc.gov.br/api/registry/person/organizations?page=1",
                from: 1,
                last_page: 1,
                last_page_url:
                    "https://scmobi.sie.sc.gov.br/api/registry/person/organizations?page=1",
                next_page_url: null,
                path:
                    "https://scmobi.sie.sc.gov.br/api/registry/person/organizations",
                // per_page: 15,
                per_page: 6,
                prev_page_url: null,
                to: 2,
                total: this.passes_total.length
            };
        }
    }
};
</script>
<template>
    <div class="w-full w-full bg-white">
        <div class="px-2 my-4 sm:px-6">
            <div class="px-2 my-4 sm:px-6">
                <Breadcrumbs class="mb-8" :crumbs="crumbs" />
            </div>

            <div class="bg-gray-light lg:max-w-full my-4">

                <div class="lg:max-w-full my-4">
                    <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                        <span class="mt-2 text-base uppercase text-blue-base">
                            Controle de Registro
                        </span>
                    </div>
                </div>

                <template v-if="getBeneficiarioLoading">
                    <div class="px-4">
                        Carregando...
                    </div>
                </template>
                <template v-else>
                    <div class="grid gap-5 grid-cols-1 md:grid-cols-12 px-3 pb-6">
                        <div class="col-span-2" >
                            <span class="text-sm form-label text-gray-dark">
                                DT. Expiração
                            </span>
                            <div class="text-red-500">
                                
                               {{ beneficiario.controle_registro.length != 0 ? format(
                                        new Date(
                                            beneficiario.controle_registro.dtexpiracao
                                        ),
                                        "dd/MM/yyyy"
                                    ) 
                                : '-' }}
                            </div>
                        </div>

                        <div class="col-span-2">
                            <span class="text-sm form-label text-gray-dark">
                                Situação
                            </span>
                            <div  :style="{
                                color: beneficiario.controle_registro.length != 0 ? getSituation(beneficiario.controle_registro.idsituacao).color : ''
                            }
                                ">
                                {{
                                    beneficiario.controle_registro.length != 0 ? getSituation(beneficiario.controle_registro.idsituacao).label : '-'
                                }}
                            </div>
                        </div>

                        <div class="col-span-8">
                            <span class="text-sm form-label text-gray-dark">
                                Observação
                            </span>
                            <div >
                                {{    beneficiario.controle_registro.length != 0 ? obs : '-' }}
                            </div>
                        </div>

                    </div>
                </template>

                <div class="lg:max-w-full my-4">
                    <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                        <span class="mt-2 text-base uppercase text-blue-base">
                            Beneficiário
                        </span>
                    </div>
                </div>

                <div class="flex space-x-4 px-3 ">
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            CPF
                        </span>
                        <span>
                            {{beneficiario.document}}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Nome
                        </span>
                        <span>
                            {{ beneficiario.nmnome }}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Tipo
                        </span>
                        <span>
                            {{ getTypeLabel(beneficiario.tpbeneficiario) }}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Cidade
                        </span>
                        <span>
                            {{ beneficiario.numunicipio.name }}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Instituição / Empresa
                        </span>
                        <span>
                            {{ instituicao.nminstituicao}}
                        </span>
                    </div>

                </div>

                <div class="bg-white shadow-lg p-4 m-2">

                    <div class="lg:max-w-full my-4">
                        <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                            <span class="mt-2 text-base uppercase text-blue-base">
                                Informações do passe
                            </span>
                        </div>
                    </div>

                    <div class="flex space-x-4 mb-3">
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                Locomoção*
                            </span>
                        </div>
                        <div v-for="locomocao in tipoLocomocao" :key="locomocao.value" class="flex space-x-4 w-3/12">
                            <hr />
                            <span>
                                <input v-model="locomocaoSelecionada" :value="locomocao.value" :id="locomocao.name"
                                    type="radio" />
                            </span>
                            <label :for="locomocao.name" class="text-sm form-label text-gray-dark">
                                {{ locomocao.name }}
                            </label>
                        </div>
                        
                    </div>
                    <div v-if="hasErrors && !locomocaoSelecionada"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                    <template v-if="screenType == 'passes'">
                        <div class="flex space-x-4 mb-3">
                            <div class="w-3/12">
                                <span class="text-sm form-label text-gray-dark">
                                    Tipo de passe*
                                </span>
                            </div>
                            <div class="flex space-x-4 w-3/12">
                                <hr />
                                <span>
                                    <input v-model="tpPasseSelecionado" :disabled='this.tpPasseSelecionado == 2'  :value="1" 
                                                type="radio"  />
                                </span>
                                <label class="text-sm form-label text-gray-dark">
                                    Físico
                                </label>
                            </div>
                            <div class="flex space-x-4 w-3/12">
                                <hr />
                                <span>
                                    <input v-model="tpPasseSelecionado" :value="2" 
                                        type="radio" />
                                </span>
                                <label class="text-sm form-label text-gray-dark">
                                    Eletronico
                                </label>
                            </div>
                        </div>
                        <div class="flex space-x-4 mb-2 ">
                            <div class="col-span-4 flex justify-start items-center">
                                <span class="text-sm form-label text-gray-dark">
                                    Quantidade de passes*
                                </span>
                                <div class="ml-2">
                                    <input v-model="passeForm.quantidade" type="number" size="3"
                                        class="h-auto py-2 rounded form-input mb-0" :class="{
                                            'bg-red-100 border-red-400':
                                                hasErrors &&
                                                !passeForm.quantidade
                                        }
                                            " />
                                    <div v-if="hasErrors && !passeForm.quantidade"
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                        Campo obrigatório
                                    </div>
                                </div>

                            </div>

                            <div class="col-span-4 flex justify-start items-center">

                                <div class="mr-2">
                                    <input type="checkbox" @change="passeForm.quantidade = 30" v-model="passeForm.jornada12x36"
                                        class="h-auto py-2 rounded mb-0" />
                                </div>

                                <span class="text-sm form-label text-gray-dark">
                                    Jornada 12x36
                                </span>
                            </div>
                        </div>
                    </template>
                    <div class="lg:max-w-full my-4">
                        <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                            <span class="mt-2 text-base uppercase text-blue-base">
                                Observações
                            </span>
                        </div>
                    </div>
                  
                        <div class="flex items-center space-x-4 mt-2 mb-4">
                            <div class="flex space-x-2">
                                <span>
                                    <input v-model="ispendencia" :value="ispendencia ? true : false" id="pendencia"
                                        type="checkbox" />
                                </span>
                                <label for="pendencia"   class="text-sm form-label text-gray-dark">
                                    Pendência
                                </label>
                            </div>
                        </div>
                        <template v-if="screenType == 'passes'">
                            <div class="flex items-center space-x-4 mt-2 mb-4">
                                <div class="w-full">
                                    <textarea v-model="obs"  maxlength="255" class="py-2 rounded form-input" row="20"
                                        style="max-height: 200px; min-height: 200px;"
                                        placeholder="Escreva aqui sua observação"></textarea>
                                </div>
                            </div>     
                        </template>
                        <template v-else>
                            <div class="flex items-center space-x-4 mt-2 mb-4">
                                <div class="w-full">
                                    <textarea v-model="obs_pendencia" :disabled="!ispendencia" maxlength="255" class="py-2 rounded form-input" row="20"
                                        style="max-height: 200px; min-height: 200px;"
                                        placeholder="Escreva aqui sua observação"></textarea>
                                </div>
                            </div>     
                        </template>
                       
                        

                    <div
                        class="flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4 justify-between">
                        
                        <div class="flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4">
                            <button @click="goBack" type="button"
                                class="flex items-center justify-center py-2 form-submit md:w-auto"
                                style="background: #828282">
                                Voltar
                            </button>
                            <button type="button" class="flex items-center justify-center py-2 form-submit md:w-auto"
                                @click.prevent="$refs.modal.showModal()">
                                Confirmar
                            </button>
                        </div>
                        <div style="color: #1767b2">Passo 3 de 3</div>
                    </div>
                </div>

                <template v-if="screenType == 'passes' && passes.length > 0" >
                    <div class="lg:max-w-full my-4">
                        <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base bg-white shadow-lg">
                            <span class="mt-2 text-base uppercase text-blue-base">
                                Histórico de passes do beneficiário
                            </span>
                        </div>
                    </div>
                    <div class="px-2 pb-4" >
                        <ResponsiveTable :headers="headers" :data="dataMap(passes)" :pagination="pagination"
                            :disabled="loading" :status="status"
                            @changePage="page => { changePage(page) ;}">
                            <template #row="props">
                                <td :class="props.tdClass">{{ props.row.mesvigencia }}</td>
                                <td :class="props.tdClass">{{ props.row.quantidade }}</td>
                                <td :class="props.tdClass">{{ props.row.tipo_beneficiario }}</td>
                                <td :class="props.tdClass">{{ props.row.empresa }}</td>
                                <td :class="props.tdClass">{{ props.row.dt_entrega }}</td>
                                <td :class="props.tdClass">{{ props.row.observacao }}</td>
                            </template>
                        </ResponsiveTable>
                    </div>
                </template>

            </div>
        </div>
    
        <dialog ref="modal">
            <template v-if="ispendencia">
                <h3>
                    Confirmar pendência do(a) {{ beneficiario.nmnome }}?
                </h3>
            </template>
            <template v-if="screenType == 'passes'">
                <h3>
                    Confirmar a entrega de {{ passeForm.quantidade }} passes do(a)
                    {{ beneficiario.nmnome }}?
                </h3>
            </template>
        
            <template v-if="screenType == 'document' || screenType == undefined">
                <h3>
                    Confirmar a entrega de documentos do(a)
                    {{ beneficiario.nmnome }}?
                </h3>
            </template>
            <template v-if="screenType == 'passes' && this.passes_total.length > 0 && this.passes[0] != null && this.passes[0].nupasses != null">
                <h3 style="color: red;">
                    Existe uma entrega de {{ this.passes[0].nupasses }} passes.
                </h3>
            </template>
            <div
                class="mt-4 flex items-center justify-center flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4">
                <button type="button" class="flex items-center justify-center py-2 form-submit md:w-auto"
                    style="background: #828282" @click.prevent="$refs.modal.close()">
                    Cancelar
                </button>
                <button type="button" class="flex items-center justify-center py-2 form-submit md:w-auto"
                    @click.prevent="$refs.modal.close(); submit();">
                    Confirmar
                </button>
            </div>
        </dialog>

    </div>
</template>
<style scoped>
dialog {
    border: 2px solid #1767b2;
    background: white;
    outline: none;
}

dialog::backdrop {
    background: #c0c0c080;
}

h3 {
    color: #1767b2;
    font-weight: 900;
    font-size: 30px;
    text-align: center;
}
</style>
