export default [
    {
        path: "/gerenciamento-suporte",
        name: "support-manager.index",
        component: () =>
            import(/* webpackChunkName: "chunk-support" */ "./views/Index.vue"),
        meta: {
            forAuth: true,
            module: "support-manager"
        }
    },
    {
        path: "/gerenciamento-suporte/usuarios",
        name: "support-manager.users",
        component: () =>
            import(/* webpackChunkName: "chunk-support" */ "./views/Users.vue"),
        meta: {
            forAuth: true,
            module: "support-manager"
        }
    },
    {
        path: "/gerenciamento-suporte/usuario/:id",
        name: "support-manager.user.show",
        component: () =>
            import(/* webpackChunkName: "chunk-support" */ "./views/User.vue"),
        meta: {
            forAuth: true,
            module: "support-manager"
        }
    },
    {
        path: "/gerenciamento-suporte/chamado",
        name: "support-manager.ticket",
        component: () =>
            import(
                /* webpackChunkName: "chunk-support" */ "./views/Tickets.vue"
            ),
        meta: {
            forAuth: true,
            module: "support-manager"
        }
    },
    {
        path: "/gerenciamento-suporte/chamado/novo",
        name: "support-manager.ticket.create",
        component: () =>
            import(
                /* webpackChunkName: "chunk-support" */ "./views/CreateTicket.vue"
            ),
        meta: {
            forAuth: true,
            module: "support-manager"
        }
    },
    {
        path: "/gerenciamento-suporte/chamado/:id",
        name: "support-manager.ticket.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-support" */ "./views/ShowTicket.vue"
            ),
        meta: {
            forAuth: true,
            module: "support-manager"
        }
    },
    {
        path: "/gerenciamento-suporte/agentes",
        name: "support-manager.agent",
        component: () =>
            import(
                /* webpackChunkName: "chunk-support" */ "./views/Agents.vue"
            ),
        meta: {
            forAuth: true,
            module: "support-manager"
        }
    },
    {
        path: "/gerenciamento-suporte/categorias",
        name: "support-manager.category",
        component: () =>
            import(
                /* webpackChunkName: "chunk-support" */ "./views/Categories.vue"
            ),
        meta: {
            forAuth: true,
            module: "support-manager"
        }
    }
];
