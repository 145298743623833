<script>
import { Component, Prop } from "vue-property-decorator";
import IconBadge from "@/core/components/badges/IconBadge";
import Work from "../components/modal/Work";
import Comment from "../components/modal/Comment";
import Finish from "../components/modal/Finish";
import Reopen from "../components/modal/Reopen";
import Reassign from "../components/modal/Reassign";
import Delete from "../components/modal/Delete";
import TicketService from "@/domains/support/services/TicketsService";
import NewAttachment from "../components/modal/NewAttachment";

@Component({
    components: {
        NewAttachment,
        IconBadge,
        work: Work,
        comment: Comment,
        finish: Finish,
        reopen: Reopen,
        reassign: Reassign,
        delete: Delete
    }
})
export default class Ticket extends TicketService {
    @Prop()
    id;

    constructor() {
        super();
        this.reopen_ticket = null;
        this.work_ticket = null;
        this.comment_ticket = null;
        this.attachment_ticket = null;
        this.finish_ticket = null;
        this.reassign_ticket = null;
        this.delete_ticket = null;
        this.config = false;
        this.agent = false;
    }

    mounted() {
        this.$Progress.finish();
        this.$nextTick(function() {
            this.configPermission();
            this.agentAuth();
        });
    }

    configPermission() {
        this.$http.post("/api/support/ticket/config", {}).then(
            () => {
                this.config = true;
            },
            () => {
                this.config = false;
            }
        );
    }

    async deleteTicket() {
        await this.deleteSupportTicket(this.delete_ticket.id);
        this.delete_ticket = null;
        this.$router.replace({ name: "support-manager.ticket" });
    }

    agentAuth() {
        return this.$http.post("/api/support/agent/auth", {}).then(
            () => {
                this.agent = true;
            },
            () => {
                this.agent = false;
            }
        );
    }

    data() {
        return {
            loading: false,
            ticket: null
        };
    }

    close() {
        this.reopen_ticket = null;
        this.work_ticket = null;
        this.comment_ticket = null;
        this.attachment_ticket = null;
        this.finish_ticket = null;
        this.reassign_ticket = null;
        this.$emit("fetchTicket");
        this.getTicket(this.id);
    }

    beforeMount() {
        this.getTicket(this.id).then(() => {
            if (
                this.$store.state.auth.permissions &&
                this.$store.state.auth.permissions.includes("close-ticket")
            ) {
                return;
            }
            if (
                this.ticket &&
                this.ticket.user_id !== this.$store.state.auth.user.id
            ) {
                this.$toast.warning({
                    title: "",
                    message: "Você não é o autor deste chamado."
                });
                this.$router.replace({ name: "support.ticket" });
            }
        });
    }

    async getTicket(id) {
        this.loading = true;
        try {
            const response = await this.$axios.get("/api/support/ticket/" + id);
            this.loading = false;
            this.ticket = response.data;
        } catch (err) {
            this.loading = false;
        }
    }

    download(attachment) {
        this.$http
            .get("/api/support/attachment/download/" + attachment._id, {
                dataType: "binary",
                processData: false,
                responseType: "arraybuffer"
            })
            .then(
                response => {
                    let blob = new Blob([response.data], {
                        type: response.headers["Content-Type"]
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = attachment.filename;
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                },
                response => {
                    this.errors = response.data;

                    if (response.data.error) {
                        this.$toast.error({
                            title: "",
                            message: response.data.error,
                            icon: "fa fa-exclamation"
                        });
                    }
                }
            );
    }
}
</script>

<style scoped>
.editor >>> a {
    text-decoration: underline;
    color: #0c599f;
}
</style>

<template>
    <div v-if="!loading">
        <div v-if="ticket">
            <h1
                class="py-1 mb-1 uppercase border-b-2 text-blue-base border-blue-base"
            >
                Chamado
            </h1>
            <div class="p-4 mb-1 bg-white rounded-sm shadow">
                <div class="flex items-center justify-end">
                    <img
                        v-on:click="download(ticket.attachments[0])"
                        class="w-4 mr-1 cursor-pointer"
                        src="@/core/assets/images/paperclip.svg"
                        alt="SCmobi"
                    />
                    <div class="mr-2 text-xs text-gray-darker">
                        {{ ticket.created }}
                    </div>
                </div>
                <h1 class="py-1 mb-1 font-medium uppercase text-gray-darker">
                    {{ ticket.subject }}
                </h1>
                <div
                    v-html="ticket.content"
                    class="lg:max-w-3xl xl:max-w-4xl py-2 text-sm text-gray-darker break-words editor"
                />
                <div class="flex ">
                    <div class="mb-1 mr-1 text-xs font-bold text-gray-dark">
                        Autor:
                    </div>
                    <div class="mb-1 mr-2 text-xs text-gray-dark ">
                        {{ ticket.user.name }}
                    </div>
                </div>
                <div class="flex">
                    <div class="mb-1 mr-1 text-xs font-bold text-gray-dark ">
                        CPF:
                    </div>
                    <div class="mb-1 mr-2 text-xs text-gray-dark ">
                        {{ ticket.user.document }}
                    </div>
                </div>
                <div v-if="ticket.organization">
                    <div class="flex">
                        <div class="mb-1 mr-1 text-xs font-bold text-gray-dark">
                            Organização:
                        </div>
                        <div class="mb-1 mr-2 text-xs text-gray-dark ">
                            {{ ticket.organization.id }} -
                            {{ ticket.organization.business_name }}
                        </div>
                    </div>
                    <div class="flex mb-4 border-b-2 border-gray-light">
                        <div class="mb-1 mr-1 text-xs font-bold text-gray-dark">
                            CNPJ:
                        </div>
                        <div class="mb-2 mr-2 text-xs text-gray-dark">
                            {{ ticket.organization.document_formatted }}
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center w-100">
                    <button
                        class="flex mb-2 form-submit md:w-auto"
                        v-on:click="attachment_ticket = true"
                    >
                        <img
                            class="w-4 mr-1 cursor-pointer"
                            src="@/core/assets/images/paperclip_white.png"
                            alt="SCmobi"
                        />
                        anexar arquivo
                    </button>
                </div>
                <div v-if="ticket.attachments.length" class="flex flex-wrap">
                    <div
                        v-for="attachment in ticket.attachments"
                        v-bind:key="attachment.id"
                        class="w-64 mb-2 mr-2 border rounded-sm border-gray-base"
                    >
                        <div class="px-1 py-2 border-t-4 border-green-base">
                            <div class="flex items-center py-1">
                                <div
                                    class="mx-1 text-xs font-bold uppercase text-gray-dark"
                                >
                                    {{ attachment.metadata.description }}
                                </div>
                            </div>
                            <div class="flex pl-2">
                                <div class="text-xs text-gray-dark">Data:</div>
                                <div
                                    class="mx-2 text-xs font-medium text-gray-dark"
                                >
                                    {{ attachment.created }}
                                </div>
                            </div>
                            <div class="flex pl-2">
                                <div class="text-xs text-gray-dark">
                                    Tamanho:
                                </div>
                                <div
                                    class="mx-2 text-xs font-medium text-gray-dark"
                                >
                                    {{ attachment.readableLength }}
                                </div>
                            </div>
                        </div>

                        <div class="p-2 bg-gray-base">
                            <button
                                class="px-2 py-1 shadow form-green"
                                v-on:click="download(attachment)"
                            >
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mx-2 mt-4 mb-2">
                <div class="text-xs text-gray-darker">Situação:</div>
                <div class="ml-1 mr-4 text-xs text-blue-base">
                    <IconBadge :text="ticket.status.name" />
                </div>
                <div class="text-xs text-gray-darker">Categoria:</div>
                <div class="ml-1 mr-4 text-xs text-blue-base">
                    {{ ticket.category.name }}
                </div>
                <div class="text-xs text-gray-darker">Prioridade:</div>
                <div class="ml-1 mr-4 text-xs text-blue-base">
                    {{ ticket.priority.name }}
                </div>
                <div class="text-xs text-gray-darker">Agente:</div>
                <div class="ml-1 text-xs text-blue-base">
                    {{ ticket.agent ? ticket.agent.name : "Não atribuído" }}
                </div>
            </div>
            <div class="mx-2 mt-6 sm:flex ">
                <span v-if="agent" class="flex">
                    <button
                        v-on:click="reopen_ticket = ticket"
                        v-if="ticket.completed_at != null"
                        class="w-full px-2 py-2 mb-2 sm:w-auto form-green sm:py-1 sm:mr-1"
                    >
                        reabrir
                    </button>
                    <button
                        v-on:click="work_ticket = ticket"
                        v-if="
                            ticket.agent_id == null &&
                                ticket.completed_at == null
                        "
                        class="w-full px-2 py-2 mb-2 sm:w-auto form-green sm:py-1 sm:mr-1"
                    >
                        trabalhar
                    </button>
                    <button
                        v-on:click="finish_ticket = ticket"
                        v-if="ticket.completed_at == null"
                        class="w-full px-2 py-2 mb-2 sm:w-auto form-green sm:py-1 sm:mr-1"
                    >
                        encerrar
                    </button>
                </span>
                <span v-if="config" class="flex">
                    <button
                        v-on:click="reassign_ticket = ticket"
                        v-if="ticket.completed_at == null"
                        class="w-full px-2 py-2 mb-2 mr-1 sm:w-auto form-gray sm:py-1 sm:mr-1"
                    >
                        reatribuir
                    </button>
                    <button
                        v-on:click="delete_ticket = ticket"
                        class="w-full px-2 py-2 mb-2 mr-1 sm:w-auto form-red sm:py-1 sm:mr-1"
                    >
                        excluir
                    </button>
                </span>
            </div>
            <div
                class="flex py-1 mt-20 mb-1 border-b border-gray-dark justify-content-between"
            >
                <h1 class="uppercase text-gray-dark ">
                    Comentários
                </h1>
                <div>
                    <button
                        class="form-submit md:w-auto"
                        v-on:click="comment_ticket = true"
                    >
                        novo comentário
                    </button>
                </div>
            </div>
            <div
                v-for="comment in ticket.comments"
                v-bind:key="comment.id"
                class="p-4 mb-1 bg-white rounded-sm shadow"
            >
                <h2 class="text-xs font-bold uppercase text-gray-dark">
                    {{ comment.user.name }}:
                </h2>
                <div class="mb-4 text-xs text-gray-dark">
                    Data:
                    {{ new Date(comment.created_at).toLocaleString() }}
                </div>
                <div
                    v-html="comment.content"
                    class="lg:max-w-3xl xl:max-w-4xl text-sm rounded-sm text-gray-darker break-words editor"
                />
            </div>
        </div>
        <div v-else>
            <t-alert show :dismissible="false" variant="warning">
                Chamado não encontrado.
            </t-alert>
        </div>

        <reopen v-if="reopen_ticket" :ticket="ticket" @close="close"></reopen>
        <work v-if="work_ticket" :ticket="ticket" @close="close"></work>
        <finish
            v-if="finish_ticket"
            :ticket="finish_ticket"
            @close="close"
        ></finish>
        <reassign
            v-if="reassign_ticket"
            :reassign_ticket="reassign_ticket"
            @close="close"
        ></reassign>
        <delete
            v-if="delete_ticket"
            message="Deseja realmente apagar este chamado?"
            @ok="deleteTicket()"
            @close="delete_ticket = null"
        ></delete>
        <comment
            v-if="comment_ticket"
            :ticket="ticket"
            @close="close"
        ></comment>
        <new-attachment
            v-if="attachment_ticket"
            :ticket="ticket"
            @close="close"
        ></new-attachment>
    </div>
</template>
