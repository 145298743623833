<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import { dateFormat } from "@/core/services/helpers";
import addMonths from "date-fns/addMonths";
import { TipoBeneficiario } from "./Step01.vue";
import { mask } from "@/core/services/helpers";
import { format } from "date-fns";
export default {
    components: {
        Breadcrumbs
    },
    data: () => {
        return {
            title: "SPL Navegantes",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "SPL Navegantes", path: "spl-navegantes.index" },
                { name: "Cadastro", path: "spl-navegantes.show" }
            ],
            beneficiario: {
                nmnome: "-",
                document: "-",
                instituicao: "-",
                numunicipio: "-"
            },
            beneficiarioEstudante: {},
            beneficiarioTrabalhador: {},
            beneficiarioEstagiario: {},
            dataExpiracao: new Date(),
            tipoLocomocao: [
                { name: "Pedestre", value: 1 },
                { name: "Ciclista", value: 2 },
                { name: "Motociclista", value: 3 }
            ],
            locomocaoSelecionada: 1,
            ispendencia: false,
            observacao: "",
            hasErrors: false
        };
    },
    mounted() {
        const beneficiario = JSON.parse(
            sessionStorage.getItem("beneficiarioStore")
        );
        if (beneficiario) {
            this.beneficiario = beneficiario;
        }
        this.getStoredBeneficiarioByType();
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },

    methods: {
        dateFormat,
        addMonths,
        mask,
        getStoredBeneficiarioByType() {
            switch (this.beneficiario.tpbeneficiario) {
                case TipoBeneficiario.ESTUDANTE:
                    if (sessionStorage.getItem("beneficiarioEstudanteStore")) {
                        this.beneficiarioEstudante = JSON.parse(
                            sessionStorage.getItem("beneficiarioEstudanteStore")
                        );
                        this.beneficiario.instituicao = this.beneficiarioEstudante.nminstituicao;
                    }
                    break;
                case TipoBeneficiario.TRABALHADOR:
                    if (
                        sessionStorage.getItem("beneficiarioTrabalhadorStore")
                    ) {
                        this.beneficiarioTrabalhador = JSON.parse(
                            sessionStorage.getItem(
                                "beneficiarioTrabalhadorStore"
                            )
                        );
                        this.beneficiario.instituicao = this.beneficiarioTrabalhador.nminstituicao;
                    }
                    break;
                case TipoBeneficiario.ESTAGIARIO:
                    if (sessionStorage.getItem("beneficiarioEstagiarioStore")) {
                        this.beneficiarioEstagiario = JSON.parse(
                            sessionStorage.getItem(
                                "beneficiarioEstagiarioStore"
                            )
                        );
                        this.beneficiario.instituicao = this.beneficiarioEstagiario.nminstituicao;
                    }
                    break;
            }
        },
        isFormValid() {
            if ( this.ispendencia == true && (this.observacao === "" || this.observacao === undefined )) {
                    this.hasErrors = true;
                } else {
                    this.hasErrors = false;
                }
        },
        async onConfirmSubmit() {
            this.isFormValid();
            
            if (this.hasErrors) {
                this.$toast.error({
                    title: "Erro",
                    message: "O campo observação deve ser preenchido quando há pendencia(s)."
                });
            }else {
                this.$Progress.start();
                const body = await this.getCreateBody();
                try {
                    await this.$axios.post(
                        "/api/spl-navegantes/beneficiarios/cadastrar",
                        body
                    );
                    sessionStorage.clear();
                    this.$toast.success({
                        title: "OK",
                        message: "Dados cadastrados com sucesso"
                    });

                    this.$router.push({ name: "spl-navegantes.show" });
                } catch (error) {
                    this.$toast.error({
                        message: "Error"
                    });
                }
            }
        },
        async getCreateBody() {
            switch (this.beneficiario.tpbeneficiario) {
                case TipoBeneficiario.ESTUDANTE:
                    return {
                        document: this.beneficiario.document,
                        nmnome: this.beneficiario.nmnome,
                        dtnascimento: this.formatDate(
                            this.beneficiario.dtnascimento
                        ),
                        tpbeneficiario: Number(
                            this.beneficiario.tpbeneficiario
                        ),
                        cep: this.beneficiario.cep,
                        street: this.beneficiario.street,
                        number: this.beneficiario.number,
                        district: this.beneficiario.district,
                        complement: this.beneficiario.complement,
                        municipio: this.beneficiario.municipio,
                        emails: this.beneficiario.contatos.email.map(email => ({
                            contact: email
                        })),
                        phones: this.beneficiario.contatos.telefone.map(
                            telefone => ({ contact: telefone })
                        ),
                        filiacao: this.beneficiario.filiacao.map(filiacao => ({
                            ...filiacao,
                            municipio: filiacao.municipio,
                            emails: this.beneficiario.contato_filiacao.email.map(
                                email => ({
                                    contact: email
                                })
                            ),
                            phones: this.beneficiario.contato_filiacao.telefone.map(
                                telefone => ({ contact: telefone })
                            )
                        })),
                        dtcadastro: format(new Date(), "yyyy-MM-dd"),
                        dtalteracao: format(new Date(), "yyyy-MM-dd"),
                        instituicao: {
                            nmnome: this.beneficiarioEstudante.nminstituicao,
                            document: this.beneficiarioEstudante.document,
                            tpinstituicao: 3,
                            ...this.beneficiarioEstudante.endereco,
                            municipio: this.beneficiarioEstudante.endereco
                                .municipio,
                            emails: this.beneficiarioEstudante.contatos.email.map(
                                email => ({
                                    contact: email
                                })
                            ),
                            phones: this.beneficiarioEstudante.contatos.telefone.map(
                                telefone => ({ contact: telefone })
                            )
                        },
                        ispendencia: this.ispendencia,
                        tplocomocao: this.locomocaoSelecionada,
                        deobservacao: this.observacao,
                        tpcurso: this.beneficiarioEstudante.tpcurso,
                        nuseriecurso: this.beneficiarioEstudante.nuseriecurso,
                        idcurso: this.beneficiarioEstudante.idcurso != undefined ?? this.beneficiarioEstudante.idcurso.idcurso
                    };
                case TipoBeneficiario.ESTAGIARIO:
                    return {
                        document: this.beneficiario.document,
                        nmnome: this.beneficiario.nmnome,
                        dtnascimento: this.formatDate(
                            this.beneficiario.dtnascimento
                        ),
                        tpbeneficiario: Number(
                            this.beneficiario.tpbeneficiario
                        ),
                        cep: this.beneficiario.cep,
                        street: this.beneficiario.street,
                        number: this.beneficiario.number,
                        district: this.beneficiario.district,
                        complement: this.beneficiario.complement,
                        municipio: this.beneficiario.municipio,
                        emails: this.beneficiario.contatos.email.map(email => ({
                            contact: email
                        })),
                        phones: this.beneficiario.contatos.telefone.map(
                            telefone => ({ contact: telefone })
                        ),
                        filiacao: this.beneficiario.filiacao.map(filiacao => ({
                            ...filiacao,
                            municipio: filiacao.municipio,
                            emails: this.beneficiario.contato_filiacao.email.map(
                                email => ({
                                    contact: email
                                })
                            ),
                            phones: this.beneficiario.contato_filiacao.telefone.map(
                                telefone => ({ contact: telefone })
                            )
                        })),
                        dtcadastro: format(new Date(), "yyyy-MM-dd"),
                        dtalteracao: format(new Date(), "yyyy-MM-dd"),

                        instituicao: {
                            nmnome: this.beneficiarioEstagiario.nminstituicao,
                            document: this.beneficiarioEstagiario.document,
                            tpinstituicao: 3,
                            ...this.beneficiarioEstagiario.endereco,
                            municipio: this.beneficiarioEstagiario.endereco
                                .municipio,
                            emails: this.beneficiarioEstagiario.contatos.email.map(
                                email => ({
                                    contact: email
                                })
                            ),
                            phones: this.beneficiarioEstagiario.contatos.telefone.map(
                                telefone => ({ contact: telefone })
                            )
                        },
                        ispendencia: this.ispendencia,
                        tplocomocao: this.locomocaoSelecionada,
                        deobservacao: this.observacao,
                        dtadmissao: this.formatDate(
                            this.beneficiarioEstagiario.dtadmissao
                        ),
                        dtencerramento: this.formatDate(
                            this.beneficiarioEstagiario.dtencerramento
                        )
                    };

                // return {
                //     document: this.beneficiario.document,
                //     nmnome: this.beneficiario.nmnome,
                //     dtnascimento: this.formatDate(
                //         this.beneficiario.dtnascimento
                //     ),
                //     tpbeneficiario: 2,
                //     cep: this.beneficiario.cep,
                //     street: this.beneficiario.street,
                //     number: this.beneficiario.number,
                //     district: this.beneficiario.district,
                //     complement: this.beneficiario.complement,
                //     numunicipio: await getCityCode(this.beneficiario.numunicipio),
                //     contatos: {
                //         email: this.beneficiario.contatos.email.map(
                //             email => email
                //         ),
                //         telefone: this.beneficiario.contatos.telefone.map(
                //             telefone => telefone
                //         ),
                //     },
                //     filiacao: this.beneficiario.filiacao.map((filiacao) => ({
                //         ...filiacao,
                //         numunicipio: getCityCode(filiacao.numunicipio),
                //     })),
                //     contato_filiacao: {
                //         email: this.beneficiario.contato_filiacao.email.map(email => email),
                //         telefone: this.beneficiario.contato_filiacao.telefone.map(telefone => telefone)
                //     },
                //     dtcadastro: format(new Date(), "yyyy-MM-dd"),
                //     dtalteracao: format(new Date(), "yyyy-MM-dd"),
                //     instituicao: {
                //         nmnome: this.beneficiarioEstagiario.nminstituicao,
                //         document: this.beneficiarioEstagiario.document,
                //         tpinstituicao: 3,
                //         ...this.beneficiarioEstagiario.endereco,
                //         numunicipio: getCityCode(
                //             this.beneficiarioEstagiario.endereco
                //                 .numunicipio
                //         )
                //     },
                //     ispendencia: this.ispendencia,
                //     tplocomocao: this.locomocaoSelecionada,
                //     deobservacao: this.observacao,
                //     dtadmissao: this.formatDate(
                //         this.beneficiarioEstagiario.dtadmissao
                //     ),
                //     dtencerramento: this.formatDate(
                //         this.beneficiarioEstagiario.dtadmissao
                //     )
                // };

                case TipoBeneficiario.TRABALHADOR:
                    return {
                        document: this.beneficiario.document,
                        nmnome: this.beneficiario.nmnome,
                        dtnascimento: this.formatDate(
                            this.beneficiario.dtnascimento
                        ),
                        tpbeneficiario: Number(
                            this.beneficiario.tpbeneficiario
                        ),
                        cep: this.beneficiario.cep,
                        street: this.beneficiario.street,
                        number: this.beneficiario.number,
                        district: this.beneficiario.district,
                        complement: this.beneficiario.complement,
                        municipio: this.beneficiario.municipio,
                        emails: this.beneficiario.contatos.email.map(email => ({
                            contact: email
                        })),
                        phones: this.beneficiario.contatos.telefone.map(
                            telefone => ({ contact: telefone })
                        ),
                        filiacao: this.beneficiario.filiacao.map(filiacao => ({
                            ...filiacao,
                            numunicipio: filiacao.numunicipio,
                            emails: this.beneficiario.contato_filiacao.email.map(
                                email => ({
                                    contact: email
                                })
                            ),
                            phones: this.beneficiario.contato_filiacao.telefone.map(
                                telefone => ({ contact: telefone })
                            )
                        })),
                        dtcadastro: format(new Date(), "yyyy-MM-dd"),
                        dtalteracao: format(new Date(), "yyyy-MM-dd"),

                        instituicao: {
                            nmnome: this.beneficiarioTrabalhador.nminstituicao,
                            document: this.beneficiarioTrabalhador.document,
                            tpinstituicao: 3,
                            ...this.beneficiarioTrabalhador.endereco,
                            municipio: this.beneficiarioTrabalhador.endereco
                                .municipio,
                            emails: this.beneficiarioTrabalhador.contatos.email.map(
                                email => ({
                                    contact: email
                                })
                            ),
                            phones: this.beneficiarioTrabalhador.contatos.telefone.map(
                                telefone => ({ contact: telefone })
                            )
                        },
                        ispendencia: this.ispendencia,
                        tplocomocao: this.locomocaoSelecionada,
                        deobservacao: this.observacao,
                        profissao: this.beneficiarioTrabalhador.idprofissao,
                        vlsalario: this.beneficiarioTrabalhador.vlsalario,
                        dtadmissao: this.formatDate(
                            this.beneficiarioTrabalhador.dtadmissao
                        )
                    };
                // return {
                //     document: this.beneficiario.document,
                //     nmnome: this.beneficiario.nmnome,
                //     dtnascimento: this.formatDate(
                //         this.beneficiario.dtnascimento
                //     ),
                //     tpbeneficiario: Number(3),
                //     cep: this.beneficiario.cep,
                //     street: this.beneficiario.street,
                //     number: this.beneficiario.number,
                //     district: this.beneficiario.district,
                //     complement: this.beneficiario.complement,
                //     numunicipio: getCityCode(this.beneficiario.numunicipio),
                //     contatos: {
                //         email: this.beneficiario.contatos.email.map(email => email),
                //         telefone: this.beneficiario.contatos.telefone.map(telefone => telefone),
                //     },
                //     filiacao: this.beneficiario.filiacao.map((filiacao) => ({
                //         ...filiacao,
                //         numunicipio: getCityCode(filiacao.numunicipio),
                //     })),
                //     contato_filiacao: {
                //         email: this.beneficiario.contato_filiacao.email.map(email => email),
                //         telefone: this.beneficiario.contato_filiacao.telefone.map(telefone => telefone)
                //     },
                //     dtcadastro: format(new Date(), "yyyy-MM-dd"),
                //     dtalteracao: format(new Date(), "yyyy-MM-dd"),
                //     instituicao: {
                //         nmnome: this.beneficiarioTrabalhador.nminstituicao,
                //         document: this.beneficiarioTrabalhador.document,
                //         tpinstituicao: 3,
                //         ...this.beneficiarioTrabalhador.endereco,
                //         numunicipio: getCityCode(
                //             this.beneficiarioTrabalhador.endereco
                //                 .numunicipio
                //         )
                //     },
                //     ispendencia: this.ispendencia,
                //     tplocomocao: this.locomocaoSelecionada,
                //     deobservacao: this.observacao,
                //     idprofissao: "512115",
                //     vlsalario: this.beneficiarioTrabalhador.vlsalario,
                //     dtadmissao: this.formatDate(
                //         this.beneficiarioTrabalhador.dtadmissao
                //     )
                // };
            }
        },
        formatDate(date) {
            const [day, month, year] = date.split("/");
            return `${year}-${month}-${day}`;
        },
        getTypeLabel(type) {
            switch (type) {
                case TipoBeneficiario.ESTUDANTE:
                    return "Estudante";
                case TipoBeneficiario.TRABALHADOR:
                    return "Trabalhador";
                case TipoBeneficiario.ESTAGIARIO:
                    return "Estagiário";
            }
        },
        goBack() {
            this.$router.push({ name: "spl-navegantes.step02" });
        }
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-6" :crumbs="crumbs" />

            <div class=" lg:max-w-full my-4 lg:max-w-full my-4 px-2 ">
                <div
                    class="flex justify-between w-full mb-4 border-b-2 border-blue-base"
                >
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Controle de Registro
                    </span>
                </div>

                <div class="flex space-x-4 px-3 ">
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Data Expiração
                        </span>
                        <span>
                            {{ dateFormat(addMonths(dataExpiracao, 6)) }}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Situação
                        </span>
                        <span>
                            Cadastro iniciado
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Observação
                        </span>
                        <span>
                            -
                        </span>
                    </div>
                </div>
                <div
                    class="flex justify-between w-full mb-4 border-b-2 border-blue-base"
                >
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Beneficiário
                    </span>
                </div>

                <div class="flex space-x-4 px-3 ">
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            CPF
                        </span>
                        <span>
                            {{ mask(beneficiario.document, "###.###.###-##") }}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Nome
                        </span>
                        <span>
                            {{ beneficiario.nmnome }}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Tipo
                        </span>
                        <span>
                            {{ getTypeLabel(beneficiario.tpbeneficiario) }}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Cidade
                        </span>
                        <span>
                            {{ beneficiario.numunicipio }}
                        </span>
                    </div>
                    <div class="flex-1">
                        <span class="text-sm form-label text-gray-dark">
                            Instituição / Empresa
                        </span>
                        <span>
                            {{ beneficiario.instituicao }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="bg-white shadow-lg p-4 lg:max-w-full my-4 px-2 sm:px-6">
                <div
                    class="flex justify-between w-full mb-8 border-b-2 border-blue-base"
                >
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Informações do Passe
                    </span>
                </div>

                <div class="flex space-x-4 mb-2">
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            Locomoção
                        </span>
                    </div>
                    <div
                        v-for="locomocao in tipoLocomocao"
                        :key="locomocao.value"
                        class="flex space-x-4 w-3/12"
                    >
                        <hr />
                        <span>
                            <input
                                v-model="locomocaoSelecionada"
                                :value="locomocao.value"
                                :id="locomocao.name"
                                type="radio"
                            />
                        </span>
                        <label
                            :for="locomocao.name"
                            class="text-sm form-label text-gray-dark"
                        >
                            {{ locomocao.name }}
                        </label>
                    </div>
                </div>

                <div
                    class="flex justify-between w-full mb-8 border-b-2 border-blue-base"
                >
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Observações
                    </span>
                </div>

                <div class="flex items-center space-x-4 mt-2 mb-4">
                    <div class="flex space-x-2">
                        <span>
                            <input
                                v-model="ispendencia"
                                :value="ispendencia ? true : false"
                                id="pendencia"
                                type="checkbox"
                            />
                        </span>
                        <label
                            for="pendencia"
                            class="text-sm form-label text-gray-dark"
                        >
                            Pendência
                        </label>
                    </div>
                </div>

                <div class="flex items-center space-x-4 mt-2 mb-4">
                    <div class="w-full">
                        <textarea
                            v-model="observacao"
                            maxlength="255"
                            class="h-auto py-2 rounded form-input"
                            placeholder="Escreva aqui sua observação"
                        >
                        </textarea>
                    </div>
                    <div v-if="hasErrors && !ispendencia && observacao"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                </div>

                <div
                    class="flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4 justify-between"
                >
                    <div
                        class="flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4"
                    >
                        <button
                            @click="goBack"
                            type="button"
                            class="flex items-center justify-center py-2 form-submit md:w-auto"
                            style="background: #828282"
                        >
                            Voltar
                        </button>
                        <button
                            type="button"
                            class="flex items-center justify-center py-2 form-submit md:w-auto"
                            @click.prevent="$refs.modal.showModal()"
                        >
                            Confirmar
                        </button>
                    </div>
                    <div style="color: #1767b2">Passo 3 de 3</div>
                </div>
            </div>
        </div>

        <dialog ref="modal">
            <h3>
                Confirmar a entrega de documentos do(a)
                {{ beneficiario.nmnome }}?
            </h3>
            <div
                class="mt-4 flex items-center justify-center flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4"
            >
                <button
                    type="button"
                    class="flex items-center justify-center py-2 form-submit md:w-auto"
                    style="background: #828282"
                    @click.prevent="$refs.modal.close()"
                >
                    Cancelar
                </button>
                <button
                    type="button"
                    class="flex items-center justify-center py-2 form-submit md:w-auto"
                    @click.prevent="
                        $refs.modal.close();
                        onConfirmSubmit();
                    "
                >
                    Confirmar
                </button>
            </div>
        </dialog>
    </div>
</template>
<style scoped>
dialog {
    border: 2px solid #1767b2;
    background: white;
    outline: none;
}

dialog::backdrop {
    background: #c0c0c080;
}

h3 {
    color: #1767b2;
    font-weight: 900;
    font-size: 30px;
    text-align: center;
}
</style>
