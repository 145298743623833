<template>
    <div class="mr-3">
        <img
            v-if="hasImage"
            class="w-20 h-20 rounded-full object-cover object-center"
            :src="image"
            :alt="initials"
        />
        <span
            v-if="!hasImage"
            class="flex justify-center items-center w-20 h-20 rounded-full font-medium text-center text-4xl text-white bg-gradient-green border-blue-base border-2"
            >{{ initials }}</span
        >
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, default: "##" },
        image: { type: String, default: "" }
    },
    computed: {
        initials() {
            let words = this.name.split(/[\s-]+/);
            let initials = "";
            for (let i = 0; i < words.length; i++) {
                initials += words[i].substr(0, 1).toUpperCase();
            }
            if (initials.length > 2) {
                initials = initials.substr(0, 2);
            }
            if (initials == "CU") {
                return "C";
            }
            return initials;
        },
        hasImage() {
            return this.image !== "";
        }
    }
};
</script>
