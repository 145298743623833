<template>
    <Modal width="600" borderLeft="4px solid #40A4f4" @close="$emit('close')">
        <div slot="header">
            <h4>Trabalhar no Chamado</h4>
        </div>
        <div slot="body">
            <form v-on:submit.prevent="onFormSubmit()">
                <div class="form-group">
                    <label class="control-label">Comentário:</label>
                    <textarea
                        v-model="content"
                        class="form-control"
                        rows="4"
                        cols="65"
                        placeholder="Adicione um comentário"
                    ></textarea>
                    <div v-if="errors && errors.content">
                        <span
                            v-for="error in errors.content"
                            v-bind:key="error"
                            class="text-xs italic text-red-base"
                            >{{ error }}</span
                        >
                    </div>
                </div>
            </form>
        </div>
        <div slot="footer">
            <div class="d-flex justify-content-around">
                <button
                    v-on:click="onFormSubmit"
                    class="btn btn-primary disable-onload"
                    type="submit"
                >
                    Confirmar
                </button>
                <button
                    class="btn btn-outline-secondary close-modal"
                    @click="$emit('close')"
                >
                    Cancelar
                </button>
            </div>
        </div>
    </Modal>
</template>
<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/core/components/Modal";

@Component({
    components: {
        Modal
    }
})
export default class Work extends Vue {
    @Prop()
    ticket;

    constructor() {
        super();
        this.errors = null;
        this.content = "";
    }

    async onFormSubmit() {
        this.$Progress.start();
        const data = {
            content: this.content
        };
        try {
            const response = await this.$axios.patch(
                "/api/support/ticket/work/" + this.ticket.id,
                data
            );
            this.$Progress.finish();
            this.$toast.success({
                title: "OK",
                message: response.data.success
            });
            this.$emit("close");
        } catch (e) {
            this.$Progress.finish();
            if (e.response.status === 422) {
                this.errors = e.response.data.errors;
            } else {
                this.$toast.error({
                    title: "",
                    message: e.response.data.error,
                    icon: "fa fa-exclamation"
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped></style>
