import Service from "./Service";

export default class SecurityService extends Service {
    constructor() {
        super();
        this.routerSubordinate = "/api/security/manager/subordinate";
        this.routerManager = "/api/security/link/manager";
        this.routerLink = "/api/security/link/solicitation";
        this.routerSubMenu = "/api/security/dashboard/submenu";
        this.result = {};
        this.code = "";
    }

    async postConfirmSubordinate(data, type = "subordinate") {
        let route = this.routerSubordinate;
        if (type === "manager") route = this.routerManager;
        if (type === "solicitation") route = this.routerLink;

        let info = await this.request(route, "post", data);

        this.$Progress.finish();
        let body = info.response.data;

        if (info.status === "success") {
            this.$toast.success({
                title: "OK",
                message: body.success
            });
            return body.success;
        } else {
            this.$toast.error({
                title: "",
                message: body.error ? body.error : body.document,
                icon: "fa fa-exclamation"
            });
            return false;
        }
    }

    async postSubmenu(menu) {
        let info = await this.request(`${this.routerSubMenu}/${menu}`, "post");

        this.$Progress.finish();
        let body = info.response.data;

        if (info.status === "success") {
            let menus = body;
            if (menus.length === 0) {
                this.$router.replace({ name: "index" });
                return false;
            }
            return menus;
        } else {
            this.$router.replace({ name: "index" });
            return false;
        }
    }
}
