<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import Icon from "@/core/components/Icon";
import Select from "@/core/components/Select";
import TicketsList from "@/domains/support/partials/TicketsList";

export default {
    components: {
        Breadcrumbs,
        Icon,
        Select,
        TicketsList
    },
    data: () => {
        return {
            title: "Chamados",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Suporte", path: "support.index" },
                { name: "Chamados", path: "support.ticket" }
            ],
            search: "",
            closed: 0
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-6" :crumbs="crumbs" />
            <div class="flex flex-wrap justify-between ">
                <div class="w-full lg:w-auto lg:mb-0">
                    <router-link
                        tag="button"
                        class="px-2 py-2 mb-2 form-new-blue sm:py-1"
                        :to="{ name: 'ticket.create' }"
                    >
                        <div class="flex items-center justify-center">
                            <Icon icon="plus" class="h-4 mr-1 fill-current" />
                            Novo chamado
                        </div>
                    </router-link>
                </div>
                <div
                    class="flex flex-col justify-end flex-grow text-xs sm:flex-row"
                >
                    <div class="w-32 mb-3 sm:mb-0">
                        <Select
                            id="status"
                            class="w-full"
                            selectClass="form-select filter-select"
                            :options="[
                                { id: 0, name: 'Abertos' },
                                { id: 1, name: 'Resolvidos' }
                            ]"
                            :selected.sync="closed"
                        />
                    </div>
                    <form
                        @submit.prevent="$refs.table.getTickets"
                        class="flex w-full mb-2 sm:w-auto md:mb-0 sm:mx-1"
                    >
                        <div class="relative inline-block w-full">
                            <button
                                type="submit"
                                class="absolute right-0 flex items-center hidden p-2 border-b rounded-r-sm md:block border-blue-light bg-blue-background active:bg-image-none active:bg-blue-darkest"
                            >
                                <Icon
                                    icon="search"
                                    class="h-4 text-white fill-current"
                                />
                            </button>
                            <input
                                v-model="search"
                                class="pl-4 filter-search"
                                type="text"
                            />
                        </div>
                        <button
                            class="flex items-center justify-center w-32 ml-1 md:hidden form-submit"
                            type="submit"
                        >
                            Pesquisar
                        </button>
                    </form>
                </div>
            </div>
            <div>
                <TicketsList ref="table" :closed="closed" :search="search" />
            </div>
        </div>
    </div>
</template>
