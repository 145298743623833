<script>
import ActionsDropdown from "@/core/components/ActionsDropdown";
import ResponsiveTable from "@/core/components/table/ResponsiveTable";
import IconBadge from "@/core/components/badges/IconBadge";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    components: {
        ActionsDropdown,
        ResponsiveTable,
        IconBadge,
        FontAwesomeIcon
    },
    props: {
        closed: {
            default: 0
        },
        search: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            faEye,
            status: "pending",
            loading: false,
            headers: [
                "Número",
                "Assunto",
                "Situação",
                "Última atualização",
                ""
            ],
            page: 1,
            pagination: {},
            tickets: []
        };
    },
    watch: {
        closed: function() {
            this.page = 1;
            this.getTickets();
        }
    },
    mounted() {
        this.getTickets();
    },
    methods: {
        getTickets() {
            this.$Progress.start();
            this.loading = true;
            this.$axios
                .post("/api/support/ticket/search" + "?page=" + this.page, {
                    search: this.search,
                    closed: this.closed,
                    orderBy: {
                        id: "DESC"
                    }
                })
                .then(resp => {
                    this.tickets = resp.data.data;
                    this.pagination = resp.data;
                    delete this.pagination.data;
                    this.loading = false;
                    this.status = "finished";
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.tickets = [];
                    this.loading = false;
                    this.status = "rejected";
                    this.$Progress.finish();
                });
        },
        changePage(page) {
            if (page !== this.page) {
                this.page = page;
                this.getTickets();
            }
        },
        dataMap(data) {
            if (data.length > 0) {
                return data.map(item => {
                    return {
                        id: item.id,
                        subject: item.subject,
                        status: item.status,
                        last_update: item.last_update
                    };
                });
            }
            return [];
        }
    }
};
</script>

<template>
    <ResponsiveTable
        :headers="headers"
        :data="dataMap(tickets)"
        :pagination="pagination"
        :disabled="loading"
        :status="status"
        @changePage="
            page => {
                changePage(page);
            }
        "
    >
        <template #row="props">
            <td :class="props.tdClass">{{ props.row.id }}</td>
            <td :class="props.tdClass">{{ props.row.subject }}</td>
            <td :class="props.tdClass">
                <IconBadge :text="props.row.status.name" />
            </td>
            <td :class="props.tdClass">{{ props.row.last_update }}</td>
            <td :class="props.tdClass">
                <router-link
                    :to="{
                        name: 'ticket.show',
                        params: { id: props.row.id }
                    }"
                    class="text-blue-base"
                    title="Ver detalhes"
                >
                    <FontAwesomeIcon :icon="faEye"></FontAwesomeIcon>
                </router-link>
            </td>
        </template>
        <template #responsive="props">
            <tr>
                <th :class="props.thClass">Número</th>
                <td :class="props.tdClass + ' relative'">
                    <ActionsDropdown class="absolute right-0 top-0">
                        <template slot="actions">
                            <router-link
                                :to="{
                                    name: 'ticket.show',
                                    params: { id: props.row.id }
                                }"
                                class="block hover:text-white text-gray-800 px-4 py-2 hover:bg-blue-500 w-full text-left"
                            >
                                Ver detalhes
                            </router-link>
                        </template>
                    </ActionsDropdown>
                    {{ props.row.id }}
                </td>
            </tr>
            <tr>
                <th :class="props.thClass">Assunto</th>
                <td :class="props.tdClass">{{ props.row.subject }}</td>
            </tr>
            <tr>
                <th :class="props.thClass">Situação</th>
                <td :class="props.tdClass">
                    <IconBadge :text="props.row.status.name" />
                </td>
            </tr>
            <tr>
                <th :class="props.thClass">Última atualização</th>
                <td :class="props.tdClass">{{ props.row.last_update }}</td>
            </tr>
        </template>
    </ResponsiveTable>
</template>
