<template>
    <div class="relative h-full">
        <button
            @click="isOpen = !isOpen"
            class="relative z-30 h-full lg:text-right lg:flex lg:items-center text-blue-lightest hover:text-white focus:text-white focus:outline-none"
            v-click-outside="close"
        >
            <p class="text-sm">Olá, {{ this.$store.state.auth.user.name }}</p>
            <Icon icon="userSimple" class="mx-2 h-4 fill-current" />
            <Icon icon="arrowDown" class="h-2 fill-current" />
        </button>
        <div
            v-if="isOpen"
            class="absolute z-30 right-0 w-48 bg-gray-light rounded-sm shadow-md border-white border-4 "
        >
            <button
                class="w-full flex justify-end items-center px-3 py-2 text-gray-800 hover:bg-gray-base hover:text-gray-darker active:text-white active:bg-red-base focus:outline-none"
                @click="logout"
            >
                <span class="mr-1">Sair</span>
                <Icon icon="logout" class="ml-1 h-5 fill-current" />
            </button>
        </div>
    </div>
</template>

<script>
import Icon from "@/core/components/Icon";

export default {
    components: {
        Icon
    },
    data() {
        return {
            isOpen: false
        };
    },
    created() {
        const handleEscape = e => {
            if (e.key === "Esc" || e.key === "Escape") {
                this.close();
            }
        };
        document.addEventListener("keydown", handleEscape);
        this.$once("hook:beforeDestroy", () => {
            document.removeEventListener("keydown", handleEscape);
        });
    },
    methods: {
        logout() {
            this.$Progress.start();
            this.$store
                .dispatch("getLogout")
                .then(() => {
                    this.$router.push({ name: "login" });
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$router.push({ name: "login" });
                    this.$Progress.finish();
                });
        },
        close() {
            this.isOpen = false;
        }
    }
};
</script>
