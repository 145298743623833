import { Component, Prop, Emit } from "vue-property-decorator";
import Modal from "@/core/components/Modal";
import SecurityService from "@/core/services/SecurityService";

@Component
class ManagerConfirmation extends SecurityService {
    @Prop({ default: null })
    action;

    @Prop({ default: null })
    manager;

    @Prop({ default: false })
    modal;

    @Emit()
    Confirm() {}

    constructor() {
        super();
        this.visible = false;
        this.isLoading = false;
    }

    confirm() {
        this.isLoading = true;
        this.$Progress.start();
        const data = {
            action: this.action,
            manager_id: this.manager.id
        };

        let info = this.postConfirmSubordinate(data, "manager");
        this.visible = false;
        this.isLoading = false;

        if (info) this.Confirm();
    }

    open() {
        this.visible = true;
    }

    close() {
        this.visible = false;
    }

    renderButton() {
        switch (this.action) {
            case "link":
                return (
                    <button
                        class="link-button"
                        disabled={this.isLoading}
                        onClick={this.open}
                        title="Vincular esta pessoa"
                    >
                        ↩ Vincular
                    </button>
                );
            case "accept":
                return (
                    <button
                        class="mr-1 btn btn-sm btn-outline-success"
                        disabled={this.isLoading}
                        onClick={this.open}
                        title="Aceitar pedido de vínculo"
                    >
                        Aceitar
                    </button>
                );
            case "deny":
                return (
                    <button
                        class="btn btn-sm btn-outline-danger"
                        disabled={this.isLoading}
                        onClick={this.open}
                        title="Rejeitar pedido de vínculo"
                    >
                        Rejeitar
                    </button>
                );
            case "enable":
                return (
                    <button
                        class="btn btn-primary form-new-blue m-0 btn-list-manager"
                        disabled={this.isLoading}
                        onClick={this.open}
                        title="Ativar vínculo"
                    >
                        ✓ Ativar
                    </button>
                );
            case "disable":
                return (
                    <button
                        class="form-new-blue m-0 btn-list-manager"
                        disabled={this.isLoading}
                        onClick={this.open}
                        title="Desativar vínculo"
                    >
                        ✗ Desativar
                    </button>
                );
            default:
                return "";
        }
    }

    renderMessage() {
        switch (this.action) {
            case "link":
            case "accept":
            case "enable":
                return (
                    <h5>
                        Deseja vincular <strong>{this.manager.name}</strong> e
                        adicionar o perfil de gestor?
                    </h5>
                );
            case "deny":
                return (
                    <h5>
                        Deseja rejeitar o pedido de vínculo de{" "}
                        <strong>{this.manager.name}</strong>?
                    </h5>
                );
            case "disable":
                return (
                    <h5>
                        Deseja desativar o vínculo de{" "}
                        <strong>{this.manager.name}</strong>?
                    </h5>
                );
            default:
                return "";
        }
    }

    getContent() {
        return (
            <div class="container p-3 mt-5">
                <div class="mb-5 row justify-content-center">
                    {this.renderMessage()}
                </div>
                <div class="row justify-content-center">
                    <button
                        class="form-new-blue m-0 btn-list-manager mr-2"
                        onClick={this.close}
                    >
                        Cancelar
                    </button>
                    <button
                        class="btn btn-primary form-new-blue m-0 btn-list-manager"
                        onClick={this.confirm}
                    >
                        Confirmar
                    </button>
                </div>
            </div>
        );
    }

    getContentModal() {
        return (
            <Modal width="800" noPadding={true} onClose={this.close}>
                <div slot="header" style="text-align: right;">
                    <button
                        class="btn btn-outline-secondary mt-2 mr-2"
                        onClick={this.close}
                    >
                        Fechar
                    </button>
                </div>
                <div slot="body">{this.getContent()}</div>
                <div slot="footer" />
            </Modal>
        );
    }

    render() {
        return (
            <div class="d-inline">
                {this.renderButton()}
                {this.visible
                    ? this.modal
                        ? this.getContentModal()
                        : this.getContent()
                    : ""}
            </div>
        );
    }
}

export default ManagerConfirmation;
