<template>
    <div class="relative h-full -mt-1" v-click-outside="close">
        <button
            @click="isOpen = !isOpen"
            class="h-full lg:text-right lg:flex lg:items-center text-blue-lightest hover:text-white focus:text-white focus:outline-none"
        >
            <Icon icon="modules" class="h-8 fill-current" />
        </button>
        <div
            v-if="isOpen"
            class="absolute z-50 right-0 w-64 bg-gray-light rounded-sm shadow-md border-white border-4"
        >
            <div class="text-xs flex flex-col bg-white p-1">
                <div v-if="modules.length" class="justify-between items-center">
                    <router-link
                        v-for="m in modules"
                        :key="m.route"
                        :to="{ name: m.route }"
                        :title="m.name"
                        v-slot="{
                            href,
                            route,
                            navigate,
                            isActive
                        }"
                        exact
                    >
                        <button
                            :class="[
                                isActive
                                    ? 'bg-gray-dark text-white'
                                    : 'text-gray-darker hover:bg-gray-base active:bg-gray-dark active:text-white',
                                'w-20 rounded-sm my-1 p-2 align-middle'
                            ]"
                            :active="isActive"
                            :href="href"
                            @click="
                                e => {
                                    close();
                                    navigate(e);
                                }
                            "
                        >
                            <div
                                class="ml-2 icon h-12 w-12 mb-1 text-blue-base"
                            >
                                <Icon :icon="m.icon" class="h-6 fill-current" />
                            </div>
                            <p
                                class="truncate tracking-wide font-medium text-xs justify-center"
                            >
                                {{ m.name }}
                            </p>
                        </button>
                    </router-link>
                </div>
                <div v-else class="text-right text-gray-dark w-full p-2">
                    Nenhum módulo disponível
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/core/components/Icon";
import { getModules } from "@/domains/home/services";

export default {
    components: {
        Icon
    },
    data() {
        return {
            isOpen: false,
            modules: []
        };
    },
    created() {
        const handleEscape = e => {
            if (e.key === "Esc" || e.key === "Escape") {
                this.close();
            }
        };
        document.addEventListener("keydown", handleEscape);
        this.$once("hook:beforeDestroy", () => {
            document.removeEventListener("keydown", handleEscape);
        });
    },
    mounted() {
        getModules()
            .then(resp => {
                this.modules = Object.values(resp.data);
                this.modules.forEach(item => {
                    item.name = item.name.replace("Gerenciamento", "Ger.");
                });
            })
            .catch(() => {});
    },
    methods: {
        close() {
            this.isOpen = false;
        }
    }
};
</script>
