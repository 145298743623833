import izitoast from "izitoast";

export default function(Vue) {
    Vue.toast = izitoast;

    Object.defineProperties(Vue.prototype, {
        $toast: {
            get() {
                return Vue.toast;
            }
        }
    });
}
