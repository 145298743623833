var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-between w-full my-8 border-b-2 border-blue-base bg-white shadow-lg"},[_c('span',{staticClass:"mt-2 text-base uppercase text-blue-base"},[_vm._v(" Informações do estágio ")]),_vm._t("default")],2),_c('div',{staticClass:"grid gap-5 grid-cols-1 md:grid-cols-12 px-3"},[_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" CNPJ ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.mask(_vm.beneficiarioEstagiario.document, "##.###.###/####-##"))+" ")]:[_c('span',[_c('TheMask',{staticClass:"h-auto py-2 rounded form-input",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstagiarioForm.hasErrors &&
                                !_vm.beneficiarioEstagiarioForm.document
                        },attrs:{"mask":['##.###.###/####-##'],"required":""},on:{"input":_vm.checkCPFValid},model:{value:(_vm.beneficiarioEstagiarioForm.document),callback:function ($$v) {_vm.$set(_vm.beneficiarioEstagiarioForm, "document", $$v)},expression:"beneficiarioEstagiarioForm.document"}}),(_vm.beneficiarioEstagiarioForm.hasErrors &&
                        !_vm.beneficiarioEstagiarioForm.document
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2),_c('div',{staticClass:"col-span-7"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Nome da Instituição ")]),_c('span',[(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.nminstituicao)+" ")]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstagiarioForm.nminstituicao),expression:"beneficiarioEstagiarioForm.nminstituicao"}],staticClass:"h-auto py-2 rounded form-input",class:{
                        'bg-red-100 border-red-400':
                            _vm.beneficiarioEstagiarioForm.hasErrors &&
                            !_vm.beneficiarioEstagiarioForm.nminstituicao
                    },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioEstagiarioForm.nminstituicao)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstagiarioForm, "nminstituicao", $event.target.value)}}}),(_vm.beneficiarioEstagiarioForm.hasErrors &&
                        !_vm.beneficiarioEstagiarioForm.nminstituicao
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()]],2)]),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Data de admissão ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.dtadmissao)+" ")]:[_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstagiarioForm.dtadmissao),expression:"beneficiarioEstagiarioForm.dtadmissao"}],staticClass:"h-auto py-2 rounded form-input",class:{
                            'bg-red-100 border-red-400': _vm.hasErrors && (!_vm.beneficiarioEstagiarioForm.dtadmissao || !_vm.validBirthDate),
                        },attrs:{"type":"text","required":""},domProps:{"value":(_vm.beneficiarioEstagiarioForm.dtadmissao)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstagiarioForm, "dtadmissao", $event.target.value)},$event => _vm.validateAdmissionDateOnInput($event.target.value)]}}),(!_vm.beneficiarioEstagiarioForm.isValidAdmissionDate && _vm.beneficiarioEstagiarioForm.dtadmissao && _vm.beneficiarioEstagiarioForm.dtadmissao.length === 10)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Data inválida ")]):_vm._e(),(!_vm.beneficiarioEstagiarioForm.isValidAdmissionDate && _vm.beneficiarioEstagiarioForm.dtadmissao.length === 8)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Data inválida ")]):_vm._e(),(_vm.beneficiarioEstagiarioForm.hasErrors &&
                        !_vm.beneficiarioEstagiarioForm.dtadmissao
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Data de termino ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.dtencerramento)+" ")]:[_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstagiarioForm.dtencerramento),expression:"beneficiarioEstagiarioForm.dtencerramento"}],staticClass:"h-auto py-2 rounded form-input",class:{
                            'bg-red-100 border-red-400': _vm.hasErrors && (!_vm.beneficiarioEstagiarioForm.dtencerramento || !_vm.isValidClosureDate),
                        },attrs:{"type":"text","required":""},domProps:{"value":(_vm.beneficiarioEstagiarioForm.dtencerramento)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstagiarioForm, "dtencerramento", $event.target.value)},$event => _vm.validateClosureDateOnInput($event.target.value)]}}),(!_vm.beneficiarioEstagiarioForm.isValidClosureDate && _vm.beneficiarioEstagiarioForm.dtencerramento.length === 10)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Data inválida ")]):_vm._e(),(!_vm.beneficiarioEstagiarioForm.isValidClosureDate && _vm.beneficiarioEstagiarioForm.dtencerramento.length === 8)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Data inválida ")]):_vm._e(),(_vm.beneficiarioEstagiarioForm.hasErrors &&
                        !_vm.beneficiarioEstagiarioForm.dtencerramento
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2)]),_c('div',{staticClass:"grid gap-5 grid-cols-1 md:grid-cols-12 px-3"},[_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" CEP ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.cep ? _vm.mask(_vm.beneficiarioEstagiario.cep, "##.###-##") : '')+" ")]:[_c('div',[_c('TheMask',{staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstagiarioForm.hasErrors &&
                                !_vm.beneficiarioEstagiarioForm.cep
                        },attrs:{"mask":['##.###-###'],"required":""},on:{"input":_vm.onCepChangeInstituicao},model:{value:(_vm.beneficiarioEstagiarioForm.cep),callback:function ($$v) {_vm.$set(_vm.beneficiarioEstagiarioForm, "cep", $$v)},expression:"beneficiarioEstagiarioForm.cep"}}),(
                        _vm.beneficiarioEstagiarioForm.hasErrors &&
                        !_vm.beneficiarioEstagiarioForm.cep
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2),_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Endereço ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.street)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstagiarioForm.street),expression:"beneficiarioEstagiarioForm.street"}],staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstagiarioForm.hasErrors &&
                                !_vm.beneficiarioEstagiarioForm.street
                        },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioEstagiarioForm.street)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstagiarioForm, "street", $event.target.value)}}}),(
                        _vm.beneficiarioEstagiarioForm.hasErrors &&
                        !_vm.beneficiarioEstagiarioForm.street
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2),_c('div',{staticClass:"col-span-1"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Número ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.number)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstagiarioForm.number),expression:"beneficiarioEstagiarioForm.number"}],staticClass:"h-auto py-2 rounded form-input mb-0",attrs:{"type":"number"},domProps:{"value":(_vm.beneficiarioEstagiarioForm.number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstagiarioForm, "number", $event.target.value)}}})])]],2),_c('div',{staticClass:"col-span-2"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Bairro ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.district)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstagiarioForm.district),expression:"beneficiarioEstagiarioForm.district"}],staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstagiarioForm.hasErrors &&
                                !_vm.beneficiarioEstagiarioForm.district
                        },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioEstagiarioForm.district)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstagiarioForm, "district", $event.target.value)}}}),(
                        _vm.beneficiarioEstagiarioForm.hasErrors &&
                        !_vm.beneficiarioEstagiarioForm.district
                    )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Complemento ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.complement)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstagiarioForm.complement),expression:"beneficiarioEstagiarioForm.complement"}],staticClass:"h-auto py-2 rounded form-input mb-0",attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioEstagiarioForm.complement)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstagiarioForm, "complement", $event.target.value)}}})])]],2),_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Cidade/estado ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstagiario.municipio ? _vm.beneficiarioEstagiario.municipio.name + " / " + _vm.beneficiarioEstagiario.municipio.state .name : '')+" ")]:[_c('div',[_c('search-select',{attrs:{"selected":_vm.beneficiarioEstagiarioForm.municipio,"search-keys":['name'],"search-remote":true,"lazy-load":true,"options-url":"/api/registry/location?take=50&paginate=false","option-key":"id","option-value":city =>
                            city.name +
                            ' / ' +
                            city.state.initials,"field-class":"py-2","name":"city","placeholder":"Selecione uma cidade..."},on:{"update:selected":function($event){return _vm.$set(_vm.beneficiarioEstagiarioForm, "municipio", $event)},"changed":_vm.cityInstituicaoSelected}}),(_vm.beneficiarioEstagiarioForm.hasErrors &&
                        !_vm.beneficiarioEstagiarioForm.municipio)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }