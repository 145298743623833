import { render, staticRenderFns } from "./support-manager.vue?vue&type=template&id=febd370e&scoped=true&"
var script = {}
import style0 from "./support-manager.vue?vue&type=style&index=0&id=febd370e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "febd370e",
  null
  
)

export default component.exports