import Vue from "vue";
import App from "./App";
import router from "./core/router";
import store from "./core/store";
import { sync } from "vuex-router-sync";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import AutoFocus from "@/core/directives/autoFocus";
import HandleOutsideClick from "@/core/directives/handleOutsideClick";
import RouteGuard from "@/core/middleware/guard";
import Toast from "@/core/packages/izitoast/toast";
import VueTailwind from "vue-tailwind";
import VueMq from "vue-mq";
import Portal from "portal-vue";
import SweetAlert from "vue-swal";
import ProgressBar from "vue-progressbar";
import theme from "@/core/packages/vue-tailwind/custom-theme";
import "./core/assets/css/main.css";
import "leaflet/dist/leaflet.css";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$http = axios;

sync(store, router);

Vue.use(VueMq, {
    breakpoints: {
        xs: 640,
        sm: 768,
        md: 1024,
        lg: 1280,
        xl: Infinity
    }
});
Vue.use(AutoFocus);
Vue.use(HandleOutsideClick);
Vue.use(RouteGuard, { store, router });
Vue.use(Toast);
Vue.use(VueTailwind, theme);
Vue.use(Portal);
Vue.use(SweetAlert);
Vue.use(ProgressBar, {
    color: "#25ae88",
    failedColor: "#874b4b",
    thickness: "7px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300
    },
    autoRevert: false,
    inverse: false,
    location: "top"
});

dayjs.extend(utc);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
