<script>
export default {
    props: {
        to: { type: String, default: "modals" },
        contentClass: { type: String, default: "" },
        closeOnClickOutside: { type: Boolean, default: true }
    },
    data() {
        return {
            open: false,
            background: false,
            content: false
        };
    },
    methods: {
        show() {
            this.open = true;
        },
        hide() {
            this.content = false;
        }
    }
};
</script>

<template>
    <portal :to="to">
        <transition @after-enter="background = true">
            <div
                v-if="open"
                class="fixed inset-x-0 bottom-0 z-40 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
            >
                <transition
                    enter-active-class="transition-opacity duration-100 ease-out"
                    leave-active-class="transition-opacity duration-200 ease-in"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                    @after-enter="content = true"
                    @after-leave="open = false"
                >
                    <div
                        v-if="background"
                        @click.stop="
                            () => {
                                if (closeOnClickOutside) content = false;
                            }
                        "
                        class="fixed inset-0"
                    >
                        <div
                            class="absolute inset-0 bg-gray-600 opacity-75"
                        ></div>
                    </div>
                </transition>

                <transition
                    enter-active-class="transition-all duration-200 ease-out transform"
                    leave-active-class="transition-all duration-200 ease-in transform"
                    enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
                    enter-to-class="translate-y-0 opacity-100 sm:scale-100"
                    leave-class="translate-y-0 opacity-100 sm:scale-100"
                    leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
                    @after-leave="background = false"
                >
                    <div
                        @click.stop
                        v-if="content"
                        :class="[
                            'relative z-50 bg-white rounded-lg overflow-hidden shadow-xl sm:max-w-lg sm:w-full',
                            contentClass
                        ]"
                    >
                        <slot />
                    </div>
                </transition>
            </div>
        </transition>
    </portal>
</template>
