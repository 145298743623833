var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full bg-gray-light"},[_c('div',{staticClass:"mx-2 my-4 modal-edit",class:{ 'edit-shadow': _vm.isEdit }},[_c('div',{staticClass:"flex flex-wrap justify-between items-center align-baseline border-b-2 border-blue-base mb-5"},[_c('h1',{staticClass:"text-blue-base uppercase pt-5"},[_vm._v(" Informações Jurídicas ")]),(!_vm.$store.getters.isOrganization)?_c('div',{staticClass:"form-new-blue",on:{"click":function($event){_vm.isEdit = !_vm.isEdit}}},[_c('div',[(_vm.isEdit)?_c('span',[_vm._v("Fechar")]):_c('span',[_vm._v("Editar")])])]):_vm._e()]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onFormSubmit()}}},[_c('div',{staticClass:"form-group"},[(!_vm.editable)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"w-100 alert alert-primary"},[_vm._v(" Para editar essas informações, faça login com seu CPF possuindo perfil de gestor. ")])]):_vm._e(),_c('div',{staticClass:"row text-xs"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-1/2 lg:w-64 mr-1 mb-4"},[_c('span',{staticClass:"form-label text-gray-dark text-xs"},[_vm._v("CPF / CNPJ")]),(_vm.isEdit)?_c('the-mask',{staticClass:"form-control rounded py-2 h-auto",attrs:{"mask":[
                                    '###.###.###-##',
                                    '##.###.###/####-##'
                                ],"disabled":"disabled"},model:{value:(_vm.user.document),callback:function ($$v) {_vm.$set(_vm.user, "document", $$v)},expression:"user.document"}}):_c('span',[_vm._v(" "+_vm._s(_vm.user.document))])],1),_c('div',{staticClass:"w-1/2 lg:w-64 mr-1 mb-4"},[_c('span',{staticClass:"form-label text-gray-dark text-xs"},[_vm._v("Nome Fantasia")]),(_vm.isEdit)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.legalInformation.business_name),expression:"legalInformation.business_name"}],staticClass:"form-control rounded py-2 h-auto",attrs:{"disabled":!_vm.editable},domProps:{"value":(_vm.legalInformation.business_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.legalInformation, "business_name", $event.target.value)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.legalInformation.business_name))])]),_c('div',{staticClass:"w-1/2 lg:w-64 mr-1 mb-4"},[_c('span',{staticClass:"form-label text-gray-dark text-xs"},[_vm._v("Inscrição Estadual")]),(_vm.isEdit)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.legalInformation.state_registration
                                ),expression:"\n                                    legalInformation.state_registration\n                                "}],staticClass:"form-control rounded py-2 h-auto",attrs:{"disabled":!_vm.editable},domProps:{"value":(
                                    _vm.legalInformation.state_registration
                                )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.legalInformation, "state_registration", $event.target.value)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.legalInformation.state_registration))])]),_c('div',{staticClass:"w-1/2 lg:w-64 mr-1 mb-4"},[_c('span',{staticClass:"form-label text-gray-dark text-xs"},[_vm._v("Inscrição Municipal")]),(_vm.isEdit)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.legalInformation.county_registration
                                ),expression:"\n                                    legalInformation.county_registration\n                                "}],staticClass:"form-control rounded py-2 h-auto",attrs:{"disabled":!_vm.editable},domProps:{"value":(
                                    _vm.legalInformation.county_registration
                                )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.legalInformation, "county_registration", $event.target.value)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.legalInformation.county_registration))])]),_c('div',{staticClass:"w-1/2 lg:w-64 mr-1 mb-4"},[_c('span',{staticClass:"form-label text-gray-dark text-xs"},[_vm._v("Referência")]),(_vm.isEdit)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.legalInformation.reference),expression:"legalInformation.reference"}],staticClass:"form-control rounded py-2 h-auto",attrs:{"disabled":!_vm.editable},domProps:{"value":(_vm.legalInformation.reference)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.legalInformation, "reference", $event.target.value)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.legalInformation.reference))])])])])]),_c('div',{staticClass:"flex justify-content-center"},[(_vm.editable && _vm.isEdit)?_c('button',{staticClass:"form-submit w-full sm:w-auto"},[_vm._v(" Salvar ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }