<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import SystemInfo from "@/core/components/portlets/SystemInfo";
import CarrierInformation from "@/domains/transport/portlets/CarrierInformation";

export default {
    components: {
        Breadcrumbs,
        SystemInfo,
        CarrierInformation
    },
    data: () => {
        return {
            title: "Minha Instituição",
            crumbs: [
                { name: "Início", path: "index" },
                {
                    name: "Minha Instituição",
                    path: "organization.index"
                }
            ],
            alerts: [
                "Mantenha os dados da sua instituição atualizados.",
                "A renovação de registro fica disponível somente um mês antes do vencimento.",
                "Não utilize uma guia de solicitação em outra solicitação. Uma guia de vistoria gerada em uma inclusão de veículo não pode ser usada na solicitação de renovação de registro.",
                "Esse Menu trata apenas de informações da sua conta de pessoa jurídica (CNPJ), para gerenciar informações da sua conta física (CPF) acesse o Menu Usuário SCmobi"
            ]
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <portal-target name="modals" multiple />
        <div class="grid gap-6 px-2 my-4 lg:max-w-5xl sm:px-6">
            <Breadcrumbs :crumbs="crumbs" />
            <SystemInfo :alerts="alerts" />
            <CarrierInformation />
            <div>
                <a
                    target="_blank"
                    title="Ajuda sobre Instituição"
                    href="https://ajuda.sie.sc.gov.br/bc/organizacao/"
                    class="inline-flex items-center space-x-2 text-sm underline text-cool-gray-500 hover:text-blue-light"
                >
                    <svg
                        class="w-4 h-4 fill-current"
                        viewBox="0 0 384 512"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"
                        ></path>
                    </svg>
                    <div>
                        Ajuda sobre Instituição
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
