var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}}},[_c('div',{staticClass:"bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"},[_c('div',{staticClass:"sm:flex sm:items-start"},[_c('div',{class:[
                    _vm.status === 'finished' ? 'bg-green-100' : 'bg-blue-100',
                    'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10'
                ]},[_c('svg',{class:[
                        'h-6 w-6',
                        _vm.status === 'finished'
                            ? 'text-green-600'
                            : 'text-blue-600'
                    ],attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"}})])]),_c('div',{staticClass:"mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"},[_c('h3',{staticClass:"text-lg leading-normal font-medium text-gray-900"},[(_vm.status === 'finished')?_c('span',[_vm._v(" Renovação solicitada ")]):_c('span',[_vm._v("Solicitar Renovação de Registro")])]),(_vm.carrier.field_activity.name === 'Privado')?_c('div',{staticClass:"mt-2"},[(_vm.status === 'finished')?_c('p',{staticClass:"text-left text-sm leading-tight text-gray-dark"},[_vm._v(" Agora imprima e pague as guias de Renovação e Vistoria no módulo "),_c('router-link',{staticClass:"text-blue-base underline",attrs:{"to":{ name: 'financial.bill' }}},[_vm._v(" Financeiro")]),_vm._v(". ")],1):_c('p',{staticClass:"text-left text-sm leading-tight text-gray-500"},[_vm._v(" Esta ação irá iniciar o procedimento de "),_c('span',{staticClass:"font-medium"},[_vm._v("Renovação")]),_vm._v(" do seu Registro. Informe abaixo a quantidade de veículos que serão renovados. ")])]):_c('div',{staticClass:"mt-2"},[(_vm.status === 'finished')?_c('p',{staticClass:"text-left text-sm leading-tight text-gray-dark"},[_vm._v(" A renovação foi solicitada com sucesso. ")]):_c('p',{staticClass:"text-left text-sm leading-tight text-gray-500"},[_vm._v(" Esta ação irá iniciar o procedimento de Renovação do seu Registro. ")])]),(
                        _vm.carrier.field_activity.name === 'Privado' &&
                            _vm.status !== 'finished'
                    )?_c('div',{staticClass:"mt-3 sm:mt-2"},[_c('label',{staticClass:"uppercase tracking-wide font-medium text-gray-500 text-xs"},[_vm._v(" Quantidade de veículos ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quantity),expression:"quantity"},{name:"focus",rawName:"v-focus"}],staticClass:"form-input w-full",attrs:{"required":""},domProps:{"value":(_vm.quantity)},on:{"input":function($event){if($event.target.composing)return;_vm.quantity=$event.target.value}}}),(_vm.errors && _vm.errors.quantity)?_c('div',_vm._l((_vm.errors.quantity),function(error,index){return _c('p',{key:index,staticClass:"italic text-red-base text-xs"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"},[(_vm.status !== 'finished')?_c('span',{staticClass:"flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"},[_c('button',{staticClass:"uppercase inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-xs leading-normal font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:leading-tight",attrs:{"type":"submit"}},[_vm._v(" Confirmar ")])]):_vm._e(),_c('span',{staticClass:"mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"},[_c('button',{staticClass:"uppercase inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-xs leading-normal font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:leading-tight",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[(_vm.status === 'finished')?_c('span',[_vm._v("Fechar")]):_c('span',[_vm._v("Cancelar")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }