import axios from "axios";

export default {
    install(Vue, { store, router }) {
        /* 
        http.options.root = process.env.DOMAIN;
        */
        axios.defaults.headers.common["Authorization"] =
            store.state.auth.type + " " + store.state.auth.token;

        if (store.state.auth.link !== null) {
            axios.defaults.headers.common["X-Link"] =
                store.state.auth.link.organization_id + "";
        }

        router.beforeEach((to, from, next) => {
            if (to.matched.some(record => record.meta.forVisitors)) {
                if (store.state.auth.user !== null) {
                    next({ name: "index" });
                }
            }
            if (to.matched.some(record => record.meta.forAuth)) {
                if (store.state.auth.user === null) {
                    // localStorage.setItem('redirect_uri', to.name);
                    next({ name: "login" });
                }
            }
            next();
        });
    }
};
