<template>
    <div class="w-full bg-gray-light">
        <div class="mx-2 my-4 sm:px-6">
            <breadcrumb class="mb-8" :crumbs="crumbs"></breadcrumb>
            <h1 class="text-blue-base uppercase border-b-2 border-blue-base">
                Alterar Senha
            </h1>
            <form @submit.prevent="submit" class="text-xs pt-4">
                <div
                    class="w-full lg:w-1/2 xl:w-2/4 xl:max-w-screen-md bg-yellow-base border border-gray-dark p-5 mb-6"
                >
                    <h2 class="font-medium text-lg text-gray-darker mb-3">
                        Escolha uma senha forte. Não a reutilize em outras
                        contas.
                    </h2>
                    A alteração de sua senha desconectará você de todos os seus
                    dispositivos, incluindo o smartphone. É necessário digitar a
                    nova senha em todos os seus dispositivos.
                </div>
                <div
                    class="md:w-1/2 md:mx-12 lg:mx-20 lg:w-1/3 xl:w-1/5 xl:max-w-screen-sm mb-6 mt-4"
                >
                    <div class="flex flex-col mb-3">
                        <label class="form-label" for="password_old">
                            Senha atual
                        </label>
                        <input
                            id="password_old"
                            class="form-input"
                            v-model="data.password_old"
                            placeholder="Senha atual"
                            type="password"
                        />
                        <div v-if="errors && errors.password_old">
                            <p
                                v-for="(error, index) in errors.password_old"
                                v-bind:key="index"
                                class="italic text-red-base"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>
                    <div class="flex flex-col mb-3">
                        <label class="form-label" for="password">
                            Nova senha
                        </label>
                        <input
                            id="password"
                            class="form-input"
                            v-model="data.password"
                            placeholder="Nova senha"
                            type="password"
                        />
                        <div v-if="errors && errors.password">
                            <p
                                v-for="(error, index) in errors.password"
                                v-bind:key="index"
                                class="italic text-red-base"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>
                    <div class="flex flex-col mb-3">
                        <label class="form-label" for="password_confirmation">
                            Confirmar senha
                        </label>
                        <input
                            id="password_confirmation"
                            class="form-input"
                            v-model="data.password_confirmation"
                            type="password"
                            placeholder="Confirmar nova senha"
                        />
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        class="form-submit w-full sm:w-auto md:mx-12 lg:mx-20"
                    >
                        Salvar
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Component } from "vue-property-decorator";
import Breadcrumbs from "@/core/components/Breadcrumbs";
import RegistryService from "@/core/services/RegistryService";

@Component({
    components: {
        breadcrumb: Breadcrumbs
    }
})
export default class ChangePassword extends RegistryService {
    constructor() {
        super();
        this.user = null;

        this.errors = [];
        this.errorInfo = false;

        this.data = {
            password_old: "",
            password: "",
            password_confirmation: ""
        };

        this.title = "Alterar Senha";
        this.crumbs = [
            { name: "Início", path: "index" },
            {
                name: "Minha Instituição",
                path: "organization.index"
            },
            { name: "Alterar Senha", path: "organization.password" }
        ];
    }

    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
        this.getOrganizationData();
    }

    submit() {
        this.$Progress.start();
        this.errors = [];
        this.$axios
            .put("/api/registry/user/" + this.user.id + "/password", this.data)
            .then(response => {
                this.data = {
                    password_old: "",
                    password: "",
                    password_confirmation: ""
                };
                this.$Progress.finish();
                this.$toast.success({
                    title: "OK",
                    message: response.data.success
                });
            })
            .catch(e => {
                this.$Progress.finish();
                if (e.response.status === 422) {
                    this.errors = e.response.data.errors;
                } else {
                    this.$toast.error({
                        title: "",
                        message:
                            e.response.data.message || e.response.data.error
                    });
                }
            });
    }

    async getOrganizationData() {
        let info = await this.getUserInfo(
            this.$store.getters.isOrganization
                ? this.$store.state.auth.user.document
                : this.$store.state.auth.link.organization.document
        );
        if (info.error) {
            this.errorInfo = info.error;
        } else {
            this.user = info;
            this.$Progress.finish();
        }
    }
}
</script>
