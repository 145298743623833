<template>
    <div class="w-full bg-gray-light md:p-5 p-0">
        <div class="md:px-2">
            <div
                class="flex flex-wrap justify-between align-baseline mb-1 border-blue-base border-b-2 py-2"
            >
                <h1 class="text-blue-base uppercase">
                    Tecnologia
                </h1>
            </div>
        </div>
        <div class="mx-2 bg-white p-5">
            <div class="flex md:px-4 pt-8 text-xs">
                <form v-on:submit.prevent="onFormSubmit()" class="w-full">
                    <div class="flex justify-center md:justify-start flex-wrap">
                        <div class="flex flex-col md:w-1/2 w-full ">
                            <span
                                class="form-label text-gray-dark text-sm flex justify-between"
                            >
                                A EMPRESA POSSUI SISTEMA DE BILHETAGEM
                                ELETRÔNICA:
                                <div
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                    v-if="showError"
                                >
                                    <span
                                        v-for="error in errors.ticketing"
                                        v-bind:key="error"
                                    >
                                        {{ error }}
                                    </span>
                                </div>
                            </span>
                            <span v-if="isEdit">
                                <select
                                    class="form-select rounded py-4 h-auto mr-1"
                                    v-model="organization.ticketing"
                                >
                                    <option selected value="0"
                                        >Selecione</option
                                    >
                                    <option selected value="1"
                                        >Em toda frota</option
                                    >
                                    <option selected value="2"
                                        >Em parte da frota</option
                                    >
                                    <option selected value="3"
                                        >Não possuo</option
                                    >
                                </select>
                            </span>
                        </div>
                        <div class="flex flex-col md:w-1/2 w-full mt-4 md:mt-0">
                            <span class="form-label text-gray-dark text-sm">
                                Empresa de Tecnologia
                            </span>
                            <span v-if="isEdit">
                                <input
                                    class="form-input rounded py-4 md:ml-1 h-auto"
                                    v-model="organization.ticketing_technology"
                                />
                            </span>
                            <div
                                v-if="errors && errors.document"
                                class="col-lg-12"
                            >
                                <div class="row mt-1">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.document"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex justify-center md:justify-start md:mt-5 flex-wrap w-full"
                    >
                        <div class="flex flex-col md:w-1/2 w-full mt-4 md:mt-0">
                            <span
                                class="form-label text-gray-dark text-sm flex justify-between"
                            >
                                A frota possui sistema monitoramento de frota
                                por georreferenciamento?

                                <div
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                    style="font-size: 10px"
                                    v-if="showError"
                                >
                                    <span
                                        v-for="error in errors.telemetry"
                                        v-bind:key="error"
                                    >
                                        {{ error }}
                                    </span>
                                </div>
                            </span>
                            <span v-if="isEdit">
                                <select
                                    class="form-select rounded py-4 h-auto mr-1"
                                    v-model="organization.telemetry"
                                >
                                    <option selected value="0"
                                        >Selecione</option
                                    >
                                    <option value="1">Em toda frota</option>
                                    <option value="2">Em parte da frota</option>
                                    <option value="3">Não possuo</option>
                                </select>
                            </span>

                            <div v-if="errors && errors.name" class="col-lg-12">
                                <div class="row mt-1">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.name"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col md:w-1/2 w-full mt-4 md:mt-0">
                            <span class="form-label text-gray-dark text-sm">
                                Empresa de Tecnologia
                            </span>
                            <span v-if="isEdit">
                                <input
                                    class="form-input rounded py-4 md:ml-1 h-auto"
                                    v-model="organization.telemetry_technology"
                                />
                            </span>
                            <div
                                v-if="errors && errors.document"
                                class="col-lg-12"
                            >
                                <div class="row mt-1">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.document"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="isEdit"
                        class="flex justify-center sm:justify-start mt-8 w-full mb-6"
                    >
                        <div
                            v-on:click="backStep()"
                            class="form-submit w-full sm:w-auto px-10 py-2 mr-5 cursor-pointer"
                            style="background: #828282"
                        >
                            voltar
                        </div>
                        <button class="form-submit w-full sm:w-auto px-10 py-2">
                            Avançar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import RegistryService from "@/core/services/RegistryService";
import SearchSelect from "@/core/components/SearchSelect";
import Select from "@/core/components/Select";

@Component({
    components: {
        Select,
        "search-select": SearchSelect,
        "the-mask": TheMask
    }
})
export default class StepThree extends RegistryService {
    @Prop()
    organization;

    constructor() {
        super();
        this.errors = {};
        this.isEdit = true;
        this.showError = false;
    }

    valideStep() {
        if (!this.organization.ticketing) {
            this.errors.ticketing = ["* Selecione uma opção"];
            this.showError = true;
        }

        if (!this.organization.telemetry) {
            this.errors.telemetry = ["* Selecione uma opção"];
            this.showError = true;
        }

        if (!this.showError) {
            this.$emit("update:organization", this.organization);
            this.$emit("progressStep");
        }
    }

    citySelected(city) {
        this.user.city = city;
        this.user.city_id = city.id;
    }

    backStep() {
        this.$emit("returnStep");
    }

    async onFormSubmit() {
        this.valideStep();

        // let info = this.putRegistryUser(this.user);
        //
        // if (info.errors) {
        //     this.errors = info.errors;
        // } else {
        //     this.isEdit = false;
        // }
    }
}
</script>
