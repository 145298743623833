import pt from "vue-tailwind/dist/l10n/pt";

const theme = {
    TAlert: {
        fixedClasses: {
            wrapper: "rounded p-4 flex text-sm border-l-4",
            body: "flex-grow",
            close: "ml-4 rounded",
            closeIcon: "h-5 w-5 fill-current"
        },
        classes: {
            wrapper: "bg-gray-100 border-gray-500",
            body: "text-gray-700",
            close: "text-gray-700 hover:text-gray-500 hover:bg-gray-200",
            closeIcon: "h-5 w-5 fill-current"
        },
        variants: {
            info: {
                wrapper: "bg-blue-100 border-blue-500",
                body: "text-blue-700",
                close: "text-blue-700 hover:text-blue-500 hover:bg-blue-200"
            },
            warning: {
                wrapper: "bg-yellow-100 border-yellow-500",
                body: "text-yellow-700",
                close:
                    "text-yellow-700 hover:text-yellow-500 hover:bg-yellow-200"
            },
            danger: {
                wrapper: "bg-red-100 border-red-500",
                body: "text-red-700",
                close: "text-red-700 hover:text-red-500 hover:bg-red-200"
            },
            success: {
                wrapper: "bg-green-100 border-green-500",
                body: "text-green-700",
                close: "text-green-700  hover:text-green-500 hover:bg-green-200"
            }
        }
    },
    TDatepicker: {
        locale: pt,
        variants: {
            custom: {
                wrapper: "inline-flex flex-col w-full",
                input: "form-input w-full mb-0"
            }
        }
    },
    TDropdown: {
        variants: {
            custom: {
                button: "py-1 px-3"
            },
            "top-left": {
                button: "py-1 px-3",
                dropdown:
                    "origin-top-left absolute left-0 w-56 rounded-md shadow-lg bg-white"
            }
        }
    },
    TTable: {
        classes: {
            table: "w-full bg-white rounded border table h-px",
            theadTh: "uppercase text-left text-xs font-bold p-3 text-gray-700",
            tbody: "border-t lg:border-0",
            tr: "border-0 lg:border-t",
            td: "text-xs p-3",
            tfootTr: "border-t",
            tfootTd: "p-3 text-xs"
        }
    },
    TPagination: {
        classes: {
            wrapper: "table border-collapse text-center bg-white ml-auto",
            element: "table-cell border border-gray-300 w-10 h-10",
            disabledElement: "w-10 h-10 border table-cell",
            ellipsisElement: "hidden",
            activeButton:
                "flex w-full h-full items-center justify-center bg-white bg-blue-500 text-white",
            disabledButton:
                "flex w-full h-full items-center justify-center bg-white text-gray-400 cursor-not-allowed px-3",
            button:
                "flex w-full h-full items-center justify-center bg-white text-blue-500 hover:text-blue-700 hover:bg-gray-200 px-3",
            ellipsis: ""
        }
    }
};

export default theme;
