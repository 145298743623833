export default [
    {
        path: "/gerenciamento-viagens",
        name: "travel-manager.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/soc",
        name: "travel-manager.soc",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/Soc.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/soc/:id",
        name: "travel-manager.soc.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowSoc.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento-continuo",
        name: "travel-manager.continuous-charter",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ContinuousCharter.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento-continuo/:id",
        name: "travel-manager.continuous-charter.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowContinuousCharter.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento-continuo/:id",
        name: "manager.continuous_charter.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowContinuousCharter.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento-continuo/:id/viagem/:travelId",
        name: "travel-manager.continuous-charter.travel.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowContinuousTravel.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento",
        name: "travel-manager.freightage",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/Freightage.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento/:id",
        name: "travel-manager.freightage.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowFreightage.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento/:id",
        name: "manager.freightage.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowFreightage.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento/:id/viagem/:travelId",
        name: "travel-manager.freightage.travel.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowFreightageTravel.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento-eventual",
        name: "travel-manager.occasional-travel",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/Occasional.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/fretamento-eventual/:id",
        name: "travel-manager.occasional-travel.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowOccasional.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },

    {
        path: "/gerenciamento-viagens/viagem-especial",
        name: "travel-manager.special-travel",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/SpecialTravel.vue"
            ),
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    },
    {
        path: "/gerenciamento-viagens/viagem-especial/:id",
        name: "travel-manager.special-travel.show.old",
        component: () =>
            import(
                /* webpackChunkName: "chunk-travel-manager" */ "./views/ShowSpecial.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "travel-manager"
        }
    }
];
