<template>
    <div class="w-full bg-gray-light md:p-5 p-0">
        <div class="md:px-2">
            <div
                class="flex flex-wrap justify-between align-baseline mb-1 border-blue-base border-b-2 py-2"
            >
                <h1 class="text-blue-base uppercase">
                    Dados da empresa
                </h1>
            </div>
        </div>
        <div class="mx-2 bg-white p-5">
            <div class="flex md:px-4 pt-8 text-xs">
                <form v-on:submit.prevent="onFormSubmit()">
                    <div class="flex justify-center md:justify-start flex-wrap">
                        <div class="flex flex-col md:w-1/2 w-full ">
                            <span
                                class="form-label text-gray-dark text-sm flex justify-between"
                            >
                                Razão Social
                                <div
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                    v-if="showError"
                                >
                                    <span
                                        v-for="error in errors.business_name"
                                        v-bind:key="error"
                                    >
                                        {{ error }}
                                    </span>
                                </div>
                            </span>
                            <input
                                class="form-input rounded py-4 h-auto mr-1"
                                v-model="organization.business_name"
                                type="text"
                            />
                        </div>
                        <div class="flex flex-col md:w-1/2 w-full">
                            <span
                                class="form-label text-gray-dark text-sm flex justify-between"
                            >
                                Nome Fantasia

                                <div
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                    v-if="showError"
                                >
                                    <span
                                        v-for="error in errors.name"
                                        v-bind:key="error"
                                    >
                                        {{ error }}
                                    </span>
                                </div>
                            </span>
                            <input
                                class="form-input rounded py-4 md:ml-1 h-auto"
                                v-model="organization.name"
                            />
                        </div>
                    </div>

                    <div
                        class="flex flex-wrap mt-2 justify-center md:justify-start"
                    >
                        <div class="md:w-1/2 w-full flex flex-wrap">
                            <div class="w-full md:w-3/4 mr-1 mb-4">
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    Endereço

                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.street"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>
                                <input
                                    class="form-input rounded py-4 h-auto"
                                    v-model="organization.street"
                                    type="text"
                                />
                            </div>

                            <div class="mb-4 w-full md:w-1/5 ">
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    Número
                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        style="font-size: 8px"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.number"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>

                                <input
                                    class="form-input rounded py-4 h-auto"
                                    v-model="organization.number"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div class="md:w-1/2 w-full flex flex-wrap">
                            <div class="flex flex-col md:w-1/2 w-full mb-4">
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    Bairro

                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.district"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>

                                <input
                                    class="form-input rounded py-4 h-auto mr-1"
                                    v-model="organization.district"
                                    type="text"
                                />
                            </div>

                            <div class="flex flex-col md:w-1/2 w-full mb-4">
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    Complemento
                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.complement"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>
                                <input
                                    class="form-input rounded py-4 md:ml-1 h-auto"
                                    v-model="organization.complement"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div class="md:w-full w-full flex flex-wrap">
                            <div
                                class="flex flex-col md:w-2/4 w-full mb-4 mr-1"
                            >
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    CEP
                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.cep"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>
                                <the-mask
                                    class="form-input rounded py-4 h-auto"
                                    :mask="['#####-###']"
                                    v-model="organization.cep"
                                />
                            </div>

                            <div class="flex flex-col md:w-1/3  w-full  mb-4">
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    Cidade / Estado
                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.city"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>
                                <search-select
                                    :selected.sync="organization.city"
                                    :search-keys="['name']"
                                    :search-remote="true"
                                    :lazy-load="true"
                                    options-url="/api/registry/location?take=50&paginate=false"
                                    option-key="id"
                                    :option-value="
                                        city =>
                                            city.name +
                                            ' / ' +
                                            city.state.initials
                                    "
                                    field-class="py-4"
                                    name="city"
                                    placeholder="Selecione uma cidade..."
                                    @changed="citySelected"
                                />
                            </div>
                        </div>

                        <div class="w-full flex flex-wrap">
                            <div class="flex flex-col md:w-1/2 w-full mb-4">
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    E-mail Institucional

                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.email"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>

                                <input
                                    class="form-input rounded py-4 h-auto mr-1"
                                    v-model="organization.email"
                                    type="text"
                                />
                            </div>

                            <div class="flex flex-col md:w-1/4 w-full mb-4">
                                <span class="form-label text-gray-dark text-sm">
                                    Telefone Comercial
                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.commercial_phone"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>
                                <the-mask
                                    class="form-input rounded py-4 h-auto md:ml-1"
                                    :mask="['(##)#####-####']"
                                    v-model="organization.commercial_phone"
                                />
                            </div>
                            <div class="flex flex-col md:w-1/4 w-full mb-4">
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    Celular Comercial
                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.cell_phone"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>

                                <the-mask
                                    class="form-input rounded py-4 h-auto md:ml-2"
                                    :mask="['(##)#####-####']"
                                    v-model="organization.cell_phone"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex justify-center sm:justify-start mt-8 w-full mb-6"
                    >
                        <button class="form-submit w-full sm:w-auto px-10 py-2">
                            Avançar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import RegistryService from "@/core/services/RegistryService";
import SearchSelect from "@/core/components/SearchSelect";

@Component({
    components: {
        "search-select": SearchSelect,
        "the-mask": TheMask
    }
})
export default class StepOne extends RegistryService {
    @Prop()
    organization;

    constructor() {
        super();
        this.showError = false;
        this.errors = {};
        this.isEdit = true;
    }

    valideStep() {
        let validation = true;

        if (this.organization.business_name === "") {
            validation = false;
            this.errors.business_name = ["* Preencha o campo"];
            this.showError = true;
        }
        if (this.organization.name === "") {
            validation = false;
            this.errors.name = ["* Preencha o campo"];
        }
        if (this.organization.street === "") {
            validation = false;
            this.errors.street = ["* Preencha o campo"];
        }
        if (this.organization.number === "") {
            validation = false;
            this.errors.number = ["campo vazio"];
        }
        if (this.organization.district === "") {
            validation = false;
            this.errors.district = ["* Preencha o campo"];
        }
        // if (this.organization.complement === "") {
        //     validation = false;
        //     this.errors.complement = ["* Preencha o campo"];
        // }
        if (this.organization.cep === "") {
            validation = false;
            this.errors.cep = ["* Preencha o campo"];
        }
        if (this.organization.city === "") {
            validation = false;
            this.errors.city = ["* Preencha o campo"];
        }
        if (this.organization.email === "") {
            validation = false;
            this.errors.email = ["* Preencha o campo"];
        }
        if (this.organization.cell_phone === "") {
            validation = false;
            this.errors.cell_phone = ["* Preencha o campo"];
        }

        if (validation) {
            this.$emit("update:organization", this.organization);
            this.$emit("progressStep");
        }
    }

    citySelected(city) {
        this.organization.city = city;
        // this.user.city = city;
        // this.user.city_id = city.id;
    }

    async onFormSubmit() {
        this.valideStep();
    }
}
</script>
