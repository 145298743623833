export default [
    {
        path: "/gerenciamento-instituicoes",
        name: "organization-manager.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-org-manager" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            module: "organization-manager"
        }
    },
    {
        path: "/gerenciamento-instituicoes/consulta",
        name: "organization-manager.list",
        component: () =>
            import(
                /* webpackChunkName: "chunk-org-manager" */ "./views/List.js"
            ),
        meta: {
            forAuth: true,
            module: "organization-manager"
        }
    },
    {
        path: "/gerenciamento-instituicoes/:id/alterar-razao-social",
        name: "organization-manager.update-name",
        component: () =>
            import(
                /* webpackChunkName: "chunk-org-manager" */ "./views/UpdateCompanyName.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "organization-manager"
        }
    },
    {
        path: "/gerenciamento-instituicoes/:id/alterar-situacao-registro",
        name: "organization-manager.update-status",
        component: () =>
            import(
                /* webpackChunkName: "chunk-org-manager" */ "./views/UpdateCarrierStatus.vue"
            ),
        props: true,
        meta: {
            forAuth: true,
            module: "organization-manager"
        }
    }
];
