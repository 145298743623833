<template>
    <div class="w-full bg-gray-light md:p-5 p-0">
        <div class="md:px-2">
            <div
                class="flex flex-wrap justify-between align-baseline mb-1 border-blue-base border-b-2 py-2"
            >
                <h1 class="text-blue-base uppercase">
                    Responsavel pelo Cadastramento
                </h1>
            </div>
        </div>
        <div class="mx-2 bg-white p-5">
            <div class="flex md:px-4 pt-8 text-xs ">
                <form v-on:submit.prevent="onFormSubmit()" class="w-full">
                    <div
                        class="flex justify-center md:justify-start flex-wrap w-full"
                    >
                        <div class="flex flex-col md:w-1/2 w-full">
                            <span
                                class="form-label text-gray-dark text-sm flex justify-between"
                            >
                                Nome Completo
                                <div
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                    v-if="showError"
                                >
                                    <span
                                        v-for="error in errors.nameResponsible"
                                        v-bind:key="error"
                                    >
                                        {{ error }}
                                    </span>
                                </div>
                            </span>
                            <span v-if="isEdit">
                                <input
                                    class="form-input rounded py-4 h-auto mr-1"
                                    v-model="organization.confirmation.name"
                                    type="text"
                                />
                            </span>

                            <div v-if="errors && errors.name" class="col-lg-12">
                                <div class="row mt-1">
                                    <div class="alert alert-danger">
                                        <span
                                            v-for="error in errors.name"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex flex-wrap mt-2 justify-center md:justify-start"
                    >
                        <div class="md:w-1/2 w-full flex flex-wrap">
                            <div class="w-full md:w-3/4 mr-1 mb-4">
                                <span
                                    class="form-label text-gray-dark text-sm flex justify-between"
                                >
                                    CPF
                                    <div
                                        class="alert alert-danger text-red-500 text-xs mr-2 font-bold"
                                        v-if="showError"
                                    >
                                        <span
                                            v-for="error in errors.documentResponsible"
                                            v-bind:key="error"
                                        >
                                            {{ error }}
                                        </span>
                                    </div>
                                </span>
                                <the-mask
                                    class="form-input rounded py-4 h-auto"
                                    :mask="['###.###.###-##']"
                                    v-model="organization.confirmation.document"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="isEdit"
                        class="flex justify-center sm:justify-start mt-8 w-full mb-6"
                    >
                        <div
                            v-on:click="backStep()"
                            class="form-submit w-full sm:w-auto px-10 py-2 mr-5 cursor-pointer"
                            style="background: #828282"
                        >
                            voltar
                        </div>
                        <button class="form-submit w-full sm:w-auto px-10 py-2">
                            Avançar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import RegistryService from "@/core/services/RegistryService";
import SearchSelect from "@/core/components/SearchSelect";

@Component({
    components: {
        "search-select": SearchSelect,
        "the-mask": TheMask
    }
})
export default class StepFour extends RegistryService {
    @Prop()
    organization;

    constructor() {
        super();
        this.showError = false;
        this.errors = {};
        this.isEdit = true;
    }

    valideStep() {
        this.showError = false;

        if (this.organization.confirmation.name === "") {
            this.errors.nameResponsible = ["* Preencha o campo"];
            this.showError = true;
        }

        if (this.organization.confirmation.document === "") {
            this.errors.documentResponsible = ["* Preencha o campo"];
            this.showError = true;
        }

        if (!this.validaCpf(this.organization.confirmation.document)) {
            this.errors.documentResponsible = ["* CPF inválido"];
            this.showError = true;
        }

        if (!this.showError) {
            this.$emit("update:organization", this.organization);
            this.$emit("progressStep");
        }
    }

    validaCpf(cpf) {
        cpf = cpf.replace(/[^\d]+/g, "");
        if (cpf === "") {
            return false;
        }

        if (
            cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999"
        ) {
            return false;
        }

        let add = 0;
        for (let i = 0; i < 9; i++) {
            add += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11) {
            rev = 0;
        }
        if (rev !== parseInt(cpf.charAt(9))) {
            return false;
        }
        add = 0;
        for (let i = 0; i < 10; i++) {
            add += parseInt(cpf.charAt(i)) * (11 - i);
        }
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11) {
            rev = 0;
        }
        if (rev !== parseInt(cpf.charAt(10))) {
            return false;
        }
        return true;
    }

    citySelected(city) {
        this.user.city = city;
        this.user.city_id = city.id;
    }

    backStep() {
        this.$emit("returnStep");
    }

    async onFormSubmit() {
        this.valideStep();
    }
}
</script>
