import Vue from "vue";
import VueRouter from "vue-router";
import login from "@/domains/auth/router";
import home from "@/domains/home/router";
import notifications from "@/domains/notifications/router";
import user from "@/domains/user/router";
import admin from "@/domains/admin/router";
import organization from "@/domains/organization/router";
import organizationManager from "@/domains/organization-manager/router";
import support from "@/domains/support/router";
import supportManager from "@/domains/support-manager/router";
import travel from "@/domains/travel/router";
import travelManager from "@/domains/travel-manager/router";
import transport from "@/domains/transport/router";
import transportManager from "@/domains/transport-manager/router";
import financial from "@/domains/financial/router";
import financialManager from "@/domains/financial-manager/router";
import protocol from "@/domains/protocol/router";
import protocolManager from "@/domains/protocol-manager/router";
import metrics from "@/domains/metrics/router";
import lines from "@/domains/lines/router";
import reRegistration from "@/domains/re-registration/router";
import splNavegante from "@/domains/spl-navegantes/router";

Vue.use(VueRouter);

// TODO: add a 404 Not Found page component instead of plain redirect
const route404 = [{ path: "*", name: "404", redirect: "/" }];

const routes = []
    .concat(login)
    .concat(home)
    .concat(notifications)
    .concat(user)
    .concat(admin)
    .concat(organization)
    .concat(organizationManager)
    .concat(support)
    .concat(supportManager)
    .concat(travel)
    .concat(travelManager)
    .concat(transport)
    .concat(transportManager)
    .concat(financial)
    .concat(financialManager)
    .concat(protocol)
    .concat(protocolManager)
    .concat(metrics)
    .concat(lines)
    .concat(reRegistration)
    .concat(splNavegante)
    .concat(route404);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function(to) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(to.hash ? { selector: to.hash } : { x: 0, y: 0 });
            }, 1000);
        });
    }
});

export default router;
