<script>
import Modal from "@/core/components/Modal";
import Editor from "@/core/components/Editor";

export default {
    components: {
        Modal,
        Editor
    },
    props: {
        ticket: { required: true }
    },

    data() {
        return {
            errors: null,
            content: ""
        };
    },
    methods: {
        async onFormSubmit() {
            this.$Progress.start();
            const data = {
                content: this.content,
                ticket_id: this.ticket.id
            };
            try {
                const response = await this.$axios.post(
                    "/api/support/comment",
                    data
                );
                this.$Progress.finish();
                this.$toast.success({
                    title: "OK",
                    message: response.data.success
                });
                this.$emit("close");
            } catch (e) {
                this.$Progress.finish();
                if (e.response.status === 422) {
                    this.errors = e.response.data.errors;
                } else {
                    this.$toast.error({
                        title: "",
                        message: e.response.data.error,
                        icon: "fa fa-exclamation"
                    });
                }
            }
        }
    }
};
</script>

<template>
    <Modal width="800" borderLeft="4px solid #40A4f4" @close="$emit('close')">
        <div slot="header">
            <h1
                class="py-1 mb-1 uppercase border-b-2 text-blue-base border-blue-base"
            >
                Novo Comentário
            </h1>
        </div>
        <div slot="body">
            <form v-on:submit.prevent="onFormSubmit()">
                <div class="w-full mt-5 mb-5">
                    <label class="text-sm form-label" for="description">
                        Comentário:
                    </label>
                    <div class="w-3/4 py-3 mx-auto">
                        <Editor
                            id="content"
                            class="w-full bg-white"
                            v-model="content"
                            placeholder="Escreva aqui seu comentário"
                        />
                    </div>
                    <div v-if="errors && errors.content">
                        <p
                            v-for="error in errors.content"
                            v-bind:key="error"
                            class="text-xs italic text-red-base"
                        >
                            {{ error }}
                        </p>
                    </div>
                </div>
            </form>
        </div>
        <div slot="footer">
            <div class="items-center d-flex justify-content-center">
                <button
                    class="mr-2 btn btn-outline-secondary close-modal"
                    @click="$emit('close')"
                >
                    Cancelar
                </button>
                <button
                    v-on:click="onFormSubmit"
                    class="btn btn-primary disable-onload"
                    type="submit"
                >
                    Confirmar
                </button>
            </div>
        </div>
    </Modal>
</template>
