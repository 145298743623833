import { Vue, Component, Prop } from "vue-property-decorator";
import moment from "moment";
import business from "moment-business";
import isDate from "date-fns/isDate";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const mask = (value, pattern) => {

    if (value) {
        if (typeof pattern === "string") {
            var i = 0,
                v = value.toString();
            return pattern.replace(/#/g, () => v[i++] ?? '');
        }
        return value;
    }
    return '';
};

export const validateCPF = cpf => {
    cpf = filterDocument(cpf);
    if (cpf === "") return false;

    // validates first digit
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;

    // validates second digit
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
};

export const validateCNPJ = cnpj => {
    cnpj = cnpj.replace(/[^\d]+/g, "");
    if (cnpj == "") return false;
    if (cnpj.length != 14) return false;

    if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
    )
        return false;

    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    let i = null;

    for (i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(0)) return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(1)) return false;

    return true;
};

export const dateFormat = (date, withTime = false) => {
    if (isDate(date)) {
        return format(date, withTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy");
    }
    if (typeof date === "string") {
        return format(
            parseISO(date),
            withTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"
        );
    }
    return date;
};

export const hourFormat = time => {
    const times = time.split(":");
    return [times[0], times[1]].join(":");
};

export const filterDocument = string => {
    return string.replace(/[/.-]/g, "").trim();
};

export const filterId = string => {
    return string
        .replace(/\D/g, "")
        .trim()
        .slice(0, 8);
};

/** Mimicking Bootstrap 4 Collapse */
const fnmap = {
    toggle: "toggle",
    show: "add",
    hide: "remove"
};

export const collapse = (selector, cmd) => {
    const targets = Array.from(document.querySelectorAll(selector));
    targets.forEach(target => {
        target.classList[fnmap[cmd]]("show");
    });
};

export const tftDueDate = () => {
    let now = moment();
    let tenth = moment().date(10);
    let lastDay = moment().endOf("month");

    if (business.weekDays(now, tenth) < 5 || now.diff(tenth, "days") >= 0) {
        let date = business.addWeekDays(moment(), 5);
        if (date.diff(lastDay, "days") > 0) {
            return lastDay;
        } else {
            return date;
        }
    } else {
        return tenth;
    }
};

@Component
export class StatusBadge extends Vue {
    @Prop({ default: "" })
    string;

    render() {
        if (
            ["Cancelado", "Cancelada", "Inválido", "Em aberto"].includes(
                this.string
            )
        ) {
            return <span class="badge badge-danger">{this.string}</span>;
        }
        if (
            [
                "Finalizado",
                "Pago",
                "Ativo",
                "Ativa",
                "Suspenso judicialmente",
                "Impresso",
                "Impressa"
            ].includes(this.string)
        ) {
            return <span class="badge badge-success">{this.string}</span>;
        }
        if (
            [
                "Solicitado",
                "Suspenso",
                "Vencido",
                "Vencida",
                "Aguardando Pagamento"
            ].includes(this.string)
        ) {
            return <span class="badge badge-warning">{this.string}</span>;
        }
        return <span class="badge badge-default">{this.string}</span>;
    }
}
