import Service from "@/core/services/Service";

export default class ReassignService extends Service {
    constructor() {
        super();
        this.routerReassign = "/api/support/";
        this.result = {};
        this.errors = null;
    }

    validateError() {
        if (this.result["status"] !== "success") {
            this.errors = this.result["response"]["body"];
            this.onError(this.result["response"]);
            this.result["response"]["body"] = [];
        }
    }

    async getCategories() {
        try {
            this.result = await this.request(
                this.routerReassign + "category/search",
                "post",
                {
                    take: false,
                    paginate: false,
                    order: { name: "ASC" }
                }
            );
            this.validateError();
            return this.result;
        } catch (e) {
            return e;
        }
    }

    async getPriorities() {
        try {
            this.result = await this.request(
                this.routerReassign + "priority/search",
                "post",
                {
                    take: false,
                    paginate: false,
                    order: { name: "ASC" }
                }
            );
            if (this.result["status"] !== "success") {
                this.onError(this.result["response"]);
                this.result["response"]["body"] = [];
            }
            return this.result;
        } catch (e) {
            return e;
        }
    }

    async getAgents() {
        try {
            this.result = await this.request(
                this.routerReassign + "agent?paginate=false&take=false",
                "get"
            );
            this.validateError();
            return this.result;
        } catch (e) {
            return e;
        }
    }

    onError(response) {
        this.$Progress.finish();
        if (response.data.status) {
            this.$toast.error({
                title: "",
                message: response.body.status,
                icon: "fa fa-exclamation"
            });
        }
    }
}
