export default [
    {
        path: "/financeiro",
        name: "financial.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-financial" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            module: "financial"
        }
    },
    {
        path: "/financeiro/guia",
        name: "financial.bill",
        component: () =>
            import(
                /* webpackChunkName: "chunk-financial" */ "./views/Bills.vue"
            ),
        meta: {
            forAuth: true,
            module: "financial"
        }
    },
    {
        path: "/financeiro/guia/:id",
        name: "financial.bill.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-financial" */ "./views/ShowBill.vue"
            ),
        meta: {
            forAuth: true,
            module: "financial"
        }
    },
    {
        path: "/financeiro/:cnpj/list",
        name: "financial.bill.iframe",
        component: () =>
            import(
                /* webpackChunkName: "chunk-financial" */ "./views/BillsIframe.vue"
            ),
        props: true,
        meta: {
            forVisitors: true
        }
    }
];
