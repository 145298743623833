<script>
import { TheMask } from "vue-the-mask";
import Breadcrumbs from "@/core/components/Breadcrumbs";
import { TipoBeneficiario } from "./Step01.vue";
import { Money } from "v-money";
import Icon from "@/core/components/Icon";
import { validateCNPJ } from "@/core/services/helpers";
import SearchSelect from "@/core/components/SearchSelect";
import {
    checkIfCepIsValidForBeneficiario,
    getAddressByCep
} from "../getAddressByCep";
import { parse } from "date-fns";
export default {
    components: {
        Breadcrumbs,
        TheMask,
        Money,
        Icon,
        SearchSelect
    },
    data: () => {
        return {
            TipoBeneficiario,
            title: "SPL Navegantes",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "SPL Navegantes", path: "spl-navegantes.index" },
                { name: "Cadastro", path: "spl-navegantes.show" }
            ],

            beneficiarioTrabalhador: {
                idprofissao: "",
                vlsalario: 0,
                dtadmissao: "",
                nminstituicao: "",
                document: ""
            },

            beneficiarioEstudante: {
                nminstituicao: "",
                tpcurso: null,
                nuseriecurso: null,
                idcurso: null,
                document: ""
            },

            beneficiarioEstagiario: {
                dtadmissao: "",
                nminstituicao: "",
                dtencerramento: "",
                document: ""
            },

            tipoCursos: [
                { name: "Fundamental", value: 1 },
                { name: "Médio", value: 2 },
                { name: "Superior", value: 3 },
                { name: "Especialização", value: 4 },
                { name: "Técnico", value: 5 }
            ],

            endereco: {
                cep: "",
                street: "",
                number: "",
                district: "",
                complement: "",
                municipio: ""
            },

            contatosInstituicao: {
                email: [""],
                telefone: [""]
            },

            selectedTipoBeneficiario: null,

            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                precision: 2
            },
            isValidAdmissionDate: true,
            isValidClosureDate: true,
            hasErrors: false
        };
    },
    mounted() {
        const beneficiario = JSON.parse(
            sessionStorage.getItem("beneficiarioStore")
        );

        this.selectedTipoBeneficiario = beneficiario.tpbeneficiario;
        this.getStoredBeneficiarioByType();

        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    },

    methods: {
        getStoredBeneficiarioByType() {
            switch (this.selectedTipoBeneficiario) {
                case TipoBeneficiario.ESTUDANTE:
                    if (sessionStorage.getItem("beneficiarioEstudanteStore")) {
                        const { endereco, contatos, ...rest } = JSON.parse(
                            sessionStorage.getItem("beneficiarioEstudanteStore")
                        );
                        this.contatosInstituicao = contatos;
                        this.endereco = endereco;
                        this.beneficiarioEstudante = rest;
                    }
                    break;
                case TipoBeneficiario.ESTAGIARIO:
                    if (sessionStorage.getItem("beneficiarioEstagiarioStore")) {
                        const { endereco, contatos, ...rest } = JSON.parse(
                            sessionStorage.getItem(
                                "beneficiarioEstagiarioStore"
                            )
                        );
                        this.contatosInstituicao = contatos;
                        this.endereco = endereco;
                        this.beneficiarioEstagiario = rest;
                    }
                    break;
                case TipoBeneficiario.TRABALHADOR:
                    if (
                        sessionStorage.getItem("beneficiarioTrabalhadorStore")
                    ) {
                        const { endereco, contatos, ...rest } = JSON.parse(
                            sessionStorage.getItem(
                                "beneficiarioTrabalhadorStore"
                            )
                        );
                        this.contatosInstituicao = contatos;
                        this.endereco = endereco;
                        this.beneficiarioTrabalhador = rest;
                    }
                    break;
            }
        },
        cityEnderecoSelected(city) {
            this.endereco.municipio = city;
        },
        storeBeneficiarioByType() {
            switch (this.selectedTipoBeneficiario) {
                case TipoBeneficiario.ESTUDANTE:
                    sessionStorage.setItem(
                        "beneficiarioEstudanteStore",
                        JSON.stringify({
                            ...this.beneficiarioEstudante,
                            endereco: this.endereco,
                            contatos: this.contatosInstituicao
                        })
                    );
                    break;
                case TipoBeneficiario.ESTAGIARIO:
                    sessionStorage.setItem(
                        "beneficiarioEstagiarioStore",
                        JSON.stringify({
                            ...this.beneficiarioEstagiario,
                            endereco: this.endereco,
                            contatos: this.contatosInstituicao
                        })
                    );
                    break;
                case TipoBeneficiario.TRABALHADOR:
                    sessionStorage.setItem(
                        "beneficiarioTrabalhadorStore",
                        JSON.stringify({
                            ...this.beneficiarioTrabalhador,
                            endereco: this.endereco,
                            contatos: this.contatosInstituicao
                        })
                    );
                    break;
            }
        },
        async searchCities(searchText) {
            let data = {};
            if (searchText.length > 0) {
                data.name = searchText.toLowerCase();
            }
            return this.$http.post("/api/registry/location/search", data);
        },
        async validateCEP() {
            this.$Progress.start();
            const isCepValid = await checkIfCepIsValidForBeneficiario(
                this.endereco.cep
            );
            this.$Progress.finish();

            if (isCepValid) {
                return true;
            }
            this.$toast.error({
                title: "Erro",
                message:
                    "CEP da instituição inválido! Município deve ser Itajaí ou Navegantes"
            });
            return false;
        },

        async onCepChange(cep) {
            if (cep.length === 8) {
                this.$Progress.start();
                const {
                    logradouro,
                    bairro,
                    localidade,
                    uf,
                    ibge
                } = await getAddressByCep(cep);
                if (logradouro) {
                    this.endereco.street = logradouro;
                    this.endereco.district = bairro;
                    this.endereco.municipio = {
                        id: '',
                        name: localidade,
                        ibge_code: ibge,
                        state: {
                            id: '',
                            name: uf,
                            initials: uf
                        }
                    }
                } else {
                    this.$toast.error({
                        message: "CEP Inválido."
                    });
                }
                this.$Progress.finish();
            }
        },

        async onNextStepClick() {
            this.isFormValid();
            this.validateDocuments();
            if (this.hasErrors) {
                this.$toast.error({
                    title: "Erro",
                    message: "Verifique os campos obrigatórios!"
                });
            }
            else {
                //  const isCepValid = await this.validateCEP();
                //  if (isCepValid) {
                //     this.storeBeneficiarioByType();
                //      this.$router.push({ name: "spl-navegantes.step03" });
                //  }
                this.storeBeneficiarioByType();
                this.$router.push({ name: "spl-navegantes.step03" });
            }
        },
        async getInstituicaoTrabalhadorByDocument(document) {
            if (document.length == 14 || document.length == 11) {
                const { data } = await this.$axios.get(
                    `/api/spl-navegantes/beneficiarios/documento/${document}`
                );
                if (data) {
                    this.beneficiarioTrabalhador.nminstituicao = data.name;
                    this.endereco.street = data.street;
                    this.endereco.district = data.district;
                    this.endereco.municipio = data.city;
                    this.endereco.number = data.number;
                    this.endereco.complement = data.complement;
                    this.endereco.cep = data.cep;
                }
            }
        },
        async getInstituicaoEstudanteByDocument(document) {
            if (document.length == 14) {
                const { data } = await this.$axios.get(
                    `/api/spl-navegantes/beneficiarios/documento/${document}`
                );
                if (data) {
                    this.beneficiarioEstudante.nminstituicao = data.name;
                    this.endereco.street = data.street;
                    this.endereco.district = data.district;
                    this.endereco.municipio = data.city;
                    this.endereco.number = data.number;
                    this.endereco.complement = data.complement;
                    this.endereco.cep = data.cep;
                }
            }
        },
        async getInstituicaoEstagiarioByDocument(document) {
            if (document.length == 14) {
                const { data } = await this.$axios.get(
                    `/api/spl-navegantes/beneficiarios/documento/${document}`
                );
                if (data) {
                    this.beneficiarioEstagiario.nminstituicao = data.name;
                    this.endereco.street = data.street;
                    this.endereco.district = data.district;
                    this.endereco.municipio = data.city;
                    this.endereco.number = data.number;
                    this.endereco.complement = data.complement;
                    this.endereco.cep = data.cep;
                }
            }
        },
        validateDocuments() {
            switch (this.selectedTipoBeneficiario) {
                case TipoBeneficiario.TRABALHADOR:
                    if (this.beneficiarioTrabalhador.document.length == 14 && !validateCNPJ(this.beneficiarioTrabalhador.document)) {
                        this.$toast.error({
                            title: "Erro",
                            message: "CNPJ inválido!"
                        });
                        this.hasErrors = true;
                    }
                    break;
            }
        },
        compareAdmissionDateAndClosureDate(dtadmissao, dtencerramento) {
            if (dtadmissao.length === 10 && dtencerramento.length === 10) {
                const parsedAdmissionDate = parse(dtadmissao, 'dd/MM/yyyy', new Date());
                const parsedClosureDate = parse(dtencerramento, 'dd/MM/yyyy', new Date());
                if (parsedAdmissionDate > parsedClosureDate) {
                    this.$toast.error({
                        title: "Erro",
                        message: "Data de encerramento não pode ser menor que a data de admissão!"
                    });
                    this.isValidClosureDate = false;
                    return false;
                } else {
                    this.isValidClosureDate = true;
                    return true;
                }
            }
            return true;
        },
        validateClosureDateOnInput(value) {
            let originalValue = value.slice(0, -1);
            let valid = true;

            // previne que a data seja maior que 10 caracteres
            if (value.length > 10) {
                valid = false;
            }

            // valida se foi digitado alfanumérico
            if (isNaN(value.charAt(value.length - 1)) && value.charAt(value.length - 1) !== '/') {
                valid = false;
            }

            // valida primeiro digito
            if (value.length == 1) {
                valid = new RegExp(/^[0-3]$/).test(value.charAt(0));
            }

            // valida segundo digito
            if (value.length == 2) {
                if (value.charAt(0) == '3') {
                    valid = new RegExp(/^[0-1]$/).test(value.charAt(1));
                } else if (value.charAt(0) == '0') {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(1));
                } else {
                    valid = new RegExp(/^[0-9]$/).test(value.charAt(1));
                }
                value += '/';
            }

            // valida terceiro digito
            if (value.length == 4) {
                valid = new RegExp(/^[0-1]$/).test(value.charAt(3));
            }

            // valida quarto digito
            if (value.length == 5) {
                if (value.charAt(3) == '1') {
                    valid = new RegExp(/^[0-2]$/).test(value.charAt(4));
                } else {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(4));
                }
                value += '/';
            }

            if (valid) {
                this.beneficiarioEstagiario.dtencerramento = value;
                let dtadmissao = this.beneficiarioEstagiario.dtadmissao;
                if (value.length === 10) {
                    if (dtadmissao && this.compareAdmissionDateAndClosureDate(dtadmissao, value)) {
                        const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
                        if (parsedDate < new Date()) {
                            this.$toast.error({
                                title: "Erro",
                                message: "Data de encerramento não pode ser menor que a data atual!"
                            });
                            this.isValidClosureDate = false;
                        } else {
                            this.isValidClosureDate = true;
                        }
                    }
                }
                if (value.length === 8) {
                    this.isValidClosureDate = false;
                }
            } else {
                this.$nextTick(() => {
                    this.beneficiarioEstagiario.dtencerramento = originalValue;
                });
            }
        },
        validateAdmissionDateOnInput(value) {
            let originalValue = value.slice(0, -1);
            let valid = true;

            // previne que a data seja maior que 10 caracteres
            if (value.length > 10) {
                valid = false;
            }

            // valida se foi digitado alfanumérico
            if (isNaN(value.charAt(value.length - 1)) && value.charAt(value.length - 1) !== '/') {
                valid = false;
            }

            // valida primeiro digito
            if (value.length == 1) {
                valid = new RegExp(/^[0-3]$/).test(value.charAt(0));
            }

            // valida segundo digito
            if (value.length == 2) {
                if (value.charAt(0) == '3') {
                    valid = new RegExp(/^[0-1]$/).test(value.charAt(1));
                } else if (value.charAt(0) == '0') {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(1));
                } else {
                    valid = new RegExp(/^[0-9]$/).test(value.charAt(1));
                }
                value += '/';
            }

            // valida terceiro digito
            if (value.length == 4) {
                valid = new RegExp(/^[0-1]$/).test(value.charAt(3));
            }

            // valida quarto digito
            if (value.length == 5) {
                if (value.charAt(3) == '1') {
                    valid = new RegExp(/^[0-2]$/).test(value.charAt(4));
                } else {
                    valid = new RegExp(/^[1-9]$/).test(value.charAt(4));
                }
                value += '/';
            }

            if (valid) {
                if (this.selectedTipoBeneficiario == TipoBeneficiario.ESTAGIARIO) {
                    this.beneficiarioEstagiario.dtadmissao = value;
                } else {
                    this.beneficiarioTrabalhador.dtadmissao = value;
                }
                if (value.length === 10) {
                    const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
                    if (parsedDate > new Date()) {
                        this.$toast.error({
                            title: "Erro",
                            message: "Data de admissão não pode ser maior que a data atual!"
                        });
                        this.isValidAdmissionDate = false;
                    } else {
                        this.isValidAdmissionDate = true;
                    }
                }
                if (value.length === 8) {
                    this.isValidAdmissionDate = false;
                }
            } else {
                if (this.selectedTipoBeneficiario == TipoBeneficiario.ESTAGIARIO) {
                    this.$nextTick(() => {
                        this.beneficiarioEstagiario.dtadmissao = originalValue;
                    });
                } else {
                    this.$nextTick(() => {
                        this.beneficiarioTrabalhador.dtadmissao = originalValue;
                    });
                }
            }
        },
        isFormValid() {
            switch (this.selectedTipoBeneficiario) {
                case TipoBeneficiario.ESTUDANTE:
                    {
                        const {
                            nminstituicao,
                            tpcurso,
                            nuseriecurso,
                            idcurso,
                            document
                        } = this.beneficiarioEstudante;
                        if (
                            this.isNullOrEmpty(nminstituicao) ||
                            this.isNullOrEmpty(tpcurso) ||
                            (this.isNullOrEmpty(idcurso) && tpcurso > 2) ||
                            this.isNullOrEmpty(nuseriecurso) ||
                            this.isNullOrEmpty(document)
                        ) {
                            this.hasErrors = true;
                        } else {
                            this.hasErrors = false;
                        }
                    }
                    break;
                case TipoBeneficiario.ESTAGIARIO:
                    {
                        const {
                            dtadmissao,
                            nminstituicao,
                            dtencerramento,
                            document
                        } = this.beneficiarioEstagiario;
                        if (
                            this.isNullOrEmpty(nminstituicao) ||
                            this.isNullOrEmpty(dtadmissao) ||
                            this.isNullOrEmpty(document) ||
                            this.isNullOrEmpty(dtencerramento) ||
                            !this.isValidAdmissionDate ||
                            !this.isValidClosureDate
                        ) {
                            this.hasErrors = true;
                        } else {
                            this.hasErrors = false;
                        }
                    }
                    break;
                case TipoBeneficiario.TRABALHADOR:
                    {
                        const {
                            idprofissao,
                            vlsalario,
                            dtadmissao,
                            nminstituicao,
                            document
                        } = this.beneficiarioTrabalhador;
                        if (
                            this.isNullOrEmpty(vlsalario) ||
                            this.isNullOrEmpty(idprofissao) ||
                            this.isNullOrEmpty(nminstituicao) ||
                            this.isNullOrEmpty(document) ||
                            this.isNullOrEmpty(dtadmissao) ||
                            !this.isValidAdmissionDate
                        ) {
                            this.hasErrors = true;
                        } else {
                            this.hasErrors = false;
                        }
                    }
                    break;
            }
            const {
                cep,
                district,
                // number,
                municipio,
                street
            } = this.endereco;
            if (
                this.isNullOrEmpty(cep) ||
                this.isNullOrEmpty(district) ||
                // this.isNullOrEmpty(number) ||
                this.isNullOrEmpty(street) ||
                municipio === ""
            ) {
                this.hasErrors = true;
            }
        },

        isNullOrEmpty(value) {
            return value === null || value === "" || value === 0;
        },

        goBack() {
            this.$router.push({ name: "spl-navegantes.step01" });
        },

        addEmail() {
            this.contatosInstituicao.email.push("");
        },
        removeEmail(email) {
            this.contatosInstituicao.email = this.contatosInstituicao.email.filter(
                item => item !== email
            );
        },
        removePhone(telefone) {
            this.contatosInstituicao.telefone = this.contatosInstituicao.telefone.filter(
                item => item !== telefone
            );
        },
        addPhone() {
            this.contatosInstituicao.telefone.push("");
        }
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-6" :crumbs="crumbs" />
            <div class="bg-white shadow-lg p-4  lg:max-w-full my-4 px-2 sm:px-6 ">
                <template v-if="selectedTipoBeneficiario === TipoBeneficiario.TRABALHADOR">
                    <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                        <span class="mt-2 text-base uppercase text-blue-base">
                            Informações trabalhistas
                        </span>
                    </div>

                    <div class="flex space-x-4">
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                CPF/CNPJ *
                            </span>
                            <span>
                                <TheMask v-model="beneficiarioTrabalhador.document" :mask="[
                                    '###.###.###-##',
                                    '##.###.###/####-##'
                                ]" :class="{
                                    'bg-red-100 border-red-400':
                                        hasErrors &&
                                        !beneficiarioTrabalhador.document
                                }" class="h-auto py-2 rounded form-input" required
                                    @input="getInstituicaoTrabalhadorByDocument" />
                                <div v-if="hasErrors &&
                                    !beneficiarioTrabalhador.document
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                        <div class="w-6/12">
                            <span class="text-sm form-label text-gray-dark">
                                Nome da empresa/pessoa *
                            </span>
                            <span>
                                <input v-model="beneficiarioTrabalhador.nminstituicao
                                    " :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioTrabalhador.nminstituicao
                                    }" type="text" class="h-auto py-2 rounded form-input" />
                                <div v-if="hasErrors &&
                                    !beneficiarioTrabalhador.nminstituicao
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                Data de admissão *
                            </span>
                            <span>
                                <input v-model="beneficiarioTrabalhador.dtadmissao" type="text"
                                    class="h-auto py-2 rounded form-input" :class="{
                                        'bg-red-100 border-red-400': hasErrors && (!beneficiarioTrabalhador.dtadmissao || !isValidAdmissionDate),
                                    }" required @input="$event => validateAdmissionDateOnInput($event.target.value)" />
                                <div v-if="!isValidAdmissionDate && beneficiarioTrabalhador.dtadmissao && beneficiarioTrabalhador.dtadmissao.length === 10"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Data inválida
                                </div>
                                <div v-if="!isValidAdmissionDate && beneficiarioTrabalhador.dtadmissao.length === 8"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Data inválida
                                </div>
                                <div v-if="hasErrors &&
                                    !beneficiarioTrabalhador.dtadmissao
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                    </div>

                    <div class="flex space-x-4">
                        <div class="w-6/12">
                            <span class="text-sm form-label text-gray-dark">
                                Profissão *
                            </span>
                            <span>
                                <SearchSelect :selected.sync="beneficiarioTrabalhador.idprofissao" :search-remote="true"
                                    :lazy-load="true" :option-value="$event => $event.nmprofissao"
                                    option-key="idprofissao" name="idprofissao" no-query-param :options-url="`/api/spl-navegantes/profissoes/`
                                        " field-class="py-2"></SearchSelect>
                                <div v-if="hasErrors &&
                                    !beneficiarioTrabalhador.idprofissao
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                Salário *
                            </span>
                            <span>
                                <money class="h-auto py-2 rounded form-input"
                                    v-model="beneficiarioTrabalhador.vlsalario" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioTrabalhador.vlsalario
                                    }" v-bind="money" required />
                                <div v-if="hasErrors &&
                                    !beneficiarioTrabalhador.vlsalario
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                    </div>
                </template>
                <template v-else-if="selectedTipoBeneficiario === TipoBeneficiario.ESTUDANTE">
                    <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                        <span class="mt-2 text-base uppercase text-blue-base">
                            Informações estudante
                        </span>
                    </div>

                    <div class="flex space-x-4">
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                CNPJ *
                            </span>
                            <span>
                                <TheMask v-model="beneficiarioEstudante.document" :mask="['##.###.###/####-##']"
                                    class="h-auto py-2 rounded form-input" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioEstudante.document
                                    }" required @input="getInstituicaoEstudanteByDocument" />
                                <div v-if="hasErrors &&
                                    !beneficiarioEstudante.document
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                        <div class="w-6/12">
                            <span class="text-sm form-label text-gray-dark">
                                Nome da Instituição *
                            </span>
                            <span>
                                <input v-model="beneficiarioEstudante.nminstituicao
                                    " :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioEstudante.nminstituicao
                                    }" type="text" class="h-auto py-2 rounded form-input" />
                                <div v-if="hasErrors &&
                                    !beneficiarioEstudante.nminstituicao
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                    </div>

                    <div class="flex space-x-4 mt-4">
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                Tipo de Curso *
                            </span>
                            <div v-if="hasErrors && !beneficiarioEstudante.tpcurso
                            " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </div>
                        <div v-for="curso in tipoCursos" :key="curso.value" class="flex space-x-4 w-3/12">
                            <span>
                                <input v-model="beneficiarioEstudante.tpcurso" :value="curso.value" :id="curso.name"
                                    type="radio" />
                            </span>
                            <label :for="curso.name" class="text-sm form-label text-gray-dark">
                                {{ curso.name }}
                            </label>
                        </div>
                    </div>

                    <div class="flex space-x-4 mt-4 ">
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                Ano de Inicío *
                            </span>
                            <span>
                                <TheMask v-model="beneficiarioEstudante.nuseriecurso" :mask="['####']"
                                    class="h-auto py-2 rounded form-input" :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioEstudante.nuseriecurso
                                    }" required />
                                <div v-if="hasErrors &&
                                    !beneficiarioEstudante.nuseriecurso
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                        <div class="w-6/12" v-if="beneficiarioEstudante.tpcurso > 2">
                            <span class="text-sm form-label text-gray-dark">
                                Curso *
                            </span>
                            <span>
                                <SearchSelect :selected.sync="beneficiarioEstudante.idcurso"
                                    :search-keys="['nome_curso']" :search-remote="true" :lazy-load="true"
                                    :option-value="$event => $event.nmcurso" option-key="idcurso" name="idcurso"
                                    no-query-param :options-url="`/api/spl-navegantes/cursos/${beneficiarioEstudante.tpcurso}/`
                                        " field-class="py-2" :disabled="Number(beneficiarioEstudante.tpcurso) === 1 ||
                                            Number(beneficiarioEstudante.tpcurso) === 2
                                            "></SearchSelect>
                                <div v-if="hasErrors && (!beneficiarioEstudante.idcurso && beneficiarioEstudante.tpcurso > 2)"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                        <span class="mt-2 text-base uppercase text-blue-base">
                            Informações do estágio
                        </span>
                    </div>

                    <div class="flex space-x-4">
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                CNPJ *
                            </span>
                            <span>
                                <TheMask v-model="beneficiarioEstagiario.document" :mask="[
                                    '###.###.###-##',
                                    '##.###.###/####-##'
                                ]" :class="{
                                    'bg-red-100 border-red-400':
                                        hasErrors &&
                                        !beneficiarioEstagiario.document
                                }" class="h-auto py-2 rounded form-input" required
                                    @input="getInstituicaoEstagiarioByDocument" />
                                <div v-if="hasErrors &&
                                    !beneficiarioEstagiario.document
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                        <div class="w-6/12">
                            <span class="text-sm form-label text-gray-dark">
                                Nome da Instituição *
                            </span>
                            <span>
                                <input v-model="beneficiarioEstagiario.nminstituicao
                                    " :class="{
                                        'bg-red-100 border-red-400':
                                            hasErrors &&
                                            !beneficiarioEstagiario.nminstituicao
                                    }" type="text" class="h-auto py-2 rounded form-input" />
                                <div v-if="hasErrors &&
                                    !beneficiarioEstagiario.nminstituicao
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                    </div>

                    <div class="flex space-x-4">
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                Data de admissão *
                            </span>
                            <span>
                                <input v-model="beneficiarioEstagiario.dtadmissao" type="text"
                                    class="h-auto py-2 rounded form-input" :class="{
                                        'bg-red-100 border-red-400': hasErrors && (!beneficiarioEstagiario.dtadmissao || !isValidAdmissionDate),
                                    }" required @input="$event => validateAdmissionDateOnInput($event.target.value)" />
                                <div v-if="!isValidAdmissionDate && beneficiarioEstagiario.dtadmissao && beneficiarioEstagiario.dtadmissao.length === 10"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Data inválida
                                </div>
                                <div v-if="!isValidAdmissionDate && beneficiarioEstagiario.dtadmissao.length === 8"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Data inválida
                                </div>
                                <div v-if="hasErrors &&
                                    !beneficiarioEstagiario.dtadmissao
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                        <div class="w-3/12">
                            <span class="text-sm form-label text-gray-dark">
                                Data de término *
                            </span>
                            <span>
                                <input v-model="beneficiarioEstagiario.dtencerramento" type="text"
                                    class="h-auto py-2 rounded form-input" :class="{
                                        'bg-red-100 border-red-400': hasErrors && (!beneficiarioEstagiario.dtencerramento || !isValidClosureDate),
                                    }" required @input="$event => validateClosureDateOnInput($event.target.value)" />
                                <div v-if="!isValidClosureDate && beneficiarioEstagiario.dtadmissao && beneficiarioEstagiario.dtadmissao.length === 10"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Data inválida
                                </div>
                                <div v-if="!isValidClosureDate && beneficiarioEstagiario.dtadmissao.length === 8"
                                    class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Data inválida
                                </div>
                                <div v-if="hasErrors &&
                                    !beneficiarioEstagiario.dtencerramento
                                " class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                    Campo obrigatório
                                </div>
                            </span>
                        </div>
                    </div>
                </template>
                <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Endereço da Instituição
                    </span>
                </div>
                <div class="flex space-x-4 mt-2">
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            CEP *
                        </span>
                        <span>
                            <TheMask v-model="endereco.cep" :mask="['##.###-###']" :class="{
                                'bg-red-100 border-red-400':
                                    hasErrors && !endereco.cep
                            }" class="h-auto py-2 rounded form-input" required @input='onCepChange' />
                            <div v-if="hasErrors && !endereco.cep"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                    <div class="w-4/12">
                        <span class="text-sm form-label text-gray-dark">
                            Endereço *
                        </span>
                        <span>
                            <input v-model="endereco.street" :class="{
                                'bg-red-100 border-red-400':
                                    hasErrors && !endereco.street
                            }" type="text" class="h-auto py-2 rounded form-input" />
                            <div v-if="hasErrors && !endereco.street"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                    <div class="w-2/12">
                        <span class="text-sm form-label text-gray-dark">
                            Número
                        </span>
                        <span>
                            <input v-model="endereco.number" type="number" class="h-auto py-2 rounded form-input" />
                        </span>
                    </div>
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            Bairro *
                        </span>
                        <span>
                            <input v-model="endereco.district" :class="{
                                'bg-red-100 border-red-400':
                                    hasErrors && !endereco.district
                            }" type="text" class="h-auto py-2 rounded form-input" />
                            <div v-if="hasErrors && !endereco.district"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            Complemento
                        </span>
                        <span>
                            <input v-model="endereco.complement" type="text" class="h-auto py-2 rounded form-input" />
                        </span>
                    </div>

                    <div class="w-3/12">
                        <span class="text-sm form-label text-gray-dark">
                            Cidade/Estado *
                        </span>
                        <span>
                            <search-select :selected.sync="endereco.municipio" :search-keys="['name']"
                                :search-remote="true" :lazy-load="true"
                                options-url="/api/registry/location?take=50&paginate=false" option-key="id"
                                :option-value="city =>
                                    city.name +
                                    ' / ' +
                                    city.state.initials
                                    " field-class="py-2" name="city" placeholder="Selecione uma cidade..."
                                @changed="cityEnderecoSelected" />
                            <div v-if="hasErrors && !endereco.municipio"
                                class="alert alert-danger text-red-500 text-xs mr-2 font-bold">
                                Campo obrigatório
                            </div>
                        </span>
                    </div>
                </div>
                <div class="flex justify-between w-full mb-8 border-b-2 border-blue-base">
                    <span class="mt-2 text-base uppercase text-blue-base">
                        Contato
                    </span>
                </div>

                <div class="flex space-x-4 mt-2">
                    <div class="w-6/12">
                        <span class="text-sm form-label text-gray-dark">Email</span>
                        <span v-for="(email, index) in contatosInstituicao.email" :key="index"
                            class="flex items-center gap-5 mb-3">
                            <input v-model="contatosInstituicao.email[index]" type="text"
                                class="h-auto py-2 mb-0 rounded form-input" />

                            <div @click="removeEmail(email)">
                                <Icon v-if="contatosInstituicao.email.length > 1" icon="x"
                                    class="h-6 cursor-pointer fill-current" />
                            </div>
                        </span>
                    </div>
                    <div class="w-6/12">
                        <span class="text-sm form-label text-gray-dark">
                            Telefone
                        </span>
                        <span v-for="(telefone,
                            index) in contatosInstituicao.telefone" :key="index" class="flex items-center gap-5 mb-3">
                            <TheMask v-model="contatosInstituicao.telefone[index]" :mask="['(##) #####.####']"
                                class="h-auto py-2 mb-0 rounded form-input" required style="flex: 1" />
                            <div @click="removePhone(telefone)">
                                <Icon v-if="contatosInstituicao.telefone.length > 1
                                " icon="x" class="h-6 cursor-pointer fill-current" />
                            </div>
                        </span>
                    </div>
                </div>

                <div class="flex space-x-4 mt-2 ">
                    <div class="w-6/12">
                        <button @click="addEmail" class="px-2 py-2 mb-2 form-new-green sm:py-1">
                            <div class="flex items-center justify-center">
                                Novo Email
                            </div>
                        </button>
                    </div>
                    <div class="w-6/12">
                        <button @click="addPhone" class="px-2 py-2 mb-2 form-new-green sm:py-1">
                            <div class="flex items-center justify-center">
                                Novo Telefone
                            </div>
                        </button>
                    </div>
                </div>

                <div
                    class="mt-10 flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4 justify-between">
                    <div class="flex flex-col space-y-4 lg:items-center lg:flex-row lg:space-y-0 lg:space-x-4">
                        <button @click="goBack()" type="button"
                            class="flex items-center justify-center py-2 form-submit md:w-auto"
                            style="background: #828282">
                            Voltar
                        </button>
                        <button @click="onNextStepClick()" type="button"
                            class="flex items-center justify-center py-2 form-submit md:w-auto">
                            Avançar
                        </button>
                    </div>
                    <div style="color: #1767b2">Passo 2 de 3</div>
                </div>
            </div>
        </div>
    </div>
</template>
