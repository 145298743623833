import { Component, Prop, Emit } from "vue-property-decorator";
import Modal from "@/core/components/Modal";
import SecurityService from "@/core/services/SecurityService";

@Component
class SolicitConfirmation extends SecurityService {
    @Prop({ default: null })
    organization;

    @Prop({ default: false })
    modal;

    @Emit()
    Confirm() {}

    constructor() {
        super();
        this.visible = false;
        this.isLoading = false;
    }

    confirm() {
        this.isLoading = true;
        this.$Progress.start();
        const data = {
            organization_id: this.organization.id
        };

        let info = this.postConfirmSubordinate(data, "solicitation");
        this.visible = false;
        this.isLoading = false;

        if (info) this.Confirm();
    }

    open() {
        this.visible = true;
    }

    close() {
        this.visible = false;
    }

    getContent() {
        return (
            <div class="container p-3 mt-5">
                <div class="mb-5 row justify-content-center">
                    <h5>
                        Deseja solicitar vínculo com{" "}
                        <strong>{this.organization.name}</strong>?
                    </h5>
                </div>
                <div class="row justify-content-center">
                    <button class="form-new-blue" onClick={this.close}>
                        Cancelar
                    </button>
                    <button class="form-new-blue" onClick={this.confirm}>
                        Confirmar
                    </button>
                </div>
            </div>
        );
    }

    getContentModal() {
        return (
            <portal to="modals" multiple>
                <Modal width="800" noPadding={true} onClose={this.close}>
                    <div slot="header" style="text-align: right;">
                        <button
                            class="btn btn-outline-secondary mt-2 mr-2"
                            onClick={this.close}
                        >
                            Fechar
                        </button>
                    </div>
                    <div slot="body">{this.getContent()}</div>
                    <div slot="footer" />
                </Modal>
            </portal>
        );
    }

    render() {
        return (
            <div class="d-inline">
                <button
                    class="btn btn-bluegradient btn-solicit"
                    disabled={this.isLoading}
                    onClick={this.open}
                    title="Solicitar vínculo com esta organização"
                >
                    Solicitar Vínculo
                </button>
                {this.visible
                    ? this.modal
                        ? this.getContentModal()
                        : this.getContent()
                    : ""}
            </div>
        );
    }
}

export default SolicitConfirmation;
