export default [
    {
        path: "/notificacoes",
        name: "notifications.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-notifications" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            hideSidebar: true
        }
    }
];
