<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip, LIcon } from "vue2-leaflet";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCheck,
    faTimes,
    faMap
} from "@fortawesome/free-solid-svg-icons";
import SearchSelect from "@/core/components/SearchSelect";
import { TheMask } from "vue-the-mask";
import Loader from "@/core/components/loaders/BallClipRotate";
import { hourFormat } from "@/core/services/helpers";

export default {
    components: {
        Breadcrumbs,
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LTooltip,
        Loader,
        FontAwesomeIcon,
        "search-select": SearchSelect,
        "the-mask": TheMask
    },
    props: {
        id: { required: false }
    },
    data: () => {
        return {
            faChevronDown,
            faChevronUp,
            faCheck,
            faTimes,
            faMap,
            loader: false,
            loaderSearch: false,
            loaderMap: false,
            showAllButtons: true,
            showButtonOriginPoints: true,
            showButtonPoints: true,
            showButtonDestinyPoints: true,
            //Info Api Google
            apiKeyGoogle: "AIzaSyAkcloxsrUNIO4Z0JInU_L5Hp9zHTHvN9c",
            latSearchGgle: "",
            lngSearchGgle: "",
            addressSearchGgle: "",
            numberAddressSearchGgle: "",
            cityAddressSearchGgle: "",
            zipAddressSearchGgle: "",
            districtAddressSearchGgle: "",

            title: "Linhas",
            errors: false,
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Linhas", path: "lines.index" },
                { name: "Georreferenciamento", path: "lines.sessions" },
                { name: "Editar Georreferenciamento", path: "" }
            ],
            searchAddress: {
                street: "",
                number: "",
                district: "",
                zip: "",
                city: ""
            },
            line: {
                line: "",
                name: "",
                itinerary: "",
                ordTravel: "",
                classification: "",
                landing: "",
                originPoint: {},
                destinyPoint: {},
                points: [],
                markers: []
            },
            posSanfOpen: 0,
            countPointsNotOk: 0,
            hideCardPointSessions: false,
            titlePage: "Novo Registro de Seção",
            indexAlterPoint: "",
            block: false,
            hideCardMap: false,
            messageAlert: false,
            showMessageAlert: false,
            typePoint: "",
            textAlert: "",
            subTextAlert: "",
            search: "",
            closed: 0,
            zoom: 7.5,
            zoomMiniMap: 9,
            center: L.latLng(-27.597456, -48.53366),
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            withPopup: L.latLng(47.41322, -1.219482),
            withTooltip: L.latLng(47.41422, -1.250482),
            currentZoom: 7.5,
            currentCenter: L.latLng(-27.597456, -48.53366),
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5,
                scrollWheelZoom: true
            },
            icon: L.icon({
                iconUrl:
                    "https://www.shareicon.net/data/512x512/2016/05/29/772542_interface_512x512.png",
                iconSize: [38, 38],
                iconAnchor: [16, 37]
            }),
            staticAnchor: [16, 37],
            customText: "Foobar",
            iconSize: [38, 38],
            firstIcon:
                "https://www.shareicon.net/data/128x128/2015/09/15/640966_public_512x512.png",
            middleIcon:
                "https://www.shareicon.net/data/512x512/2016/05/29/772542_interface_512x512.png",
            lastIcon:
                "https://www.shareicon.net/data/128x128/2015/09/17/642215_flag_512x512.png",
            showMap: true,
            showMiniMap: false,
            loadTimeMap: true
        };
    },
    async mounted() {
        if (this.id) {
            this.titlePage = "Editar Registro da rota";
            try {
                const response = await this.$axios.get(
                    "/api/lines/route/" + parseInt(this.id)
                );

                this.line.name = response.data.name;
                this.line.deitinerario = response.data.deitinerario;
                this.line.points = response.data["points"];
                this.line.originPoint = response.data["origin_point"];
                this.line.destinyPoint = response.data["destiny_point"];
                this.line.schedules = response.data["line_schedules"];
                this.convertVariablesInMarks();
            } catch (e) {
                this.status = "rejected";
                this.$Progress.finish();
            }
        }
    },
    methods: {
        async updateRoute() {
            try {
                this.$http
                    .put("/api/lines/route/" + this.id, this.line)
                    .then(response => {
                        this.$toast.success({
                            title: "OK",
                            message: response.data.success
                        });
                        this.showModal = false;
                        this.$router.push({ name: "lines.sessions" });
                    });
            } catch (err) {
                throw err;
            }
        },
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        showLongText() {
            this.showParagraph = !this.showParagraph;
        },
        innerClick() {
            alert("Click!");
        },
        removeMarker(index) {
            this.line.markers.splice(index, 1);
        },
        verifyPoints() {
            if (this.line.markers.length) {
                return false;
            }
        },
        blockAdd() {
            this.block = true;
        },
        showAdd() {
            if (this.hideCardPointSessions) this.block = false;
        },
        closeSelectSessions() {
            this.typePoint = "";
            this.hideCardMap = false;
        },
        setHideCardPosition() {
            this.countPointsOk();
            let h = this.setHideChideCardPointSessions;
            this.setHideChideCardPointSessions = !h;
        },
        validatePoints() {
            //validate origin
            if (this.line.originPoint.gelongitude) {
                this.showButtonOriginPoints = false;
            } else {
                this.showButtonOriginPoints = true;
                this.showAllButtons = false;
            }

            //validate points
            this.showButtonPoints = false;

            for (let m of this.line.points) {
                let lng = m.origin_point.gelongitude;
                let id = m.origin_point.idpontosecao;
                let idOr = this.line.originPoint.idpontosecao;
                let idDes = this.line.originPoint.idpontosecao;

                if (!lng && id !== idOr && id !== idDes) {
                    this.showButtonPoints = true;
                    break;
                }
            }

            //validate destiny
            if (this.line.destinyPoint.gelongitude) {
                this.showButtonDestinyPoints = false;
            }
        },
        convertVariablesInMarks() {
            let markOrigin = {
                id: this.line.originPoint.idpontosecao,
                lat: this.line.originPoint.gelatitude,
                lng: this.line.originPoint.gelongitude,
                name: this.line.originPoint.nmlocalidade,
                description: this.line.originPoint.delocalidade
            };

            this.line.markers.push(markOrigin);

            for (let m of this.line.points) {
                if (
                    m.origin_point.idpontosecao !==
                        this.line.originPoint.idpontosecao &&
                    m.origin_point.idpontosecao !==
                        this.line.destinyPoint.idpontosecao
                ) {
                    let markPoints = {
                        id: "",
                        lat: "",
                        lng: "",
                        name: "",
                        description: ""
                    };

                    markPoints.id = m.origin_point.idpontosecao;
                    markPoints.lat = m.origin_point.gelatitude;
                    markPoints.lng = m.origin_point.gelongitude;
                    markPoints.name = m.origin_point.nmlocalidade;
                    markPoints.description = m.origin_point.delocalidade;

                    this.line.markers.push(markPoints);
                }
            }

            let markDestiny = {
                id: this.line.destinyPoint.idpontosecao,
                lat: this.line.destinyPoint.gelatitude,
                lng: this.line.destinyPoint.gelongitude,
                name: this.line.destinyPoint.nmlocalidade,
                description: this.line.destinyPoint.delocalidade
            };

            this.line.markers.push(markDestiny);

            this.validatePoints();
            this.countPointsOk();
        },
        countPointsOk() {
            let count = 0;
            let maxPoints = this.line.markers.length - 2;

            for (let m of this.line.markers) {
                if (m.update) {
                    count++;
                }
            }

            this.countPointsNotOk = maxPoints - count;
        },
        setFormSearchNull() {
            this.searchAddress = {
                street: "",
                number: "",
                district: "",
                zip: "",
                city: ""
            };
        },
        async addMarker(event) {
            event.latlng.city_id = null;

            if (!this.messageAlert && this.hideCardMap && !this.block) {
                event.latlng.position = this.typePoint;

                let sizeArray = this.line.markers.length;
                let lastPosition = sizeArray - 1;

                if (this.typePoint === "sessionPoint") {
                    if (Number.isInteger(this.indexAlterPoint)) {
                        //flag alteração de status do ponto
                        event.latlng.update = true;
                        event.latlng.id = this.line.markers[
                            this.indexAlterPoint
                        ].id;
                        event.latlng.name = this.line.markers[
                            this.indexAlterPoint
                        ].name;
                        this.line.markers[this.indexAlterPoint] = event.latlng;

                        this.posSanfOpen = this.indexAlterPoint + 1;

                        this.countPointsOk();

                        this.indexAlterPoint = "";
                        this.block = true;
                        this.hideCardPointSessions = false;
                    } else {
                        event.latlng.name =
                            "ponto de seção criado" + lastPosition;
                        //Temporario, apenas para Criação de novos pontos de seção
                        if (
                            this.line.markers[lastPosition].position ===
                            "finishPoint"
                        ) {
                            let marker = this.line.markers[lastPosition];
                            this.line.markers[lastPosition] = event.latlng;
                            this.line.markers.push(marker);
                        } else {
                            this.line.markers.push(event.latlng);
                        }
                    }
                } else {
                    this.block = true;
                    this.loaderSearch = true;
                    let city = await this.searchCityByLatLng(event.latlng);
                    this.loaderSearch = false;

                    if (city) {
                        event.latlng.city = city;
                        event.latlng.city_id = city.id;
                    } else {
                        event.latlng.city_id = null;
                    }

                    if (sizeArray > 0) {
                        if (this.typePoint === "initialPoint") {
                            event.latlng.id = this.line.markers[0].id;
                            event.latlng.name = this.line.markers[0].name;
                            this.line.markers[0] = event.latlng;
                        } else {
                            if (this.id) {
                                event.latlng.id = this.line.markers[
                                    sizeArray - 1
                                ].id;
                                event.latlng.name = this.line.markers[
                                    sizeArray - 1
                                ].name;
                                this.line.markers[sizeArray - 1] = event.latlng;
                            } else {
                                //Apenas temporário para criação
                                event.latlng.name = "ponto final xx";
                                this.line.markers.push(event.latlng);
                            }
                        }
                    } else {
                        event.latlng.name =
                            "ponto de seção criado" + lastPosition;
                        this.line.markers.push(event.latlng);
                    }

                    this.typePoint = "";
                    this.hideCardMap = false;
                }
                this.verifyPoints();
            }
        },
        formatHour(time) {
            return hourFormat(time);
        },
        async searchLatByCity(city, address = false) {
            let proxyurl = "https://cors-anywhere.herokuapp.com/";
            let url = "";

            if (address) {
                url =
                    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                    this.searchAddress.number +
                    " " +
                    this.searchAddress.street +
                    " " +
                    this.searchAddress.zip +
                    " " +
                    this.searchAddress.district +
                    ", " +
                    this.searchAddress.city +
                    "&key=AIzaSyAkcloxsrUNIO4Z0JInU_L5Hp9zHTHvN9c";
            } else {
                url =
                    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                    city.name +
                    ", " +
                    city.state.initials +
                    ", BRA&key=AIzaSyAkcloxsrUNIO4Z0JInU_L5Hp9zHTHvN9c";
            }

            //let lat = '';
            //let lng = '';

            await this.$axios.get(proxyurl + url).then(response => {
                //address = response.data.results[0]['address_components'];

                if (response.data.status === "OK") {
                    this.center = L.latLng(
                        response.data.results[0].geometry.location.lat,
                        response.data.results[0].geometry.location.lng
                    );
                    this.zoom = 15;

                    if (address) {
                        let marker = {
                            latlng: {
                                lat:
                                    response.data.results[0].geometry.location
                                        .lat,
                                lng:
                                    response.data.results[0].geometry.location
                                        .lng,
                                city_id: null
                            }
                        };
                        this.block = false;
                        this.addMarker(marker);
                        this.block = true;
                    }
                }

                this.setFormSearchNull();
            });
        },
        async searchCityByLatLng(latLng) {
            let proxyurl = "https://cors-anywhere.herokuapp.com/";
            let url =
                "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                latLng.lat +
                "," +
                latLng.lng +
                "&key=AIzaSyAkcloxsrUNIO4Z0JInU_L5Hp9zHTHvN9c";
            let address = "";
            let c = false;

            await this.$axios.get(proxyurl + url).then(response => {
                address = response.data.results[0]["address_components"];
            });

            for (let a of address) {
                if (a["types"][0] == "administrative_area_level_2") {
                    c = await this.searchCity(a.long_name);
                }
            }

            return c;
        },
        async searchCity(name) {
            let cityObj = false;
            await this.$http
                .get(
                    "/api/registry/location?take=50&paginate=false&name=" + name
                )
                .then(data => {
                    if (data.data) {
                        let city = data.data;
                        if (city.length === 1) {
                            cityObj = city[0];
                        }
                    }
                });
            return cityObj;
        },
        showInitialPoint() {
            if (this.showButtonOriginPoints) {
                this.hideCardMap = true;
                this.textAlert =
                    "Clique em um ponto do mapa, para definir o ponto de partida: ";
                this.subTextAlert =
                    this.line.markers[0].name +
                    " (" +
                    this.line.markers[0].description +
                    ")";
                this.messageAlert = true;
                this.showMessageAlert = true;
                this.showAllButtons = true;
                this.typePoint = "initialPoint";

                setTimeout(() => (this.showMessageAlert = false), 4000);
                setTimeout(() => (this.messageAlert = false), 5000);
                setTimeout(() => (this.block = false), 5000);
                setTimeout(() => (this.textAlert = ""), 5000);
            }
        },
        showSessionPoints() {
            this.hideCardMap = true;
            this.textAlert = "Clique no ponto que deseja alterar a localização";
            this.subTextAlert =
                "Após defini-los, selecione um ponto do mapa como referência";
            this.messageAlert = true;
            this.showMessageAlert = true;

            if (this.id) this.block = true;

            setTimeout(() => (this.showMessageAlert = false), 4000);
            setTimeout(() => (this.messageAlert = false), 5000);
            setTimeout(() => (this.textAlert = ""), 5000);
            //setTimeout(() => this.block = false, 5000);
            setTimeout(() => (this.subTextAlert = ""), 5000);
            setTimeout(() => (this.typePoint = "sessionPoint"), 5000);
        },
        showFinishPoints() {
            this.hideCardMap = true;
            this.textAlert =
                "Clique em um ponto do mapa, para definir o ponto final: ";
            this.subTextAlert =
                this.line.destinyPoint.nmlocalidade +
                " (" +
                this.line.destinyPoint.delocalidade +
                ")";
            this.messageAlert = true;
            this.showMessageAlert = true;
            this.typePoint = "finishPoint";

            setTimeout(() => (this.showMessageAlert = false), 3000);
            setTimeout(() => (this.messageAlert = false), 4000);
            setTimeout(() => (this.block = false), 4000);
            setTimeout(() => (this.textAlert = ""), 4000);
            setTimeout(() => (this.subTextAlert = ""), 4000);
        },
        showM() {
            this.loaderMap = true;
            this.loadTimeMap = false;
            this.showMap = true;
            this.showMiniMap = false;
            setTimeout(() => (this.loaderMap = false), 1200);
            setTimeout(() => (this.loadTimeMap = true), 1300);
        },
        closeM() {
            this.loaderMap = true;
            this.loadTimeMap = false;
            this.showMap = false;
            this.showMiniMap = true;
            setTimeout(() => (this.loaderMap = false), 1200);
            setTimeout(() => (this.loadTimeMap = true), 1300);
        },
        updatePointSession(position) {
            this.indexAlterPoint = position;
            this.hideCardPointSessions = true;
            setTimeout(() => (this.block = false), 1000);
        },
        citySelected(city) {
            this.searchLatByCity(city);
        },
        async searchLatLngByAddress(position) {
            this.loader = true;
            this.indexAlterPoint = position;

            await this.searchLatByCity(false, true);

            this.loader = false;
        },
        showMessagePointInvalid(nameLine) {
            this.showM();
            this.messageAlert = true;
            this.showMessageAlert = true;
            this.hideCardMap = true;
            this.textAlert = "Não foi definida a localização: ";
            this.subTextAlert = "do ponto " + nameLine;

            window.scrollTo(0, 0);

            setTimeout(() => (this.showMessageAlert = false), 6000);
            setTimeout(() => (this.messageAlert = false), 7000);
            setTimeout(() => (this.hideCardMap = false), 7000);
            setTimeout(() => (this.textAlert = ""), 7000);
            setTimeout(() => (this.subTextAlert = ""), 7000);
        },
        dateOfWeekend(dateW) {
            if (dateW == 1) return "Segunda-Feira";
            if (dateW == 2) return "Terça-Feira";
            if (dateW == 3) return "Quarta-Feira";
            if (dateW == 4) return "Quinta-Feira";
            if (dateW == 5) return "Sexta-Feira";
            if (dateW == 6) return "Sábado";
            if (dateW == 7) return "Domingo";
        },
        async save() {
            for (let m of this.line.markers) {
                if (!m.id || !m.lat || !m.lng) {
                    this.showMessagePointInvalid(m.name);
                    return false;
                }
            }

            if (this.id) {
                this.updateRoute();
            } else {
                this.$http
                    .post("/api/lines/route", this.line)
                    .then(response => {
                        this.$toast.success({
                            title: "OK",
                            message: response.data.success
                        });
                        this.showModal = false;
                        this.$router.push({ name: "lines.sessions" });
                    });
            }
        }
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs :crumbs="crumbs" />
            <form
                @submit.prevent="save"
                id="travel-form"
                class="flex flex-col space-y-4"
            >
                <div class="w-full p-2">
                    <div
                        class="flex flex-wrap justify-between mt-2 align-baseline border-b-2 border-blue-base"
                    >
                        <h1 class="pt-2 uppercase text-blue-base">
                            Informações da Linha
                        </h1>
                    </div>

                    <div
                        class="grid grid-flow-row gap-6 mt-5 text-xs md:grid-cols-2 lg:grid-cols-3 justify-content-between"
                    >
                        <div class="flex flex-col w-full ">
                            <label class="form-label">
                                Origem
                            </label>
                            <div>
                                {{
                                    line.originPoint.nmlocalidade +
                                        " (" +
                                        line.originPoint.delocalidade +
                                        ")"
                                }}
                            </div>
                        </div>
                        <div class="flex flex-col w-full">
                            <label class="form-label">
                                Destino
                            </label>
                            <div>
                                {{
                                    line.destinyPoint.nmlocalidade +
                                        " (" +
                                        line.destinyPoint.delocalidade +
                                        ")"
                                }}
                            </div>
                        </div>
                        <div class="flex flex-col w-full">
                            <label class="form-label">
                                Descrição do Itinerário
                            </label>
                            <div>
                                {{ line.deitinerario }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-wrap justify-between mt-2 align-baseline border-b-2 border-blue-base"
                    >
                        <h1 class="pt-2 uppercase text-blue-base">
                            {{ titlePage }}
                        </h1>
                    </div>
                    <div class="w-full p-2">
                        <div
                            class="flex-col messageAlert"
                            v-if="loaderMap"
                            style="background-color: #ffffff;
                        position: inherit;
                        height: 100vh"
                        >
                            <div class="p-2">
                                <img
                                    src="@/core/assets/images/loader-logo.gif"
                                />
                            </div>
                        </div>
                        <div
                            class="flex items-center w-full justify-content-center h-300 "
                            v-bind:class="{ 'h-500': showMap }"
                        >
                            <l-map
                                v-if="showMap && loadTimeMap"
                                :zoom="zoom"
                                :center="center"
                                :options="mapOptions"
                                @update:center="centerUpdate"
                                @update:zoom="zoomUpdate"
                                @click="addMarker"
                                style="z-index: 1;"
                            >
                                <l-tile-layer
                                    :url="url"
                                    :attribution="attribution"
                                />
                                <div
                                    v-if="messageAlert"
                                    class="flex-col messageAlert"
                                >
                                    <div class="p-2">
                                        {{ textAlert }}
                                    </div>
                                    <div class="p-2">
                                        {{ subTextAlert }}
                                    </div>
                                </div>

                                <div
                                    v-if="loaderSearch"
                                    class="flex-col messageAlert"
                                    style="background-color: #ffffff"
                                >
                                    <div class="p-2">
                                        <img
                                            src="@/core/assets/images/loader-logo.gif"
                                        />
                                    </div>
                                </div>

                                <div
                                    class="p-2 card-info-map box-search-map"
                                    v-if="typePoint === 'sessionPoint'"
                                    @mouseover="blockAdd()"
                                    @mouseleave="showAdd()"
                                >
                                    <div
                                        class="flex flex-col items-center w-full justify-content-center"
                                    >
                                        <search-select
                                            class="w-full"
                                            :search-keys="['name']"
                                            :search-remote="true"
                                            :lazy-load="true"
                                            options-url="/api/registry/location?take=50&paginate=false"
                                            option-key="id"
                                            :option-value="
                                                city =>
                                                    city.name +
                                                    ' / ' +
                                                    city.state.initials
                                            "
                                            field-class="py-2"
                                            name="city"
                                            placeholder="consulte uma cidade ou localização no mapa"
                                            @changed="citySelected"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="p-2 card-info-map "
                                    v-if="typePoint === 'sessionPoint'"
                                    style="min-height: 120px;margin-left: 60%; width: 40%"
                                    @mouseover="blockAdd()"
                                    @mouseleave="showAdd()"
                                >
                                    <div
                                        class="flex flex-col items-center w-full justify-content-center"
                                    >
                                        <div
                                            class="text-card-map"
                                            v-if="hideCardPointSessions"
                                        >
                                            <div class="mb-2">
                                                <div>
                                                    {{ countPointsNotOk }}
                                                    pontos de seção não foram
                                                    alterados
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="w-full overflow-scroll text-card-map"
                                            v-else
                                            style="max-height: 410px;"
                                        >
                                            <div
                                                class="mb-2"
                                                v-for="(marker,
                                                index) in line.markers"
                                                :key="index"
                                            >
                                                <div
                                                    v-if="
                                                        index !== 0 &&
                                                            index !==
                                                                line.markers
                                                                    .length -
                                                                    1
                                                    "
                                                    class="w-full p-2 text-xs bg-white border shadow text-blue-base"
                                                >
                                                    <div
                                                        class="flex items-center cursor-pointer justify-content-center"
                                                        @click="
                                                            posSanfOpen = index
                                                        "
                                                    >
                                                        <span
                                                            v-bind:class="{
                                                                'text-gray-dark font-10':
                                                                    posSanfOpen !==
                                                                    index
                                                            }"
                                                            >Ponto:
                                                            {{
                                                                marker.name
                                                            }}</span
                                                        >
                                                        <span
                                                            v-if="marker.update"
                                                            class="ml-2 text-green-600"
                                                            style="font-size: 8px"
                                                            ><FontAwesomeIcon
                                                                :icon="faCheck"
                                                            ></FontAwesomeIcon>
                                                            alterado</span
                                                        >

                                                        <span
                                                            v-else
                                                            class="ml-2 text-red-500"
                                                            style="font-size: 8px"
                                                        >
                                                            <FontAwesomeIcon
                                                                :icon="faTimes"
                                                            ></FontAwesomeIcon>
                                                            inalterado
                                                        </span>
                                                    </div>

                                                    <div
                                                        v-if="
                                                            posSanfOpen ===
                                                                index
                                                        "
                                                    >
                                                        <form
                                                            v-if="!loader"
                                                            v-on:submit.prevent="
                                                                searchLatLngByAddress(
                                                                    index
                                                                )
                                                            "
                                                            class="flex flex-wrap font-light text-gray-500"
                                                            style="font-size: 10px"
                                                        >
                                                            <div
                                                                class="w-1/3 p-2"
                                                            >
                                                                <div>rua</div>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        class="form-points-map"
                                                                        v-model="
                                                                            searchAddress.street
                                                                        "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="w-1/3 p-2"
                                                            >
                                                                <div>
                                                                    numero
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        class="form-points-map"
                                                                        v-model="
                                                                            searchAddress.number
                                                                        "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="w-1/3 p-2"
                                                            >
                                                                <div>cep</div>
                                                                <div>
                                                                    <the-mask
                                                                        class="form-points-map"
                                                                        :mask="[
                                                                            '#####-###'
                                                                        ]"
                                                                        v-model="
                                                                            searchAddress.zip
                                                                        "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="w-1/3 p-2"
                                                            >
                                                                <div>
                                                                    bairro
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        class="form-points-map"
                                                                        v-model="
                                                                            searchAddress.district
                                                                        "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="w-1/3 p-2"
                                                            >
                                                                <div>
                                                                    cidade
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        class="form-points-map"
                                                                        v-model="
                                                                            searchAddress.city
                                                                        "
                                                                    />
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div
                                                            v-if="loader"
                                                            class="flex w-full p-2 justify-content-center "
                                                        >
                                                            <loader
                                                                wrapperClass="text-blue-700"
                                                                loaderClass="w-10 h-10"
                                                            />
                                                        </div>
                                                        <div
                                                            class="flex w-full justify-content-center"
                                                        >
                                                            <div
                                                                class="py-2 font-bold cursor-pointer form-submit w-fit"
                                                                @click="
                                                                    searchLatLngByAddress(
                                                                        index
                                                                    )
                                                                "
                                                            >
                                                                Definir Ponto
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="flex items-center p-2 justify-content-center"
                                                        >
                                                            <div
                                                                class="w-full border"
                                                            ></div>
                                                            <div
                                                                class="p-1 w-fit"
                                                            >
                                                                Ou
                                                            </div>
                                                            <div
                                                                class="w-full border"
                                                            ></div>
                                                        </div>
                                                        <div>
                                                            <div
                                                                class="py-2 font-bold cursor-pointer btn btn-blue"
                                                                @click="
                                                                    updatePointSession(
                                                                        index
                                                                    )
                                                                "
                                                            >
                                                                <FontAwesomeIcon
                                                                    :icon="
                                                                        faMap
                                                                    "
                                                                ></FontAwesomeIcon>
                                                                definir com um
                                                                clique no mapa
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="py-2 font-bold cursor-pointer btn btn-red-2"
                                            @click="closeSelectSessions()"
                                        >
                                            finalizar seleção
                                        </div>

                                        <div
                                            class="cursor-pointer"
                                            @click="setHideCardPosition()"
                                        >
                                            <FontAwesomeIcon
                                                :icon="faChevronDown"
                                                v-if="hideCardPointSessions"
                                            ></FontAwesomeIcon>
                                            <FontAwesomeIcon
                                                :icon="faChevronUp"
                                                v-else
                                            ></FontAwesomeIcon>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-col card-info-map subtitle-map ml-1p "
                                    v-if="typePoint === 'sessionPoint'"
                                >
                                    <div class="p-2">
                                        * clique no local desejado no mapa para
                                        adicionar um ponto de seção
                                    </div>
                                    <div class="p-2">
                                        * clique sobre o ponto de seção, para
                                        desmarca-lo da rota
                                    </div>
                                </div>
                                <div
                                    class="p-2 card-info-map "
                                    v-bind:class="{ 'hide-card': hideCardMap }"
                                >
                                    <div
                                        class="flex flex-col items-center w-full justify-content-center"
                                        v-if="!hideCardMap"
                                    >
                                        <div
                                            class="text-card-map"
                                            v-if="line.markers.length === 0"
                                        >
                                            Não foi definido nenhum ponto de
                                            seção<br />
                                            para esta linha.
                                        </div>
                                        <div class="text-card-map" v-else>
                                            Selecione uma das ações abaixo.
                                        </div>
                                        <div
                                            class="flex items-center w-full p-2 justify-content-center"
                                        >
                                            <div
                                                class="flex flex-col items-center p-2 ml-2 mr-2 cursor-pointer btn-util-map"
                                                @click="showInitialPoint"
                                                v-bind:class="{
                                                    'card-none': !showButtonOriginPoints
                                                }"
                                            >
                                                <div
                                                    class="p-5 mb-2 border-2 border-white rounded-full"
                                                    v-bind:class="{
                                                        'bg-blue-background': showButtonOriginPoints
                                                    }"
                                                >
                                                    <img
                                                        src="@/core/assets/images/bus_128x128.png"
                                                        style="height: 35px;width: 35px;"
                                                    />
                                                </div>
                                                <div
                                                    v-if="
                                                        line.markers.length > 0
                                                    "
                                                >
                                                    Alterar Ponto Inicial da
                                                    Rota
                                                </div>
                                                <div v-else>
                                                    Definir Ponto Inicial da
                                                    Rota
                                                </div>
                                            </div>
                                            <div
                                                class="flex flex-col items-center p-2 ml-2 mr-2 cursor-pointer btn-util-map"
                                                @click="showSessionPoints"
                                                v-if="
                                                    line.markers.length > 0 &&
                                                        showButtonPoints &&
                                                        showAllButtons
                                                "
                                            >
                                                <div
                                                    class="p-5 mb-2 border-2 border-white rounded-full"
                                                >
                                                    <img
                                                        src="@/core/assets/images/point_session_512x512.png"
                                                        style="height: 35px;width: 35px;"
                                                    />
                                                </div>
                                                <div>
                                                    Definir Pontos de Seção da
                                                    Rota
                                                </div>
                                            </div>
                                            <div
                                                class="flex flex-col items-center p-2 ml-2 mr-2 cursor-pointer btn-util-map"
                                                @click="showFinishPoints"
                                                v-if="
                                                    line.markers.length > 0 &&
                                                        showButtonDestinyPoints &&
                                                        showAllButtons
                                                "
                                            >
                                                <div
                                                    class="p-5 mb-2 bg-white border-2 border-white rounded-full"
                                                >
                                                    <img
                                                        src="@/core/assets/images/finish_point_512x512.png"
                                                        style="height: 40px;width: 40px;"
                                                    />
                                                </div>
                                                <div>
                                                    Definir Ponto Final da Rota
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="line.markers.length > 1"
                                            class="py-2 font-bold cursor-pointer btn btn-blue"
                                            @click="closeM"
                                        >
                                            Finalizar Rota
                                        </div>
                                    </div>
                                </div>
                                <l-marker
                                    v-for="(marker, index) in line.markers"
                                    :key="index"
                                    :lat-lng="marker"
                                    :icon="icon"
                                >
                                    <l-icon
                                        v-if="index === 0"
                                        :icon-url="firstIcon"
                                        :icon-size="iconSize"
                                    ></l-icon>
                                    <l-icon
                                        v-else-if="
                                            index !== line.markers.length - 1
                                        "
                                        :icon-url="middleIcon"
                                        :icon-size="iconSize"
                                    ></l-icon>
                                    <l-icon
                                        v-else
                                        :icon-url="lastIcon"
                                        :icon-size="iconSize"
                                    ></l-icon>

                                    <l-tooltip
                                        :options="{
                                            permanent: false,
                                            interactive: true
                                        }"
                                    >
                                        <div @click="innerClick">
                                            <div
                                                v-if="index === 0"
                                                class="tooltip-map first-point"
                                            >
                                                {{ marker.name }}
                                            </div>
                                            <div
                                                v-else-if="
                                                    index ===
                                                        line.markers.length - 1
                                                "
                                                class="tooltip-map first-point"
                                            >
                                                {{ marker.name }}
                                            </div>
                                            <div v-else class="tooltip-map">
                                                {{ marker.name }}
                                            </div>

                                            <p v-show="showParagraph">
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit.
                                                Quisque sed pretium nisl, ut
                                                sagittis sapien. Sed vel
                                                sollicitudin nisi. Donec finibus
                                                semper metus id malesuada.
                                            </p>
                                        </div>
                                    </l-tooltip>
                                </l-marker>
                            </l-map>

                            <l-map
                                v-if="showMiniMap && loadTimeMap"
                                :zoom="zoomMiniMap"
                                :center="center"
                                :options="mapOptions"
                                class="lines-map"
                                @update:center="centerUpdate"
                                @update:zoom="zoomUpdate"
                                style="z-index: 1;"
                            >
                                <l-tile-layer
                                    :url="url"
                                    :attribution="attribution"
                                />
                                <l-marker
                                    v-for="(marker, index) in line.markers"
                                    :key="index"
                                    :lat-lng="marker"
                                    :icon="icon"
                                >
                                    <l-icon
                                        v-if="index === 0"
                                        :icon-url="firstIcon"
                                        :icon-size="iconSize"
                                    ></l-icon>
                                    <l-icon
                                        v-else-if="
                                            index !== line.markers.length - 1
                                        "
                                        :icon-url="middleIcon"
                                        :icon-size="iconSize"
                                    ></l-icon>
                                    <l-icon
                                        v-else
                                        :icon-url="lastIcon"
                                        :icon-size="iconSize"
                                    ></l-icon>

                                    <l-tooltip
                                        :options="{
                                            permanent: false,
                                            interactive: true
                                        }"
                                    >
                                        <div>
                                            <div
                                                v-if="index === 0"
                                                class="tooltip-map first-point"
                                            >
                                                {{ marker.name }}
                                            </div>
                                            <div
                                                v-else-if="
                                                    index ===
                                                        line.markers.length - 1
                                                "
                                                class="tooltip-map first-point"
                                            >
                                                {{ marker.name }}
                                            </div>
                                            <div v-else class="tooltip-map">
                                                {{ marker.name }}
                                            </div>
                                        </div>
                                    </l-tooltip>
                                </l-marker>
                            </l-map>

                            <div v-if="showMiniMap" class="w-full p-2">
                                <div class="flex flex-col">
                                    <div
                                        class="flex items-center mb-5 font-bold uppercase justify-content-center text-blue-darkest"
                                    >
                                        Resumo da Rota
                                    </div>
                                    <div
                                        class="flex mb-5 text-xs justify-evenly"
                                    >
                                        <div class="flex flex-col">
                                            <span
                                                class="uppercase text-gray-dark"
                                            >
                                                Ponto de Origem
                                            </span>
                                            <span v-if="line.markers[0].city">{{
                                                line.markers[0].city.name
                                            }}</span>
                                            <span v-else>---</span>
                                        </div>
                                        <div class="flex flex-col">
                                            <span
                                                class="uppercase text-gray-dark"
                                            >
                                                Quantidade de pontos de Seção
                                            </span>
                                            <span>{{
                                                line.markers.length
                                            }}</span>
                                        </div>
                                        <div class="flex flex-col">
                                            <span
                                                class="uppercase text-gray-dark"
                                            >
                                                Ponto de Destino
                                            </span>
                                            <span
                                                v-if="
                                                    line.markers[
                                                        line.markers.length - 1
                                                    ].city
                                                "
                                            >
                                                {{
                                                    line.markers[
                                                        line.markers.length - 1
                                                    ].city.name
                                                }}</span
                                            >
                                            <span v-else>---</span>
                                        </div>
                                    </div>
                                    <div
                                        class="py-2 font-bold cursor-pointer btn btn-blue"
                                        @click="showM"
                                    >
                                        Exibir Mapa
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex flex-wrap justify-between mt-2 align-baseline border-b-2 border-blue-base"
                    >
                        <h1 class="pt-2 uppercase text-blue-base">
                            Horários da Linha
                        </h1>
                    </div>
                    <div
                        class="grid grid-flow-row gap-6 mt-5 text-xs md:grid-cols-2 lg:grid-cols-2 justify-content-between"
                    >
                        <div class="flex flex-col w-full items-center">
                            <label class="form-label">
                                Horários de partida
                            </label>
                            <div
                                class="flex flex-wrap"
                                style="max-height: 140px;overflow-x: auto;"
                            >
                                <div
                                    v-for="(schedule, index) in line.schedules"
                                    :key="index"
                                >
                                    <div
                                        v-if="schedule.tpdirecao == 1"
                                        class="flex flex-column mr-4 items-center"
                                        style="width: 100px;"
                                    >
                                        <div class="font-10">
                                            {{
                                                dateOfWeekend(
                                                    schedule.nudiasemana
                                                )
                                            }}
                                        </div>
                                        <div class="timer-clock-lines">
                                            {{ formatHour(schedule.hrpartida) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col w-full items-center">
                            <label class="form-label">
                                Horários de partida
                            </label>
                            <div
                                class="flex flex-wrap"
                                style="max-height: 140px;overflow-x: auto;"
                            >
                                <div
                                    v-for="(schedule, index) in line.schedules"
                                    :key="index"
                                >
                                    <div
                                        v-if="schedule.tpdirecao == 2"
                                        class="flex flex-column mr-4 items-center"
                                        style="width: 100px;"
                                    >
                                        <div class="font-10">
                                            {{
                                                dateOfWeekend(
                                                    schedule.nudiasemana
                                                )
                                            }}
                                        </div>
                                        <div class="timer-clock-lines">
                                            {{ formatHour(schedule.hrpartida) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="flex items-center justify-center py-2 mt-4 mb-2 form-submit md:w-auto"
                    >
                        <svg
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            class="w-4 h-4 mr-1"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M2 10a4 4 0 004 4h3v3a1 1 0 102 0v-3h3a4 4 0 000-8 4 4 0 00-8 0 4 4 0 00-4 4zm9 4H9V9.414l-1.293 1.293a1 1 0 01-1.414-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 9.414V14z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                        Validar e Salvar
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
