export default [
    {
        path: "/protocolo",
        name: "protocol.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-protocol" */ "./views/Index.vue"
            ),
        meta: {
            forAuth: true,
            module: "protocol"
        }
    },
    {
        path: "/protocolo/processo",
        name: "process.index",
        component: () =>
            import(
                /* webpackChunkName: "chunk-protocol" */ "./views/Process.vue"
            ),
        meta: {
            forAuth: true,
            module: "protocol"
        }
    },
    {
        path: "/protocolo/processo/:id",
        name: "process.show",
        component: () =>
            import(
                /* webpackChunkName: "chunk-protocol" */ "./views/ShowProcess.vue"
            ),
        meta: {
            forAuth: true,
            module: "protocol"
        }
    }
];
