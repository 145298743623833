var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-between w-full my-8 border-b-2 border-blue-base bg-white shadow-lg"},[_c('span',{staticClass:"mt-2 text-base uppercase text-blue-base"},[_vm._v(" Informações estudante ")]),_vm._t("default")],2),_c('div',{staticClass:"grid gap-5 grid-cols-1 md:grid-cols-12 px-3"},[_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" CNPJ ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.mask(_vm.beneficiarioEstudante.document, "##.###.###/####-##"))+" ")]:[_c('span',[_c('TheMask',{staticClass:"h-auto py-2 rounded form-input",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.document
                        },attrs:{"mask":['##.###.###/####-##'],"required":""},on:{"input":_vm.checkCPFValid},model:{value:(_vm.beneficiarioEstudanteForm.document),callback:function ($$v) {_vm.$set(_vm.beneficiarioEstudanteForm, "document", $$v)},expression:"beneficiarioEstudanteForm.document"}}),(_vm.beneficiarioEstudanteForm.hasErrors && !_vm.beneficiarioEstudanteForm.document
                        )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2),_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Nome da Instituição ")]),_c('span',[(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.nminstituicao)+" ")]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstudanteForm.nminstituicao),expression:"beneficiarioEstudanteForm.nminstituicao"}],staticClass:"h-auto py-2 rounded form-input",class:{
                        'bg-red-100 border-red-400':
                        _vm.beneficiarioEstudanteForm.hasErrors &&
                            !_vm.beneficiarioEstudanteForm.nminstituicao
                    },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioEstudanteForm.nminstituicao)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstudanteForm, "nminstituicao", $event.target.value)}}}),(_vm.beneficiarioEstudanteForm.hasErrors &&
                        !_vm.beneficiarioEstudanteForm.nminstituicao
                        )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()]],2)]),_c('div',{class:{ 'col-span-12': !_vm.readonly, 'col-span-3': _vm.readonly }},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Tipo de Curso ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.getTpCurso(_vm.beneficiarioEstudante.tpcurso))+" ")]:[_c('div',{staticClass:"flex"},_vm._l((_vm.tipoCursos),function(curso){return _c('div',{key:curso.value,staticClass:"flex-1 flex align-center"},[_c('span',{staticClass:"px-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstudanteForm.tpcurso),expression:"beneficiarioEstudanteForm.tpcurso"}],attrs:{"id":curso.name,"type":"radio"},domProps:{"value":curso.value,"checked":_vm._q(_vm.beneficiarioEstudanteForm.tpcurso,curso.value)},on:{"click":function($event){_vm.beneficiarioEstudanteForm.idcurso = null},"change":function($event){return _vm.$set(_vm.beneficiarioEstudanteForm, "tpcurso", curso.value)}}})]),_c('label',{staticClass:"text-sm form-label text-gray-dark",attrs:{"for":curso.name},on:{"click":function($event){_vm.beneficiarioEstudanteForm.idcurso = null}}},[_vm._v(" "+_vm._s(curso.name)+" ")])])}),0),(_vm.beneficiarioEstudanteForm.hasErrors && !_vm.beneficiarioEstudanteForm.tpcurso)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()]],2),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Ano de Inicío ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.nuseriecurso)+" ")]:[_c('span',[_c('TheMask',{staticClass:"h-auto py-2 rounded form-input",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.nuseriecurso
                        },attrs:{"mask":['####'],"required":""},model:{value:(_vm.beneficiarioEstudanteForm.nuseriecurso),callback:function ($$v) {_vm.$set(_vm.beneficiarioEstudanteForm, "nuseriecurso", $$v)},expression:"beneficiarioEstudanteForm.nuseriecurso"}}),(_vm.beneficiarioEstudanteForm.hasErrors &&
                        !_vm.beneficiarioEstudanteForm.nuseriecurso
                        )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2),(_vm.beneficiarioEstudanteForm.tpcurso > 2)?_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Curso ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.nmcurso)+" ")]:[_c('SearchSelect',{staticClass:"h-auto rounded form-select",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.curso
                        },attrs:{"selected":_vm.beneficiarioEstudanteForm.curso,"search-keys":['nome_curso'],"search-remote":true,"lazy-load":true,"option-value":$event => $event.nmcurso,"option-key":"idcurso","name":"idcurso","no-query-param":"","options-url":`/api/spl-navegantes/cursos/${_vm.beneficiarioEstudanteForm.tpcurso}/`,"field-class":"py-2","disabled":(_vm.beneficiarioEstudanteForm.tpcurso == 1) || (_vm.beneficiarioEstudanteForm.tpcurso == 2)},on:{"update:selected":function($event){return _vm.$set(_vm.beneficiarioEstudanteForm, "curso", $event)},"changed":_vm.cursoSelected}})],(_vm.beneficiarioEstudanteForm.hasErrors && !_vm.beneficiarioEstudanteForm.curso)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],2):_vm._e()]),_c('div',{staticClass:"grid gap-5 grid-cols-1 md:grid-cols-12 px-3"},[_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" CEP ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.cep ? _vm.mask(_vm.beneficiarioEstudante.cep, "##.###-##") : '')+" ")]:[_c('div',[_c('TheMask',{staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                            _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.cep
                        },attrs:{"mask":['##.###-###'],"required":""},on:{"input":_vm.onCepChangeInstituicao},model:{value:(_vm.beneficiarioEstudanteForm.cep),callback:function ($$v) {_vm.$set(_vm.beneficiarioEstudanteForm, "cep", $$v)},expression:"beneficiarioEstudanteForm.cep"}}),(
                            _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.cep
                        )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2),_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Endereço ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.street)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstudanteForm.street),expression:"beneficiarioEstudanteForm.street"}],staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.street
                        },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioEstudanteForm.street)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstudanteForm, "street", $event.target.value)}}}),(
                            _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.street
                        )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2),_c('div',{staticClass:"col-span-1"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Número ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.number)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstudanteForm.number),expression:"beneficiarioEstudanteForm.number"}],staticClass:"h-auto py-2 rounded form-input mb-0",attrs:{"type":"number"},domProps:{"value":(_vm.beneficiarioEstudanteForm.number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstudanteForm, "number", $event.target.value)}}})])]],2),_c('div',{staticClass:"col-span-2"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Bairro ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.district)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstudanteForm.district),expression:"beneficiarioEstudanteForm.district"}],staticClass:"h-auto py-2 rounded form-input mb-0",class:{
                            'bg-red-100 border-red-400':
                                _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.district
                        },attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioEstudanteForm.district)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstudanteForm, "district", $event.target.value)}}}),(
                            _vm.beneficiarioEstudanteForm.hasErrors &&
                                !_vm.beneficiarioEstudanteForm.district
                        )?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()])]],2),_c('div',{staticClass:"col-span-3"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Complemento ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.complement)+" ")]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiarioEstudanteForm.complement),expression:"beneficiarioEstudanteForm.complement"}],staticClass:"h-auto py-2 rounded form-input mb-0",attrs:{"type":"text"},domProps:{"value":(_vm.beneficiarioEstudanteForm.complement)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beneficiarioEstudanteForm, "complement", $event.target.value)}}})])]],2),_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"text-sm form-label text-gray-dark"},[_vm._v(" Cidade/estado ")]),(_vm.readonly)?[_vm._v(" "+_vm._s(_vm.beneficiarioEstudante.municipio ? _vm.beneficiarioEstudante.municipio.name + " / " + _vm.beneficiarioEstudante.municipio.state .name : '')+" ")]:[_c('div',[_c('search-select',{attrs:{"selected":_vm.beneficiarioEstudanteForm.municipio,"search-keys":['name'],"search-remote":true,"lazy-load":true,"options-url":"/api/registry/location?take=50&paginate=false","option-key":"id","option-value":city =>
                                city.name +
                                ' / ' +
                                city.state.initials,"field-class":"py-2","name":"city","placeholder":"Selecione uma cidade..."},on:{"update:selected":function($event){return _vm.$set(_vm.beneficiarioEstudanteForm, "municipio", $event)},"changed":_vm.cityInstituicaoSelected}}),(_vm.beneficiarioEstudanteForm.hasErrors &&
                        !_vm.beneficiarioEstudanteForm.municipio)?_c('div',{staticClass:"alert alert-danger text-red-500 text-xs mr-2 font-bold"},[_vm._v(" Campo obrigatório ")]):_vm._e()],1)]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }