<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import Ticket from "@/domains/support/partials/Ticket";

export default {
    components: {
        Breadcrumbs,
        Ticket
    },
    props: {
        id: { required: true }
    },
    data() {
        return {
            title: "Chamado",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Suporte", path: "support.index" },
                { name: "Chamados", path: "support.ticket" },
                { name: "Detalhes", path: "ticket.show" }
            ]
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>

<template>
    <div class="w-full bg-gray-light">
        <div class="px-2 my-4 sm:px-6">
            <Breadcrumbs class="mb-8" :crumbs="crumbs" />
            <Ticket :id="id" />
        </div>
    </div>
</template>
