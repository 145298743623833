<script>
import { Component, Prop } from "vue-property-decorator";
import { TheMask } from "vue-the-mask";
import RegistryService from "@/core/services/RegistryService";
import Icon from "@/core/components/Icon";

@Component({
    components: {
        Icon,
        TheMask
    }
})
export default class Contacts extends RegistryService {
    @Prop()
    user;

    @Prop({ default: false })
    editable;

    @Prop({ default: false })
    support;

    constructor() {
        super();
        this.errors = [];
        this.isEdit = false;
        this.classHeader = "shadow bg-white";
        this.classShadow = "shadow bg-white shadow-card p-2 bg-white";
        this.phones = [{ contact: "", description: "" }];
        this.emails = [{ contact: "", description: "" }];
    }

    mounted() {
        if (this.user.phones && this.user.phones.length > 0) {
            this.phones = JSON.parse(JSON.stringify(this.user.phones));
        }
        if (this.user.emails && this.user.emails.length > 0) {
            this.emails = JSON.parse(JSON.stringify(this.user.emails));
        }
    }

    addPhone() {
        this.phones.push({ contact: "", description: "" });
    }

    removePhone(index) {
        if (index > -1 && this.phones.length > 1) {
            this.phones.splice(index, 1);
        }
    }

    addEmail() {
        this.emails.push({ contact: "", description: "" });
    }

    removeEmail(index) {
        if (index > -1 && this.emails.length > 1) {
            this.emails.splice(index, 1);
        }
    }

    async save() {
        this.$Progress.start();
        this.errors = [];
        let data = {
            phones: this.phones,
            emails: this.emails
        };

        let info = await this.putRegistryUserContact(
            this.user.id,
            data,
            this.support
        );

        if (info.success) {
            Promise.resolve(this.refreshStoreContacts());
            this.isEdit = false;
        }

        if (info.errors) this.errors = info.errors;
    }

    async refreshStoreContacts() {
        const response = await this.$http.get(
            "/api/registry/user/" + this.user.id + "/contact"
        );
        if (this.$store.state.auth.user.document === this.user.document) {
            this.user.phones = response.data.phones;
            this.user.emails = response.data.emails;
            this.$store.commit("setPhones", response.data.phones);
            this.$store.commit("setEmails", response.data.emails);
        }
    }
}
</script>

<template>
    <div class="w-full bg-gray-light">
        <div :class="'mx-2 my-4 modal-edit' + (isEdit ? ' edit-shadow' : '')">
            <div class="flex flex-wrap justify-between align-baseline mb-1">
                <h1 class="text-blue-base uppercase">Contatos</h1>
                <div
                    v-if="!this.$store.getters.isOrganization"
                    class="form-new-blue"
                    @click="() => (isEdit = !isEdit)"
                >
                    <div>{{ isEdit ? "Fechar" : "Editar" }}</div>
                </div>
            </div>
            <div class="border-t-2 border-blue-base pt-8">
                <div class="flex">
                    <div
                        v-if="isEdit"
                        class="w-full lg:w-1/2 xl:w-2/4 xl:max-w-screen-md bg-yellow-base border border-gray-dark p-5 mb-4 sm:mx-4"
                    >
                        <p v-if="editable" class="text-xs mb-2">
                            Seu cadastro deve conter pelo menos um telefone e um
                            e-mail
                            <span class="font-bold"> válido </span>
                            . Para editar o contato primário, acrescente um novo
                            contato.
                        </p>
                        <p v-else class="text-xs mb-2">
                            Para editar essas informações, faça login com seu
                            CPF possuindo perfil de gestor.
                        </p>
                    </div>
                </div>
                <div class="flex flex-wrap py-5 text-xs">
                    <div class="w-full md:w-1/2 flex flex-column">
                        <h3
                            class="items-start mb-4 px-2 bg-gray-base uppercase"
                        >
                            Telefones
                        </h3>
                        <div
                            v-for="(phone, index) in phones"
                            :key="phone.id"
                            class="w-full px-8 sm:px-10 md:px-0"
                        >
                            <div class="w-full well">
                                <div class="flex flex-col items-end">
                                    <div class="">
                                        <a
                                            v-if="
                                                editable &&
                                                    phones.length > 1 &&
                                                    isEdit
                                            "
                                            class=""
                                            title="Remover telefone"
                                            @click="() => removePhone(index)"
                                        >
                                            <Icon
                                                icon="close"
                                                class="h-5 fill-current text-red-base focus:outline-none rounded-full bg-white shadow hover:bg-red-base hover:text-white active:bg-red-800"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="w-1/2 lg:w-48">
                                        <div class="flex flex-col mr-1">
                                            <span class="form-label">
                                                Telefone
                                            </span>
                                            <TheMask
                                                v-if="isEdit"
                                                :disabled="
                                                    !editable ||
                                                        (phones.length < 2 &&
                                                            phone.hasOwnProperty(
                                                                'id'
                                                            ))
                                                "
                                                class="form-input rounded py-2 h-auto"
                                                v-model="phone.contact"
                                                :mask="[
                                                    '(##) ####-####',
                                                    '(##) #####-####'
                                                ]"
                                            />
                                            <span v-else>{{
                                                phone.contact
                                            }}</span>
                                            <span
                                                v-for="error in errors[
                                                    `phones.${index}.contact`
                                                ]"
                                                v-bind:key="error"
                                                class="text-red-500 text-xs font-bold pb-1"
                                            >
                                                {{ error }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="w-1/2">
                                        <div class="flex flex-col">
                                            <span class="form-label">
                                                Observação
                                            </span>
                                            <div v-if="isEdit">
                                                <input
                                                    :disabled="!editable"
                                                    class="form-input rounded py-2 h-auto"
                                                    v-model="phone.description"
                                                />
                                                <span
                                                    v-for="error in errors[
                                                        `phones.${index}.description`
                                                    ]"
                                                    v-bind:key="error"
                                                    class="text-red-500 text-xs font-bold pb-1"
                                                >
                                                    {{ error }}
                                                </span>
                                            </div>
                                            <span v-else>{{
                                                phone.description
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="editable && isEdit">
                            <button
                                class="form-green py-1 w-1/2 sm:w-40 mb-6"
                                @click="addPhone"
                            >
                                Novo telefone
                            </button>
                        </div>
                    </div>
                    <div
                        class="w-full md:w-1/2 lg:1/4 flex flex-column mt-8 md:mt-0"
                    >
                        <h3
                            class="items-start mb-4 bg-gray-base px-1 uppercase"
                        >
                            E-mails
                        </h3>
                        <div
                            v-for="(email, index) in emails"
                            :key="email.id"
                            class="w-full px-8 sm:px-10 md:px-0"
                        >
                            <div class="w-full">
                                <div class="flex flex-col items-end">
                                    <div>
                                        <a
                                            v-if="
                                                editable &&
                                                    emails.length > 1 &&
                                                    isEdit
                                            "
                                            class=""
                                            title="Remover e-mail"
                                            @click="() => removeEmail(index)"
                                        >
                                            <Icon
                                                icon="close"
                                                class="h-5 fill-current text-red-base focus:outline-none rounded-full bg-white shadow hover:bg-red-base hover:text-white active:bg-red-800"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="w-1/2">
                                        <div class="flex flex-col mr-1">
                                            <span class="form-label">
                                                E-mail
                                            </span>
                                            <input
                                                v-if="isEdit"
                                                :disabled="
                                                    !editable ||
                                                        (emails.length < 2 &&
                                                            email.hasOwnProperty(
                                                                'id'
                                                            ))
                                                "
                                                class="form-input rounded py-2 h-auto"
                                                v-model="email.contact"
                                            />
                                            <span v-else>{{
                                                email.contact
                                            }}</span>
                                            <span
                                                v-for="error in errors[
                                                    `emails.${index}.contact`
                                                ]"
                                                v-bind:key="error"
                                                class="text-red-500 text-xs font-bold pb-1"
                                            >
                                                {{ error }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="w-1/2">
                                        <div class="flex flex-col">
                                            <span class="form-label">
                                                Observação
                                            </span>
                                            <input
                                                v-if="isEdit"
                                                :disabled="!editable"
                                                class="form-input rounded py-2 h-auto"
                                                v-model="email.description"
                                            />
                                            <span v-else>{{
                                                email.description
                                            }}</span>
                                            <span
                                                v-for="error in errors[
                                                    `emails.${index}.description`
                                                ]"
                                                v-bind:key="error"
                                                class="text-red-500 text-xs font-bold pb-1"
                                            >
                                                {{ error }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="editable && isEdit">
                            <button
                                class="form-green py-1 focus:outline-none w-1/2 sm:w-40 mb-6"
                                @click="addEmail"
                            >
                                Novo e-mail
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="editable && isEdit"
                        class="flex justify-center sm:justify-start mt-8 px-2 w-full ml-2"
                    >
                        <button
                            class="form-submit w-full sm:w-auto"
                            @click="save"
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
