export default [
    {
        path: "/indicadores",
        name: "metrics.index",
        component: () =>
            import(/* webpackChunkName: "chunk-metrics" */ "./views/Index.vue"),
        meta: {
            forAuth: true,
            hideSidebar: true
        }
    }
];
