<script>
import { Component, Prop } from "vue-property-decorator";
import Modal from "@/core/components/Modal";
import SearchSelect from "@/core/components/deprecated/SearchSelect";
import Select from "@/core/components/Select";
import ReassignService from "@/domains/support/services/ReassignService";

@Component({
    components: {
        Modal,
        Select,
        "ajax-select": SearchSelect
    }
})
export default class Reassign extends ReassignService {
    @Prop()
    reassign_ticket;

    constructor() {
        super();
        this.categories = [];
        this.priorities = [];
        this.agents = [];
        this.ticket_priority_id = null;
        this.ticket_category_id = null;
        this.ticket_agent_id = null;
    }

    beforeMount() {
        this.fetchCategories();
        this.fetchPriorities();
        this.fetchAgents();

        this.ticket_priority_id = this.reassign_ticket.priority
            ? this.reassign_ticket.priority.id
            : null;
        this.ticket_category_id = this.reassign_ticket.category
            ? this.reassign_ticket.category.id
            : null;
        this.ticket_agent_id = this.reassign_ticket.agent
            ? this.reassign_ticket.agent.id
            : null;
    }

    async fetchCategories() {
        let info = await this.getCategories();
        this.categories = info["response"]["data"];
    }

    async fetchPriorities() {
        let info = await this.getPriorities();
        this.priorities = info["response"]["data"];
    }

    async fetchAgents() {
        let info = await this.getAgents();
        this.agents = info["response"]["data"];
    }

    onSelectPriority(priority) {
        this.ticket_priority_id = priority;
    }

    onSelectCategory(category) {
        this.ticket_category_id = category;
    }

    onSelectAgent(agent) {
        this.ticket_agent_id = agent;
    }

    get authorizedAgents() {
        return this.agents.filter(agent => {
            return agent.categories.find(
                category => category.id === this.ticket_category_id
            );
        });
    }

    async onFormSubmit() {
        this.$Progress.start();
        const data = {
            priority_id: this.ticket_priority_id,
            category_id: this.ticket_category_id,
            agent_id: this.ticket_agent_id
        };
        try {
            const response = await this.$axios.patch(
                "/api/support/ticket/reassign/" + this.reassign_ticket.id,
                data
            );
            this.$Progress.finish();
            this.$toast.success({
                title: "OK",
                message: response.data.success
            });
            this.$emit("close");
        } catch (e) {
            this.$Progress.finish();
            if (e.response.status === 422) {
                this.errors = e.response.data.errors;
            } else {
                this.$toast.error({
                    title: "",
                    message: e.response.data.error,
                    icon: "fa fa-exclamation"
                });
            }
        }
    }
}
</script>
<template>
    <Modal width="700" borderLeft="4px solid #B8C2CC" @close="$emit('close')">
        <div slot="header" class="text-center">
            <h2 class="text-muted">Atenção!</h2>
        </div>
        <div slot="body">
            <form v-on:submit.prevent="onFormSubmit()">
                <div class="form-group">
                    <label class="control-label">Prioridade:</label>
                    <Select
                        id="priority"
                        class="w-full mb-3"
                        :options="priorities"
                        :selected="ticket_priority_id"
                        @changed="onSelectPriority"
                    />

                    <div v-if="errors && errors.priority_id">
                        <span
                            v-for="error in errors.priority_id"
                            v-bind:key="error"
                            class="help-block alert alert-danger"
                            >{{ error }}</span
                        >
                    </div>
                </div>
                <div class="flex justify-content-between form-group">
                    <div>
                        <label class="control-label">Categoria:</label>
                        <Select
                            id="categories"
                            class="w-full mb-3"
                            :options="categories"
                            :selected="ticket_category_id"
                            @changed="onSelectCategory"
                        />

                        <div v-if="errors && errors.category_id">
                            <span
                                v-for="error in errors.category_id"
                                v-bind:key="error"
                                class="help-block alert alert-danger"
                                >{{ error }}</span
                            >
                        </div>
                    </div>
                    <div class="w-1/2">
                        <label class="control-label">Agente:</label>
                        <Select
                            id="agents"
                            class="w-full mb-3"
                            :options="authorizedAgents"
                            :selected="ticket_agent_id"
                            @changed="onSelectAgent"
                        />
                        <div v-if="errors && errors.agent_id">
                            <span
                                v-for="error in errors.agent_id"
                                v-bind:key="error"
                                class="help-block alert alert-danger"
                                >{{ error }}</span
                            >
                        </div>
                    </div>
                </div>
            </form>
            <div
                class="w-full bg-yellow-base border border-gray-dark p-5 mb-6 text-sm flex justify-content-center"
                role="alert"
            >
                Esta ação irá reatribuir o chamado e colocá-lo como "Pendente".
            </div>
        </div>
        <div slot="footer">
            <div class="flex justify-content-center">
                <button
                    v-on:click="onFormSubmit"
                    class="btn btn-primary disable-onload"
                    type="submit"
                >
                    Confirmar
                </button>
                <button
                    class="btn btn-outline-secondary close-modal"
                    @click="$emit('close')"
                >
                    Cancelar
                </button>
            </div>
        </div>
    </Modal>
</template>
<style lang="scss" scoped>
.alert {
    margin-top: 5rem;
}
</style>
