<template>
    <div class="w-full bg-gray-light">
        <div class="my-4 px-2 sm:px-6">
            <Breadcrumbs class="mb-8" :crumbs="crumbs" />
            <CreateTicketForm />
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/core/components/Breadcrumbs";
import CreateTicketForm from "@/domains/support/components/CreateTicketForm";

export default {
    components: {
        Breadcrumbs,
        CreateTicketForm
    },
    data() {
        return {
            title: "Novo Chamado",
            crumbs: [
                { name: "Início", path: "index" },
                { name: "Suporte", path: "support.index" },
                { name: "Novo Chamado", path: "ticket.create" }
            ]
        };
    },
    mounted() {
        document.title = this.title + " - " + process.env.VUE_APP_TITLE;
    }
};
</script>
